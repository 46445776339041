import React, { useState } from "react";
import Table from "components/shared/Table";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { columnsInvoices } from "../finance-customer.util";
import Pagination from "components/shared/Pagination";

type IPagination = {
  total: number;
  onChange: any;
  current: number;
};

export interface IPropsPastInvoices {
  listPastInvoices?: any;
}

const PastInvoices: React.FC<IPropsPastInvoices> = ({ listPastInvoices }) => {
  const { customerId }: any = useParams<Record<string, string | undefined>>();
  const history = useHistory();
  const [current, setCurrent] = useState<number>(1);
  const pageSize: number = 5; // set item pages

  const handleClickInvoice = (record: any) => {
    history.push(
      `/finance-payment/payments-by-customers-details-inv/${customerId}/${record.key}`
    );
  };

  // paging
  const getData = (current: number, pageSize: number) => {
    return listPastInvoices.slice((current - 1) * pageSize, current * pageSize);
  };

  const MyPagination = ({ total, onChange, current }: IPagination) => {
    return (
      <Pagination
        onChange={onChange}
        total={total}
        current={current}
        pageSize={pageSize}
      />
    );
  };

  return (
    <>
      <Table
        title={() => "Past Invoices"}
        dataSource={getData(current, pageSize)}
        columns={columnsInvoices}
        pagination={false}
        className="table-invoices"
        onRow={(record, rowIndex) => {
          return {
            onClick: () => handleClickInvoice(record),
          };
        }}
      />
      <MyPagination
        total={listPastInvoices.length}
        current={current}
        onChange={setCurrent}
      />
    </>
  );
};

export default PastInvoices;
