import React, { useEffect, useState } from "react";
import ForgotPasswordWrapper from "./styled";
import Flex, { FlexItem } from "components/shared/Flex";
import Button from "components/shared/Button";
import Input from "components/shared/Input";
import Logo from "assets/images/ddl-logo.png";
import { ArrowBackIcon, PersonSharpIcon } from "components/shared/Icons";
import { useHistory } from "react-router";
import { Form } from "antd";
import userService from "services/user.service";

const ForgetPassword: React.FC = (props) => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const _handleSubmitFrom = async ({ email }: any) => {
    setLoading(true);
    const isSuccess = await userService.forgetPassword(email);
    if (isSuccess) {
      history.push("/reset-password-success");
    }
    setLoading(false);
  };

  function onKeypress(e: any) {
    if (e.key === "Enter") {
      form.submit();
    }
  }

  useEffect(() => {
    window.addEventListener("keypress", onKeypress);
    return () => {
      window.removeEventListener("keypress", onKeypress);
    };
  });

  return (
    <ForgotPasswordWrapper>
      <ForgotPasswordWrapper.Back
        onClick={() => {
          history.push("/login");
        }}
      >
        <ArrowBackIcon height="34px" width="34px" />
        <span>Back to login</span>
      </ForgotPasswordWrapper.Back>
      <Flex
        css={`
          flex: 1;
        `}
        justifyCenter
        nowrap
      >
        <ForgotPasswordWrapper.Heading>
          <ForgotPasswordWrapper.Image src={Logo} alt="logo-ddlunch" />
          <div className="title-login">Forget Password</div>
          <ForgotPasswordWrapper.Description>
            Enter your corporate unique ID and your password will be sent via
            your email. Do remember to change your password once login.
          </ForgotPasswordWrapper.Description>
        </ForgotPasswordWrapper.Heading>
      </Flex>
      <Flex
        css={`
          flex: 1;
        `}
        justifyCenter
        nowrap
      >
        <FlexItem>
          {loading && (
            <ForgotPasswordWrapper.Description>
              Resetting...
            </ForgotPasswordWrapper.Description>
          )}
          <Form name="basic" onFinish={_handleSubmitFrom} form={form}>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "Enter your corporate email",
                },
              ]}
            >
              <Input
                type="text"
                name="email"
                prefix={<PersonSharpIcon />}
                placeholder="Enter your corporate email"
                className="customer-input"
              />
            </Form.Item>
            <Form.Item style={{ marginTop: 10 }}>
              <Button type="info" htmlType="submit" className="customer-btn mb">
                Reset
              </Button>
            </Form.Item>
          </Form>
        </FlexItem>
      </Flex>
    </ForgotPasswordWrapper>
  );
};

export default ForgetPassword;
