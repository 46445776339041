import React from 'react';
import { Select } from "antd";
import { durationKeys } from "utils/common.util";
const { Option } = Select;

interface IDuration {
  duration: string;
  onChangeDuration: (durationKey: string) => void;
  suffixIcon?: any;
}

const Duration: React.FC<IDuration> = ({
  duration,
  onChangeDuration,
  suffixIcon,
  ...rest
}) => {
  return (
    <Select
        defaultValue={duration}
        onChange={onChangeDuration}
        suffixIcon={suffixIcon}
      >
        <Option value={durationKeys["7days"]}>Last 7 days</Option>
        <Option value={durationKeys["14days"]}>Last 14 days</Option>
        <Option value={durationKeys["30days"]}>Last 30 days</Option>
      </Select>
  );
}

export default Duration;
