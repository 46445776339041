import React, { useEffect } from "react";
import LoginWrapper from "./styled";
import Flex, { FlexItem } from "components/shared/Flex";
import Button from "components/shared/Button";
import Input from "components/shared/Input";
import Select from "components/shared/Select";
import Logo from "assets/images/ddl-logo.png";
import { PersonSharpIcon, LockClosedSharpIcon } from "components/shared/Icons";
import userService from "services/user.service";
import { useHistory } from "react-router";
import { Form } from "antd";

const LoginUI: React.FC = (props) => {
  const history = useHistory();
  const [form] = Form.useForm();

  const _handleSubmitFrom = async (values: any) => {
    const user = await userService.login(values);
    if (user) {
      switch (user.roles[0].id) {
        case "admin":
          history.push("/dashboard");
          break;
        case "corporate":
          history.push(`/manage-customers/customer-details/${user.userId}`);
          // history.push(`/dashboard`);
          break;
        case "cloud-kitchen":
          history.push(`/manage-orders/new`);
          break;
        default:
          break;
      }
    }
  };

  function onKeypress(e: any) {
    if (e.key === "Enter") {
      form.submit();
    }
  }

  useEffect(() => {
    window.addEventListener("keypress", onKeypress);
    return () => {
      window.removeEventListener("keypress", onKeypress);
    };
  });

  const _handleForgetPassword = () => {
    history.push("/forget-password");
  };

  return (
    <LoginWrapper>
      <Flex
        css={`
          flex: 1;
        `}
        justifyCenter
        nowrap
      >
        <FlexItem>
          <LoginWrapper.Image src={Logo} alt="logo-ddlunch" />
          <div className="title-login">Log In</div>
        </FlexItem>
      </Flex>
      <Flex
        css={`
          flex: 1;
        `}
        justifyCenter
        nowrap
      >
        <FlexItem>
          <Form name="basic" onFinish={_handleSubmitFrom} form={form}>
            <Form.Item
              name="role"
              rules={[{ required: true, message: "Please select your role!" }]}
            >
              <Select
                options={[
                  { label: "Administrator", value: "admin" },
                  { label: "Cloud Kitchen", value: "cloud-kitchen" },
                  { label: "Corporate", value: "corporate" },
                ]}
                placeholder="Select role"
              />
            </Form.Item>
            <Form.Item
              name="email"
              rules={[{ required: true, message: "Please input your email!" }]}
            >
              <Input
                type="text"
                name="email"
                prefix={<PersonSharpIcon />}
                placeholder="Enter your user ID"
                className="customer-input"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
            >
              <Input
                type="password"
                name="password"
                prefix={<LockClosedSharpIcon />}
                placeholder="Enter your account password"
                className="customer-input"
              />
            </Form.Item>
            <Form.Item>
              <Button type="info" htmlType="submit" className="customer-btn mb">
                Log in
              </Button>
            </Form.Item>
            <LoginWrapper.ForgetPassword onClick={_handleForgetPassword}>
              Forget Password
            </LoginWrapper.ForgetPassword>
          </Form>
        </FlexItem>
      </Flex>
    </LoginWrapper>
  );
};

export default LoginUI;
