import styled from "styled-components";

const StyleRenderImgFood = styled.div`
  & {
    .custome-img-food {
      border: 2px solid ${props => props.theme.global.backColor};
      width: 69px;
      height: 69px;
      object-fit: cover;
    }
  }
`;

export default StyleRenderImgFood;
