import 'antd/dist/antd.css'
import './App.scss'
import React from 'react'
import { Router } from 'react-router-dom'
import { useAppRoutesContext } from './AppRoutesContext'
import ReactHookOutsideWrapper from 'wrappers/HookOutsideWrapper'
import { setupHooksOutside } from 'utils/hooks'
import renderRoutes from './renderRoutes'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import history from './utils/history';


setupHooksOutside()

const App: React.FC = () => {
  const { routes } = useAppRoutesContext()
  return (
    <div className="App">
      <ReactHookOutsideWrapper />
      <Router history={history}>{renderRoutes({ routes })}</Router>
      <ToastContainer />
    </div>
  )
}

export default App
