import styled from 'styled-components'
import InputSearch from './InputSearch'
import { getCss } from 'utils/cssHelpers';

const StyledInputSearch = styled(InputSearch)`
    height: 32px;
    border-radius: 20px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    display: flex;
    align-items: end;
    ${getCss('background-color')('theme.global.whiteColor')};
    border: 0;
    .ant-input-prefix > span {
        width: auto;
        height: auto;
        svg {
            width: 22px;
            height: 22px;
            vertical-align: middle;
        }
    }
    input.ant-input {
        &::placeholder {
            ${getCss('color')('theme.global.grayColor')};
            font-size: 14px;
            font-style: italic;
        }
    }
`

export default StyledInputSearch