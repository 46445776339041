import { orderStatus as STATUS } from "pages/manage-orders/manage-orders.config";
import Tag from "components/shared/Tag";

const columns = [
  {
    title: "Customer Name",
    dataIndex: "cloudKitchen",
    key: "cloudKitchen",
  },
  {
    title: "For The Month",
    dataIndex: "month",
    key: "month",
  },
  {
    title: "INV",
    dataIndex: "inv",
    key: "inv",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render(value: string) {
      return (
        <Tag
          type={
            value === STATUS.PAID || value === STATUS.COMPLETED
              ? "primary"
              : "danger"
          }
        >
          {value}
        </Tag>
      );
    },
  },
];

export { columns };
