import React, { MouseEvent, useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import Button from 'components/shared/Button';
import Modal from 'components/shared/Modal';
import InputDatePicker from 'components/shared/InputDatePicker';
import Flex, { FlexItem } from 'components/shared/Flex';
import { ArrowForwardCircleIcon, ArrowDownCircleSharpIcon } from 'components/shared/Icons';
import { WeeklMenuWrapper, ModalDuplicateMenuStyled } from './styled';
import moment from 'moment';
import menuService from 'services/menu.service';
import { Toastify } from 'components/shared/Toast/Toast';
import { Calendar } from 'react-multi-date-picker';

type IPropsWeeklyMenu = {
  dateFrom: string
  items: any
}

const WeeklMenuUI: React.FC = () => {
  const history = useHistory();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [weeklyMenu, setWeeklyMenu] = useState<Array<any>>([]);
  const [dataWeekly, setDataWeekly] = useState<Array<any>>([]);
  const [fromDate, setFromDate] = useState<string>("");
  const [toDate, setToDate] = useState<any>(null);
  const [duplicate, setDuplicate] = useState<any>();
  const [noOfElement, setNoOfElement] = useState<number>(1);
  const slice = weeklyMenu.slice(0, noOfElement);

  const viewNextDay = () => {setNoOfElement(noOfElement + 1)}

  const getWeeklyMenu = () => {
    menuService.getWeeklyMenu().then((weeklyMenu) => {
      setDataWeekly(weeklyMenu);
      const result = weeklyMenu.map((row: IPropsWeeklyMenu, index: number) => ({
        key: index,
        time: row.dateFrom,
        item: row.items.map((item: any) => {
          return {
            date: item.day,
            duplicateMenu: true,
          }
        })
      }));
      setWeeklyMenu(result);
    })
  }

  useEffect(() => {
    getWeeklyMenu();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDuplicateMenu = (e: MouseEvent, date: any) => {
    e.stopPropagation();
    const month = slice.map((item, i) => item.time).slice(-1);
    const found = month.find((e) => e !== month);

    const dateDiffOfWeek: any = {
      "monday": 1,
      "tuesday": 2,
      "wednesday": 3,
      "thursday": 4,
      "friday": 5,
      "saturday": 6,
      "sunday": 7,
    }

    const diff = new Date(found);
    const day = new Date(diff.setDate(diff.getDate() - diff.getDay() + dateDiffOfWeek[date]));

    setDuplicate(day?.toISOString())
    setModalVisible(true);
  }

  const changeDatePicker = (date: any) => {
    const result = date?.toISOString();
    setFromDate(result);
  }

  const changeRangeOfDate = (date: any) => {
    const dateStart = date?.[0] ? new Date(date?.[0])?.toISOString() : "";
    const dateEnd = date?.[1] ? new Date(date?.[1])?.toISOString() : "";
    if (dateEnd === "") {
      Toastify.showDangerToast('select date end !');
    } else {
     setToDate([dateStart, dateEnd]);
    }
  }

  const saveDuplicateMenu = () => {
    const body = {
      fromDate: fromDate || duplicate,
      toDate: toDate,
    }
    menuService.duplicateMenu(body)
    .then(() => {
      Toastify.showSuccessToast('Duplicate menu success !');
      setModalVisible(false);
      getWeeklyMenu();
    })
    .catch((error) => {
      Toastify.showDangerToast(error?.response?.data?.messages?.[0]);
    })
  }

  const handleClickItemDate = (date: any) => {
    history.push({
      pathname: `/manage-menu/weekly-details/${date}`,
      state: {
        days: date,
        data: dataWeekly,
      }
    });
  };

  return (
    <WeeklMenuWrapper>
      <WeeklMenuWrapper.Title css={`font-size: 20px`}> Weekly Menu </WeeklMenuWrapper.Title>

      {slice.map((item: any, i: number) =>
        <WeeklMenuWrapper.WDate key={i}>
          <WeeklMenuWrapper.Title>{moment(item.time).format("DD-MM-YYYY")}</WeeklMenuWrapper.Title>
          {item.item.reduce((arr: any[], item: any) => {
            // Remove duplication
            if (arr.find(it => it.date === item.date)) return arr;
            return [...arr, item];
          }, []).map((date: any, j: number) =>
            <WeeklMenuWrapper.ItemDate key={j} onClick={() => handleClickItemDate(date.date)}>
              <Flex justifyBetween>
                <FlexItem css={`width: 148px`}>
                  {date.duplicateMenu
                    ? <Button type="info" onClick={e => handleDuplicateMenu(e, date.date)}>Duplicate Menu</Button>
                    : null
                  }
                </FlexItem>
                <WeeklMenuWrapper.Date>{date.date}</WeeklMenuWrapper.Date>
                <Flex>
                  <Button type={date.duplicateMenu ? "yellow" : 'disabled'}>Menu added</Button>
                  <ArrowForwardCircleIcon width="37px" height="37px" className="svg-details-menu"/>
                </Flex>
              </Flex>
            </WeeklMenuWrapper.ItemDate>
          )}
        </WeeklMenuWrapper.WDate>
      )}
      {dataWeekly.length === slice.length
        ? "" 
        : (
            <Flex alignCenter justifyCenter className="view-next-7-days" onClick={viewNextDay}>
              <span css={`margin-right: 10px`}>View Next 7 Days</span>
              <ArrowDownCircleSharpIcon />
            </Flex>
          )
      }

      {/* modal Duplicate Menu*/}
      <Modal
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
      >
        <ModalDuplicateMenuStyled>
          <Flex column justifyBetween css={`margin-bottom: 14px;`}>
            <div className="lable-item">Duplicate This Menu</div>
            <InputDatePicker
              placeholder={moment(duplicate).format("DD-MM-YYYY")}
              format="DD-MM-YYYY"
              css={`margin: auto`}
              className='input-date-sample'
              onChange={(date) => changeDatePicker(date)}
            />
          </Flex>

          <div className="lable-item">Select Range Of Dates To Duplicate</div>
          <WeeklMenuWrapper.StyledRangeOfDates>
            <Calendar
              value={toDate}
              showOtherDays
              range
              onChange={(date: any) => changeRangeOfDate(date)}
            />
          </WeeklMenuWrapper.StyledRangeOfDates>
          <Button type="info" className='btn-save-duplicate' onClick={saveDuplicateMenu}>Save</Button>
        </ModalDuplicateMenuStyled>
      </Modal>
    </WeeklMenuWrapper>
  )
}

export default WeeklMenuUI;
