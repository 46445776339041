import styled from "styled-components";

const OverviewReportStyled = styled.div`
  .ant-select-selector {
    color: white;
    background-color: ${(props) => props.theme.global.primaryColor} !important;
    border: 1px solid ${(props) => props.theme.global.primaryColor} !important;
  }
  .ant-select-arrow {
    top: 35%;
  }
`;

export default OverviewReportStyled;
