import React, { useState, useEffect } from "react";
import { ProcessingOrdersWrapper } from "./styled";
import { ArrowForwardIcon } from "components/shared/Icons";
import Table from "components/shared/Table";
import DetailsPurchaseOrders from "./DetailsPurchaseOrder";
import orderService from "services/order.service";
import map from "lodash/map";
import { purchaseColumns } from "../manage-orders.config";

const PurchaseOrder: React.FC = () => {
  const [isDetails, setIsDetails] = useState<boolean>(false);
  const [orders, setOrders] = useState<any[]>([]);
  const [order, setOrder] = useState<object>({});

  const handleClickView = (order: any) => {
    setIsDetails(true);
    setOrder(order);
  };

  const getPurchaseOrders = () => {
    orderService
      .getPurchaseOrders()
      .then((res) => {
        const newOrders = map(res, (item) => ({
          ...item,
          key: item.orderId,
          detail: <ArrowForwardIcon type="danger" />,
        }));

        setOrders(newOrders);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getPurchaseOrders();
  }, []);

  return (
    <ProcessingOrdersWrapper.PurchaseOrder>
      {!isDetails && (
        <Table
          title={() => "New Orders In Bulk"}
          dataSource={orders}
          columns={purchaseColumns}
          pagination={false}
          onRow={(record, rowIndex) => {
            return {
              onClick: () => handleClickView(record),
            };
          }}
        />
      )}
      {isDetails && (
        <DetailsPurchaseOrders
          order={order}
          clickBack={() => setIsDetails(false)}
        />
      )}
    </ProcessingOrdersWrapper.PurchaseOrder>
  );
};

export default PurchaseOrder;
