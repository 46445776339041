import styled from "styled-components";
import Form from "./Form";
import FormItem from './FormItem';

const StyledFormComponent = styled(Form)`
    & {}
`;

export const StyledFormItem = styled(FormItem)`
    & {}
`;

export default StyledFormComponent;
