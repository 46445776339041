import React from 'react'
import { Redirect } from 'react-router-dom'
import AuthService from 'services/auth.service'

interface AuthWrapperProps {}

const AuthWrapper: React.FC<AuthWrapperProps> = ({ children }) => {
  return AuthService.isAuthenticated() ? <>{children}</> : <Redirect to="/login" />
}

export default AuthWrapper
