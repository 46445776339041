import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router";
import { ArrowBackOutlineIcon } from "components/shared/Icons";
import WrapCustomers from "./styled";
import GroupTitle from "components/shared/GroupTitle";
import DetailsReceipt from "components/shared/DetailsReceipt";
import OrdersForLocation from "components/shared/OrdersForLocation";
import Flex, { FlexItem } from "components/shared/Flex";
import Theme from "theme";
import orderService from "services/order.service";
import groupBy from "lodash/groupBy";
import {
  totalPrice,
  buildFoodLocation,
  totalQuantity,
} from "pages/manage-orders/manage-order.util";

const PaymentsByCustomerDetailsReceipt: React.FC = () => {
  const history = useHistory();
  const { customerId, invId, receiptId }: any =
    useParams<Record<string, string | undefined>>();
  const [receiptDetail, setReceiptDetail] = useState<any>({});

  useEffect(() => {
    orderService.getOrder(receiptId).then((customers) => {
      const locationGroup = groupBy(
        customers.items,
        (item) => item.location?.id
      );

      const customer = [
        {
          "Organisation Name": customers.corporate?.name,
          "Registered Address": customers.corporate?.registeredAddress,
        },
        {
          "Main Contact Number": customers.corporate?.phoneNumber,
          "Email Address": customers.corporate?.user?.email,
        },
        {
          "No. Of Order Location": Object.keys(locationGroup).length,
          "Total QTY + Adds": totalQuantity(customers.items),
        },
      ];

      const vendor = [
        {
          "Vendor Name": customers.vendor?.name,
          "Registered Address": customers.vendor?.address,
        },
        {
          "Main Contact Number": customers.vendor?.phone,
          "Email Address": customers.vendor?.user?.email,
        },
      ];

      const foodLocation = {
        totalQTY: totalQuantity(customers.items),
        totalCost: totalPrice(customers.items),
        noOfLocation: Object.keys(locationGroup).length,
        data: buildFoodLocation(locationGroup),
      };

      setReceiptDetail({ vendor, customer, orders: foodLocation });
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickToBack = () => {
    history.push(
      `/finance-payment/payments-by-customers-details-inv/${customerId}/${invId}`
    );
  };

  return (
    <WrapCustomers>
      <WrapCustomers.Details>
        <GroupTitle
          fontSize={16}
          icon={
            <ArrowBackOutlineIcon
              color={Theme.global.whiteColor}
              onClick={handleClickToBack}
            />
          }
          padding={5}
          className="group-title header-settlement"
        >
          Receipt No {receiptId} / {invId}
        </GroupTitle>
        <Flex>
          <FlexItem
            className="general-info"
            css={`
              margin-right: 7px;
            `}
          >
            <div className="title-general-info">Customer</div>
            <DetailsReceipt dataInfo={receiptDetail.customer} />
          </FlexItem>
          <FlexItem
            className="general-info"
            css={`
              margin-left: 7px;
            `}
          >
            <div className="title-general-info">Vendor On This Day</div>
            <DetailsReceipt dataInfo={receiptDetail.vendor} />
          </FlexItem>
        </Flex>
        <OrdersForLocation dataInfo={receiptDetail.orders} />
      </WrapCustomers.Details>
    </WrapCustomers>
  );
};

export default PaymentsByCustomerDetailsReceipt;
