import React from "react";
import Table from 'components/shared/Table';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { columnsInvoicesCloudKitchen } from "../finance-CloudKitchenVendors.util";

export interface IPropsSendPayment {
  listSendPayment?: any[];
}

const SendPayment: React.FC<IPropsSendPayment> = ({
  listSendPayment
}) => {
  const history = useHistory();
  const { idKitchen }: any = useParams<Record<string, string | undefined>>();

  const _handleClickInvoice = (record: any) => {
    history.push(`/finance-payment/cloud-kitchen-vendors-details-inv/${idKitchen}/${record.NoNumberINV}`);
  };

  return (
    <Table
      title={() => 'To Send Payment (Auto-Generated)'}
      dataSource={listSendPayment}
      columns={columnsInvoicesCloudKitchen}
      pagination={false}
      className="table-invoices"
      onRow={(record) => {
        return {
          onClick: () => _handleClickInvoice(record),
        };
      }}
  />
  )
}

export default SendPayment;
