import React from 'react'
import styled from 'styled-components'

const ManageMenuContainer = styled.div`
  width: 100%;
`

const ManageMenu: React.FC = ({ children }) => {
  return <ManageMenuContainer>{ children }</ManageMenuContainer>
}

export default ManageMenu
