import React from 'react'
import { Card as AntCard, CardProps as AntCardProps } from 'antd'
import { omit } from 'utils'

export type BorderHidden = {
  hideBorderLeft?: boolean
  hideBorderRight?: boolean
  hideBorderTop?: boolean
  hideBorderBottom?: boolean
}
export type CardProps = {
  withGreenBorder?: boolean
} & AntCardProps &
  BorderHidden

const Card: React.FC<CardProps> = (props) => {
  return (
    <AntCard
      className="ddl-card"
      {...omit(props, ['hideBorderLeft', 'hideBorderRight', 'hideBorderTop', 'hideBorderBottom', 'withGreenBorder'])}
    />
  )
}

export default Card
