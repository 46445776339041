import styled from "styled-components";
import { getCss } from "utils/cssHelpers";

const ResetPasswordSuccessWrapper: any = styled.div`
  padding-top: 50px;
  ${getCss("background-color")("theme.global.whiteColor")};
  & {
    .title-login {
      font-size: 28px;
      ${getCss("color")("theme.global.color444444")};
      padding-bottom: 20px;
      text-align: center;
    }
    .customer-input,
    .customer-btn {
      width: 480px;
      height: 50px;
    }
    .ant-input,
    .ant-select {
      box-shadow: none !important;
    }
  }
`;

ResetPasswordSuccessWrapper.Image = styled.img``;

ResetPasswordSuccessWrapper.Heading = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

ResetPasswordSuccessWrapper.Description = styled.p`
  text-align: center;
  font-size: 12px;
  margin-bottom: 20px;
  max-width: 470px;
`;

export default ResetPasswordSuccessWrapper;
