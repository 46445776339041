import React, { useEffect, useState } from "react";
import HandleButton from "./HandleButton";
import StyledDetails from "./styled";
import VendorCompanyDetails from "./table-details-kitchen/VendorCompanyDetails";
import PointOfContact from "./table-details-kitchen/PointOfContact";
import WeeklyMenu from "./table-details-kitchen/WeeklyMenu";
// import PayInSettlement from "./table-details-kitchen/PayInSettlement";
import { useHistory } from "react-router-dom";
import cloudKitchenService from "services/cloud-kitchen.service";
import map from "lodash/map";
import max from "lodash/max";
import { Toastify } from "components/shared/Toast/Toast";
import Flex, { FlexItem } from "components/shared/Flex";

const DetailsKitchen: React.FC = () => {
  const history: any = useHistory();
  const [vendorCompany, setVendorCompany] = useState({});
  const [pointOfContacts, setPointOfContacts] = useState([]);
  const [weeklyMenuState, setWeeklyMenu] = useState([]);
  const [maxRow, setMaxRow] = useState({});
  const [loading, setLoading] = useState<boolean>(true);

  const userId = history?.location?.state?.user?.id;

  const getVendor = (id: any) => {
    cloudKitchenService.getVendor(id).then((res) => {
      setLoading(false);
      setVendorCompany(res);

      const points: any = map(res?.user?.pointOfContacts, (point, index) => ({
        ...point,
        key: index,
      }));
      setPointOfContacts(points);
    });
  };

  const getWeeklyMenu = () => {
    cloudKitchenService.getWeeklyMenu().then((res) => {
      setLoading(false);
      const foodTypes: any = {
        mainMenu: {
          monday: [],
          tuesday: [],
          wednesday: [],
          thursday: [],
          friday: [],
          saturday: [],
          sunday: [],
        },
        fruitPlatter: {
          monday: [],
          tuesday: [],
          wednesday: [],
          thursday: [],
          friday: [],
          saturday: [],
          sunday: [],
        },
        addOn: {
          monday: [],
          tuesday: [],
          wednesday: [],
          thursday: [],
          friday: [],
          saturday: [],
          sunday: [],
        },
      };
      const weeklyMenu: any = [];
      map(res[0]?.items, (dayItem) => {
        map(dayItem.foods, (food) => {
          if (food.logicType === "main-foods") {
            foodTypes.mainMenu[dayItem.day].push(food.name);
          }
          if (food.logicType === "addons") {
            foodTypes.addOn[dayItem.day].push(food.name);
          }
          if (food.logicType === "additionals") {
            foodTypes.fruitPlatter[dayItem.day].push(food.name);
          }
        });
      });

      const maxRowAddOn =
        max(map(foodTypes.addOn, (items) => items.length)) || 1;
      const maxRowMainMenu =
        max(map(foodTypes.mainMenu, (items) => items.length)) || 1;
      const maxRowFruitPlatter =
        max(map(foodTypes.fruitPlatter, (items) => items.length)) || 1;
      setMaxRow({ maxRowAddOn, maxRowFruitPlatter, maxRowMainMenu });

      for (let i = 0; i < maxRowMainMenu; i++) {
        const menuRow = {
          key: `mainMenu${i}`,
          monday: foodTypes.mainMenu.monday[i],
          tuesday: foodTypes.mainMenu.tuesday[i],
          wednesday: foodTypes.mainMenu.wednesday[i],
          thursday: foodTypes.mainMenu.thursday[i],
          friday: foodTypes.mainMenu.friday[i],
          saturday: foodTypes.mainMenu.saturday[i],
          sunday: foodTypes.mainMenu.sunday[i],
        };
        weeklyMenu.push(menuRow);
      }

      for (let i = 0; i < maxRowFruitPlatter; i++) {
        const menuRow = {
          key: `fruitPlatter${i}`,
          monday: foodTypes.fruitPlatter.monday[i],
          tuesday: foodTypes.fruitPlatter.tuesday[i],
          wednesday: foodTypes.fruitPlatter.wednesday[i],
          thursday: foodTypes.fruitPlatter.thursday[i],
          friday: foodTypes.fruitPlatter.friday[i],
          saturday: foodTypes.fruitPlatter.saturday[i],
          sunday: foodTypes.fruitPlatter.sunday[i],
        };
        weeklyMenu.push(menuRow);
      }

      for (let i = 0; i < maxRowAddOn; i++) {
        const menuRow = {
          key: `addOn${i}`,
          monday: foodTypes.addOn.monday[i],
          tuesday: foodTypes.addOn.tuesday[i],
          wednesday: foodTypes.addOn.wednesday[i],
          thursday: foodTypes.addOn.thursday[i],
          friday: foodTypes.addOn.friday[i],
          saturday: foodTypes.addOn.saturday[i],
          sunday: foodTypes.addOn.sunday[i],
        };
        weeklyMenu.push(menuRow);
      }

      setWeeklyMenu(weeklyMenu);
    });
  };

  useEffect(() => {
    getVendor(userId);
    getWeeklyMenu();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteVendor: any = (userId: any) => {
    cloudKitchenService
      .deleteVendor(userId)
      .then((res) => {
        history.push(`/manage-cloud-kitchen/all-cloud-kitchens`);
      })
      .catch((err) =>
        Toastify.showDangerToast(err?.response?.data?.messages?.[0])
      );
  };

  return (
    <StyledDetails>
      <HandleButton vendor={vendorCompany} deleteVendor={deleteVendor} />
      <VendorCompanyDetails vendorCompany={vendorCompany} />
      <Flex>
        <FlexItem style={{ width: "100%" }}>
          {loading ? "Loading..." : <PointOfContact pointOfContacts={pointOfContacts} />}
        </FlexItem>
      </Flex>
      {loading ? "Loading..." : <WeeklyMenu weeklyMenu={weeklyMenuState} maxRow={maxRow} />}
      {/* <PayInSettlement /> */}
    </StyledDetails>
  );
};

export default DetailsKitchen;
