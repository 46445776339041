import { FunnelSharpIcon, ImageSharpIcon } from 'components/shared/Icons';

const renderTitleTable = (title: string) => {
  return <>{title} <FunnelSharpIcon  height="14px" /></>
}

// Table Country Menu
const collumsCountryMenu = [
  {
    title: <ImageSharpIcon width='32px' height='24px' />,
    dataIndex: 'image',
    key: 'image',
  },
  {
    title: renderTitleTable('Food ID'),
    dataIndex: 'food',
    key: 'food',
  },
  {
    title: renderTitleTable('Name'),
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: renderTitleTable('Menu Category'),
    dataIndex: 'category',
    key: 'category',
  },
  {
    title: renderTitleTable('Food Type'),
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: renderTitleTable('Calories'),
    dataIndex: 'calories',
    key: 'calories',
  },
  {
    title: renderTitleTable('Price'),
    dataIndex: 'price',
    key: 'price',
  },
]
// Table Country Menu

export {
  collumsCountryMenu,
}
