import React from 'react';
import StyledAllMenu from '../styled';
import FormNewMenu from '../form-new-nemu';
import { Form } from "antd";
import menuService from 'services/menu.service';
import { Toastify } from "components/shared/Toast/Toast";
export interface IPropsMenu {
  listMenu?: any;
}

const AddMenuUI: React.FC<IPropsMenu> = ({
  listMenu,
}) => {
  const [form] = Form.useForm();

  const addMenu = (body: any) => {
    menuService
      .postListMenu(body)
      .then(() => {
        Toastify.showSuccessToast("Create success!");
        form.resetFields();
        listMenu();
      })
      .catch((err) =>
        Toastify.showDangerToast(err?.response?.data?.messages?.[0])
      );
  };

  return (
    <StyledAllMenu.NewMenu>
      <div className="all-new-menu-title">All New Menu</div>
      <div className="label-item">Menu Name*</div>
      <Form
        name="basic"
        form={form}
      >
        <Form.Item>
          <FormNewMenu
            isCheck={true}
            submitForm={addMenu}
            form={form}
          />
        </Form.Item>
      </Form>
    </StyledAllMenu.NewMenu>
  )
}

export default AddMenuUI;
