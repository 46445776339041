import React from 'react'
import { User } from 'shared/user'
import Header from 'components/shared/Layout/Header'

export interface LayoutHeaderProps {
  user: User | null
  loading?: boolean
  headerRightContentRender(...args: any[]): React.ReactNode
}

const LayoutHeader: React.FC<LayoutHeaderProps> = ({ headerRightContentRender }) => {
  return (
    <Header
      css={`
        background: ${(props: any) => props.theme.global.secondaryColor};
        > .header-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      `}
      className="admin-layout__header"
    >
      <div className="header-wrapper">
        <div style={{ flex: '1 1 0%' }}></div>
        <div className="header-right-content">{headerRightContentRender()}</div>
      </div>
    </Header>
  )
}

export default LayoutHeader
