import React from 'react'
import { Tabs as AntTabs, TabsProps as AntTabsProps } from 'antd'
import TabPane from './TabPane'

export interface TabsProps extends AntTabsProps {}

const Tabs: React.FC<TabsProps> & { TabPane?: typeof TabPane } = (props) => {
  return (
    <AntTabs className='ddl-tabs' { ...props } />
  )
}

export default Tabs




