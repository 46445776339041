import styled from 'styled-components'
import { getCss } from 'utils/cssHelpers';

const MyMenuWrapper = styled.div`
  & {
    .ant-tabs-tab-btn {
      font-size: 14px;
      ${getCss('color')('theme.tabs.textColor')};
    }
    .ant-tabs-ink-bar-animated {
      bottom: 0 !important;
      background-color: ${props => props.theme.global.primaryColor} !important;
      height: 3px !important;
    }
  }
`;

export default MyMenuWrapper;
