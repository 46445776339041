import React, { useState } from "react";
import GroupTitle from "components/shared/GroupTitle";
import Button from "components/shared/Button";
import { ArrowBackOutlineIcon } from "components/shared/Icons";
import Flex, { FlexItem } from "components/shared/Flex";
import { useHistory } from "react-router-dom";
import Modal from "components/shared/Modal";

interface IHandleButton {
  vendor?: any;
  deleteVendor: (userId: any) => ({});
}

const HandleButton: React.FC<IHandleButton> = ({ vendor, deleteVendor }) => {
  const history = useHistory();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const _handleEditInfo = () => {
    history.push({
      pathname: `/manage-cloud-kitchen/edit/${vendor?.user?.id}`,
      state: { user: vendor?.user },
    });
  };

  const openModal = () => {
    setIsModalVisible(true);
  };

  const _handleBack = () => {
    history.push("/manage-cloud-kitchen/all-cloud-kitchens");
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  const handleDelete = () => {
    deleteVendor(vendor?.user?.id)
    setIsModalVisible(false);
  };

  return (
    <Flex justifyStart alignCenter>
      <FlexItem style={{ width: "50%" }}>
        <GroupTitle
          padding={5}
          icon={
            <ArrowBackOutlineIcon
              type="success"
              color="#fff"
              onClick={_handleBack}
            />
          }
        >
          <Button type="info" onClick={_handleEditInfo}>
            Edit Information
          </Button>
        </GroupTitle>
      </FlexItem>
      <FlexItem style={{ width: "50%", textAlign: "end" }}>
        <Button type="primary" onClick={openModal}>
          Delete Account
        </Button>
      </FlexItem>
      <Modal visible={isModalVisible}>
        <Flex justifyCenter>
          <p className="content-request-for-payment">
          Do you want to delete this vendor?.
          </p>
        </Flex>

        <Flex justifyEnd>
          <Button type="info" onClick={closeModal}>
            Cancel
          </Button>
          <Button onClick={handleDelete} style={{ marginLeft: "15px" }}>
            OK
          </Button>
        </Flex>
      </Modal>
    </Flex>
  );
};

export default HandleButton;
