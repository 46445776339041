import groupBy from "lodash/groupBy";
import reduce from "lodash/reduce";
import map from "lodash/map";
import { formatCurrency } from "utils/common.util";

const totalQuantity = (items: any[]) => {
  const total = reduce(items, (result, item) => +item.quantity + result, 0);
  return total;
};

const buildFoodLocation = (foodLocation: object) => {
  return map(foodLocation, (data: any[], locationId) => {
    const foodsByType = groupBy(data, (item) => item.food.logicType);

    const mainFoodsItems = (foodsByType["main-foods"] || []).filter(
      (item) => !item.forExtends
    );
    const additionalItems = (foodsByType["main-foods"] || []).filter(
      (item) => item.forExtends
    );

    const foodLocationDetail = {
      key: locationId,
      location: data[0]?.location?.name,
      deliveryLocation: data[0]?.location?.address,
      totalOrders: totalQuantity(mainFoodsItems),
      billQTY: map(mainFoodsItems, (item) => ({
        name: item.food?.name,
        QTY: item.quantity,
        cost: `$${formatCurrency(item.price * item.quantity)}`,
      })),

      addOns: {
        name: data[0]?.customerName || "N/A",
        addsMain: map(additionalItems, (item) => ({
          name: item.food?.name,
          amount: item.quantity,
        })),
        addsSide: map(foodsByType.addons, (item) => ({
          name: item.food?.name,
          amount: item.quantity,
        })),
        Cost: totalPrice([...additionalItems, ...(foodsByType.addons || [])]),
      },
    };

    return foodLocationDetail;
  });
};

const totalPrice = (items: any[]) => {
  const total = reduce(
    items,
    (result, item) => +item.quantity * +item.price + result,
    0
  );

  if (!total) {
    return "$0";
  }

  return `$${formatCurrency(total)}`;
};

export { totalPrice, buildFoodLocation, totalQuantity };
