import React from 'react';
import StyleRenderImgFood from './styled';
import { Image as AntImage, ImageProps as AntImageProps } from 'antd';

export interface ImageProps extends AntImageProps  {
  src?: any;
  alt?: string;
  className?: string;
}

const RenderImgFood: React.FC<AntImageProps> = ({
  src,
  alt,
  className
}) => {
  return (
    <StyleRenderImgFood>
      {src ? <AntImage src={src} alt={alt} className={className ? className : 'custome-img-food'} /> : null}
    </StyleRenderImgFood>
  );
}

export default RenderImgFood;
