import React from "react";
import AddNewCloudKitchenWrapper from "./styled";
import VendorForm from "../VendorForm";
import cloudKitchenService from "services/cloud-kitchen.service";
import { Form } from "antd";
import { Toastify } from "components/shared/Toast/Toast";
import GroupTitle from "components/shared/GroupTitle";
import { ArrowBackOutlineIcon } from "components/shared/Icons";
import { useHistory } from "react-router";

const AddNewCloudKitchenUI: React.FC = () => {
  const [form] = Form.useForm();
  const history: any = useHistory();

  const addVendor = (body: any) => {
    cloudKitchenService
      .addVendor(body)
      .then(() => {
        Toastify.showSuccessToast("Create success!");
        form.resetFields();

        history.push('/manage-cloud-kitchen/all-cloud-kitchens');
      })
      .catch((err) =>
        Toastify.showDangerToast(err?.response?.data?.messages[0])
      );
  };

  return (
    <AddNewCloudKitchenWrapper>
      <GroupTitle
        fontSize={16}
        icon={
          <ArrowBackOutlineIcon color="#fff" onClick={() => history.goBack()} />
        }
        padding={5}
        className="styled-create-account"
      >
        Create A Cloud Kitchen Account
      </GroupTitle>
      <Form
        name="basic"
        form={form}
      >
        <Form.Item>
          <VendorForm
            isNewVendor={true}
            submitVendor={addVendor}
            form={form}
          />
        </Form.Item>
      </Form>
    </AddNewCloudKitchenWrapper>
  );
};

export default AddNewCloudKitchenUI;
