import { getCss } from 'utils/cssHelpers';
import styled from "styled-components";

interface IProps {
  width?: number;
}

const Wrapper: any = styled.div``;

Wrapper.DetailsReports = styled.div`
  padding: 10px 0;
  width: ${(props: IProps) => props.width || '100%'};
  height: 90px;
  & {
    .number {
      margin: 0;
      padding-bottom: 8px;
      font-size: 20px;
      font-weight: 500;
      ${getCss('color')('theme.global.primaryColor')}
    }
    .text {
      margin: 0;
      font-family: Montserrat;
      font-size: 11px;
      font-weight: normal;
      ${getCss('color')('theme.global.color444444')}

    }
    .description {
      font-size: 9px;
      font-weight: normal;
      font-style: italic;
      ${getCss('color')('theme.global.color444444')}
    }
  }
`;

Wrapper.CustomDate = styled.div`
  .custome-btn-go {
    border-radius: 50px;
    margin-left: 20px;
  }
`;

Wrapper.StyledRangeOfDates = styled.div`
  margin-bottom: 20px;
  & {
    .title-range-date {
      ${getCss('color')('theme.global.grayColor')};
      font-size: 16px;
      font-weight: 600;
      text-align: center;
      margin-bottom: 10px;
    }
    .rmdp-wrapper {
        margin: auto;
        box-shadow: none;
    }
    .rmdp-calendar {
        padding: 0;
        ${getCss('background-color')('theme.global.colorCbcbcb')};
    }
    .rmdp-day-picker {
        padding: 0;
    }
    .rmdp-header {
        display: none !important;
    }
    .rmdp-week-day {
        width: 30px;
        height: 20px;
        margin: 6px 8px;
        font-size: 8px;
    }
    .rmdp-day {
        margin: 6px 8px;
        border-radius: 50px;
        width: 26px;
        height: 26px;
        span {
            width: 20px;
            height: 20px;
            font-size: 9px;
            ${getCss('color')('theme.global.whiteColor')};
        }
        &.rmdp-selected,
        &.rmdp-today {
            span {
                background: unset;
            }
        }
        .rmdp-day.rmdp-today span {
            background: transparent;
        }
        .rmdp-day.rmdp-today .rmdp-selected {
            ${getCss('background')('theme.global.orangeColor')};
        }
        .rmdp-day.rmdp-selected span:not(.highlight) {
            ${getCss('background')('theme.global.orangeColor')};
        }
    }
    .rmdp-week {
        padding: 0 4px;
        &:first-child {
            ${getCss('background-color')('theme.global.primaryColor')};
        }
        &:last-child {
            padding-bottom: 7px;
        }
        .rmdp-week-day {
            ${getCss('color')('theme.global.whiteColor')};
        }
        .rmdp-range {
            ${getCss('background')('theme.global.orangeColor')};
        }
    }
    .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
        ${getCss('background')('theme.global.orangeColor')};
    }
    .rmdp-disabled {
        opacity: 0.4;
    }
  }
`;

export default Wrapper;