import React, { useState, useEffect } from 'react';
import Table3 from 'components/shared/Table';
import Flex, { FlexItem } from 'components/shared/Flex';
import GroupTitle from 'components/shared/GroupTitle';
import InputSearch from 'components/shared/InputSearch';
import StyledCountryMenu from './styled';
import { ArrowBackOutlineIcon } from 'components/shared/Icons';
import Tag from 'components/shared/Tag';
import { useHistory } from "react-router-dom";
import RenderImgFood from 'components/shared/RenderImgFood';
import menuService from 'services/menu.service';
import config from "config";
import { collumsCountryMenu } from "./CounTryMenu.config";
import Theme from "theme";
import Pagination from "components/shared/Pagination";

type IDetailMenuProps = {
  id: number,
  photo: string,
  name: string,
  menu: any,
  types: any,
  calories: string,
  price: string,
}

type IPagination = {
  total: number,
  onChange: any,
  current: number
}

const _renderImg = (url: string) => {
  return <RenderImgFood src={url} alt={url} />
}

const CountryMenu:React.FC = () => {
  const history: any = useHistory();
  const [detailMenu, setDetailMenu] = useState([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [allDetailMenu, setAllDetailMenu] = useState<any>([]);
  const [current, setCurrent] = useState<number>(1);
  const pageSize: number = 5; // set item pages

  const idMenu = history?.location?.state?.key;
  const menuFood = history?.location?.state?.data;

  const getUrl = (url: any) => {
    if (url.startsWith("http")) {
      return url;
    }
    return config.hostName + url
  }

  const _handleRenderDetailMenu = () => {
    menuService.getDetailMenu(idMenu).then((detailMenu) => {
      setLoading(false);
      const allListDetailMenu = detailMenu?.map((row: IDetailMenuProps) => ({
          key: row.id,
          image: _renderImg(`${getUrl(row.photo)}`),
          food: row.id,
          name: row.name,
          category: row?.menu?.name,
          type: row?.types?.map((item: any, index: number) => {
            return (
              <Tag type="primary" style={{backgroundColor: `${item.color}`}} key={index}>
                {item.name}
              </Tag>
            );
          }),
          calories: row.calories,
          price: `$${row.price}`,
        }));
        setAllDetailMenu(allListDetailMenu);
        setDetailMenu(allListDetailMenu);
    });
  }

  const _handleSearch = (e: any) => {
    const value = e.target.value.toLowerCase();
    var dataSourceFilter: Array<string> = detailMenu;
    if (value !== '') {
      const listKeysSearch: Array<string> = ['name'];
      const checkItem = (itemData: any) => {
        if (listKeysSearch.some(key => itemData[key].toLowerCase().indexOf(value) !== -1)) {
          return itemData;
        } else {
          return null;
        }
      }
      dataSourceFilter = detailMenu.map((item: any) => checkItem(item)).filter((item: any) => item !== null);
    }
    setAllDetailMenu(dataSourceFilter);
  }

  useEffect(() => {
    _handleRenderDetailMenu();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _handleBackManageMenu = (record: any) => {
    history.push('/manage-menu/my-menu');
  }

  const getData = (current: number, pageSize: number) => {
    return allDetailMenu.slice((current - 1) * pageSize, current * pageSize);
  }

  const MyPagination = ({ total, onChange, current }: IPagination) => {
    return (
      <Pagination
        onChange={onChange}
        total={total}
        current={current}
        pageSize={pageSize}
      />
    )
  }
  
  return (
    <StyledCountryMenu.CountryMenu>
      <Flex justifyBetween style={{ paddingBottom: '39px' }}>
          <FlexItem>
              <GroupTitle
                fontSize={16}
                icon={<ArrowBackOutlineIcon color={Theme.global.whiteColor} onClick={_handleBackManageMenu} />} 
                padding={5}
              >
                {menuFood} Cuisine Menu
              </GroupTitle>
          </FlexItem>
          <FlexItem>
              <InputSearch
                placeholder='Search Name Food In This Menu'
                style={{ minWidth: '300px'}}
                onInput={(e) => _handleSearch(e)}
              />
          </FlexItem>
      </Flex>
      <Flex>
        <FlexItem css={`flex: 1`}>
          {
            loading 
            ? "Loading..."
            : (
              <>
                <Table3
                  title={() => 'All'}
                  columns={collumsCountryMenu}
                  dataSource={getData(current, pageSize)}
                  pagination={false}
                />
                <MyPagination
                  total={allDetailMenu.length}
                  current={current}
                  onChange={setCurrent}
                />
              </>
            )
          }
        </FlexItem>
      </Flex>
    </StyledCountryMenu.CountryMenu>
  );
}

export default CountryMenu;