import React, { useState } from 'react';
import StyledOrder from './styled';
import Button from 'components/shared/Button';
import {Tabs, TabPane} from 'components/shared/Tabs';
import Flex from 'components/shared/Flex';
import TotalOrder from './TotalOrder';
import SalesOrder from './SalesOrder';
import { useHistory } from "react-router-dom";

const Order: React.FC = (props) => {
  const history = useHistory();
  const [isActive, setActive] = useState<boolean>(false);

  const tabsList: Array<string> = ['Total Orders', 'Sales'];

  const _handleTotalOrders = () => {
    return <TotalOrder />
  }

  const _handleSales = () => {
    return <SalesOrder />
  }

  const _handleSwitchTabs: any = (e: any) => {
    if(e === 0) {
      return _handleTotalOrders();
    } else {
      return _handleSales();
    }
  }

  const _handleOrders = () => {
    history.push('/reports/orders');
    setActive(true);
  }

  const _handleCustomers = () => {
    history.push('/reports/customers');
  }

  const _handleChangeTabs = () => {}

  return (
    <StyledOrder.OrderWrap>
      <Flex>
        <Button className={`custome-btn ${!isActive ? 'active-btn' : null}`} onClick={_handleOrders}>Orders</Button>
        <Button className='custome-btn ml-20' onClick={_handleCustomers}>Customers</Button>
      </Flex>
      <Flex style={{ paddingTop: '24px' }}>
        <Tabs defaultActiveKey="0" onChange={_handleChangeTabs}>
          {
            tabsList.map((item, index) => {
              return (
                <TabPane tab={item} key={index}>
                    {_handleSwitchTabs(index)}
                </TabPane>
              );
            })
          }
        </Tabs>
      </Flex>
    </StyledOrder.OrderWrap>
  );
}

export default Order;
