import React, { useState, useEffect } from 'react';
import StyledAllMenu from './styled';
import Flex, { FlexItem } from 'components/shared/Flex';
import Table3 from 'components/shared/Table';
import InputSearch from 'components/shared/InputSearch';
import { useHistory } from "react-router-dom";
import menuService from 'services/menu.service';
import { Toastify } from 'components/shared/Toast/Toast';
import AddMenuUI from './add-menu/AddMenu';
import EditMenuUI from './edit-menu/EditMenu';
import Modal from "components/shared/Modal";
import Button from "components/shared/Button";
import moment from "moment";
import { collumsMenu } from "./AllMenu.config";
import Pagination from "components/shared/Pagination";

type IMenuProps = {
  id: number,
  name: string,
  description: string,
  createdAt: string,
  updatedAt: string,
  listMenu: () => void
}

type IPagination = {
  total: number,
  onChange: any,
  current: number
}

const AllMenuTable:React.FC = (props) => {
  const history = useHistory();
  const [allMenu, setAllMenu] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [itemMenu, setItemMenu] = useState<any>([]);
  const [idMenu, setIdMenu] = useState<any>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [checkId, setCheckId] = useState<any>();
  const [submit, setSubmit] = useState<boolean>(false);
  const [current, setCurrent] = useState<number>(1);
  const [countItem, setCountItem] = useState<number>(10);
  const pageSize: number = 5; // set item pages
  const defaultPages: number = 0; // pages default

  const _handleEditCategory = (id: number, isCheck: boolean) => {
    setIdMenu(id);
    setSubmit(isCheck);
  }

  const countPageSize = () => {
    if (current > 0) {
      setCountItem(countItem + pageSize);
    }
  }

  useEffect(() => {
    countPageSize();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current]);

  const _handleDeleteCategory = () => {
    menuService.deleteMenu(checkId)
    .then(() => {
      setIsModalVisible(false);
      Toastify.showSuccessToast('Menu deleted!');
      getAllListMenu();
    })
    .catch((err) => {
      Toastify.showDangerToast('Failed to delete menu');
    });
  }

  const closeModal = () => {
    setIsModalVisible(false);
  };

  
  const openModal = () => {
    setIsModalVisible(true);
  };

  const _handleCoure = (id: number, name: string) => {
    setCheckId(id);
    return (
      <>
        <p className='all-menu-name'>
          {name}
        </p>
        <div className='all-menu-action'>
          <span onClick={ (e) => { _handleEditCategory(id, false); e.stopPropagation(); } }>Edit |</span>
          <span onClick={ (e)=> { openModal(); e.stopPropagation(); } }> Delete</span>
        </div>
      </>
    );
  }

  const getAllListMenu = () => {
    menuService.getAllMenu(defaultPages, countItem).then((allMenu) => {
      setLoading(false);
      const listAllMenu = allMenu?.items?.map((row: IMenuProps) => ({
          key: row.id,
          name: _handleCoure(row.id, row.name),
          category: row.description,
          created: moment(row.createdAt).format("DD-MM-YYYY"),
          updated: moment(row.updatedAt).format("DD-MM-YYYY"),
          menuFood: row.name,
        }));
        setItemMenu(listAllMenu);
        setAllMenu(listAllMenu);
    })
  }

  useEffect(() => {
    getAllListMenu();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _handleSearch = (e: any) => {
    const value = e.target.value.toLowerCase();
    var dataSourceFilter = allMenu;
    if (value !== '') {
      const listKeysSearch: Array<string> = ['category', 'created', 'updated'];
      const checkItem = (itemData: any) => {
        if (listKeysSearch.some(key => itemData[key].toLowerCase().indexOf(value) !== -1)) {
          return itemData;
        } else {
          return null;
        }
      }
      dataSourceFilter = allMenu.map((item: any) => checkItem(item)).filter((item: any) => item !== null);
    }
    setItemMenu(dataSourceFilter);
  };

  const _handleClickDetails = (record: any) => {
    history.push({
      pathname: `/manage-menu/menu-details/${record.key}`,
      state: {
        key: record?.key,
        data: record?.menuFood,
      }
    });
  }

  const checkSubmit = () => {
    return (
      submit
        ? <AddMenuUI listMenu={getAllListMenu} />
        : idMenu ? <EditMenuUI id={idMenu} listMenu={getAllListMenu} checkSubmit={setSubmit} /> : <AddMenuUI listMenu={getAllListMenu} />
      )
  }

  const getData = (current: number, pageSize: number) => {
    return itemMenu.slice((current - 1) * pageSize, current * pageSize);
  }

  const MyPagination = ({ total, onChange, current }: IPagination) => {
    return (
      <Pagination
        onChange={onChange}
        total={total}
        current={current}
        pageSize={pageSize}
      />
    )
  }

  return (
    <StyledAllMenu>
      <Flex>
        <FlexItem basis='40%' style={{ paddingRight: '60px' }}>
          {checkSubmit()}
        </FlexItem>
        <FlexItem basis='60%'>
          <StyledAllMenu.MenuTable>
          <Flex>
            <FlexItem style={{ textAlign: 'end', width: '100%' }}>
              <InputSearch
                placeholder='Search Menu'
                className='custom-search'
                onInput={e => _handleSearch(e)}
              />
            </FlexItem>
          </Flex>
          {
            loading
            ? "Loading..."
            : (
              <>
                <Table3
                  title={() => 'All'}
                  columns={collumsMenu}
                  dataSource={getData(current, pageSize)}
                  className='custome-table'
                  pagination={false}
                  onRow={(record) => {
                    return {
                      onClick: () => _handleClickDetails(record),
                    };
                  }}
                />
                <MyPagination
                  total={itemMenu.length}
                  current={current}
                  onChange={setCurrent}
                />
              </>
            )
          }
          </StyledAllMenu.MenuTable>
        </FlexItem>
      </Flex>
      <Modal visible={isModalVisible}>
        <Flex justifyCenter>
          <p className="content-request-for-payment">
            Do you want to delete this menu?.
          </p>
        </Flex>
        <Flex justifyEnd>
          <Button type="info" onClick={closeModal}>
            Cancel
          </Button>
          <Button onClick={_handleDeleteCategory} style={{ marginLeft: "15px" }}>
            OK
          </Button>
        </Flex>
      </Modal>
    </StyledAllMenu>
  );
}

export default AllMenuTable;
