import React, { useMemo, useState, useEffect } from "react";
import { User } from "shared/user";
import { NavLink, useLocation } from "react-router-dom";
import Sider from "./styled";
import Menu from "components/shared/Menu";
import { IRoute } from "renderRoutes";
import userService from "services/user.service";
export interface LayoutSiderProps {
  logo?: string;
  title?: string;
  loading?: boolean;
  menuData: IRoute[];
  user: User | null;
  menuHeaderRender?(logo: string, title: string): React.ReactNode;
}

const generateMenus = (
  data: LayoutSiderProps["menuData"],
  user: any
): React.ReactNode => {
  return data.map((item) => {
    const roleUser = user?.roles?.[0].id;
    if (
      roleUser &&
      item.roles &&
      item.roles.findIndex((role: any) => role === roleUser) !== -1
    ) {

      if (item.routes?.length) {
        return (
          <Menu.SubMenu
            key={item.key}
            icon={item.meta?.icon}
            title={item.meta?.title}
          >
            {generateMenus(item.routes, user)}
          </Menu.SubMenu>
        );
      }
      if (item.meta?.title === "Customer Details") {
        if (roleUser !== "admin") {
          return (
            <Menu.Item key={item.key} icon={item.meta?.icon}>
              <NavLink
                to={`${item.path?.replace(":id", "")}${user?.userId}` || "#"}
              >
                <span>{item.meta?.title}</span>
              </NavLink>
            </Menu.Item>
          );
        } else {
          return null;
        }
      }

      return (
        <Menu.Item key={item.key} icon={item.meta?.icon}>
          <NavLink to={item.path || "#"}>
            <span>{item.meta?.title}</span>
          </NavLink>
        </Menu.Item>
      );
    } else {
      return null;
    }
  });
};


const LayoutSider: React.FC<LayoutSiderProps> = ({ menuData, logo }) => {
  const { pathname } = useLocation();
  const [openKeys, setOpenKeys] = useState<any>([]);
  const [user, setUser] = useState<any>();
  const rootSubMenuKeys = menuData.map((item: any) => item.key);
  
  const defaultSelectedKeys = useMemo(() => {
    const paths = pathname.split("/").filter((path) => !!path);
    const defaultOpenKeys = paths.length > 1 ? [paths[0]] : [];
    
    let defaultSelectedKeys = paths.join("/");

    // Highlight report menu when click 'report customer'
    if(pathname.startsWith("/reports/")) {
      defaultSelectedKeys = "reports/orders"
    }

    // if(pathname.startsWith("/manage-cloud-kitchen/details-kitchen/")){
    //   defaultSelectedKeys = "manage-cloud-kitchen/all-cloud-kitchens"
    // }
    
    if(defaultOpenKeys !== openKeys) {
      setOpenKeys(defaultOpenKeys);
    }
    return [defaultSelectedKeys];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);


  const onOpenChange = (keys: any) => {
    const latestOpenKey = keys.find((key: any) => openKeys.indexOf(key) === -1);
    
    if (rootSubMenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  useEffect(() => {
    userService.getMe().then((res) => {
      setUser(res);
    });
  }, []);

  return (
    <Sider style={{ width: 256 }}>
      <div className="ddl-logo">
        <NavLink to="/dashboard">
          <img src={logo} alt="logo" />
        </NavLink>
      </div>
      <Menu
        selectedKeys={defaultSelectedKeys}
        mode="inline"
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        style={{ height: "100%", borderRight: 0 }}
      >
        {generateMenus(menuData, user)}
      </Menu>
    </Sider>
  );
};

export default LayoutSider;
