import { BaseAPI } from "core/services/BaseAPI";
import { User } from "shared/user";
import authService from "services/auth.service";
import { Toastify } from "components/shared/Toast/Toast";
export interface IUserService {
  login(user: any): Promise<any>;
  logout(): void;
  getCurrentUser(): Promise<User>;
  getUserProfileId(): Promise<User>;
  getMe(): Promise<{ userId: number }>;
  getAllUsers(): Promise<any>;
  getAdmin(userId: string): Promise<any>;
  updateAdmin(userId: string, body: object): Promise<any>;
}
class UserService extends BaseAPI implements IUserService {
  async forgetPassword(email: string) {
    try {
      const result = await this.post("auth/forgot-password", {
        email,
        checkForCorp: true,
      });
      return result;
    } catch (error: any) {
      if (error.response && error.response.data) {
        let errMss = error.response.data.message;
        errMss = Array.isArray(errMss) ? errMss : [errMss];
        for (const mss of errMss) {
          Toastify.showDangerToast(mss);
        }
      }
    }
  }

  async login(user: any) {
    try {
      const result = await this.post("auth/login", user);
      authService.setToken("access-token", result?.accessToken);
      localStorage.setItem(
        "me",
        JSON.stringify({ userId: result?.userId, roles: result?.roles })
      );
      return result;
    } catch (error: any) {
      if (error.response && error.response.data) {
        Toastify.showDangerToast(error.response.data.message);
      }
    }
  }

  async logout() {
    await authService.removeToken("access-token");
  }

  async getCurrentUser(): Promise<User> {
    try {
      const infoUser = await this.get("users/profile", undefined);
      return infoUser;
    } catch (error) {
      console.log("error :", error);
    }
    return Promise.resolve({} as User);
  }

  async getMe(): Promise<{ userId: number; roles: Array<any> }> {
    return JSON.parse(localStorage.getItem("me") || "{}");
  }

  getUser(): { userId: number; roles: Array<any> } {
    return JSON.parse(localStorage.getItem("me") || "{}");
  }

  async getUserProfileId(): Promise<User> {
    return Promise.resolve({} as User);
  }

  async getAllUsers(): Promise<any> {
    try {
      const allUser = await this.get("manage-customers/corporates", undefined);
      return allUser;
    } catch (error) {
      console.log("error :", error);
    }
  }

  getAdmin(userId: string): Promise<any> {
    return this.get(`admin/${userId}`);
  }

  updateAdmin(userId: string, body: object): Promise<any> {
    return this.patch(`admin/${userId}`, body);
  }
}

export default new UserService();
