import React from "react";
import Table2 from "components/shared/Table";
import Flex, { FlexItem } from "components/shared/Flex";
import GroupTitle from "components/shared/GroupTitle";
import AllVendorsWrapper from "../styled";

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Contact Number",
    dataIndex: "phoneNumber",
    key: "phoneNumber",
  },
  {
    title: "Email Address",
    dataIndex: "email",
    key: "email",
  },
];

interface IPointOfContact {
  pointOfContacts: any[];
}

const PointOfContact: React.FC<IPointOfContact> = ({ pointOfContacts }) => {
  return (
    <AllVendorsWrapper.WrapperPointOfContact>
      <GroupTitle
        fontSize={18}
        className="poin-of-contact-title"
        style={{ margin: "0" }}
      >
        Point Of Contact
      </GroupTitle>
      <Flex>
        <FlexItem
          css={`
            flex: 1;
          `}
        >
          <Table2
            columns={columns}
            dataSource={pointOfContacts}
            pagination={false}
          />
        </FlexItem>
      </Flex>
    </AllVendorsWrapper.WrapperPointOfContact>
  );
};

export default PointOfContact;
