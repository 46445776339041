import React from 'react'
import Flex from 'components/shared/Flex'
import { 
  // HelpIcon, 
  ArrowDownIcon, ArrowUpIcon } from 'components/shared/Icons'
import Card, { BorderHidden } from 'components/shared/Card'
import Theme from 'theme'
import { DurationRender } from './interfaces'

type ReportDetailProps = {
  title: React.ReactNode
  value: number | string
  valueRender?: (value: number | string) => React.ReactNode
  percent: number | string
  percentCalculator?: (value: number | string) => number
  duration: number | string
  type: 'up' | 'down'
} & DurationRender &
  BorderHidden

const getColorByReportType = (type: ReportDetailProps['type']) => {
  return type === 'up' ? Theme.global.primaryColor : Theme.global.redColor
}

const ArrowIcon: React.FC<Pick<ReportDetailProps, 'type'>> = ({ type }) => {
  return type === 'up' ? <ArrowUpIcon /> : <ArrowDownIcon />
}

const ReportDetail: React.FC<ReportDetailProps> = ({
  title,
  value,
  valueRender,
  percent,
  percentCalculator,
  duration,
  durationRender,
  type,
  ...rest
}) => {
  return (
    <Card 
      withGreenBorder 
      bordered={false} 
      title={title} 
      // extra={<HelpIcon />} 
      {...rest}
    >
      <span
        css={`
          font-size: 50px;
        `}
      >
        {valueRender ? valueRender(value) : value}
      </span>
      <Flex column>
        <Flex alignCenter>
          <ArrowIcon type={type} />
          <span
            css={`
              font-size: 11px;
            `}
            style={{ color: getColorByReportType(type) }}
          >
            {`${percentCalculator ? percentCalculator(value) : percent}%`}
          </span>
        </Flex>
        <div
          css={`
            padding-bottom: 11px;
          `}
        >
          {durationRender ? durationRender(duration) : `From previous ${duration} days`}
        </div>
      </Flex>
    </Card>
  )
}

export default ReportDetail
