import React from 'react'
import { Layout } from 'antd'

const { Header: AntHeader } = Layout

interface HeaderProps extends React.HTMLAttributes<HTMLDivElement> {}

const Header: React.FC<HeaderProps> = (props) => {
  return <AntHeader className='ddl-layout-header' {...props} />
}

export default Header
