import React from 'react'
import { Menu as AntMenu, MenuItemProps as AntMenuItemProps } from 'antd'

const { Item: AntMenuItem } = AntMenu

export interface MenuItemProps extends AntMenuItemProps {}

const MenuItem: React.FC<MenuItemProps> = (props) => {
  return <AntMenuItem className='ddl-menu-item' {...props} />
}

export default MenuItem