import React from "react";
import {
  SettingsSharp,
  HelpCircleSharp,
  ArrowDownSharp,
  ArrowUpSharp,
  ArrowForwardCircleSharp,
  ArrowBackCircleSharp,
  ArrowBackOutline,
  FunnelSharp,
  SearchOutline,
  AddOutline,
  ArrowForwardCircle,
  ArrowDownCircleSharp,
  ImageSharp,
  CloseSharp,
  PersonCircleOutline,
  ArrowForwardSharp,
  ChevronDownOutline,
  CloudDownloadSharp,
  CloudUploadSharp,
  PersonSharp,
  LockClosedSharp,
  NotificationsSharp,
} from "react-ionicons";
import theme from "theme";

type IoniconProps = {
  color?: string;
  style?: Object;
  height?: string;
  width?: string;
  fontSize?: string;
  rotate?: boolean;
  shake?: boolean;
  beat?: boolean;
  onClick?: (event?: Event) => void;
  children?: React.ReactNode;
  type?: "danger" | "success";
  className?: string;
};

export const SettingIcon: React.FC<IoniconProps> = (props) => {
  return (
    <SettingsSharp
      css={`
        cursor: pointer;
      `}
      color={theme.global.whiteColor}
      {...props}
    />
  );
};

export const HelpIcon: React.FC<IoniconProps> = (props) => {
  return (
    <HelpCircleSharp
      css={`
        cursor: pointer;
      `}
      color={theme.global.primaryColor}
      {...props}
    />
  );
};

export const ArrowUpIcon: React.FC<IoniconProps> = (props) => {
  return (
    <ArrowUpSharp
      css={`
        cursor: pointer;
      `}
      color={theme.global.primaryColor}
      {...props}
    />
  );
};

export const ArrowDownIcon: React.FC<IoniconProps> = (props) => {
  return (
    <ArrowDownSharp
      css={`
        cursor: pointer;
      `}
      color={theme.global.redColor}
      {...props}
    />
  );
};

export const ArrowForwardIcon: React.FC<IoniconProps> = ({ type, ...rest }) => {
  return (
    <ArrowForwardCircleSharp
      css={`
        cursor: pointer;
      `}
      color={
        type === "danger" ? theme.global.redColor : theme.global.primaryColor
      }
      {...rest}
    />
  );
};

export const ArrowForwardCircleIcon: React.FC<IoniconProps> = (props) => {
  return (
    <ArrowForwardCircle
      css={`
        cursor: pointer;
      `}
      color={theme.global.primaryColor}
      {...props}
    />
  );
};

export const ArrowBackIcon: React.FC<IoniconProps> = ({ type, ...rest }) => {
  return (
    <ArrowBackCircleSharp
      css={`
        cursor: pointer;
      `}
      color={
        type === "danger" ? theme.global.redColor : theme.global.primaryColor
      }
      {...rest}
    />
  );
};

export const ArrowNextIcon: React.FC<IoniconProps> = ({ type, ...rest }) => {
  return (
    <ArrowForwardSharp
      css={`
        cursor: pointer;
      `}
      color={
        type === "danger" ? theme.global.redColor : theme.global.primaryColor
      }
      {...rest}
    />
  );
};

export const FunnelSharpIcon: React.FC<IoniconProps> = (props) => {
  return (
    <FunnelSharp
      css={`
        cursor: pointer;
      `}
      color={theme.global.whiteColor}
      {...props}
    />
  );
};

export const SearchOutlineIcon: React.FC<IoniconProps> = (props) => {
  return (
    <SearchOutline
      css={`
        cursor: pointer;
      `}
      color={theme.global.grayColor}
      {...props}
    />
  );
};

export const AddOutlineIcon: React.FC<IoniconProps> = (props) => {
  return (
    <AddOutline
      css={`
        cursor: pointer;
      `}
      color={theme.global.grayColor}
      {...props}
    />
  );
};

export const ArrowDownCircleSharpIcon: React.FC<IoniconProps> = (props) => {
  return (
    <ArrowDownCircleSharp
      css={`
        cursor: pointer;
      `}
      color={theme.global.grayColor}
      {...props}
    />
  );
};

export const ImageSharpIcon: React.FC<IoniconProps> = (props) => {
  return (
    <ImageSharp
      css={`
        cursor: pointer;
      `}
      color={theme.global.whiteColor}
      {...props}
    />
  );
};

export const CloseSharpIcon: React.FC<IoniconProps> = (props) => {
  return (
    <CloseSharp
      css={`
        cursor: pointer;
      `}
      color={theme.global.dangerColor}
      {...props}
    />
  );
};

export const PersonCircleOutlineIcon: React.FC<IoniconProps> = (props) => {
  return (
    <PersonCircleOutline
      css={`
        cursor: pointer;
      `}
      color={theme.global.primaryColor}
      {...props}
    />
  );
};

export const ArrowBackOutlineIcon: React.FC<IoniconProps> = (props) => {
  return (
    <ArrowBackOutline
      css={`
        cursor: pointer;
      `}
      color={theme.global.whiteColor}
      {...props}
    />
  );
};

export const ChevronDownOutlineIcon: React.FC<IoniconProps> = (props) => {
  return (
    <ChevronDownOutline
      css={`
        cursor: pointer;
      `}
      color={theme.global.grayColor}
      {...props}
    />
  );
};

export const CloudDownloadSharpIcon: React.FC<IoniconProps> = (props) => {
  return (
    <CloudDownloadSharp
      css={`
        cursor: pointer;
      `}
      color={theme.global.whiteColor}
      {...props}
    />
  );
};

export const PersonSharpIcon: React.FC<IoniconProps> = (props) => {
  return (
    <PersonSharp
      css={`
        cursor: pointer;
      `}
      color={theme.global.primaryColor}
      {...props}
    />
  );
};

export const LockClosedSharpIcon: React.FC<IoniconProps> = (props) => {
  return (
    <LockClosedSharp
      css={`
        cursor: pointer;
      `}
      color={theme.global.primaryColor}
      {...props}
    />
  );
};

export const NotificationsSharpIcon: React.FC<IoniconProps> = (props) => {
  return (
    <NotificationsSharp
      css={`
        cursor: pointer;
      `}
      color={theme.global.colorBcbccb}
      {...props}
    />
  );
};

export const CloudUploadSharpIcon: React.FC<IoniconProps> = (props) => {
  return (
    <CloudUploadSharp
      css={`
        cursor: pointer;
      `}
      color={theme.global.whiteColor}
      {...props}
    />
  );
};
