import React from "react";
import Card from "components/shared/Card";
import Flex from "components/shared/Flex";
import { ArrowForwardIcon } from "components/shared/Icons";
import { useHistory } from "react-router";
import { Text, QtyCardExtraTitle } from "./styled";

const CompanyAddress: React.FC<{ address: string }> = ({ address }) => {
  return (
    <Text
      css={`
        flex: 1;
        text-align: left;
        padding-bottom: 12px;
      `}
    >
      {address}
    </Text>
  );
};

const OrderQty: React.FC<{ qty: number, userId: string | number }> = ({ qty, userId }) => {
  const history = useHistory();

  const navigateOrderDetail = () => {
    history.push({
      pathname: `/manage-customers/customer-details/${userId}`,
      state: { user: {id: userId} },
    });
  };
  
  return (
    <Flex
      css={`
        flex: 1;
      `}
      column
      alignEnd
      justifyBetween
    >
      <Text>{qty}</Text>
      <ArrowForwardIcon type="danger" onClick={navigateOrderDetail} />
    </Flex>
  );
};

const CompanyOrder: React.FC<any> = ({ orderDetails }) => {
  return (
    <Card
      css={`
        margin-top: 4px;
      `}
      withGreenBorder
      title={<span style={{ fontWeight: "bold" }}>{orderDetails?.name}</span>}
      extra={<QtyCardExtraTitle><span style={{ fontWeight: "bold" }}>QTY</span></QtyCardExtraTitle>}
    >
      <Flex>
        <CompanyAddress address={orderDetails?.address} />
        <OrderQty qty={orderDetails?.qty} userId={orderDetails?.userId}/>
      </Flex>
    </Card>
  );
};

export default CompanyOrder;
