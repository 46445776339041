import React, { useEffect, useState } from "react";
import AllVendors from "./styled";
import { Table2 } from "components/shared/Table";
import Flex, { FlexItem } from "components/shared/Flex";
import { ArrowNextIcon, FunnelSharpIcon } from "components/shared/Icons";
import { useHistory } from "react-router-dom";
import Button from "components/shared/Button";
import InputSearch from "components/shared/InputSearch";
import ManageCloudKitchen from "..";
import cloudKitchenService from "services/cloud-kitchen.service";
import map from "lodash/map";

const AllVendorsUI: React.FC = (props) => {
  const history = useHistory();
  const [vendors, setVendors] = useState<any>([]);
  const [vendorsDataSource, setVendorsDataSource] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    getVendors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getVendors = () => {
    cloudKitchenService.getVendors().then((res: any[]) => {
      setLoading(false);
      const vendorList: any[] = map(res, (vendor, index) => ({
        ...vendor,
        ...{
          key: vendor.vendorId,
          vendorNo: index + 1,
          icon: (
            <ArrowNextIcon
              onClick={() => _handleViewDetails(vendor)}
              color="#fff"
              type="success"
              style={{
                backgroundColor: "#1aae48",
                padding: "3px",
                cursor: "pointer",
                borderRadius: "50px",
              }}
            />
          ),
        },
      }));

      setVendors(vendorList);
      setVendorsDataSource(vendorList);
    });
  };

  const HeaderColumns = [
    {
      title: "No.",
      dataIndex: "vendorNo",
      key: "vendorNo",
    },
    {
      title: "Cloud Kitchen",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Contact Number",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Email Address",
      dataIndex: ["user", "email"],
      key: "email",
    },
    {
      title: (function icon() {
        return (
          <FunnelSharpIcon
            color="#fff"
            style={{
              cursor: "pointer",
            }}
          />
        );
      })(),
      dataIndex: "icon",
      key: "icon",
    },
  ];

  const _handleViewDetails = (vendor: any) => {
    history.push({
      pathname: `/manage-cloud-kitchen/details-kitchen/${vendor?.user?.id}`,
      state: { user: vendor?.user },
    });
  };

  const _handleSearch = (e: any) => {
    const value = e.target.value.toLowerCase();
    let dataSourceFilter = map(vendorsDataSource, (item) => ({
      ...item,
      userEmail: item.user.email,
    }));
    if (value !== "") {
      const arrKeysSearch = ["name", "address", "phone", "userEmail"];
      const checkItem = (itemData: any) => {
        const hasItem = arrKeysSearch.some((key) => itemData[key]?.toLowerCase().indexOf(value) !== -1)
        if (hasItem) {
          return itemData;
        } else {
          return null;
        }
      };
      dataSourceFilter = dataSourceFilter
        .map((item: any) => checkItem(item))
        .filter((item: any) => item !== null);
    }
    setVendors(dataSourceFilter);
  };

  const _handleClickAddNew = () => {
    history.push("/manage-cloud-kitchen/new");
  };

  return (
    <ManageCloudKitchen>
      <AllVendors>
        <Flex justifyBetween alignCenter>
          <FlexItem>
            <Button
              type="info"
              className="btn-add-new"
              onClick={() => _handleClickAddNew()}
            >
              Add New
            </Button>
            <Button
              type="primary"
              className="btn-all-vendors"
              onClick={() => getVendors()}
            >
              All Vendors
            </Button>
          </FlexItem>
          <FlexItem>
            <InputSearch
              placeholder="Search Vendors"
              onChange={(e) => _handleSearch(e)}
              className="styled-search"
            />
          </FlexItem>
        </Flex>
        <FlexItem style={{ paddingTop: "10px" }}>
          {
            loading
            ? "Loading..."
            : (
              <Table2
                columns={HeaderColumns}
                dataSource={vendors}
                pagination={false}
              />
            )
          }
        </FlexItem>
      </AllVendors>
    </ManageCloudKitchen>
  );
};

export default AllVendorsUI;
