import React from "react";
import { Form } from "antd";
import { Row, Col } from "components/shared/Grid";
import Input from "components/shared/Input";
import { FieldData } from "../../DetailsEmployees";

export interface DetailsInfoProps {
  form: any;
  dataInfo: FieldData[];
  className?: string;
  title?: string;
  edit?: boolean;
  onChange: (fields: FieldData[]) => void;
  span?: number
}

const DetailsInfo: React.FC<DetailsInfoProps> = ({
  form,
  dataInfo,
  className,
  title,
  edit = false,
  onChange,
  span
}) => {
  return (
    <div className={className}>
      {title ? <div className="title-info-details">{title}</div> : null}
      <div className="main-info-details">
        <Form
          form={form}
          name="editForm"
          fields={dataInfo}
          onFieldsChange={(_, allFields) => {
            const fields = dataInfo.map((data, i) => {
              return {
                title: data.title,
                ...allFields[i],
              };
            });
            onChange(fields);
          }}
        >
          <Row gutter={[8, 8]}>
            {dataInfo.map((data: any, i) => (
              <Col span={span || 6} key={i}>
                <div className="title-info">{data.title}</div>
                {edit ? (
                  <Form.Item className="value-info" name={data.name[0]}>
                    {data.name[0] === "password" ? (
                      <Input isVisible={true} />
                    ) : (
                      <Input disabled={data.name[0] === "employeeId"} />
                    )}
                  </Form.Item>
                ) : (
                  <div className="value-info">{data.value}</div>
                )}
              </Col>
            ))}
          </Row>
        </Form>
      </div>
    </div>
  );
};
export default DetailsInfo;
