import React from "react";
import StyledOrderForLocation from "./styled";
import { Row, Col } from "../Grid";
import Flex, { FlexItem } from "../Flex";
import map from "lodash/map";
export interface IProps {
  dataInfo: any;
  className?: string;
  title?: string;
}

const OrdersForLocation: React.FC<IProps> = ({
  dataInfo = {},
  className,
  title,
}) => {
  return (
    <div className={className}>
      {map(dataInfo.data, (location: any, index: number) => (
        <div key={location.key}>
          <StyledOrderForLocation.WrapLocation>
            <Flex justifyBetween alignCenter>
              <FlexItem css={"width: 70%"}>
                <Row>
                  <Col span={20} css={"padding-left: 0!important"}>
                    Orders For Location {index + 1}: {location.location}
                  </Col>
                  <Col span={4} className="exportPDF">
                    {/* Export PDF */}
                  </Col>
                </Row>
              </FlexItem>
              <FlexItem css={"width: 30%"}>
                Total Orders For This Location: {location.totalOrders}
              </FlexItem>
            </Flex>

            <Flex className="header-order-location">
              <FlexItem css={"width: 70%"}>
                <Row>
                  <Col span={6}>Order</Col>
                  <Col span={14}>QTY</Col>
                  <Col span={4}>Cost</Col>
                </Row>
              </FlexItem>
              <Flex justifyCenter alignCenter css={"width: 30%"}>
                Delivery Location
              </Flex>
            </Flex>

            <Flex>
              <FlexItem css={"width: 70%"}>
                <StyledOrderForLocation.BillQTY>
                  {map(location.billQTY, (billQTY: any, index: number) => (
                    <Row key={index}>
                      <Col span={6}>{billQTY.name}</Col>
                      <Col span={14}>{billQTY.QTY}</Col>
                      <Col span={4}>{billQTY.cost}</Col>
                    </Row>
                  ))}
                </StyledOrderForLocation.BillQTY>

                {location.addOns?.name && (
                  <StyledOrderForLocation.AddOns>
                    <Row>
                      <Col span={24}>Add Ons</Col>
                    </Row>
                    <Row>
                      <Col span={6}>{location.addOns?.name}</Col>
                      <Col span={14}>
                        <Flex>
                          <FlexItem css={"width: 110px"}>Adds(Main):</FlexItem>
                          <FlexItem>
                            {map(location.addOns.addsMain, (addMain: any) => (
                              <StyledOrderForLocation.AddsDetails
                                key={addMain.name}
                              >
                                {addMain.name} x {addMain.amount}
                              </StyledOrderForLocation.AddsDetails>
                            ))}
                          </FlexItem>
                        </Flex>
                        <Flex>
                          <FlexItem css={"width: 110px"}>Adds(Side):</FlexItem>
                          <FlexItem>
                            {location.addOns?.addsSide.map((addSide: any) => (
                              <StyledOrderForLocation.AddsDetails
                                key={addSide.name}
                              >
                                {addSide.name} x {addSide.amount}
                              </StyledOrderForLocation.AddsDetails>
                            ))}
                          </FlexItem>
                        </Flex>
                      </Col>
                      <Col span={4}>{location.addOns?.Cost}</Col>
                    </Row>
                  </StyledOrderForLocation.AddOns>
                )}
              </FlexItem>
              <Flex className="delivery-location">
                {location.deliveryLocation}
              </Flex>
            </Flex>
          </StyledOrderForLocation.WrapLocation>
        </div>
      ))}
      <Flex justifyEnd className="summary">
        <FlexItem css={"width: 33.33333%"}>
          <StyledOrderForLocation.TitleSummary>
            Total QTY
          </StyledOrderForLocation.TitleSummary>
          <StyledOrderForLocation.ValSummary>
            {dataInfo.totalQTY}
          </StyledOrderForLocation.ValSummary>
        </FlexItem>
        {dataInfo.totalCost && (
          <FlexItem css={"width: 33.33333%"}>
            <StyledOrderForLocation.TitleSummary>
              Total Cost
            </StyledOrderForLocation.TitleSummary>
            <StyledOrderForLocation.ValSummary>
              {dataInfo.totalCost}
            </StyledOrderForLocation.ValSummary>
          </FlexItem>
        )}
        <FlexItem css={"width: 33.33333%"}>
          <StyledOrderForLocation.TitleSummary>
            No. Of Locations
          </StyledOrderForLocation.TitleSummary>
          <StyledOrderForLocation.ValSummary>
            {dataInfo.noOfLocation}
          </StyledOrderForLocation.ValSummary>
        </FlexItem>
      </Flex>
    </div>
  );
};

export default OrdersForLocation;
