import React, { useState, useEffect } from "react";
import { Tabs, TabPane } from "components/shared/Tabs";
import PackingAndCourier from "./PackingAndCourier";
import CloudKitchen from "./CloudKitchen";
import PurchaseOrder from "./PurchaseOrder";
import { ProcessingOrdersWrapper } from "./styled";
import userService from "services/user.service";
import downLoadIcon from "assets/images/downLoadBlack.svg";

import orderService from "services/order.service";

const ProcessingOrdersUI: React.FC = () => {
  const [activeTab, setActiveTab] = useState<any>("1");
  const [user, setUser] = useState<any>({});

  const getUser = () => {
    userService.getMe().then((res) => {
      setUser(res);
    });
  };

  const navigateTab = (tab: string) => {
    setActiveTab(tab);
  };

  const handleExport = () => {
    orderService.exportCompletedOrders();
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <ProcessingOrdersWrapper>
      <ProcessingOrdersWrapper.ExportButton onClick={handleExport}>
        <span>Export PDF</span>
        <img src={downLoadIcon} alt="download" />
      </ProcessingOrdersWrapper.ExportButton>
      <Tabs activeKey={activeTab} onChange={navigateTab}>
        <TabPane tab="Cloud Kitchen" key="1">
          <CloudKitchen navigateTab={navigateTab} user={user} />
        </TabPane>

        {user?.roles?.[0].id !== "cloud-kitchen" && (
          <>
            <TabPane tab="Packing & Courier" key="2">
              <PackingAndCourier />
            </TabPane>
            <TabPane tab="Purchase Order" key="3">
              <PurchaseOrder />
            </TabPane>
          </>
        )}
      </Tabs>
    </ProcessingOrdersWrapper>
  );
};

export default ProcessingOrdersUI;
