import React, { useState, useEffect } from 'react';
import Input from 'components/shared/Input';
import Flex, { FlexItem } from 'components/shared/Flex';
import Select from 'components/shared/Select';
import StyledAllAddOns from './styled';
import { CloseSharpIcon } from 'components/shared/Icons';
import Button from 'components/shared/Button';
import Upload from 'components/shared/Upload';
import menuService from 'services/menu.service';
import commonService from "services/common.service";
import { Form } from "antd";
import { Toastify } from 'components/shared/Toast/Toast';
import Theme from "theme";
export interface IPropsAddOns {
  isCheck: boolean;
  submitForm(body: any): any;
  form: any;
}

const FormAddOns: React.FC<IPropsAddOns> = ({
  isCheck,
  submitForm,
  form,
}) => {
  const [menuCategory, setMenuCategory] = useState([]);
  const [menuId, setMenuId] = useState<any>();
  const [image, setImage] = useState<any>([]);
  const [defaultImage, setDefaultImage] = useState<any>([]);

  useEffect(() => {
    menuService.getMenuNoPagination().then((resultMenu) => {
      const menuCategory = resultMenu.map((item: any) => {
        return {
          id: item.id,
          name: item.name,
        };
      });
      setMenuCategory(menuCategory);
    });
  }, []);

  const dropMenuCategory = menuCategory.map((item: any, index: number) => {
    return {
      id: item.id,
      value: item.id,
      label: item.name,
    }
  })

  const _handleChangeMenuCategory = (e: any) => {setMenuId(e)}

  const _handlePublished = async (isPush: boolean) => {
    try {
      const values = await form.validateFields();
      const imageData = new FormData();
      imageData.append("files", image[0]);
      const body: any = {
        name: values.name,
        photo: "",
        logicType: "addons",
        menuId: menuId,
        calories: parseInt(values.calories),
        price: parseInt(values.price),
        isPublished: isPush
      }
      commonService
      .uploadFile(imageData)
      .then((res) => {
        body.photo = res[0];
        addOns(body);
      })
      .catch((err) => {
        Toastify.showDangerToast(`Upload Profile ${err} Picture fail!`);
      });
    } catch (error) {
      console.log("Failed:", error);
    }
  }

  const addOns = (body: any) => {
    submitForm(body);
  }

  const propsUpload = {
    showUploadList: {
      showRemoveIcon: true,
      removeIcon: (
        <CloseSharpIcon
          style={{ color: Theme.global.coloreD3833, fontSize: "20px", top: 0, right: 0 }}
          onClick={(e: any) => {
            setImage([]);
            setDefaultImage([]);
          }}
        />
      ),
    },
    accept: "image/*",

    beforeUpload: (file: any) => {
      setImage([file]);

      return false;
    },
    fileList: defaultImage,
  };

  // Remove old picture
  if (!!image.length) {
    delete propsUpload.fileList;
  }

  function onKeypress(e: any) {
    if (e.key === "Enter") {
      _handlePublished(true || false);
    }
  }

  useEffect(() => {
    window.addEventListener("keypress", onKeypress);
    return () => {
      window.removeEventListener("keypress", onKeypress);
    };
  });

  return (
    <StyledAllAddOns.AddOns>
      <Flex nowrap>
        <FlexItem>
          <Form.Item
            name="name"
            rules={[
              { required: true, message: "Please input your name!" },
            ]}
          >
            <Input
              label='Add On Name'
              style={{ maxWidth: '350px' }}
              placeholder=''
            />
          </Form.Item>
            <Flex style={{ padding: '17px 0', maxWidth: '350px' }}>
              <FlexItem>
                <p className="label">Add Food Image</p>
                <div className="add-food-image">
                  <Form.Item
                    name="photo"
                    rules={[
                      { required: true, message: "Please input your photo!" },
                    ]}
                  >
                    <Upload
                      listType="picture"
                      maxCount={1}
                      name="photo"
                      {...propsUpload}
                    >
                        Upload Image
                    </Upload>
                  </Form.Item>
                  <p className="notes">
                    Image size at least 500px by 500px in
                    Jpeg or PNG format.
                  </p>
                </div>
              </FlexItem>
            </Flex>
            <Form.Item
              name="menuId"
              rules={[
                { required: true, message: "Please input your category!" },
              ]}
            >
              <Select
                notesTop='Add Menu Category'
                placeholder='Dessert'
                onChange={(e) => _handleChangeMenuCategory(e)}
                options={dropMenuCategory}
              >
              </Select>
            </Form.Item>
            <Form.Item
              name="calories"
              rules={[
                { required: true, message: "Please input your calories!" },
              ]}
            >
              <Input
                label='Add Calories'
                style={{ maxWidth: '350px' }}
                placeholder='Enter Calories'
                notes='Choose amount of Calories'
              />
            </Form.Item>
            <Form.Item
              name="price"
              rules={[
                { required: true, message: "Please input your price!" },
              ]}
            >
              <Input
                label='Add Price'
                style={{ maxWidth: '350px' }}
                placeholder='Enter Price'
                notes='Select amount'
              />
            </Form.Item>
        </FlexItem>
      </Flex>
      <Flex>
        <FlexItem style={{ paddingTop: '38px' }}>
          <Button
            type='delete'
            className='custom-btn-add-food'
            onClick={() => _handlePublished(false)}
            htmlType="submit"
          >
            Save As Draft
          </Button>
          <Button
            type='primary'
            style={{ marginLeft: '20px' }}
            className='custom-btn-add-food'
            onClick={() => _handlePublished(true)}
            htmlType="submit"
          >
            {isCheck ? "Publish" : "Edit"}
          </Button>
        </FlexItem>
      </Flex>
    </StyledAllAddOns.AddOns>
  );
} 

export default FormAddOns;
