import styled from 'styled-components';

const StyleCheckBox = styled.div`
  align-items: unset;
  & {
    .ddl-checkbox {
      align-items: center;
    }
    .ant-checkbox-inner {
      width: 30px;
      height: 30px;
      background-color: ${props => props.theme.global.whiteColor};
      border-color: ${props => props.theme.global.whiteColor};
      border: none;
      &:after {
        width: 10px;
        top: 12px;
        height: 20px;
        border-color: ${props => props.theme.global.primaryColor};
      }
    }
    .ant-checkbox-checked {
      &:after {
        border: 0;
      }
    }
    .ant-checkbox + span {
      font-size: 14px;
      font-weight: normal;
      color: ${props => props.theme.global.grayColor} !important;
    }
  }
`;

export default StyleCheckBox;
