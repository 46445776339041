import React from 'react'
import styled from 'styled-components'

const ManageCloudKitchenContainer = styled.div`
  width: 100%;
`

const ManageCloudKitchen: React.FC = ({ children }) => {
  return <ManageCloudKitchenContainer>{ children }</ManageCloudKitchenContainer>
}

export default ManageCloudKitchen
