import React, { useState, useEffect } from "react";
import StyledCustomers from "./styled";
import Flex, { FlexItem } from "components/shared/Flex";
import Button from "components/shared/Button";
import { Tabs2, TabPane } from "components/shared/Tabs";
import ReportDetails from "../reports-shared/ReportDetails";
import Chart from "react-apexcharts";
import { useHistory } from "react-router-dom";
import GroupTitle from "components/shared/GroupTitle";
import downloadIcon from "assets/images/download.svg";
import Theme from "theme";
import reportService from "services/report.service";
import { getPeriodTimeParam } from "../reports.util";
import map from "lodash/map";
import CustomDate from "../reports-shared/CustomDate";

const dataDetailsReport = [
  {
    id: 1,
    value: "",
    subtitle: "Total New Corporate Sign Ups In this period",
    description: "*Not inclusive of employee sign ups",
    borderTop: "hideBorderTop",
    width: "28%",
  },
  {
    id: 2,
    value: "",
    subtitle: (
      <>
        Average employee sign ups <br /> Per corporate
      </>
    ),
    description: null,
    borderLeft: "hideBorderLeft",
    borderRight: "hideBorderRight",
    borderTop: "hideBorderTop",
    width: "25%",
  },
  {
    id: 3,
    value: "",
    subtitle: (
      <>
        Terminate contract in this <br /> Period
      </>
    ),
    description: null,
    borderRight: "hideBorderRight",
    borderTop: "hideBorderTop",
    width: "25%",
  },
  {
    id: 4,
    value: null,
    subtitle: null,
    description: null,
    borderTop: "hideBorderTop",
    width: "22%",
  },
];

const CustomersUI: React.FC = (props) => {
  const history = useHistory();
  const [isActive, setActive] = useState<boolean>(false);
  const [customerReport, setCustomerReport] = useState<any>(dataDetailsReport);
  const [series, setSeries] = useState<any>([]);

  const clickGoBtn = (dateFrom: any, dateTo: any) => {
    const param = getPeriodTimeParam(4, { from: dateFrom, to: dateTo });
    getCustomReport(param);
  };

  const titleCard: Array<any> = [
    "Last Month",
    "This Month",
    "Last 7 Days",
    "1 Year",
    <CustomDate clickGoBtn={clickGoBtn} />,
    <span>
      {" "}
      {/* Export CSV */}
      <img src={downloadIcon} alt="download" style={{ marginLeft: "10px" }} />
    </span>,
  ];

  const getCustomReport = (param: string) => {
    reportService.getCustomerReport(param).then((result) => {
      const dataCustome = customerReport;

      dataCustome[0].value = result.totalNewCorporateSignup;
      dataCustome[1].value = result.avgEmployeeSignupPerCorp;
      dataCustome[2].value = result.terminatedContract;

      setCustomerReport([...dataCustome]);
      setSeries([...[{ data: result.data }]]);
    });
  };

  const tabTimeChange = (tab: string) => {
    // Custom Date
    if (tab === "4") {
      return;
    }

    // Export CSV
    if (tab === "5") {
      return;
    }

    const param = getPeriodTimeParam(+tab);
    getCustomReport(param);
  };

  useEffect(() => {
    const param = getPeriodTimeParam(0);
    getCustomReport(param);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const options: any = {
    chart: {
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: { show: false },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    title: {
      text: "No. Of Sign Ups",
      align: "right",
      style: {
        fontSize: "10px",
        fontWeight: "500",
        color: `${Theme.global.primaryColor}`,
      },
    },
    grid: {
      row: {
        colors: [`${Theme.global.whiteColor}`, `${Theme.global.whiteColor}`],
      },
      column: {
        colors: [`${Theme.global.whiteColor}`, `${Theme.global.whiteColor}`],
      },
    },
    colors: [`${Theme.global.orangeColor}`],
    xaxis: {
      title: {
        text: "Date",
      },
      labels: {
        style: {
          colors: `${Theme.global.primaryColor}`,
          fontSize: "9px",
          fontWeight: 500,
          cssClass: "apexcharts-xaxis-label",
        },
      },
    },
    yaxis: {
      show: true,
      opposite: true,
      // min: 1,
      // max: 31,
      labels: {
        show: true,
        align: "right",
        style: {
          colors: `${Theme.global.primaryColor}`,
          fontSize: "9px",
          fontWeight: 500,
          cssClass: "apexcharts-xaxis-label",
        },
      },
    },
  };

  const _handleOrders = () => {
    history.push("/reports/orders");
  };

  const _handleCustomers = () => {
    history.push("/reports/customers");
    setActive(true);
  };

  const _handleNewCorporateSignUps = () => {
    history.push("/manage-customers/new");
  };

  return (
    <StyledCustomers>
      <Flex>
        <Button className="customer-btn" onClick={_handleOrders}>
          Orders
        </Button>
        <Button
          className={`customer-btn ml-20 ${!isActive ? "active-btn" : null}`}
          onClick={_handleCustomers}
        >
          Customers
        </Button>
      </Flex>
      <Flex>
        <GroupTitle
          fontSize={16}
          onClick={_handleNewCorporateSignUps}
          style={{
            cursor: "pointer",
            padding: "24px 0",
            marginLeft: 0,
            fontWeight: 600,
            textDecoration: "underline",
          }}
        >
          New Corporate Sign Ups
        </GroupTitle>
      </Flex>
      <Tabs2 defaultActiveKey="0" onChange={tabTimeChange}>
        {titleCard.map((item, index) => (
          <TabPane tab={item} key={index}></TabPane>
        ))}
      </Tabs2>
      <Flex style={{ flexWrap: "wrap" }}>
        {map(customerReport, (item: any, index: number) => {
          return (
            <FlexItem key={index} style={{ width: item.width }}>
              <ReportDetails
                value={item.value}
                subtitle={item.subtitle}
                description={item.description}
                hideBorderLeft={item.borderLeft}
                hideBorderRight={item.borderRight}
                hideBorderTop={item.borderTop}
              />
            </FlexItem>
          );
        })}
      </Flex>
      <div
        style={{
          paddingTop: "15px",
          backgroundColor: `${Theme.global.whiteColor}`,
        }}
      >
        <Chart width="99%" options={options} series={series} type="line" />
      </div>
    </StyledCustomers>
  );
};

export default CustomersUI;
