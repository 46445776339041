import styled from "styled-components";
import { getCss } from "utils/cssHelpers";

export const StyledDetails: any = styled.div``;

const AllVendorsWrapper: any = styled.div``;

AllVendorsWrapper.WrapperVendorCompanyDetails = styled.div`
  & {
    .vendor-company-details-title {
      padding: 12px 0 13px 0;
      margin-left: 0;
    }
  }
`;

AllVendorsWrapper.WrapperPointOfContact = styled.div`
  width: 100%;
  & {
    .poin-of-contact-title {
      padding: 12px 0 13px 0;
      margin-left: 0;
    }
    .ant-table-thead {
      .ant-table-cell {
        ${getCss("color")("theme.global.whiteColor")};
        background-color: ${(props) =>
          props.theme.global.primaryColor} !important;
        font-size: 18px;
        font-weight: 500;
      }
    }
    .ant-table-tbody {
      .ant-table-cell {
        ${getCss("color")("theme.menu.textColor")};
        ${getCss("background-color")("theme.menu.subItemBgColor")};
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
`;

AllVendorsWrapper.WrapperPayInSettlement = styled.div`
  & {
    .ant-table-thead {
      font-size: 14px;
      font-weight: 500;
    }
    .styled-group-title {
      flex-direction: row-reverse;
      margin-right: 13px;
      padding: 33px 0 31px 0;
    }
    .ant-table-tbody {
      font-size: 14px;
      font-weight: 500;
      ${getCss("background")("theme.table2.bodyBgColor")};
      ${getCss("color")("theme.menu.textColor")};
      .ant-table-row {
        ${getCss("background")("theme.table2.bodyBgColor")};
      }
    }
  }
`;

AllVendorsWrapper.WrapperWeeklyMenu = styled.div`
  & {
    .weekly-menu-title {
      padding: 16px 0 5px 0;
      margin-left: 0;
    }
  }
  .bg-table-weekly-menu {
    width: 100%;
    ${getCss("background")("theme.global.primaryColor")};
    .bar-menu-item {
      max-width: 60px;
      display: block;
      padding: 0 5px 33px 5px;
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      ${getCss("color")("theme.global.whiteColor")};
    }
    .ant-table-thead {
      .ant-table-cell {
        background: ${(props) => props.theme.global.primaryColor} !important;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: normal;
        ${getCss("color")("theme.global.whiteColor")};
      }
    }
    .ant-table-tbody {
      .ant-table-cell {
        background: ${(props) => props.theme.table.theadBgColor} !important;
        ${getCss("color")("theme.global.grayColor")};
        font-size: 12px;
        font-weight: 500;
      }
      [data-row-key^="fruitPlatter"] {
        .ant-table-cell {
          background: ${(props) => props.theme.table.subBodyBgColor} !important;
          padding: 20px 8px;
          ${getCss("color")("theme.global.whiteColor")};
        }
        .column-header {
          background: ${(props) => props.theme.global.primaryColor} !important;
          font-size: 14px;
          font-weight: 500;
          ${getCss("color")("theme.global.whiteColor")};
        }
      }
      .column-header {
        background: ${(props) => props.theme.global.primaryColor} !important;
        font-size: 14px;
        font-weight: 500;
        ${getCss("color")("theme.global.whiteColor")};
      }
    }
  }
`;

AllVendorsWrapper.BlockBackground = styled.div`
  padding: 50px 0 0 70px;
  ${getCss("background")("theme.table2.bodyBgColor")};
`;

export default AllVendorsWrapper;
