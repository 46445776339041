import styled from 'styled-components'
import Tabs from './Tabs'
import TabPane from './TabPane'
import { getCss } from 'utils/cssHelpers';

const StyledTabs = styled(Tabs)`
    .ant-tabs-nav {
        margin-bottom: 0;
        height: 30px;
        align-item: center;
        .ant-tabs-tab {
            padding: 0;
            font-size: 14px;
            font-weight: 500;
            ${getCss('color')('theme.tabs.tabColor')};
            &.ant-tabs-tab-active {
                .ant-tabs-tab-btn {
                    ${getCss('color')('theme.tabs.tabColor')};
                    text-shadow: none;
                }
            }
            &:hover {
                ${getCss('color')('theme.tabs.tabColor')};
            }
        }
        .ant-tabs-ink-bar {
            bottom: 7px;
            height: 1px;
            ${getCss('background')('theme.tabs.barColor')};
        }
    }
`;

const styledTabs2 = styled(StyledTabs)`
    width: 100%;
  & {
    .ant-tabs-nav {
      background: ${props => props.theme.global.primaryColor} !important;
      padding: 5px 15px !important;
    }
    .ant-tabs-tab-btn {
      font-size: 16px !important;
      font-weight: 500 !important;
      color: ${props => props.theme.global.whiteColor} !important;
    }
    .ant-tabs-content-holder {
      background: ${props => props.theme.global.tertiaryColor} !important;
    }
    .ant-tabs-ink-bar {
      bottom: 0px !important;
      background: ${props => props.theme.global.whiteColor} !important;
    }
  }
`

export const StyledTabPane = styled(TabPane)``;

export { StyledTabs, styledTabs2 }