import React, { useEffect, useState } from 'react';
import WrapCloudKitChenVendors from './styled';
import { ArrowForwardIcon } from 'components/shared/Icons';
import InputSearch from 'components/shared/InputSearch';
import Flex from 'components/shared/Flex';
import Table from 'components/shared/Table';
import { useHistory } from 'react-router-dom';
import { columns, IPropsCloudKitChenVendors } from "./finance-CloudKitchenVendors.util";
import financePaymentService from "services/finance-payment.service";

const CloudKitchenVendors: React.FC = (props) => {
  const history = useHistory();
  const [cloudKitchenVendors, setCloudKitchenVendors] = useState<Array<any>>([]);
  const [searchCloudKitchenVendors, setSearchCloudKitchenVendors] = useState<Array<any>>([]);

  const listCloudKitchenVendors = () => {
    financePaymentService.getListCloudkitchens().then((data) => {
      const result = data?.map((item: IPropsCloudKitChenVendors, index: number) => ({
        key: item.id,
        CustomerName: item.name,
        POC: item.poc,
        Contact: item.contact,
        Email: item.email,
        BillingAddress: item.billingAddress,
        Details: <ArrowForwardIcon />,
      }));

      setCloudKitchenVendors(result);
      setSearchCloudKitchenVendors(result);
    })
  }

  useEffect(() => {
    listCloudKitchenVendors();
  }, []);

  const _handleSearch = (e: any) => {
    const value = e.target.value.toLowerCase();
    var dataSourceFilter = searchCloudKitchenVendors;
    if (value !== '') {
      const listKeysSearch: Array<string> = ['CustomerName', 'POC', 'Contact', 'Email', 'BillingAddress'];
      const checkItem = (itemData: any) => {
        if (listKeysSearch.some(key => itemData[key].toLowerCase().indexOf(value) !== -1)) {
          return itemData;
        } else {
          return null;
        }
      }
      dataSourceFilter = searchCloudKitchenVendors.map((item: any) => checkItem(item)).filter((item: any) => item !== null);
    }
    setCloudKitchenVendors(dataSourceFilter);
  };

  const _handleClickCustomer = (record: any) => {
    history.push(`/finance-payment/cloud-kitchen-vendors-details/${record.key}`);
  };

  return (
    <WrapCloudKitChenVendors>
      <Flex justifyBetween alignCenter className="header-settlement">
        <div>Accounts Payable To Cloud Kitchen/Vendors</div>
        <InputSearch
          placeholder="Search Settlement"
          onInput={e => _handleSearch(e)}
          className="input-search-settlement"
        />
      </Flex>
      <Table
        dataSource={cloudKitchenVendors}
        columns={columns}
        pagination={false}
        className="table-settlement"
        onRow={(record) => {
          return {
            onClick: () => _handleClickCustomer(record),
          };
        }}
      />
    </WrapCloudKitChenVendors>
  );
}

export default CloudKitchenVendors;
