import styled from 'styled-components'
import { getCss } from 'utils/cssHelpers'
import Table from './Table'
import TableColumn from './TableColumn'

const StyledTable = styled(Table)`
  .ant-table {
    .ant-table-title {
      padding: 8px;
      font-size: 16px;
      font-weight: 500;
      ${getCss('color')('theme.global.whiteColor')};
      ${getCss('background')('theme.global.primaryColor')};
    }
    .ant-table-footer {
      background: 'inherit';
    }
    table {
      .ant-table-selection-column {
        .ant-checkbox-inner {
          ${getCss('border-color')('theme.global.primaryColor')};
          ${getCss('background')('theme.global.whiteColor')};
          width: 35px;
          height: 35px;
          border-radius: 3px;
          box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
          border-style: solid;
          border-width: 3px;
        }
      }
      .ant-table-thead {
        .ant-table-cell {
          ${getCss('background')('theme.table.theadBgColor')};
        }
      }
      .ant-table-cell {
        padding: 8px;
      }
      .ant-table-tbody > tr > td {
        border-bottom: none;
      }
    }
  }
  .ant-pagination-item {
    border-radius: 50%;
    a {
      ${getCss('color')('theme.global.whiteColor')};
    }
    ${getCss('background')('theme.global.primaryColor')};
    &:hover {
      ${getCss('border-color')('theme.global.primaryColor')};
    }
  }
  .ant-pagination-item-active {
    color: #878787;
    ${getCss('background-color')('theme.table.theadBgColor')};
    ${getCss('border-color')('theme.table.borderColor !important')};
    a {
      ${getCss('color')('theme.global.color444444')};
    }
  }
  .ant-pagination-item-link {
    border-radius: 50%;
  }
`

const Styled2Table = styled(StyledTable)`
  .ant-table {
    table {
      .ant-table-thead {
        .ant-table-cell {
          ${getCss('background')('theme.table2.theadBgColor')};
          font-size: 20px;
          color: #ffffff;
          line-height: 1.2;
          font-weight: 500;
        }
      }
      .ant-table-tbody > tr > td {
        ${getCss('background')('theme.table2.bodyBgColor')};
        font-size: 18px;
        font-weight: 500;
      }
    }
  }
`

const Styled3Table = styled(StyledTable)`
  .ant-table {
    .ant-table-title {
      height: 60px;
      ${getCss('background')('theme.table.headeBgColor')};
      padding: 20px 0 21px;
      text-align: center;
    }
    table {
      .ant-table-thead {
        .ant-table-cell {
          ${getCss('background')('theme.global.primaryColor')};
          ${getCss('color')('theme.global.whiteColor')};
        }
      }
      .ant-table-tbody {
        .ant-table-cell {
          padding: 16px 8px;
        }
        tr {
          &:nth-child(2n + 1) {
            ${getCss('background')('theme.global.tertiaryColor')};
          }
          td {
            background: unset;
          }
          .ant-table-cell:first-child {
            background: rgba(222, 222, 222, 0.4);
          }
        }
      }
    }
  }
`;

export const StyledTableColumn = styled(TableColumn)``

export {StyledTable, Styled2Table, Styled3Table}