import React, { useState, useEffect } from "react";
import { Table2 } from "components/shared/Table";
import Button from "components/shared/Button";
import { NewOrdersWrapper } from "./styled";
import orderService from "services/order.service";
import map from "lodash/map";
import Clock from "./Clock";
import { useHistory } from "react-router";
import { orderColumns, orderStatus as STATUS } from "../manage-orders.config";
import { Toastify } from "components/shared/Toast/Toast";
import userService from "services/user.service";

const RealTimeLiveOrders: React.FC = () => {
  const [orders, setOrders] = useState<any[]>([]);
  const [orderStatus, setOrderStatus] = useState("");
  const [isStopRealTime, setIsStopRealTime] = useState<boolean>(false);
  const [user, setUser] = useState<any>();
  const history = useHistory();

  useEffect(() => {
    const intervalOrderLive = setInterval(() => getLiveOrders(), 15000);

    if (isStopRealTime) {
      clearInterval(intervalOrderLive);
    }

    return () => {
      clearInterval(intervalOrderLive);
    };
  }, [isStopRealTime]);

  const handleStopLiveOrder = (isStop: boolean) => {
    if (!isStop) {
      getLiveOrders();
    }

    setIsStopRealTime(isStop);
  };

  useEffect(() => {
    getLiveOrders();
    getTodayOrderStatus();
    getUser();
  }, []);

  const getUser = () => {
    userService.getMe().then((res) => {
      setUser(res);
    });
  }

  const getLiveOrders = () => {
    orderService
      .getLiveOrders()
      .then((res) => {
        const newOrders: any[] = map(res, (item: any, index: number) => ({
          ...item,
          menuNo: index + 1,
          key: item.food.id,
        }));
        setOrders(newOrders);
      })
      .catch((err) => {});
  };

  const getTodayOrderStatus = () => {
    orderService
      .getTodayOrderStatus()
      .then((res) => {
        setOrderStatus(res.data);
      })
      .catch((err) => {});
  };

  const startProcessOrder = () => {
    if (orderStatus === STATUS.PAID && user?.roles?.[0].id === "cloud-kitchen") {
      orderService
        .startPreparingOrder()
        .then(() => {
          Toastify.showSuccessToast("Success!");
          history.push("/manage-orders/processing");
        })
        .catch((err) => {
          Toastify.showDangerToast("Failure!");
        });
    }
  };

  return (
    <NewOrdersWrapper className="real-time-live-orders manage-orders">
      <Clock handleStopLiveOrder={handleStopLiveOrder} />

      <Table2 dataSource={orders} columns={orderColumns} pagination={false} />
      <Button
        className="btn-process-all-order"
        disabled={!isStopRealTime || orderStatus !== STATUS.PAID || user?.roles?.[0].id !== "cloud-kitchen"}
        onClick={startProcessOrder}
      >
        Process All Order
      </Button>
    </NewOrdersWrapper>
  );
};

export default RealTimeLiveOrders;
