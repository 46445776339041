import React from 'react'
import { Button as AntButton, ButtonProps as AntButtonProps } from 'antd'
import classNames from 'classnames'

export interface ButtonProps extends Omit<AntButtonProps, 'type'> {
  type?: 'primary' | 'info' | 'danger' | 'link' | 'delete' | 'yellow' | 'disabled'
}

const Button: React.FC<ButtonProps> = ({ type, ...rest }) => {
  const classes = classNames('ddl-btn')
  return <AntButton className={classes} {...rest} {...(type === 'link' ? { type } : {})} />
}

export default Button
