import React, { useState, useEffect } from "react";
import StyledMyFeedbackParticulars from "./styled";
import Button from "components/shared/Button";
import Flex, { FlexItem } from "components/shared/Flex";
import GroupTitle from "components/shared/GroupTitle";
// import Textarea from "components/shared/Textarea";
import { useHistory, useParams } from "react-router-dom";
import DetailsInfo from "components/shared/DetailsInfo";
import infocenterService from "services/infocenter.service";
// import { Form } from "antd";

const MyFeedbackParticulars: React.FC = (props) => {
  const history = useHistory();
  const { id }: any = useParams();
  // const [form] = Form.useForm();
  const [feedbackParticulars, setFeedbackParticulars] = useState({});
  const [message, setMessage] = useState<string>("");

  useEffect(() => {
    infocenterService.getFeedbackDetails(id).then((res) => {
      const newData = {
        name: {
          label: "Name",
          value: res.name,
        },
        organization: {
          label: "Organization",
          value: res.organization,
        },
        type: {
          label: "Type Of Enquiry",
          value: res.type,
        },
        subject: {
          label: "Subject",
          value: res.subject,
        },
      };
      setFeedbackParticulars(newData);
      setMessage(res.message);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _handleMyFeedback = () => {
    history.push("/information-centre/my-feedback");
  };

  // const _handleSeenFeedback = () => {
  //   history.push("/information-centre/send-feedback");
  // };

  // const _handleSubmit = (values: any) => {
  //   infocenterService.replyFeedback(id, values);
  // };

  return (
    <StyledMyFeedbackParticulars>
      <Flex>
        <FlexItem>
          <Button
            type="primary"
            className="custome-btn"
            onClick={_handleMyFeedback}
          >
            My Feedback
          </Button>
          {/* <Button
            type="primary"
            className="custome-btn ml-10"
            onClick={_handleSeenFeedback}
          >
            Send Feedback
          </Button> */}
        </FlexItem>
      </Flex>
      <Flex>
        <FlexItem basis="50%">
          <DetailsInfo
            title="Feedback Particulars"
            dataInfo={[feedbackParticulars]}
          />
          {/* <Flex style={{ paddingTop: "20px", textAlign: "end" }}>
            <FlexItem
              css={`
                flex: 1;
              `}
            >
              <Form form={form} onFinish={_handleSubmit}>
                <GroupTitle
                  fontSize={16}
                  style={{
                    fontWeight: "700",
                    marginLeft: "0",
                    paddingBottom: "15px",
                  }}
                >
                  Reply To Sender
                </GroupTitle>
                <Form.Item
                  name="message"
                  rules={[
                    { required: true, message: "Please input your message!" },
                  ]}
                >
                  <Textarea
                    placeholder="Enter message here"
                    className="custome-textarea"
                  />
                </Form.Item>
                <Button
                  type="primary"
                  style={{
                    padding: "6px 35px",
                    lineHeight: "0",
                    marginTop: "25px",
                  }}
                  htmlType="submit"
                >
                  Send Feedback
                </Button>
              </Form>
            </FlexItem>
          </Flex> */}
        </FlexItem>
        <FlexItem basis="50%" style={{ paddingLeft: "25px", marginTop: 10 }}>
          <Flex>
            <FlexItem>
              <GroupTitle
                fontSize={16}
                style={{
                  fontWeight: "700",
                  marginLeft: "0",
                  paddingBottom: "15px",
                }}
              >
                Message
              </GroupTitle>
            </FlexItem>
          </Flex>
          <Flex>
            <div className="bg-message">{message}</div>
          </Flex>
        </FlexItem>
      </Flex>
    </StyledMyFeedbackParticulars>
  );
};

export default MyFeedbackParticulars;
