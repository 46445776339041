import React, { useState, useEffect } from "react";
import Card from "components/shared/Card";
import Button from "components/shared/Button";
import Modal from "components/shared/Modal";
import InputTimePicker from "components/shared/InputTimePicker";
import InputDatePicker from "components/shared/InputDatePicker";
import { ModalSetLiveOrderStyled } from "./styled";
import commonService from "services/common.service";
import moment from "moment";
import Theme from "theme";

function msToTime(duration: number) {
  if (duration <= 0) {
    return "00:00:00";
  }

  let seconds: any = Math.floor((duration / 1000) % 60),
    minutes: any = Math.floor((duration / (1000 * 60)) % 60),
    hours: any = Math.floor((duration / (1000 * 60 * 60)) % 24);

  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  seconds = seconds < 10 ? "0" + seconds : seconds;

  return hours + ":" + minutes + ":" + seconds;
}

interface IClock {
  handleStopLiveOrder(isStopRealTime: boolean): void;
}

const Clock: React.FC<IClock> = ({ handleStopLiveOrder }) => {
  const formatTime = "HH:mm";
  const formatDate = "DD-MM-YYYY";
  const [countdown, setCountDown] = useState<string>("");
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [valueTime, setValueTime] = useState<any>(null);
  const [valueDate, setValueDate] = useState<any>(null);
  const [timeoutDefault, setTimeoutDefault] = useState<any>(null);
  const [orderTimeout, setOrderTimeout] = useState(0);
  const [isStopRealTime, setIsStopRealTime] = useState<boolean>(true);

  const handleChangeTime = (time: any) => setValueTime(time);

  const handleChangeDate = (date: any) => setValueDate(date);

  const updateLiveOrderTimeout = () => {
    console.log("parsing...", valueTime, valueDate);
    // const time = moment(valueTime).format(formatTime);
    // const date = moment(valueDate).format("YYYY-MM-DD");
    // const timeout = new Date(`${date} ${time}`).toISOString();
    // const time = moment(valueTime);
    const date = moment(valueDate);
    const hours = valueTime.get("hours");
    const minutes = valueTime.get("minutes");
    console.log("hours:minutes", hours, minutes);

    date.set("hours", hours);
    date.set("minutes", minutes);
    console.log("parsed", date);

    const timeout = date.toISOString();

    const body = { value: timeout };
    commonService
      .addLiveCutOffTime(body)
      .then(() => {
        console.log("saved");
        getLiveCutOffTime();
        setModalVisible(false);
      })
      .catch(() => {});
  };

  const getLiveCutOffTime = () => {
    commonService.getLiveCutOffTime().then((res) => {
      if (!res.data) {
        return;
      }

      const currentTimeStamp = new Date().setHours(0, 0, 0, 0);
      const orderTimeEffectTimeStamp = new Date(res.data).setHours(0, 0, 0, 0);

      if (currentTimeStamp >= orderTimeEffectTimeStamp) {
        const currDate = new Date().getTime();
        const startDate = new Date().setHours(0, 0, 0, 0);
        const endDate = new Date().setHours(
          new Date(res.data).getHours(),
          new Date(res.data).getMinutes(),
          0,
          0
        );

        // In live-time range
        if (currDate >= startDate && currDate <= endDate) {
          setIsStopRealTime(false);
          handleStopLiveOrder(false);
        } else {
          // Out of range live-time
          setIsStopRealTime(true);
          handleStopLiveOrder(true);
        }

        setOrderTimeout(endDate);
      }

      console.log(`moment check ${res.data}`, moment(res.data));
      setTimeoutDefault(res.data);
      setValueTime(moment(res.data));
      setValueDate(moment(res.data));
    });
  };

  useEffect(() => {
    getLiveCutOffTime();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const timeCount = setInterval(function () {
      const currentTimeStamp = new Date().getTime();
      const duration = orderTimeout - currentTimeStamp;
      setCountDown(msToTime(duration));

      if (duration < 0) {
        setIsStopRealTime(true);
        clearInterval(timeCount);
        handleStopLiveOrder(true);
      }
    }, 1000);

    return () => {
      clearInterval(timeCount);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderTimeout]);

  const closePopupSetTimeout = () => {
    console.log(`timeoutDefault ${timeoutDefault}`, moment(timeoutDefault));
    setValueTime(moment(timeoutDefault));
    setValueDate(moment(timeoutDefault));
    setModalVisible(false);
  };

  const getTitle = () => {
    if (isStopRealTime) {
      return `Real Time Live Order Stopped: 00:00:00`;
    } else {
      return `Real Time Live Order: ${countdown}`;
    }
  };

  return (
    <>
      <Button
        className="btn-ctrl-modal-set-live-order"
        onClick={() => setModalVisible(true)}
      >
        Update Live Order Time Out
      </Button>
      <Modal visible={modalVisible} onCancel={() => closePopupSetTimeout()}>
        <ModalSetLiveOrderStyled>
          <div className="lable-item">Set Live Order Cut Off Time</div>
          <InputTimePicker
            // defaultValue={moment('12:08', format)}
            value={valueTime}
            format={formatTime}
            placeholder={formatTime}
            onChange={handleChangeTime}
            className="input-set-time-live-order"
          />
          <div className="lable-item">With Effect From</div>
          <InputDatePicker
            onChange={handleChangeDate}
            placeholder="DDMMYYYY"
            className="input-set-date-live-order"
            value={valueDate}
            format={formatDate}
          />
          <Button
            disabled={!valueDate || !valueTime}
            className="btn-save-set-live-order"
            type="info"
            onClick={updateLiveOrderTimeout}
          >
            Save
          </Button>
        </ModalSetLiveOrderStyled>
      </Modal>

      <Card
        title={getTitle()}
        bodyStyle={{ padding: "0" }}
        headStyle={{
          backgroundColor: isStopRealTime
            ? Theme.global.color5d5d5d
            : Theme.global.redColor,
        }}
      ></Card>
    </>
  );
};

export default Clock;
