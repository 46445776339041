import React from 'react';
import { Input } from 'antd';
import { TextAreaProps as AntTextAreaProps } from 'antd/es/input';
import StyledTextarea from './styled';

export interface TextAreaProps extends AntTextAreaProps {}

const { TextArea } = Input;

const TextareaComponent: React.FC<TextAreaProps> = (props) => {
  return (
    <StyledTextarea>
        <TextArea className="ddl-textarea" {...props} />
    </StyledTextarea>
  )
}

export default TextareaComponent;