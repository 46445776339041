import React, { useEffect } from 'react';
import StyledAllMenu from '../styled';
import FormNewMenu from '../form-new-nemu';
import { Form } from "antd";
import menuService from 'services/menu.service';
import { Toastify } from "components/shared/Toast/Toast";
export interface IPropsMenu {
  id?: any
  listMenu?: any
  checkSubmit: (isSubmit: boolean) => any;
}

const EditMenuUI: React.FC<IPropsMenu> = ({
  id,
  listMenu,
  checkSubmit
}) => {
  const [form] = Form.useForm();

  const getFieldMenu = (id: number) => {
    menuService.detailMenu(id).then((resultDetail) => {
      form.setFields([
        {
          name: ["name"],
          value: resultDetail.name,
        },
        {
          name: ["description"],
          value: resultDetail.description,
        },
      ]);
    })
  }

  useEffect(() => {
    getFieldMenu(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const editMenu = (body: any) => {
    menuService.updateMenu(id, body).then(() => {
      Toastify.showSuccessToast("Edit success!");
      checkSubmit(true);
      listMenu();
    })
    .catch((err) => {
      Toastify.showDangerToast(err?.response?.data?.messages?.[0])
    })
  };

  return (
    <StyledAllMenu.NewMenu>
      <div className="all-new-menu-title">Edit New Menu</div>
      <div className="label-item">Menu Name*</div>
      <Form
        name="basic"
        form={form}
      >
        <Form.Item>
          <FormNewMenu
            isCheck={false}
            submitForm={editMenu}
            form={form}
          />
        </Form.Item>
      </Form>
    </StyledAllMenu.NewMenu>
  )
}

export default EditMenuUI;
