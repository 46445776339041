import { path } from 'ramda'
import * as CSS from 'csstype';

type cssFuncCallback<T> = (props: T) => string

export const getCss = <T = any>(cssProperty: keyof (CSS.PropertiesHyphen)) => (keyOrFunc: string | cssFuncCallback<T>) => (props: T) => {
  if (typeof keyOrFunc === 'function') {
    return `
      ${cssProperty}: ${keyOrFunc(props)};
    `
  }
  const paths = keyOrFunc.split('.');
  return `
      ${cssProperty}: ${path(paths)(props)};
    `
}