import styled from 'styled-components';
import { getCss } from 'utils/cssHelpers';

const StyledTextarea = styled.div`
  .ddl-textarea {
    padding-left: 20px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    &:hover, &:focus {
      ${getCss('border-color')('theme.global.colord9d9d9')};
      box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    }
  }
`

export default StyledTextarea;
