import styled from 'styled-components';
import { getCss } from 'utils/cssHelpers';

const StyledCountryMenu: any = styled.div``;

StyledCountryMenu.CountryMenu = styled.div`
  padding-top: 38px;
  & {
    .ant-table-title {
      background-color: ${props => props.theme.table.headeBgColor} !important;
    }
    .ant-table-thead > tr > .ant-table-cell {
      ${getCss('color')('theme.global.whiteColor')};
      background-color: ${props => props.theme.global.primaryColor} !important;
    }
    .ant-table-tbody {
      tr.ant-table-row:hover > td {
        ${getCss('background-color')('theme.global.colorTransparent')} !important;
      }
      tr:nth-child(1),
      tr:nth-child(3) {
        background-color: ${props => props.theme.table.theadBgColor} !important;
      }
      .ant-table-cell {
        padding: 50px 17px !important;
        font-size: 14px;
        font-weight: 500;
      }
      .ant-table-cell:nth-child(5) {
        span {
          display: block;
          width: 0;
          margin-bottom: 3px;
        }
      }
    }
  }
`;

export default StyledCountryMenu;
