import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'store'
import { fetchCurrenUser } from 'store/modules/current-user'
//import Helmet from 'react-helmet'
import { useAppRoutesContext } from 'AppRoutesContext'
import BaseLayout, { BaseLayoutProps } from './BaseLayout'
import DDLLogo from 'assets/images/ddl-logo.png'
import ActionsHeader from './components/ActionsUserHeader';

interface AdminLayoutProps {}

const filterRoutes: (
  routes: BaseLayoutProps['menuData'],
  predicate?: (route: BaseLayoutProps['menuData'][number]) => boolean
) => BaseLayoutProps['menuData'] = (routes, predicate = (route) => route.meta?.hidden !== true) => {
  const res = [] as BaseLayoutProps['menuData']
  routes.forEach((route) => {
    const tmp = { ...route }
    if (predicate(tmp)) {
      tmp.routes = route.routes ? filterRoutes(route.routes, predicate) : []
      res.push(tmp)
    }
  })
  return res
}

const AdminLayout: React.FC<AdminLayoutProps> = ({ children }) => {
  const { routes } = useAppRoutesContext()
  const storeDispatch = useAppDispatch()
  const { loading, currentUser } = useAppSelector((state) => state.currentUser)
  const menuData = React.useMemo(() => filterRoutes((routes.find((route) => route.path === '/') || []).routes || []), [
    routes,
  ])

  useEffect(() => {
    storeDispatch(fetchCurrenUser())
  }, [storeDispatch])

  return (
    <BaseLayout
      className='admin-layout'
      user={currentUser}
      loading={loading}
      menuData={menuData}
      logo={DDLLogo}
      headerRightContentRender={() => {
        return <ActionsHeader />
      }}
    >
      {children}
    </BaseLayout>
  )
}

export default AdminLayout
