import React, { useEffect, useState } from "react";
import StyledVendorForm from "./styled";
import AccountDetails from "./account-details/AccountDetails";
import PointOfContact from "./point-of-contact/PointOfContact";
import AccountInfo from "./account-information/AccountInfomation";
import Button from "components/shared/Button";
import commonServices from "services/common.service";
import find from "lodash/find";
import map from "lodash/map";

export interface VendorFormProps {
  isNewVendor: boolean;
  submitVendor(body: any): any;
  form: any;
}

const VendorForm: React.FC<VendorFormProps> = ({
  isNewVendor,
  submitVendor,
  form,
}) => {
  const [region, setRegion] = useState([]);

  const getRegion = () => {
    commonServices.getRegions().then((region: any) => {
      const regionBuild: any = map(region, (item: any) => {
        return {
          label: `${item.name}`,
          value: `${item.name}`,
          id: `${item.id}`,
          name: "industryId",
        };
      });
      setRegion(regionBuild);
    });
  };

  const _handleSubmitForm = async () => {
    try {
      const values = await form.validateFields();
      const regionSelected: any = find(region, ["value", values.regionId]);

      values.regionId = +regionSelected.id;
      delete values.reEnterPassword;
      submitVendor(values);
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };

  function onKeypress(e: any) {
    if (e.key === "Enter") {
      _handleSubmitForm();
    }
  }

  useEffect(() => {
    getRegion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.addEventListener("keypress", onKeypress);
    return () => {
      window.removeEventListener("keypress", onKeypress);
    };
  });

  return (
    <StyledVendorForm>
      <AccountDetails
        title="Cloud Kitchen Details"
        region={region}
      />
      <PointOfContact />
      <AccountInfo isNewVendor={isNewVendor} form={form} />
      <Button type="info" htmlType="submit" onClick={_handleSubmitForm}>
        {isNewVendor ? "Create Account" : "Edit Account"}
      </Button>
    </StyledVendorForm>
  );
};

export default VendorForm;
