import React from "react";
import styled from "styled-components";
import TabsLinkOrders from "./tabs-link";

const ManageOrdersContainer = styled.div`
  width: 100%;
`;

const ManageOrders: React.FC = ({ children }) => {
  return (
    <ManageOrdersContainer>
      <TabsLinkOrders />
      {children}
    </ManageOrdersContainer>
  );
};

export default ManageOrders;
