import { BaseAPI } from "core/services/BaseAPI";

export type FeedBackDetail = {
  id: number;
  name: string;
  organization: string;
  attended: boolean;
  type: "bugs" | "error" | "fixes";
};

export type FeedBackListItem = {
  id: number;
  name: string;
  organization: string;
  attended: boolean;
  type: "bugs" | "error" | "fixes";
};

export type PaginatedFeedback = {
  total: number;
  page: number;
  itemsPerPage: number;
  items: FeedBackListItem[];
};

export interface IInfoCenterService {
  getFeedbacks(page: number, itemsPerpage: number): Promise<PaginatedFeedback>;
}

class InfoCenterService extends BaseAPI implements IInfoCenterService {
  async getFeedbacks(
    page: number,
    itemsPerpage: number
  ): Promise<PaginatedFeedback> {
    const paginitedFeedbacks: PaginatedFeedback = await this.get(
      `information-centre/feedbacks?page=${page}&pageSize=${itemsPerpage}`
    );
    return paginitedFeedbacks;
  }

  async getFeedbackDetails(feedbackId: number) {
    return await this.get(`information-centre/feedbacks/${feedbackId}/details`);
  }

  async replyFeedback(feedbackId: number, params: Object) {
    return await this.post(
      `information-centre/feedbacks/${feedbackId}/reply`,
      params
    );
  }
}

export default new InfoCenterService();
