import React, { useEffect } from "react";
import Flex, { FlexItem } from "components/shared/Flex";
import GroupTitle from "components/shared/GroupTitle";
import StyledAllAddOns from "./styled";
import { ArrowBackOutlineIcon } from "components/shared/Icons";
import { useHistory } from "react-router-dom";
import { Form } from "antd";
import menuService from "services/menu.service";
import { Toastify } from "components/shared/Toast/Toast";
import FormAddOns from "./FormAddOns";
import Theme from "theme";

const EditAddOns: React.FC = () => {
  const history: any = useHistory();
  const foodId = history?.location?.state.food;
  const [form] = Form.useForm();

  const getDetailFoods = (foodId: any) => {
    menuService.getDetailFoods(foodId).then((detailFoods) => {
      form.setFields([
        {
          name: ["name"],
          value: detailFoods.name,
        },
        {
          name: ["photo"],
          value: detailFoods.photo,
        },
        {
          name: ["menuId"],
          value: detailFoods?.menu?.name,
        },
        {
          name: ["calories"],
          value: detailFoods.calories,
        },
        {
          name: ["price"],
          value: detailFoods.price,
        },
        {
          name: ["isPublished"],
          value: detailFoods.isPublished,
        },
      ]);
    });
  };

  const editFoods = (body: any) => {
    menuService
      .editFoods(foodId, body)
      .then(() => {
        Toastify.showSuccessToast("Edit add ons success !");
        history.goBack();
      })
      .catch((error) => {
        Toastify.showDangerToast(error?.response?.data?.messages?.[0]);
      });
  };

  useEffect(() => {
    getDetailFoods(foodId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [foodId]);

  const _handleClickAllAddOns = () => {
    history.goBack();
  };

  return (
    <StyledAllAddOns.AddOns>
      <Flex nowrap>
        <FlexItem>
          <GroupTitle
            fontSize={16}
            icon={
              <ArrowBackOutlineIcon
                color={Theme.global.whiteColor}
                onClick={_handleClickAllAddOns}
              />
            }
            padding={5}
            className="title-new-add-ons"
          >
            Edit Add Ons
          </GroupTitle>
        </FlexItem>
      </Flex>
      <Form name="basic" form={form}>
        <Flex nowrap>
          <FlexItem
            css={`
              flex: 1;
            `}
          >
            <FormAddOns isCheck={false} submitForm={editFoods} form={form} />
          </FlexItem>
        </Flex>
      </Form>
    </StyledAllAddOns.AddOns>
  );
};

export default EditAddOns;
