import { BaseAPI } from "core/services/BaseAPI";

export interface IReportService {}

class ReportService extends BaseAPI implements IReportService {

  async getCustomerReport(param: string) {
    const path = `reports/reports/customers-report?${param}`;
    return await this.get(path);
  }

  getTotalOrders(param: string) {
    return this.get(`reports/reports/total-orders?${param}`);
  }

  getTotalSales(param: string) {
    return this.get(`reports/reports/total-sales?${param}`);
  }
}

export default new ReportService();
