import { BaseAPI } from "core/services/BaseAPI";

export interface ICloudKitchenService {
  getVendors(): void;
  getVendor: (id: string) => {};
  deleteVendor: (id: string) => {};
  addVendor: (data: Object) => {};
  editVendor: (data: Object, userId: any) => {};
}

class CloudKitchenService extends BaseAPI implements ICloudKitchenService {
  getVendors() {
    return this.get("manage-cloudkitchens/vendors");
  }

  getVendor(id: string) {
    return this.get(`manage-cloudkitchens/vendors/${id}/details`);
  }

  deleteVendor(id: string) {
    return this.delete(`manage-cloudkitchens/vendors/${id}`);
  }

  addVendor(body: Object) {
    return this.post("manage-cloudkitchens/vendors", body);
  }

  editVendor(body: Object, userId: any) {
    return this.patch(`manage-cloudkitchens/vendors/${userId}`, body);
  }

  getWeeklyMenu() {
    const currentDate = new Date().toISOString();
    return this.get(`weekly?from=${encodeURIComponent(currentDate)}`);
  }
}

export default new CloudKitchenService();
