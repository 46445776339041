import styled from 'styled-components'
//import { getCss } from 'utils/cssHelpers'

const StyledVendorForm: any = styled.div`
  max-width: 100%;
`;

StyledVendorForm.Block = styled.div`
  padding: 10px 0;
  width: 100%;
`;

StyledVendorForm.Generate = styled.h3`
  margin: 12px 0 29px 0;
  font-size: 12px;
  cursor: pointer;
  text-decoration: underline;
  font-family: Montserrat;
  color: ${props => props.theme.menu.textColor};
`;

export default StyledVendorForm
