import React, { useEffect, useState } from "react";
import WrapCouriersForm from '../couriers-form/styled';
import CouriersForm from "../couriers-form";
import { Form } from "antd";
import couriersService from "services/couriers.service";
import { useHistory } from "react-router";
import { Toastify } from "components/shared/Toast/Toast";

const EditCouriers: React.FC = (props) => {
  const [form] = Form.useForm();
  const history: any = useHistory();
  const couriersId = history?.location?.state?.id;
  const [filedRegion, setFiledRegion] = useState<Array<any>>([]);

  const getCouriers = (id: number) => {
    couriersService.getDetailsCourier(id).then((courier = {}) => {
      setFiledRegion(courier.regions);
      form.setFields([
        {
          name: ["name"],
          value: courier.name,
        },
        {
          name: ["phoneNumber"],
          value: courier.phoneNumber,
        },
        {
          name: ["email"],
          value: courier?.user?.email,
        },
        {
          name: ["courierId"],
          value: courier.courierId,
        },
        {
          name: ["regionIds"],
          value: courier.regions.map((item: any) => {return item.name}),
        },
      ]);
    })
  }

  useEffect(() => {
    getCouriers(couriersId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [couriersId]);

  const editCouriers = (body: any) => {
    couriersService.editCourier(couriersId, body).then(() => {
      Toastify.showSuccessToast("Edit couriers success!");
      history.push({
        pathname: `/manage-couriers/courier-details/${couriersId}`,
        state: {user: {id: couriersId}}
      });
    })
    .catch(() => {
      Toastify.showDangerToast(`Edit couriers fail!`)
    })
  }

  return (
    <WrapCouriersForm>
      <Form
        name="basic"
        form={form}
      >
        <Form.Item>
          <CouriersForm
            isCheck={false}
            submitCouriers={editCouriers}
            form={form}
            filedRegion={filedRegion}
            setFiledRegion={setFiledRegion}
          />
        </Form.Item>
      </Form>
    </WrapCouriersForm>
  )
}

export default EditCouriers;
