// Table Menu
const collumsMenu: any = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  { 
    title: 'Food in This Category',
    dataIndex: 'category',
    key: 'category',
  },
  {
    title: 'Created',
    dataIndex: 'created',
    key: 'created',
  },
  {
    title: 'Updated',
    dataIndex: 'updated',
    key: 'updated',
  },
  {
    title: null,
    dataIndex: null,
    key: null,
  },
];
// Table Menu

export { collumsMenu };
