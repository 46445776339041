import styled from 'styled-components'
import { getCss } from 'utils/cssHelpers'

export const WeeklyMenuDetails: any = styled.div`
    .customer-title {
        padding-bottom: 39px;
    }
    .ant-table {
        margin-bottom: 20px;
        tr {
            td:first-child {
                width: 62px;
                font-size: 20px;
                font-weight: 500;
                ${getCss('color')('theme.global.primaryColor')};
            }
            td.ant-table-cell:first-child {
                text-align: center;
            }
            td:nth-child(4) {
                width: 20%;
            }
        }
        .ant-table-tbody {
            .ant-table-cell:nth-child(6) {
                span {
                  display: block;
                  width: 0;
                  margin-bottom: 3px;
                }
            }
        }
        .ant-tag {
            width: 100px;
        }
    }
    .btn-edit-weekly-menu {
        font-size: 16px;
        font-weight: 500;
        border-radius: 20px;
        margin-bottom: 23px;
        position: absolute;
        top: 0;
        right: 0;
    }
`

WeeklyMenuDetails.ImageFood = styled.div`
    width: 69px;
    height: 69px;
    border: solid 1px ${props => props.theme.global.color707070};
    .ant-image-img {
        width: 69px;
        height: 69px;
    }
`;

WeeklyMenuDetails.Tabs = styled.div`
    position: relative;
    .ant-tabs-nav {
        margin-bottom: 32px;
        .ant-tabs-tab {
            .ant-tabs-tab-btn {
                ${getCss('color')('theme.global.grayColor')};
                font-size: 16px;
                font-weight: 600;
            }
            &.ant-tabs-tab-active {
                .ant-tabs-tab-btn {
                    ${getCss('color')('theme.global.grayColor')};
                }
            }
        }
    }
`;