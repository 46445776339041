import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import Button from "components/shared/Button";
import { Table3 } from "components/shared/Table";
import Tag from "components/shared/Tag";
import GroupTitle from "components/shared/GroupTitle";
import Image from "components/shared/Image";
import { Tabs, TabPane } from "components/shared/Tabs";
import { ArrowBackOutlineIcon } from "components/shared/Icons";
import { WeeklyMenuDetails } from "./styled";
import menuService from "services/menu.service";
import config from "config";
import { columns } from "./WeeklyMenuDetails.config";
import Theme from "theme";
import _ from "lodash";

type IPropsDetailWeeklyMenu = {
  id: number;
  photo: string;
  name: string;
  calories: string;
  price: string;
  logicType: string;
  menu: any;
  types: any;
};

const renderImageFood = (src: string) => {
  return (
    <WeeklyMenuDetails.ImageFood>
      {src ? <Image src={src} alt="foods" /> : null}
    </WeeklyMenuDetails.ImageFood>
  );
};

const WeeklyMenuDetailsUI: React.FC = () => {
  const history: any = useHistory();
  const dataWeekly: any = history?.location?.state;
  const dateFood = dataWeekly?.days;
  const idWeekly = dataWeekly?.data?.[0]?.id;
  const { id } = useParams<Record<string, string | undefined>>();
  const [loading, setLoading] = useState<boolean>(true);

  const [addonsIndividual, setAddonsIndividual] = useState<Array<any>>([]);
  const [additionalsIndividual, setAdditionalsIndividual] = useState<
    Array<any>
  >([]);
  const [mainFoodsIndividual, setMainFoodsIndividual] = useState<Array<any>>(
    []
  );

  const [addonsCorporate, setAddonsCorporate] = useState<Array<any>>([]);
  const [additionalsCorporate, setAdditionalsCorporate] = useState<Array<any>>(
    []
  );
  const [mainFoodsCorporate, setMainFoodsCorporate] = useState<Array<any>>([]);

  const [roles, setRoles] = useState<any>();

  const getUrl = (url: any) => {
    if (url.startsWith("http")) {
      return url;
    }
    return config.hostName + url;
  };

  const changeTabs = (e: any) => {
    setRoles(e);

    const role = +e;
    if (role === 1) {
      menuService
        .getDetailWeeklyMenu(idWeekly, "individual", dateFood)
        .then((resultIndividual) => {
          setLoading(false);
          // const filterTypeMainfoods = resultIndividual.filter((item: any) => item.logicType === "main-foods");
          const filterTypeMainfoods = _.filter(
            resultIndividual,
            (item) =>
              item.logicType !== "addons" &&
              !item.types.some((item: any) => item.name === "Fruit")
          );

          const filterTypeAdditionals = _.filter(resultIndividual, (item) =>
            item.types.some((item: any) => item.name === "Fruit")
          );
          const filterTypeAddons = resultIndividual.filter(
            (item: any) => item.logicType === "addons"
          );

          const listAddonsIndividual = filterTypeAddons.map(
            (row: IPropsDetailWeeklyMenu, index: number) => ({
              key: index,
              MenuNumber: <span>{row.id}</span>,
              ImageFood: renderImageFood(`${getUrl(row.photo)}`),
              FoodID: row.id,
              Name: row.name,
              MenuCategory: row?.menu?.name,
              FoodType: row?.types?.map((data: any, i: number) => {
                return (
                  <Tag
                    type="primary"
                    style={{ backgroundColor: `${data.color}` }}
                    key={i}
                  >
                    {data.name}
                  </Tag>
                );
              }),
              Calories: row.calories,
              Price: `$${row.price}`,
            })
          );

          const listAdditionalsIndividual = filterTypeAdditionals.map(
            (row: IPropsDetailWeeklyMenu, index: number) => ({
              key: index,
              MenuNumber: <span>{row.id}</span>,
              ImageFood: renderImageFood(`${getUrl(row.photo)}`),
              FoodID: row.id,
              Name: row.name,
              MenuCategory: row?.menu?.name,
              FoodType: row?.types?.map((data: any, i: number) => {
                return (
                  <Tag
                    type="primary"
                    style={{ backgroundColor: `${data.color}` }}
                    key={i}
                  >
                    {data.name}
                  </Tag>
                );
              }),
              Calories: row.calories,
              Price: `$${row.price}`,
            })
          );

          const listMainfoodsIndividual = filterTypeMainfoods.map(
            (row: IPropsDetailWeeklyMenu, index: number) => ({
              key: index,
              MenuNumber: <span>{row.id}</span>,
              ImageFood: renderImageFood(`${getUrl(row.photo)}`),
              FoodID: row.id,
              Name: row.name,
              MenuCategory: row?.menu?.name,
              FoodType: row?.types?.map((data: any, i: number) => {
                return (
                  <Tag
                    type="primary"
                    style={{ backgroundColor: `${data.color}` }}
                    key={i}
                  >
                    {data.name}
                  </Tag>
                );
              }),
              Calories: row.calories,
              Price: `$${row.price}`,
            })
          );

          setAddonsIndividual(listAddonsIndividual);
          setAdditionalsIndividual(listAdditionalsIndividual);
          setMainFoodsIndividual(listMainfoodsIndividual);
        });
    } else {
      menuService
        .getDetailWeeklyMenu(idWeekly, "corporate", dateFood)
        .then((resultCorporate) => {
          setLoading(false);
          const filterTypeAddons = resultCorporate.filter(
            (item: any) => item.logicType === "addons"
          );
          const filterTypeMainfoods = _.filter(
            resultCorporate,
            (item) =>
              item.logicType !== "addons" &&
              !item.types.some((item: any) => item.name === "Fruit")
          );
          const filterTypeAdditionals = _.filter(resultCorporate, (item) =>
            item.types.some((item: any) => item.name === "Fruit")
          );

          const listAddonsCorporate = filterTypeAddons.map(
            (row: IPropsDetailWeeklyMenu, index: number) => ({
              key: index,
              MenuNumber: <span>{row.id}</span>,
              ImageFood: renderImageFood(`${getUrl(row.photo)}`),
              FoodID: row.id,
              Name: row.name,
              MenuCategory: row?.menu?.name,
              FoodType: row?.types?.map((data: any, i: number) => {
                return (
                  <Tag
                    type="primary"
                    style={{ backgroundColor: `${data.color}` }}
                    key={i}
                  >
                    {data.name}
                  </Tag>
                );
              }),
              Calories: row.calories,
              Price: `$${row.price}`,
            })
          );

          const listAdditionalsCorporate = filterTypeAdditionals.map(
            (row: IPropsDetailWeeklyMenu, index: number) => ({
              key: index,
              MenuNumber: <span>{row.id}</span>,
              ImageFood: renderImageFood(`${getUrl(row.photo)}`),
              FoodID: row.id,
              Name: row.name,
              MenuCategory: row?.menu?.name,
              FoodType: row?.types?.map((data: any, i: number) => {
                return (
                  <Tag
                    type="primary"
                    style={{ backgroundColor: `${data.color}` }}
                    key={i}
                  >
                    {data.name}
                  </Tag>
                );
              }),
              Calories: row.calories,
              Price: `$${row.price}`,
            })
          );

          const listMainfoodsCorporate = filterTypeMainfoods.map(
            (row: IPropsDetailWeeklyMenu, index: number) => ({
              key: index,
              MenuNumber: <span>{row.id}</span>,
              ImageFood: renderImageFood(`${getUrl(row.photo)}`),
              FoodID: row.id,
              Name: row.name,
              MenuCategory: row?.menu?.name,
              FoodType: row?.types?.map((data: any, i: number) => {
                return (
                  <Tag
                    type="primary"
                    style={{ backgroundColor: `${data.color}` }}
                    key={i}
                  >
                    {data.name}
                  </Tag>
                );
              }),
              Calories: row.calories,
              Price: `$${row.price}`,
            })
          );

          setAddonsCorporate(listAddonsCorporate);
          setAdditionalsCorporate(listAdditionalsCorporate);
          setMainFoodsCorporate(listMainfoodsCorporate);
        });
    }
  };

  useEffect(() => {
    changeTabs("1");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickEdit = () => {
    history.push({
      pathname: `/manage-menu/weekly-edit/${id}`,
      state: {
        data: dataWeekly,
        roles: parseInt(roles),
      },
    });
  };

  return (
    <WeeklyMenuDetails>
      <GroupTitle
        fontSize={16}
        icon={
          <ArrowBackOutlineIcon
            color={Theme.global.whiteColor}
            onClick={() => history.goBack()}
          />
        }
        padding={5}
        css={`
          text-transform: capitalize;
        `}
        className="customer-title"
      >
        {id}’s Menu
      </GroupTitle>

      <WeeklyMenuDetails.Tabs>
        <Tabs defaultActiveKey="1" onChange={(e: any) => changeTabs(e)}>
          <TabPane tab="INDIVIDUAL MENU" key="1">
            {loading ? (
              "Loading..."
            ) : (
              <>
                <Table3
                  title={() => "Main Food"}
                  dataSource={mainFoodsIndividual}
                  columns={columns}
                  pagination={false}
                  className="table-all-couriers"
                />
                <Table3
                  title={() => "Fruit Platter Menu"}
                  dataSource={additionalsIndividual}
                  columns={columns}
                  pagination={false}
                  className="table-all-couriers"
                />
                <Table3
                  title={() => "Add Ons"}
                  dataSource={addonsIndividual}
                  columns={columns}
                  pagination={false}
                  className="table-all-couriers"
                />
              </>
            )}
          </TabPane>
          <TabPane tab="CORPORATE MENU" key="2">
            {loading ? (
              "Loading..."
            ) : (
              <>
                <Table3
                  title={() => "Main Food"}
                  dataSource={mainFoodsCorporate}
                  columns={columns}
                  pagination={false}
                  className="table-all-couriers"
                />
                <Table3
                  title={() => "Fruit Platter Menu"}
                  dataSource={additionalsCorporate}
                  columns={columns}
                  pagination={false}
                  className="table-all-couriers"
                />
                <Table3
                  title={() => "Add Ons"}
                  dataSource={addonsCorporate}
                  columns={columns}
                  pagination={false}
                  className="table-all-couriers"
                />
              </>
            )}
          </TabPane>
        </Tabs>
        <Button
          className="btn-edit-weekly-menu"
          type="primary"
          onClick={handleClickEdit}
        >
          Edit
        </Button>
      </WeeklyMenuDetails.Tabs>
    </WeeklyMenuDetails>
  );
};

export default WeeklyMenuDetailsUI;
