import React, { useState } from "react";
import Table from 'components/shared/Table';
import { columnsInvoicesCloudKitchen } from "../finance-CloudKitchenVendors.util";
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import Pagination from "components/shared/Pagination";

type IPagination = {
  total: number,
  onChange: any,
  current: number
}

export interface IPropsPastInvoices {
  listPastInvoices?: any;
}

const PastInvoices: React.FC<IPropsPastInvoices> = ({
  listPastInvoices
}) => {
  const history = useHistory();
  const { idKitchen }: any = useParams<Record<string, string | undefined>>();
  const [current, setCurrent] = useState<number>(1);
  const pageSize: number = 5; // set item pages

  const _handleClickInvoice = (record: any) => {
    history.push(`/finance-payment/cloud-kitchen-vendors-details-inv/${idKitchen}/${record.NoNumberINV}`);
  };

      // paging
      const getData = (current: number, pageSize: number) => {
        return listPastInvoices.slice((current - 1) * pageSize, current * pageSize);
      }
    
      const MyPagination = ({ total, onChange, current }: IPagination) => {
        return (
          <Pagination
            onChange={onChange}
            total={total}
            current={current}
            pageSize={pageSize}
          />
        )
      }

  return (
    <>
      <Table
        title={() => 'Past Invoices'}
        dataSource={getData(current, pageSize)}
        columns={columnsInvoicesCloudKitchen}
        pagination={false}
        className="table-invoices"
        onRow={(record) => {
          return {
            onClick: () => _handleClickInvoice(record),
          };
        }}
      />
      <MyPagination
        total={listPastInvoices.length}
        current={current}
        onChange={setCurrent}
      />
    </>
  )
}

export default PastInvoices;
