import React, { useMemo } from "react";
import AllMenu from "./all-menu";
import AllFoods from "./all-foods";
import AllAddOns from "./all-add-ons";
import MyMenuWrapper from "./styled";
import { Tabs, TabPane } from "components/shared/Tabs";
import { useHistory, useLocation } from "react-router-dom";

enum MenuTab {
  Menu = "menu",
  Food = "food",
  AddOns = "addons",
}
const TagsList: Array<{ label: string; code: MenuTab }> = [
  {
    label: "All Menu",
    code: MenuTab.Menu,
  },
  {
    label: "All Foods",
    code: MenuTab.Food,
  },
  {
    label: "All Add Ons",
    code: MenuTab.AddOns,
  },
];

const MyMenuUI: React.FC = (props) => {
  const location = useLocation();
  const his = useHistory();
  const query = useMemo(() => {
    const handle = new URLSearchParams(location.search);
    const tab: MenuTab = (handle.get("tab") || MenuTab.Menu) as any;
    return {
      tab: Object.values(MenuTab).includes(tab) ? tab : MenuTab.Menu,
    };
  }, [location]);

  const _handleChangeTabs = (e: any) => {
    his.push(`/manage-menu/my-menu?tab=${e}`);
  };

  const handleSwitchDataTabs = (code: MenuTab) => {
    switch (code) {
      case MenuTab.Menu:
        return <AllMenu />;
      case MenuTab.Food:
        return <AllFoods />;
      case MenuTab.AddOns:
        return <AllAddOns />;
      default:
        break;
    }
  };

  return (
    <MyMenuWrapper>
      <Tabs activeKey={query.tab} onChange={(e) => _handleChangeTabs(e)}>
        {TagsList.map((item) => {
          return (
            <TabPane tab={item.label} key={item.code}>
              {handleSwitchDataTabs(item.code)}
            </TabPane>
          );
        })}
      </Tabs>
    </MyMenuWrapper>
  );
};

export default MyMenuUI;
