import React, { useState, useEffect } from "react";
import TabsLink, { TabLinkProps } from "components/shared/TabsLink";
import { StyledTabsLinkOrders } from "./styled";
import userService from "services/user.service";
import filter from "lodash/filter";
import includes from "lodash/includes";

const tabs: Array<TabLinkProps> = [
  {
    text: "New Order",
    link: "/manage-orders/new",
    roles: ["admin", "corporate", "cloud-kitchen"],
  },
  {
    text: "Processing",
    link: "/manage-orders/processing",
    roles: ["admin", "corporate", "cloud-kitchen"],
  },
  {
    text: "Completed",
    link: "/manage-orders/completed",
    roles: ["admin", "corporate"],
  },
];

const TabsLinkOrders: React.FC = () => {
  const [tabsLink, setTabsLink] = useState<any>([]);

  useEffect(() => {
    getUser();
  }, []);

  const getUser = () => {
    userService.getMe().then((user) => {
      const role = user?.roles?.[0].id;
      const tabsRole = filter(tabs, (tab) => includes(tab.roles, role));
      setTabsLink(tabsRole);
    });
  };

  return (
    <StyledTabsLinkOrders>
      <TabsLink tabs={tabsLink} className="tabs-link-orders" />
    </StyledTabsLinkOrders>
  );
};

export default TabsLinkOrders;
