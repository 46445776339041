import React from 'react';
import { Form } from "antd";
import MarketingForm from '../marketing-form';
import marketingService from 'services/marketing.service';
import { useHistory } from "react-router-dom";
import { Toastify } from 'components/shared/Toast/Toast';

const PromotionsUI: React.FC = (props) => {
  const history = useHistory();
  const [ form ] = Form.useForm();

  const addPromotions = (body: any) => {
    marketingService.postNews(body)
    .then(() => {
      history.push('/marketing/overview');
      Toastify.showSuccessToast('Add promotion success !');
      form.resetFields();
    })
    .catch((error) => {
      Toastify.showDangerToast(error?.response?.data?.messages?.[0]);
    })
  }

  return (
    <Form name="basic" form={form}>
      <Form.Item>
        <MarketingForm
          form={form}
          isCheck={true}
          submitPromotions={addPromotions}
        />
      </Form.Item>
    </Form>
  );
}

export default PromotionsUI;
