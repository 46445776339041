import styled from 'styled-components';
import Switch from './Switch';

const StyleSwitch = styled(Switch)`
  background-color: ${props => props.theme.global.blackColor} !important;
  & {
    .ant-switch-handle {
      &:before {
        background-color: ${props => props.theme.switch[props.type].bgColor};
      }
    }
  }
`;

StyleSwitch.defaultProps = {
  type: 'primary'
}

export default StyleSwitch;
