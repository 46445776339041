import React from 'react'
import { Layout } from 'antd'

const { Content: AntContent } = Layout

interface ContentProps extends React.HTMLAttributes<HTMLDivElement> {}

const Content: React.FC<ContentProps> = (props) => {
  return <AntContent className={'ddl-layout-content'} {...props} />
}

export default Content
