import React, { useState } from "react";
import Card from "components/shared/Card";
import { Table2 } from "components/shared/Table";
import { TabPane, Tabs } from "components/shared/Tabs";
import { NewOrdersWrapper } from "./styled";
import orderService from "services/order.service";
import map from "lodash/map";
import { orderColumns } from "../manage-orders.config";

const AllPreOrders: React.FC = () => {
  const today: number = new Date().getDay();
  const [activeDay, setActiveDay] = useState<any>(today - 1);
  const [ordersOfDay, setOrdersOfDay] = useState<any>([]);

  const getPreOrders = (date: string) => {
    orderService.getPreOrders(date).then((res) => {
      const newOrders: any[] = map(res, (item: any, index: number) => ({
        ...item,
        menuNo: index + 1,
        key: item.food.id,
      }));
      setOrdersOfDay(newOrders);
    });
  };

  const preOrdersDay = () => {
    return (
      <Table2
        dataSource={ordersOfDay}
        columns={orderColumns}
        pagination={false}
      />
    );
  };

  const OrdersIsRealLive = () => {
    return (
      <div className="wrapper-live-orders">
        <div className="box-shadow-live-orders">Currently in Live Orders</div>
      </div>
    );
  };

  const checkToday = (daySelected: any) => {
    return today - 1 === +daySelected;
  };

  const listTab: Array<string> = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const getISOActiveDay = (day: number) => {
    const curr = new Date();
    return new Date(
      curr.setDate(curr.getDate() - curr.getDay() + day + 1)
    ).toLocaleDateString();
  };

  const onActiveDayChange = (activeKey: any) => {
    setActiveDay(activeKey);

    if (checkToday(activeKey)) {
      return;
    }
    setOrdersOfDay([]);
    getPreOrders(getISOActiveDay(+activeKey));
  };

  return (
    <NewOrdersWrapper className="all-pre-orders manage-orders">
      <Card title="All Pre-Orders" bodyStyle={{ padding: "0" }}>
        <Tabs activeKey={activeDay} onChange={onActiveDayChange}>
          {listTab.map((item, index) => (
            <TabPane tab={item} key={index} />
          ))}
        </Tabs>
        {checkToday(activeDay) ? OrdersIsRealLive() : preOrdersDay()}
      </Card>
    </NewOrdersWrapper>
  );
};

export default AllPreOrders;
