import React, { useEffect } from 'react';
import StyledAllMenu from '../styled';
import Input from 'components/shared/Input';
import Button from 'components/shared/Button';
import Form from 'components/shared/Form';

export interface MenuFormProps {
  isCheck: boolean;
  submitForm(body: any): any;
  form: any;
}

const FormNewMenu: React.FC<MenuFormProps> = ({
  isCheck,
  submitForm,
  form,
}) => {

  const _handleSubmitForm = async () => {
    try {
      const values = await form.validateFields();
      submitForm(values);
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };

  function onKeypress(e: any) {
    if (e.key === "Enter") {
      _handleSubmitForm();
    }
  }

  useEffect(() => {
    window.addEventListener("keypress", onKeypress);
    return () => {
      window.removeEventListener("keypress", onKeypress);
    };
  });

  return (
    <StyledAllMenu.NewMenu>
      <div className="wrapper-input">
        <Form.Item
          name="name"
          rules={[{ required: true, message: "Please input your menu name!" }]}
        >
          <Input placeholder="" />
        </Form.Item>
      </div>
      <p className="label-item">
        This will help to categorise your foods under a menu<br />
        Category
      </p>
      <div className="label-item">Description</div>
      <div className="wrapper-input">
        <Form.Item
          name="description"
          rules={[{ required: true, message: "Please input your menu description!" }]}
        >
          <Input placeholder="" />
        </Form.Item>
      </div>
      <p className="label-item">
        Optional
      </p>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="custome-btn"
          onClick={_handleSubmitForm}
        >
          {isCheck ? "Add New Menu" : "Edit New Menu"}
        </Button>
      </Form.Item>
    </StyledAllMenu.NewMenu>
  )
}

export default FormNewMenu;
