import styled from 'styled-components';
import { getCss } from 'utils/cssHelpers';

const WrapOverview: any = styled.div`
  & {
    .ant-tabs-tab-btn,
    .ant-tabs-tab-active {
      font-size: 14px;
      font-weight: normal;
      color: ${props => props.theme.global.blackColor} !important;
    }
    .pad-top-first {
      padding-top: 36px;
    }
    .pad-top-second {
      padding-top: 65px;
    }
    .title-chart {
      display: flex;
      justify-content: space-between;
      font-weight: 600;
      p {
        margin: 0;
        color: ${props => props.theme.global.grayColor} !important;
      }
      .font-normal {
        font-weight: normal;
      }
    }
    .actions-chart {
      display: flex;
      justify-content: space-between;
      padding-top: 18px;
      .actions-chart-left,
      .actions-chart-right {
        width: 50%;
        font-weight: normal;
        span {
          font-size: 10px;
          font-weight: normal;
          color: ${props => props.theme.global.grayColor} !important;
        }
        .text-end {
          text-align: end;
          display: block;
        }
      }
      p {
        font-size: 12px;
        font-weight: 600;
        margin: 0;
      }
    }
  }
`;

WrapOverview.DetailsChart = styled.div`
  padding: 15px;
  ${getCss('background-color')('theme.global.whiteColor')};
  height: 450px;
  & {
    .title-chart {
      ${getCss('color')('theme.global.grayColor')};
      font-size: 14px;
      font-weight: 600;
      margin: 0;
    }
    .day-chart {
      font-size: 11px;
      margin: 0;
      font-weight: normal;
      ${getCss('color')('theme.global.grayColor')};
      span svg {
        vertical-align: middle !important;
      }
    }
    .apexcharts-legend.apexcharts-align-center.position-bottom {
      inset: unset !important;
      bottom: 0 !important;
    }
  }
`;

WrapOverview.ManageOverview = styled.div`
  padding-top: 15px;
  & {
    .wrap-invoices {
      padding: 15px;
      ${getCss('background-color')('theme.global.whiteColor')};
      max-height: 425px;
      max-width: 27.1vw;
      .title-invoices {
        font-size: 14px;
        font-weight: 600;
        margin: 0;
        ${getCss('color')('theme.global.grayColor')};
      }
    }
    .apexcharts-legend-marker:nth-child(1) {
      width: 16px !important;
      height: 16px !important;
      border-radius: unset !important;
    }
    .apexcharts-legend-marker:nth-child(2) {
      width: 16px !important;
      height: 16px !important;
      border-radius: unset !important;
    }
    .wrap-card {
      padding: 15px;
      ${getCss('background-color')('theme.global.whiteColor')};
      .custome-btn {
        padding: 0;
        font-size: 12px;
        font-weight: 600;
        color: ${props => props.theme.global.grayColor} !important;
      }
    }
    .report {
      font-size: 14px;
      font-weight: 600;
      ${getCss('color')('theme.global.grayColor')};
    }
    .last-day-chart {
      font-size: 12px;
      ${getCss('color')('theme.global.grayColor')};
      svg {
        vertical-align: middle;
      }
    }
    .ant-select-selector {
      border: none !important;
    }
  }
`;

WrapOverview.PaymentSetup = styled.div`
  padding-top: 15px;
  cursor: pointer;
  & {
    .ant-table-thead {
      .ant-table-cell {
        background-color: ${props => props.theme.global.color4f4f4f} !important;
        color: ${props => props.theme.global.whiteColor} !important;
      }
    }
    .ant-table-tbody {
      ${getCss('background-color')('theme.global.tertiaryColor')};
      tr.ant-table-row:hover > td {
        ${getCss('background-color')('theme.global.colorTransparent')} !important;
      }
      tr:nth-child(2),
      tr:nth-child(4) {
        background-color: ${props => props.theme.global.whiteColor} !important;
      }
      .ant-table-cell {
        padding: 30px 8px !important;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
`;

WrapOverview.PaymentSetupFrom = styled.div`
  & {
    .customer-input {
      width: 300px
    }
    .ant-form-item {
      margin-bottom: 0 !important;
    }
    .customer-textarea {
      width: 300px;
      min-height: 100px;
    }
    .ant-switch {
      background-color: ${props => props.theme.global.blackColor} !important;
      .ant-switch-handle::before {
        background-color: ${props => props.theme.global.primaryColor} !important;
      }
    }
  }
`;

WrapOverview.WrapInvoices = styled.div`
  width: 50%;
  padding: 0 20px;
  .ant-card-body {
    padding: 24px 100px !important;
  }
  .label-invoices {
    font-size: 20px;
    ${getCss('color')('theme.global.primaryColor')};
    padding-bottom: 15px;
    svg {
      vertical-align: middle;
    }
  }
  .wrap-content {
    ${getCss('background-color')('theme.global.primaryColor')};
    text-align: center;
    padding: 15px 0;
    .title {
      font-size: 16px;
      font-weight: 600;
      ${getCss('color')('theme.global.whiteColor')};
    }
    .content {
      font-size: 18px;
      font-weight: bold;
      margin: 0;
      ${getCss('color')('theme.global.whiteColor')};
    }
  }
`;

export default WrapOverview;
