import React, { useState, useEffect } from "react";
import Flex, { FlexItem } from "components/shared/Flex";
import StyledFoodType from "./styled";
import { FunnelSharpIcon } from "components/shared/Icons";
import Table3 from "components/shared/Table";
import Button from "components/shared/Button";
import menuService from "services/menu.service";
import { Toastify } from "components/shared/Toast/Toast";
import Modal from "components/shared/Modal";
import AddFoodType from "./AddFoodType";
import EditFoodType from "./EditFoodType";
import { collumsFoodsType } from "./FoodType.config";
import Pagination from "components/shared/Pagination";
import Theme from "theme";

type IAllFoodsTypeProps = {
  id: number;
  name: string;
  color: string;
};

type IPagination = {
  total: number;
  onChange: any;
  current: number;
};

const TableFoodType: React.FC = (props) => {
  const [allFoodsType, setAllFoodsType] = useState([]);
  const [checkId, setCheckId] = useState<any>();
  const [idFoodType, setIdFoodType] = useState<number>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [submit, setSubmit] = useState<boolean>(false);
  const [current, setCurrent] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(true);

  const editFoodType = (id: number, isCheck: boolean) => {
    setIdFoodType(id);
    setSubmit(isCheck);
  };

  const actionFoodType = (id: number, color: string, name: string) => {
    return (
      <div className="wrap-action" style={{ background: color }}>
        <Flex justifyBetween>
          <FlexItem basis="50%" style={{ textAlign: "start" }}>
            <p className="name-foods-type">{name}</p>
          </FlexItem>
          <FlexItem basis="50%">
            <span
              className="action-foods-type"
              onClick={() => editFoodType(id, false)}
            >
              Edit |
            </span>
            <span className="action-foods-type" onClick={() => openModal(id)}>
              {" "}
              Delete
            </span>
          </FlexItem>
        </Flex>
      </div>
    );
  };

  const listFoodType = () => {
    menuService.getAllFoodsType().then((allFoodsType) => {
      setLoading(false);
      const resultAllFoodType = allFoodsType.map(
        (row: IAllFoodsTypeProps, index: number) => ({
          key: index,
          id: row.id,
          foodName: row.name,
          action: actionFoodType(row.id, row.color, row.name),
        })
      );
      setAllFoodsType(resultAllFoodType);
    });
  };

  useEffect(() => {
    listFoodType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteFoodType = () => {
    menuService
      .deleteFoodType(checkId)
      .then(() => {
        setIsModalVisible(false);
        Toastify.showSuccessToast("Delete foods type success !");
        listFoodType();
      })
      .catch((err) => {
        setIsModalVisible(false);
        Toastify.showDangerToast(err?.response?.data?.messages?.[0]);
      });
  };

  const closeModal = () => {
    setCheckId(undefined);
    setIsModalVisible(false);
  };

  const openModal = (id: number) => {
    setCheckId(id);
    setIsModalVisible(true);
  };

  const checkSubmit = () => {
    return submit ? (
      <AddFoodType listFoodType={listFoodType} />
    ) : !idFoodType ? (
      <AddFoodType listFoodType={listFoodType} />
    ) : (
      <EditFoodType
        idFoodType={idFoodType}
        listFoodType={listFoodType}
        checkSubmit={setSubmit}
      />
    );
  };

  const pageSize: number = 10;

  const getData = (current: number, pageSize: number) => {
    const start = (current - 1) * pageSize;
    return allFoodsType.slice(start, start + pageSize);
  };

  const MyPagination = ({ total, onChange, current }: IPagination) => {
    return (
      <Pagination
        onChange={onChange}
        total={total}
        current={current}
        pageSize={pageSize}
      />
    );
  };

  return (
    <StyledFoodType>
      <Flex>
        <FlexItem basis="35%">{checkSubmit()}</FlexItem>
        <FlexItem basis="65%" style={{ paddingLeft: "35px" }}>
          <StyledFoodType.Table>
            {loading ? (
              "Loading..."
            ) : (
              <>
                <Table3
                  title={() => (
                    <>
                      All Food Types
                      <FunnelSharpIcon
                        color={Theme.global.whiteColor}
                        height="14px"
                        width="14px"
                      />
                    </>
                  )}
                  columns={collumsFoodsType}
                  dataSource={getData(current, pageSize)}
                  pagination={false}
                  className="custome-table"
                />
                <MyPagination
                  total={allFoodsType.length}
                  current={current}
                  onChange={setCurrent}
                />
              </>
            )}
          </StyledFoodType.Table>
        </FlexItem>
      </Flex>
      <Modal visible={isModalVisible}>
        <Flex justifyCenter>
          <p className="content-request-for-payment">
            Do you want to delete this food types?.
          </p>
        </Flex>
        <Flex justifyEnd>
          <Button type="info" onClick={closeModal}>
            Cancel
          </Button>
          <Button onClick={deleteFoodType} style={{ marginLeft: "15px" }}>
            OK
          </Button>
        </Flex>
      </Modal>
    </StyledFoodType>
  );
};

export default TableFoodType;
