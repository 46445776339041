import React, { useState } from 'react';
import WrapCloudKitChenVendors from './styled';
import {
  ArrowBackOutlineIcon,
  ArrowForwardIcon,
  ArrowDownCircleSharpIcon,
} from 'components/shared/Icons';
import {
  columnsRequestPaymentINV,
  columnsRequestPaymentOrder,
} from "./finance-CloudKitchenVendors.util"
import Table from 'components/shared/Table';
import Flex from 'components/shared/Flex';
import GroupTitle from 'components/shared/GroupTitle';
import Tag from 'components/shared/Tag';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import Modal from 'components/shared/Modal';
import Button from 'components/shared/Button';
import Theme from "theme";

const dataInvoices = [
  {
    key: 1,
    Date: '020221',
    ReceiptNo: 193,
    TotalQTY: 200,
    Total: '$200',
    Details: <ArrowForwardIcon />,
  },
  {
    key: 2,
    Date: '020221',
    ReceiptNo: 192,
    TotalQTY: 200,
    Total: '$200',
    Details: <ArrowForwardIcon />,
  },
  {
    key: 3,
    Date: '030221',
    ReceiptNo: 191,
    TotalQTY: 200,
    Total: '$200',
    Details: <ArrowForwardIcon />,
  },
  {
    key: 4,
    Date: '040221',
    ReceiptNo: 190,
    TotalQTY: 200,
    Total: '$200',
    Details: <ArrowForwardIcon />,
  },
  {
    key: 5,
    Date: '050221',
    ReceiptNo: 189,
    TotalQTY: 200,
    Total: '$200',
    Details: <ArrowForwardIcon />,
  },
  {
    key: 6,
    Date: '060221',
    ReceiptNo: 188,
    TotalQTY: 200,
    Total: '$200',
    Details: <ArrowForwardIcon />,
  },
  {
    key: 7,
    Date: '070221',
    ReceiptNo: 187,
    TotalQTY: 200,
    Total: '$200',
    Details: <ArrowForwardIcon />,
  },
  {
    key: 8,
    Date: '080221',
    ReceiptNo: 186,
    TotalQTY: 200,
    Total: '$200',
    Details: <ArrowForwardIcon />,
  },
];

const dataINV = [
  {
    key: 1,
    Date: '020121',
    INVNo: 'INV52',
    ForTheMonth: 'Feb 21',
    Total: '$7200.50',
    Status: <Tag type='danger'>Unpaid</Tag>,
  }
];

const CloudKitchenVendorsDetailsINV: React.FC = (props) => {
  const history = useHistory();
  const [modalRequestForPayment, setModalRequestForPayment] = useState<boolean>(false);

  const { idKitchen } = useParams<Record<string, string | undefined>>();

  const _handleClickToBack = () => {
    history.push(`/finance-payment/cloud-kitchen-vendors-details/${idKitchen}`)
  }

  const _handleCancel = () => {
    setModalRequestForPayment(false);
  }

  const _handleClickInvoice = (record: any) => {}

  return (
    <WrapCloudKitChenVendors>
      <WrapCloudKitChenVendors.DetailsINV>
        <GroupTitle
          fontSize={16}
          icon={<ArrowBackOutlineIcon color={Theme.global.whiteColor} onClick={_handleClickToBack} />} 
          padding={5}
          className="header-settlement"
        >
          INV52
        </GroupTitle>
        <Table
          dataSource={dataINV}
          columns={columnsRequestPaymentINV}
          pagination={false}
          className="table-settlement"
        />
        <Button
          type="info"
          style={{ marginBottom: "30px", padding: "20px 70px", lineHeight: 0 }}
          onClick={() => setModalRequestForPayment(true)}>
            Send Payment
          </Button>
        <Modal visible={modalRequestForPayment} onCancel={() => setModalRequestForPayment(false)}>
            <Flex css={`flex: 1`} justifyCenter>
              <WrapCloudKitChenVendors.StyledSendPayment>
                <p className="content-request-for-payment">
                  Request for payment
                </p>
                <div className="date-request-for-payment">
                </div>
                <Button type='info' onClick={_handleCancel} className='customer-btn-close'>Close</Button>
              </WrapCloudKitChenVendors.StyledSendPayment>
            </Flex>
        </Modal>
        <Table
          title={() => 'Invoice Breakdown For Main Menu '}
          dataSource={dataInvoices}
          columns={columnsRequestPaymentOrder}
          pagination={false}
          className="table-invoices-breakdown"
          onRow={(record) => {
            return {
              onClick: () => _handleClickInvoice(record),
            };
          }}
        />
        <Flex className="view-next-7-days" justifyCenter>
          <span css={`margin-right: 10px; cursor: pointer;`}>View Next 7 Days</span>
          <ArrowDownCircleSharpIcon />
        </Flex>
      </WrapCloudKitChenVendors.DetailsINV>
    </WrapCloudKitChenVendors>
  );
}

export default CloudKitchenVendorsDetailsINV;
