// @ts-nocheck
import React from 'react'
import { Menu as AntMenu, MenuProps as AntMenuProps } from 'antd'
import { MenuItemProps } from './MenuItem'
import { SubMenuProps } from './SubMenu'

interface MenuProps extends AntMenuProps {}

type MenuExt = { Item: React.FC<MenuItemProps>; SubMenu: React.FC<SubMenuProps> }

const Menu: React.FC<MenuProps> & MenuExt = ({
  children,
  ...rest
}) => {
  return (
    <AntMenu className='ddl-menu' {...rest}>
      {children}
    </AntMenu>
  )
}

export default Menu
