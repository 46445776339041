import React from "react";
import WrapOverview from "./styled";
import Flex, { FlexItem } from "components/shared/Flex";
import Duration from "components/shared/Duration/Duration";

interface IPropDetailsChart {
  title?: any;
  children?: any;
  icon?: any;
  duration: string;
  onChangeDuration: (durationKey: string) => void;
}
const DetailsChart: React.FC<IPropDetailsChart> = ({
  title,
  children,
  duration,
  onChangeDuration
}) => {
  return (
    <WrapOverview.DetailsChart>
      <Flex nowrap={true} justifyBetween alignCenter>
        <FlexItem>
          <div className="title-chart">{title}</div>
        </FlexItem>
        <FlexItem>
          <Duration duration={duration} onChangeDuration={onChangeDuration}/>
        </FlexItem>
      </Flex>
      <Flex justifyCenter>
        <div className="wrap-pie-chart">{children}</div>
      </Flex>
    </WrapOverview.DetailsChart>
  );
};

export default DetailsChart;
