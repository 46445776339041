import React, { useEffect } from "react";
import EditVendorWrapper from "./styled";
import VendorForm from "../VendorForm";
import cloudKitchenService from "services/cloud-kitchen.service";
import { Form } from "antd";
import { Toastify } from "components/shared/Toast/Toast";
import GroupTitle from "components/shared/GroupTitle";
import { ArrowBackOutlineIcon } from "components/shared/Icons";
import { useHistory } from "react-router";

const EditVendor: React.FC = () => {
  const [form] = Form.useForm();
  const history: any = useHistory();
  const userId = history?.location?.state?.user?.id;

  const getVendor = (id: any) => {
    cloudKitchenService.getVendor(id).then((vendor = {}) => {
      form.setFields([
        {
          name: ["name"],
          value: vendor.name,
        },
        {
          name: ["uen"],
          value: vendor.uen,
        },
        {
          name: ["phone"],
          value: vendor.phone,
        },
        {
          name: ["address"],
          value: vendor.address,
        },
        {
          name: ["postalCode"],
          value: vendor.postalCode,
        },
        {
          name: ["regionId"],
          value: vendor.region?.name,
        },
        {
          name: ["email"],
          value: vendor.user?.email,
        },
        {
          name: ["pocName"],
          value: vendor.user?.pointOfContacts[0]?.name,
        },
        {
          name: ["pocPhone"],
          value: vendor.user?.pointOfContacts[0]?.phoneNumber,
        },
        {
          name: ["pocEmail"],
          value: vendor.user?.pointOfContacts[0]?.email,
        },
        {
          name: ["vendorId"],
          value: vendor.vendorId,
        },
      ]);
    });
  };

  useEffect(() => {
    getVendor(userId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const editVendor = (body: any) => {
    // Password input isn't required
    if(!body.password) {
      delete body.password
    }

    cloudKitchenService
      .editVendor(body, userId)
      .then(() => {
        Toastify.showSuccessToast("Edit success!");
        history.push({
          pathname: `/manage-cloud-kitchen/details-kitchen/${userId}`,
          state: { user: {id: userId} },
        });
      })
      .catch((err) =>
        Toastify.showDangerToast(err?.response?.data?.messages?.[0])
      );
  };

  return (
    <EditVendorWrapper>
      <GroupTitle
        fontSize={16}
        icon={
          <ArrowBackOutlineIcon color="#fff" onClick={() => history.goBack()} />
        }
        padding={5}
        className="styled-create-account"
      >
        Edit A Cloud Kitchen Account
      </GroupTitle>
      <Form name="basic" form={form}>
        <Form.Item>
          <VendorForm
            isNewVendor={false}
            submitVendor={editVendor}
            form={form}
          />
        </Form.Item>
      </Form>
    </EditVendorWrapper>
  );
};

export default EditVendor;
