import React, { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import GroupTitle from "components/shared/GroupTitle";
import Image from "components/shared/Image";
import Flex from "components/shared/Flex";
import { ArrowBackOutlineIcon } from "components/shared/Icons";
import { WeeklyMenuEdit } from "./styled";
import config from "config";
import MainFoodsTable from "./MainFoodTable";
import FruitPlatterTable from "./FruitPlatterTable";
import AddOnsTable from "./AddOnsTable";
import { columns } from "./WeeklyMenuEdit.config";
import menuService from "services/menu.service";
import Theme from "theme";

const renderImageFood = (src: string) => {
  return (
    <WeeklyMenuEdit.ImageFood>
      {src ? <Image src={src} alt="" /> : null}
    </WeeklyMenuEdit.ImageFood>
  );
};

const WeeklyMenuEditUI: React.FC = () => {
  const history: any = useHistory();
  const { id } = useParams<Record<string, string | undefined>>();
  const dataWeekly: any = history?.location?.state?.data;
  const dateFood = dataWeekly?.days;
  const idWeekly = dataWeekly?.data?.[0]?.id;
  const [loading, setLoading] = useState<boolean>(true);
  const [resultFoods, setResultFoods] = useState<any[]>([]);
  const [selectedMainFoods, setSelectedMainFoods] = useState<any[]>([]);
  const [selectedFruits, setSelectedFruits] = useState<any[]>([]);
  const [selectedAddons, setSelectedAddons] = useState<any[]>([]);

  const roles: any = history?.location?.state?.roles;
  const param = roles === 1 ? "individual" : "corporate";

  const getFoodsByType = () => {
    const typeFruitPlatter: string = "main-foods";
    menuService.getFoodByType(typeFruitPlatter).then((data) => {
      setLoading(false);
      setResultFoods(data);
    });
  };

  const getSelecedFoods = async () => {
    const foods: any[] = await menuService.getDetailWeeklyMenu(
      idWeekly,
      param,
      dateFood
    );
    const {
      alreadySelectedMainFoods,
      alreadySelectedFruits,
      alreadySelectedAddons,
    } = foods.reduce(
      (obj, food) => {
        if (food.logicType === "main-foods") {
          if (food.types.some(({ name }: any) => name === "Fruit")) {
            obj.alreadySelectedFruits.push(food.id);
          } else {
            obj.alreadySelectedMainFoods.push(food.id);
          }
        } else {
          obj.alreadySelectedAddons.push(food.id);
        }
        return obj;
      },
      {
        alreadySelectedMainFoods: [],
        alreadySelectedFruits: [],
        alreadySelectedAddons: [],
      }
    );

    setSelectedMainFoods(alreadySelectedMainFoods);
    setSelectedFruits(alreadySelectedFruits);
    setSelectedAddons(alreadySelectedAddons);
  };

  const resetMenu = () => {
    setSelectedMainFoods([]);
    setSelectedFruits([]);
    setSelectedAddons([]);
  };

  useEffect(() => {
    getFoodsByType();
  }, []);

  useEffect(() => {
    getSelecedFoods();
    // eslint-disable-next-line
  }, []);

  const getUrl = (url: any) => {
    if (url.startsWith("http")) {
      return url;
    }
    return config.hostName + url;
  };

  const { mainFoods, fruits } = useMemo(() => {
    return resultFoods.reduce(
      (obj, food) => {
        if (food.logicType === "main-foods") {
          if (food.types.some(({ name }: any) => name === "Fruit")) {
            obj.fruits.push(food);
          } else {
            obj.mainFoods.push(food);
          }
        }
        return obj;
      },
      {
        mainFoods: [],
        fruits: [],
      }
    );
  }, [resultFoods]);

  return (
    <WeeklyMenuEdit>
      <Flex justifyBetween alignCenter>
        <GroupTitle
          fontSize={16}
          icon={
            <ArrowBackOutlineIcon
              color={Theme.global.whiteColor}
              onClick={() => history.goBack()}
            />
          }
          padding={5}
          css={`
            text-transform: capitalize;
          `}
          className="customer-title"
        >
          Edit {id}’s {roles === 1 ? "Individual" : "Corporate"} Menu
        </GroupTitle>
        <WeeklyMenuEdit.ResetMenu onClick={resetMenu}>
          Reset Menu
        </WeeklyMenuEdit.ResetMenu>
      </Flex>

      <MainFoodsTable
        selected={selectedMainFoods}
        onChange={setSelectedMainFoods}
        dateFood={dateFood}
        idWeekly={idWeekly}
        getRole={param}
        columns={columns}
        renderImageFood={renderImageFood}
        getUrl={getUrl}
        resultFoods={mainFoods}
        loading={loading}
      />

      <FruitPlatterTable
        selected={selectedFruits}
        onChange={setSelectedFruits}
        dateFood={dateFood}
        idWeekly={idWeekly}
        getRole={param}
        columns={columns}
        renderImageFood={renderImageFood}
        getUrl={getUrl}
        resultFoods={fruits}
        loading={loading}
      />

      <AddOnsTable
        selected={selectedAddons}
        onChange={setSelectedAddons}
        dateFood={dateFood}
        idWeekly={idWeekly}
        getRole={param}
        columns={columns}
        renderImageFood={renderImageFood}
        getUrl={getUrl}
      />
    </WeeklyMenuEdit>
  );
};

export default WeeklyMenuEditUI;
