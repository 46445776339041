import Theme from "theme";
import { formatCurrency } from "utils/common.util";

const optionsDonut: any = {
  chart: {
    type: "donut",
  },
  legend: {
    position: "bottom",
  },
  plotOptions: {
    pie: {
      donut: {
        labels: {
          show: true,
          total: {
            show: true,
            label: "",
            formatter: (w: any) => {
              const total = w.globals.seriesTotals.reduce((a: any, b: any) => {
                return a + b;
              }, 0);

              return `S$${formatCurrency(total)}`;
            },
          },
        },
      },
    },
  },
  labels: [],
  colors: [
    `${Theme.global.orangeColor}`,
    `${Theme.global.primaryColor}`,
    `${Theme.global.tertiaryColor}`,
  ],
};


const optionsBarColumn: any = {
  chart: {
    type: "bar",
    toolbar: {
      show: false,
    },
  },
  title: {
    text: "No Of Purchase",
    align: "right",
    style: {
      fontSize: "12px",
      fontWeight: "600",
      color: `${Theme.global.blackColor}`,
    },
  },
  colors: [`${Theme.global.primaryColor}`],
  plotOptions: {
    bar: {
      columnWidth: "10%",
      distributed: true,
    },
  },
  dataLabels: {
    enabled: false,
  },
  legend: {
    show: false,
  },
  yaxis: {
    show: true,
    showAlways: true,
    opposite: true,
    labels: {
      rotate: 0,
      style: {
        fontSize: "12px",
      },
    },
  },
  xaxis: {
    min: 10,
    show: true,
    categories: [],
    labels: {
      rotate: 0,
      style: {
        fontSize: "12px",
      },
    },
  },
};

export { optionsDonut, optionsBarColumn };
