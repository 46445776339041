import React from "react";
import WrapCouriersForm from "./styled";
import Flex, { FlexItem } from 'components/shared/Flex';
import { AddOutlineIcon, CloseSharpIcon } from 'components/shared/Icons';
import Input from 'components/shared/Input';
import Select from 'components/shared/Select';
import { Form } from "antd";
import Theme from 'theme';
interface IPropsEditCouriers {
  isCheck: boolean
  filedRegion: any[]
  dropRegion: any[]
  addMoreRegion(): any
  onChangeRegion(e: any, index: number): any
  removeRegion(index: number): any
}

const EditCouriersAccount: React.FC<IPropsEditCouriers> = ({
  isCheck,
  filedRegion,
  dropRegion,
  addMoreRegion,
  removeRegion,
  onChangeRegion,
}) => {

  return (
    <WrapCouriersForm>
      <div className="wrapper-title-add">Edit Courier Account</div>
      <div className="bg-color">
        <Flex row>
          <FlexItem basis='33.3%'>
            <div className="wrapper-title-edit">Name</div>
            <Form.Item
              name="name"
              rules={[{required: true, message: 'Please fill your Courier Name'}]}
            >
              <Input placeholder="Courier Name"/>
            </Form.Item>
          </FlexItem>
          <FlexItem basis='33.3%' css={`margin: 0 10px;`}>
            <div className="wrapper-title-edit">Main Contact Number</div>
            <Form.Item
              name="phoneNumber"
              rules={[{
                required: true,
                message: 'Please fill your Phone Number'
              }]}
            >
              <Input placeholder="Phone Number"/>
            </Form.Item>
          </FlexItem>
          <FlexItem basis='33.3%'>
            <div className="wrapper-title-edit">Email Address</div>
            <Form.Item
              name="email"
              rules={[{required: true, message: 'Please fill your Email Address'}]}>
              <Input
                placeholder="Email Address"
              />
            </Form.Item>
          </FlexItem>
        </Flex>
        <Flex row>
          <FlexItem basis='33.3%' className="wrapper-account-infomation">
            <div className="wrapper-title-edit">Courier ID</div>
            <Form.Item
              name="courierId"
              rules={[{required: true, message: 'Please fill your Courier ID'}]}
            >
              <Input placeholder="Courier ID"/>
            </Form.Item>
          </FlexItem>
          <FlexItem basis='33.3%' css={`margin: 0 10px;`}>
            <div className="wrapper-title-edit">Password</div>
            <Form.Item
              name="password"
              rules={[{required: isCheck ? true : false, message: 'Please fill Your Password'}]}
            >
              <Input placeholder="Enter Password" isVisible={true}/>
            </Form.Item>
          </FlexItem>
          <FlexItem className="wrapper-region" basis='33.3%'>
            <div className="wrapper-title-edit">Region Assigned</div>
            {filedRegion?.map((item: any, index: number) => (
              <Form.Item css={`margin-bottom: 0`} key={index}>
                <Flex alignCenter justifyBetween>
                  <FlexItem basis='100%'>
                    <Select
                      placeholder="Region"
                      value={item.name || null}
                      css={`margin-top: 0`}
                      options={dropRegion}
                      onChange={(e) => onChangeRegion(e, index)}
                    />
                  </FlexItem>
                  <FlexItem onClick={() => removeRegion(index)} >
                    {index > 0 
                      ? <Flex
                          style={{ color: `${Theme.global.dangerColor}`, cursor: "pointer" }} 
                          alignCenter
                        >
                          <CloseSharpIcon /> <div>Delete</div>
                        </Flex>
                      : ""
                    }
                  </FlexItem>
                </Flex>
              </Form.Item>
            ))}
            {dropRegion?.length === filedRegion?.length
              ? ""
              : (
                  <Flex
                    css={`cursor: pointer; color: ${Theme.global.whiteColor}`}
                    onClick={addMoreRegion}
                  >
                    <AddOutlineIcon
                      width="19px" height="19px"
                      style={{color: `${Theme.global.whiteColor}`}}
                    />
                    Add more assigned region
                  </Flex>
                )
            }
          </FlexItem>
        </Flex>
      </div>
    </WrapCouriersForm>
  );
}

export default EditCouriersAccount;
