import moment from "moment";

const getPeriodTimeParam = (type: number, date?: any) => {
  let start:any, end:any;
  
  if(type > 4) {
    return '';
  }

  // Last month
  if (type === 0) {
    start = moment().subtract(1, "month").startOf("month");
    end = moment().subtract(1, "month").endOf("month");
  }

  // This month
  if (type === 1) {
    start = moment().startOf("month");
    end = moment().endOf("month");
  }

  // Last 7 days
  if (type === 2) {
    start = moment().subtract(6, "days");
    end = moment();
  }

  // 1 year
  if (type === 3) {
    start = moment().startOf("year");
    end = moment();
  }

  // Custom date
  if (type === 4) {
    start = moment(date.from);
    end = moment(date.to);
  }

  const startString = start.toISOString();
  const endString = end.toISOString();

  return `from=${encodeURIComponent(startString)}&to=${encodeURIComponent(
    endString
  )}`;
};

export { getPeriodTimeParam };
