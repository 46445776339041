import { getCss } from 'utils/cssHelpers';
import styled from "styled-components";

const StyledMyFeedbackParticulars = styled.div`
& {
  .title-info-details {
    ${getCss('background-color')('theme.global.primaryColor')};
    ${getCss('color')('theme.global.whiteColor')};
    margin-bottom: 0;
    margin-top: 10px;
    padding-left: 10px;
  }
  .custome-btn {
    padding: 6px 35px;
    line-height: 0;
  }
  .ml-10 {
    margin-left: 10px;
  }
  .bg-message {
    width: 100%;
    height: auto;
    padding: 10px;
    ${getCss('background-color')('theme.global.whiteColor')}
  }
  .custome-textarea {
    min-height: 210px;
  }
  .title-info, .value-info {
    font-weight: normal;
    margin-bottom: 20px;
  }
  .ant-col-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
`;

export default StyledMyFeedbackParticulars;
