import styled from 'styled-components'
import { getCss } from 'utils/cssHelpers'

const WeeklMenuWrapper: any = styled.div`
.view-next-7-days {
    font-size: 16px;
    font-weight: 600;
    ${getCss('color')('theme.global.grayColor')};
    margin-bottom: 29px;
    margin-top: 44px;
    cursor: pointer;
    svg {
        vertical-align: bottom;
    }
}
`;

WeeklMenuWrapper.Title = styled.div`
    font-size: 18px;
    font-weight: 600;
    ${getCss('color')('theme.global.grayColor')};
    margin-bottom: 10px;
`;

WeeklMenuWrapper.WDate = styled.div`
    button {
        width: 147.5px;
        height: 34.5px;
        border-radius: 16px;
        margin-right: 19px;
    }
    .svg-details-menu {
        height: max-content;
        line-height: 0;
        svg {
            ${getCss('fill')('theme.global.whiteColor')};
        }
    }
`;

WeeklMenuWrapper.Date = styled.div`
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    ${getCss('color')('theme.global.whiteColor')};
`;

WeeklMenuWrapper.ItemDate = styled.div`
    margin-bottom: 5px;
    ${getCss('background-color')('theme.global.primaryColor')};
    padding: 25px 34px 22px 29px;
    cursor: pointer;
`;

WeeklMenuWrapper.StyledRangeOfDates = styled.div`
    .rmdp-wrapper {
        margin: auto;
        box-shadow: none;
    }
    .rmdp-calendar {
        padding: 0;
        ${getCss('background-color')('theme.global.colorCbcbcb')};
    }
    .rmdp-day-picker {
        padding: 0;
    }
    .rmdp-header {
        display: none !important;
    }
    .rmdp-week-day {
        width: 30px;
        height: 20px;
        margin: 6px 8px;
        font-size: 8px;
    }
    .rmdp-day {
        margin: 6px 8px;
        border-radius: 50px;
        width: 26px;
        height: 26px;
        span {
            width: 20px;
            height: 20px;
            font-size: 9px;
            ${getCss('color')('theme.global.whiteColor')};
        }
        &.rmdp-selected,
        &.rmdp-today {
            span {
                background: unset;
            }
        }
        .rmdp-day.rmdp-today span {
            background: transparent;
        }
        .rmdp-day.rmdp-today .rmdp-selected {
            ${getCss('background')('theme.global.orangeColor')};
        }
        .rmdp-day.rmdp-selected span:not(.highlight) {
            ${getCss('background')('theme.global.orangeColor')};
        }
    }
    .rmdp-week {
        padding: 0 4px;
        &:first-child {
            ${getCss('background-color')('theme.global.primaryColor')};
        }
        &:last-child {
            padding-bottom: 7px;
        }
        .rmdp-week-day {
            ${getCss('color')('theme.global.whiteColor')};
        }
        .rmdp-range {
            ${getCss('background')('theme.global.orangeColor')};
        }
    }
    .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
        ${getCss('background')('theme.global.orangeColor')};
    }
    .rmdp-disabled {
        opacity: 0.4;
    }
`;

const ModalDuplicateMenuStyled = styled.div`
    .lable-item {
        margin-bottom: 18px;
    }
    .btn-save-duplicate {
        width: 140px;
        margin: auto;
        display: inherit;
        margin-top: 14px;
    }
    .input-date-sample {
        width: 60%;
        ${getCss('background')('theme.global.colorCbcbcb')};
    }
    .ant-picker-input input {
        &::placeholder {
            ${getCss('color')('theme.global.whiteColor')};
        }
    }
`;

export { WeeklMenuWrapper, ModalDuplicateMenuStyled }