import React from 'react'
import { Table as AntTable, TableProps as AntTableProps, TablePaginationConfig } from 'antd'
import { ColumnsType as AntTableColumnsType } from 'antd/es/table'

export type ColumnsType<R> = AntTableColumnsType<R>

export interface TableProps<RecordType> extends AntTableProps<RecordType> {}

function Table<R extends object = any>({
  pagination = { position: ['none', 'bottomCenter'] as TablePaginationConfig['position'] },
  ...rest
}: TableProps<R>): JSX.Element {
  return <AntTable<R> className="ddl-table" pagination={pagination} {...rest} />
}

export default Table
