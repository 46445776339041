import React from 'react'
import { Menu as AntMenu, SubMenuProps as AntSubMenuProps  } from 'antd'

const { SubMenu: AntSubMenu } = AntMenu

export interface SubMenuProps extends AntSubMenuProps {}

const SubMenu: React.FC<SubMenuProps> = (props) => {
  return <AntSubMenu className='ddl-submenu' {...props} />
}

export default SubMenu