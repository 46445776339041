import React from 'react'
import { Table, TableColumnProps as AntTableColumnProps} from 'antd'

const { Column: AntTableColumn } = Table

interface ColumnProps<R> extends AntTableColumnProps<R> {}

function TableColumn<R>(props: ColumnProps<R>): JSX.Element {
  return (
    <AntTableColumn className='ddl-table-column' { ...props } />
  )
}

export default TableColumn