import React, { useCallback, useEffect, useState } from "react";
import MyFeedbackWrapper from "./styled";
import Button from "components/shared/Button";
import Flex, { FlexItem } from "components/shared/Flex";
import Table2 from "components/shared/Table";
import { useHistory } from "react-router-dom";
import { ArrowForwardIcon, FunnelSharpIcon } from "components/shared/Icons";
import infoCenterService from "services/infocenter.service";
import Theme from "theme";
import Pagination from "components/shared/Pagination";

const collums = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Organisation",
    dataIndex: "organisation",
    key: "organisation",
  },
  {
    title: "Type Of Enquiry",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "Attended?",
    dataIndex: "attended",
    key: "attended",
  },
  {
    title: "View Message",
    dataIndex: "message",
    key: "message",
  },
];
const _handleViewFeedback = () => {};

const _renderIconView = (data?: any) => {
  return (
    <>
      {data > 0 ? data : null}{" "}
      <ArrowForwardIcon type="success" onClick={_handleViewFeedback} />
    </>
  );
};

type TableItem = {
  key: string;
  name: string;
  organisation: string;
  type: string;
  attended: string;
  message: any;total: number;page: number;itemsPerPage: number;
};

type PageState = {
  page: number;
  itemsPerPage: number;
  rows: TableItem[];
  total: number;
};

const initState: PageState = {
  page: 1,
  itemsPerPage: 10,
  rows: [],
  total: 0,
};

const MyFeedbackUI: React.FC = () => {
  const history = useHistory();
  const [state, setState] = useState<PageState>(initState);

  const _handleMyFeedback = () => {
    history.push("/information-centre/my-feedback");
  };

  // const _handleSeenFeedback = () => {
  //   history.push("/information-centre/send-feedback");
  // };

  const _handleViewDetails = (record: any) => {
    history.push(`/information-centre/my-feedback-particulars/${record.key}`);
  };

  const reloadList = useCallback(
    (pageSelected: number) => {
      infoCenterService
        .getFeedbacks(pageSelected - 1, state.itemsPerPage)
        .then((result) => {
          const rows = result.items.map((item) => {
            return {
              key: `${item.id}`,
              name: item.name,
              organisation: item.organization,
              type: item.type,
              attended: item.attended ? "Yes" : "No",
              message: _renderIconView(""),
            } as TableItem;
          });

          setState((previous) => ({
            ...previous,
            rows,
            total: result.total,
            page: result.page + 1,
            itemsPerPage: result.itemsPerPage,
          }));
        });
    },
    [state]
  );

  useEffect(() => {
    reloadList(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _renderTitleTable = (data: any) => {
    return (
      <Flex justifyBetween alignCenter>
        <FlexItem>
          <div className="all-feedback-title">{data}</div>
        </FlexItem>
        <FlexItem>
          <FunnelSharpIcon
            color={Theme.global.whiteColor}
            onClick={(record: any) => _handleViewDetails(record)}
          />
        </FlexItem>
      </Flex>
    );
  };

  const onChangePage = (page: number) => {
    reloadList(page);
  };
  
  return (
    <MyFeedbackWrapper>
      <Flex>
        <FlexItem>
          <Button
            type="primary"
            className="custome-btn"
            onClick={_handleMyFeedback}
          >
            My Feedback
          </Button>
          {/* <Button
            type="primary"
            className="custome-btn ml-10"
            onClick={_handleSeenFeedback}
          >
            Send Feedback
          </Button> */}
        </FlexItem>
      </Flex>
      <FlexItem style={{ paddingTop: "10px" }}>
        <Table2
          columns={collums}
          pagination={false}
          dataSource={state.rows}
          title={() => _renderTitleTable("All Feedback")}
          onRow={(record) => {
            return {
              onClick: () => _handleViewDetails(record),
            };
          }}
        />
        <Pagination
        onChange={onChangePage}
        total={state.total}
        current={state.page}
        pageSize={state.itemsPerPage}
      />
      </FlexItem>
    </MyFeedbackWrapper>
  );
};

export default MyFeedbackUI;
