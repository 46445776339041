import { BaseAPI } from "core/services/BaseAPI";

export interface IOrderService {
  getPreOrders: (date: string) => {};
  getLiveOrders: () => {};
  getTodayOrdersGeneral: () => {};
  getTodayOrderStatus: () => {};
  startPreparingOrder: () => {};
  finishPreparingOrder: () => {};
  completeOrder: (orderId: string | number) => {};
  getTodayOrdersByRegion: (regionId: string) => {};
  getPurchaseOrders: () => {};
  getOrder: (orderId: string | number) => {};
  getCompletedOrders: () => {};
}

class OrderService extends BaseAPI implements IOrderService {
  getPreOrders(date: string) {
    const path = `manage-orders/orderedFoodsByDate?date=${encodeURIComponent(
      date
    )}`;
    return this.get(path);
  }

  getLiveOrders() {
    return this.get(`manage-orders/live`);
  }

  getTodayOrderStatus() {
    return this.get(`manage-orders/today-order-status`);
  }

  getTodayOrdersGeneral() {
    return this.get(`manage-orders/today-orders-general`);
  }

  startPreparingOrder() {
    return this.post(`manage-orders/start-preparing`);
  }

  finishPreparingOrder() {
    return this.post(`manage-orders/finish-preparing`);
  }

  completeOrder(orderId: string | number) {
    return this.post(`manage-orders/${orderId}/complete`);
  }

  getTodayOrdersByRegion(regionId: string) {
    return this.get(`manage-orders/today-orders-by-region`, { regionId });
  }

  async exportCompletedOrders() {
    const blob = await this.download(`manage-orders/export-completed-orders`);
    const a = document.createElement("a");
    a.href = URL.createObjectURL(
      new Blob([blob], { type: "text/csv; charset=utf-8" })
    );
    a.download = `orders-export.xlsx`;
    document.body.appendChild(a);
    a.click();
    setTimeout(() => {
      document.body.removeChild(a);
    }, 0);
  }

  getUnpaidInvoices(userId: number) {
    return this.get(`manage-orders/payment/${userId}/unpaid-invoices`);
  }

  getPayInSettlement(userId: number) {
    return this.get(`manage-orders/payment/${userId}/pay-in-settlement`);
  }

  getPurchaseOrders() {
    return this.get(`manage-orders/purchase-orders`);
  }

  getOrder(orderId: number | string) {
    return this.get(`manage-orders/${orderId}/details`);
  }

  getCompletedOrders() {
    return this.get(`manage-orders/completed-orders`);
  }

  getDetailUnpaidInvoices(useId: number) {
    return this.get(`manage-orders/payment/${useId}/details`);
  }
}

export default new OrderService();
