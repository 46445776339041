import { BaseAPI } from 'core/services/BaseAPI';
export interface ICommonServices {
  getIndustries(): void
  getRegions(): void
  uploadFile(body: object): void
  getLiveCutOffTime(): {}
  addLiveCutOffTime(body: object) : {}
}

class CommonServices extends BaseAPI implements ICommonServices {

  async getIndustries() {
    return this.get('common/industries');
  }

  async getRegions() {
    try {
      const result = await this.get('common/regions');
      return result;
    } catch (error) {
      console.log('error :', error);
    }
  }

  uploadFile(body: object) {
    return this.postFormData('upload/image', body);
  }

  uploadCSV(body: any) {
    return this.postFormData("upload/csv", body);
  }

  getLiveCutOffTime() {
    return this.get('common/liveCutOffTime');
  }

  addLiveCutOffTime(body: object) {
    return this.post('common/liveCutOffTime', body);
  }
}

export default new CommonServices();
