import React, { useEffect, useState } from "react";
import Flex, { FlexItem } from "components/shared/Flex";
import OverviewReport from "./overview-report";
import YourStoreToday from "./your-store-today";
import PayInSettlementReceive from "./pay-in-settlement/PayInSettlementReceive";
import PayInSettlementSend from "./pay-in-settlement/PayInSettlementSend";
import dashboardService from "services/dashboard.service";
import userService from "services/user.service";
import { getDurationTimeParam, durationKeys } from "utils/common.util";

const Dashboard: React.FC = () => {
  const [dashboard, setDashboard] = useState<any>({});
  const [user, setUser] = useState<any>([]);

  const getDashboard = (duration: string) => {
    const params = getDurationTimeParam(+duration);

    dashboardService.getDashboard(params).then((res) => {
      setDashboard(res);
    });

    userService.getMe().then((res) => {
      setUser(res);
    });
  };

  useEffect(() => {
    getDashboard(durationKeys["30days"]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex full column>
      {user?.roles?.[0].id === "admin" ? (
        <>
          <Flex full>
            <OverviewReport dashboard={dashboard} getDashboard={getDashboard} />
            <YourStoreToday dashboard={dashboard} />
          </Flex>
          <Flex
            full
            css={`
              margin-top: 1rem;
            `}
          >
            <PayInSettlementSend dashboard={dashboard} />
            <PayInSettlementReceive dashboard={dashboard} />
          </Flex>
        </>
      ) : (
        <Flex>
          <FlexItem basis="50%">
            <PayInSettlementReceive dashboard={dashboard} />
          </FlexItem>
        </Flex>
      )}
    </Flex>
  );
};

export default Dashboard;
