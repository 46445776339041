import React, { useState, useEffect } from "react";
import { CompletedOrdersWrapper } from "./styled";
import { ArrowForwardIcon } from "components/shared/Icons";
import Table from "components/shared/Table";
import CompletedOrdersDetails from "pages/manage-orders/completed-orders/CompletedOrdersDetails.UI";
import { purchaseColumns } from "../manage-orders.config";
import orderService from "services/order.service";
import map from "lodash/map";

const CompletedOrdersUI: React.FC = () => {
  const [isDetails, setIsDetails] = useState<boolean>(false);
  const [orders, setOrders] = useState<any[]>([]);
  const [order, setOrder] = useState<object>({});

  const handleClickView = (order: any) => {
    setIsDetails(true);
    setOrder(order);
  };

  const getCompletedOrders = () => {
    orderService
      .getCompletedOrders()
      .then((res) => {
        const newOrders = map(res, (item) => ({
          ...item,
          key: item.orderId,
          detail: <ArrowForwardIcon type="danger" />,
        }));

        setOrders(newOrders);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getCompletedOrders();
  }, []);

  return (
    <CompletedOrdersWrapper.CompletedOrders>
      {!isDetails ? (
        <Table
          title={() => "Completed Orders"}
          dataSource={orders}
          columns={purchaseColumns}
          pagination={false}
          onRow={(record, rowIndex) => {
            return {
              onClick: () => handleClickView(record),
            };
          }}
        />
      ) : (
        <CompletedOrdersDetails
          order={order}
          clickBack={() => setIsDetails(false)}
        />
      )}
    </CompletedOrdersWrapper.CompletedOrders>
  );
};

export default CompletedOrdersUI;
