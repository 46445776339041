import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import userService from 'services/user.service'

import initialState from './state'

export const fetchCurrenUser = createAsyncThunk(
  'user/fetchCurrent',
  async (_, thunkAPI) => {
    const response = await userService.getCurrentUser()
    return response
  }
)

const { reducer } = createSlice({
  name: 'current-user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCurrenUser.pending, (state) => {
      state.loading = true;
    })

    builder.addCase(fetchCurrenUser.fulfilled, (state, action) => {
      state.loading = false;
      state.currentUser = action.payload
    })

    builder.addCase(fetchCurrenUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload
    })
  }
})

export default reducer