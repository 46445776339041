import React, { useEffect, useState } from "react";
import WrapCustomersForm from "./styled";
import AccountDetails from "./account-details";
import PointOfContact from "./point-of-contact";
import AccountInformation from "./account-information";
import Button from "components/shared/Button";
import GroupTitle from 'components/shared/GroupTitle';
import { ArrowBackOutlineIcon } from 'components/shared/Icons';
import { useHistory } from 'react-router';
import commonServices from 'services/common.service';
import find from "lodash/find";

export interface IPropsCustomersForm {
  isCheck: boolean,
  submitForm(body: any): any,
  form: any
}

type IPropsIndustries = {
  id: number,
  name: string
}

const CustomersForm: React.FC<IPropsCustomersForm> = ({
  isCheck,
  submitForm,
  form
}) => {
  const history = useHistory();
  const [isNewCustomer, setIsNewCustomer] = useState<boolean>(true);
  const [industries, setIndustries] = useState<any>([]);

  useEffect(() => {
    setIsNewCustomer(true);
  }, []);

  useEffect(() => {
    commonServices.getIndustries().then((industries) => {
      const result = industries.map((item: IPropsIndustries) => {
        return {
          id: item.id,
          name: item.name,
          value: item.name,
        };
      })
      setIndustries(result);
    });
  }, []);

const submitCustomers = async () => {
  try {
    const values = await form.validateFields();
    const industryIdSelected: any = find((industries), ["value", values.industryId]);
    values.industryId = + industryIdSelected.id;
    delete values.reEnterPassword;
    submitForm(values);
  } catch (error) {
    console.log("Failed:", error);
  }
}

function onKeypress(e: any) {
  if (e.key === "Enter") {
    submitCustomers();
  }
}

useEffect(() => {
  window.addEventListener("keypress", onKeypress);
  return () => {
    window.removeEventListener("keypress", onKeypress);
  };
});

  return (
    <WrapCustomersForm>
      <GroupTitle
        fontSize={16}
        icon={<ArrowBackOutlineIcon color="#fff" onClick={ () => history.goBack() }/>} 
        padding={5}
        className='styled-create-account'
      >
        {isCheck ? 'Create New Customer' : 'Edit Customer'}
      </GroupTitle>
      <AccountDetails
        title={"Vendor Company Details"}
        isNewCustomer={isNewCustomer}
        industries={industries}
      />
      <PointOfContact
        title={"Point Of Contact"}
      />
      <AccountInformation
        title={"Account Information"}
        isNewCustomer={isNewCustomer}
        isCheck={isCheck}
        form={form}
      />
      <Button type="info" htmlType='submit' onClick={submitCustomers}>
        {isCheck ? "Create Account" : "Edit Account"}
      </Button>
    </WrapCustomersForm>
  );
}

export default CustomersForm;
