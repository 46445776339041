import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { DetailsCourierWrapper } from './styled';
import Button from 'components/shared/Button';
import Tag, { TagProps } from 'components/shared/Tag';
import Table from 'components/shared/Table';
import DetailsInfo from 'components/shared/DetailsInfo';
import { ArrowBackIcon } from 'components/shared/Icons';
import Flex from 'components/shared/Flex';
import CouriersService from 'services/couriers.service';
import { Toastify } from "components/shared/Toast/Toast";
import Modal from "components/shared/Modal";
import Pagination from "components/shared/Pagination";

type IPropsOrder = {
  id: number,
  vendor: any,
  corporate: any,
  date: string,
  receipt: string,
  status: string
}

type IPagination = {
  total: number,
  onChange: any,
  current: number
}

const renderTagStatus = (status: string) => {
  var type: TagProps['type'] = 'primary';
  switch (status) {
    case 'created':
      type = 'primary';
      break;
    case 'paid':
      type = 'primary';
      break;
    case 'preparing':
      type = 'info';
      break;
    case 'delivering':
      type = 'primary';
      break;
    case 'completed':
      type = 'yellow';
      break;
    case 'cancelled':
      type = 'black';
      break;
    default:
      break;
  }

  return <Tag type={type}>{status}</Tag>
};

const columns = [
  {
    title: 'From (Vendor)',
    dataIndex: 'FromVendor',
    key: 'FromVendor',
  },
  {
    title: 'To (Customer)',
    dataIndex: 'ToCustomer',
    key: 'ToCustomer',
  },
  {
    title: 'Date',
    dataIndex: 'Date',
    key: 'Date',
  },
  {
    title: 'Receipt',
    dataIndex: 'Receipt',
    key: 'Receipt',
  },
  {
    title: 'Status',
    dataIndex: 'Status',
    key: 'Status',
  },
]

const DetailsCourierUI: React.FC = (props) => {
  const history: any = useHistory();
  const id = history?.location?.state?.user?.id;
  const [couriesDetail, setCouriesDetail] = useState({});
  const [allOrder, setAllOrder] = useState<Array<any>>([]);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [current, setCurrent] = useState<number>(1);

  useEffect(() => {
    if (!id) {
      return history.push(`/manage-couriers/all-couriers`);
    }
    CouriersService.getDetailsCourier(id).then(courier => {
      if (!courier) {
        Toastify.showWarningToast('Courier not found !');
        return history.push(`/manage-couriers/all-couriers`);
      }
      setCouriesDetail(
        {
          name: {
            label: 'Name',
            value: courier.name,
          },
          phoneNumber: {
            label: 'Main Contact Number',
            value: courier.phoneNumber,
          },
          email: {
            label: 'Email Address',
            value: courier.user.email,
          },
          courierId: {
            label: 'Courier ID',
            value: courier.courierId,
          },
          password: {
            label: 'Password',
            value: '************',
          },
          region: {
            label: 'Region Assigned',
            value: courier.regions.map((r: any)=>r.name).join('\n')// 'North',
          }
        }
      );
    }).catch(error => {
      Toastify.showWarningToast('Get courier detail failed !');
      return history.push(`/manage-couriers/all-couriers`);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const resultAllOrder = () => {
    CouriersService.getDetailOrder(id).then((result) => {
      const allOrder = result.map((row: IPropsOrder) => ({
        key: row.id,
        FromVendor: row?.vendor?.name,
        ToCustomer: row?.corporate?.name,
        Date: row.date,
        Receipt: row.receipt,
        Status: renderTagStatus(`${row.status}`),
      }));
      setAllOrder(allOrder);
    })
  }

  useEffect(() => {
    resultAllOrder();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleBackAllCourier = () => {
    history.push('/manage-couriers/all-couriers');
  };

  const handleDeleteAccount = () => {
    setIsDeleting(true);
    CouriersService.deleteDetailsCourier(id).then(res => {
      if (res.statusCode === 200) {
        Toastify.showSuccessToast('Delete Success !');
        history.push(`/manage-couriers/all-couriers`);
      } else {
        setIsDeleting(false);
        Toastify.showWarningToast(res.error || 'Delete failed !');
      }
    }).catch(error => {
      setIsDeleting(false);
      Toastify.showDangerToast('Error! Delete failed.');
      console.log(error);
    });
  };

  const _handleEditCourier = () => {
    history.push({
      pathname: `/manage-couriers/edit/${id}`,
      state: {id: history?.location?.state?.user?.id}
    });
  };

  const openModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  const pageSize: number = 5;

  const getData = (current: number, pageSize: number) => {
    return allOrder.slice((current - 1) * pageSize, current * pageSize);
  }

  const MyPagination = ({ total, onChange, current }: IPagination) => {
    return (
      <Pagination
        onChange={onChange}
        total={total}
        current={current}
        pageSize={pageSize}
      />
    )
  }

  return (
    <DetailsCourierWrapper>
      <Flex justifyBetween className="group-action">
        <Flex alignCenter>
          <ArrowBackIcon height="45px" width="45px" onClick={handleBackAllCourier}/>
          <Button type="info" className="btn-edit-account" onClick={_handleEditCourier}>
            Edit Information
          </Button>
        </Flex>
        <Button
          type="delete"
          disabled={isDeleting}
          className="btn-delete-account"
          onClick={openModal}
        >
          Delete Account
        </Button>
      </Flex>
      <DetailsInfo
        dataInfo={[couriesDetail]}
        className="courier-details"
        title="Vendor Company Details"
      />
      <Table
        className="table-all-orders-courier"
        title={() => 'All Orders'}
        dataSource={getData(current, pageSize)}
        columns={columns}
        pagination={false}
      />
       <MyPagination
          total={allOrder.length}
          current={current}
          onChange={setCurrent}
        />
      <Modal visible={isModalVisible}>
        <Flex justifyCenter>
          <p className="content-request-for-payment">
          Do you want to delete this couriers?.
          </p>
        </Flex>
        <Flex justifyEnd>
          <Button type="info" onClick={closeModal}>
            Cancel
          </Button>
          <Button onClick={handleDeleteAccount} style={{ marginLeft: "15px" }}>
            OK
          </Button>
        </Flex>
      </Modal>
    </DetailsCourierWrapper>
  )
}

export default DetailsCourierUI;
