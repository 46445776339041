import { BaseAPI } from "core/services/BaseAPI";

export interface IMenuService {}
class MenuService extends BaseAPI implements IMenuService {
  async getAllMenu(page: number, pageSize: number) {
    return await this.get(
      `manage-menus/menus?page=${page}&pageSize=${pageSize}`
    );
  }

  async getAllFoods(page: number, pageSize: number, keyword?: string) {
    return await this.get(
      `manage-menus/foods?page=${page}&pageSize=${pageSize}&keyword=${keyword}`
    );
  }

  async postListMenu(data: any) {
    return await this.post("manage-menus/menus", data);
  }

  async getMenuNoPagination() {
    return await this.get("manage-menus/menus/nopagination");
  }

  async getDetailMenu(id: number) {
    return await this.get(`manage-menus/menus/${id}/foods`);
  }

  async getAllFoodsType() {
    return await this.get("manage-menus/food-types");
  }

  async postNewFoods(data: any) {
    return await this.post("manage-menus/food-types", data);
  }

  async deleteFoodType(id: number) {
    return await this.delete(`manage-menus/food-types/${id}`);
  }

  async editFoodType(id: number, data: any) {
    return await this.patch(`manage-menus/food-types/${id}`, data);
  }

  async getDetailFoodType(idFood: number) {
    return await this.get(`manage-menus/food-types/${idFood}/details`);
  }

  async deleteMenu(id: number) {
    return await this.delete(`manage-menus/menus/${id}`);
  }

  async addFoods(data: any) {
    return await this.post(`manage-menus/foods`, data);
  }

  async editFoods(id: number, body: any) {
    return await this.patch(`manage-menus/foods/${id}`, body);
  }

  async getDetailFoods(id: number) {
    return await this.get(`manage-menus/foods/${id}/details`);
  }

  async upload(data: any) {
    return await this.post("upload/image", data);
  }

  async updateMenu(id: number, body: any) {
    return await this.patch(`manage-menus/menus/${id}`, body);
  }

  async detailMenu(id: number) {
    return await this.get(`manage-menus/menus/${id}`);
  }

  async getFoodByType(param: string) {
    return await this.get(`manage-menus/foods/bytype?logicType=${param}`);
  }

  async getDetailWeeklyMenu(id: number, roleId: string, day: string) {
    const path = `weekly/${id}/${roleId}/${day}/foods`;
    return await this.get(path);
  }

  async getWeeklyMenu() {
    const now = new Date();
    const day = now.getDay(),
      diff = now.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
    const monday = new Date(now.setDate(diff)).toISOString();
    const path = `weekly?from=${encodeURIComponent(monday)}`;

    return await this.get(path);
  }

  async editWeeklyMenu(id: number, roleId: string, days: string, body: any) {
    return await this.put(`weekly/${id}/${roleId}/${days}/foods`, body);
  }

  async duplicateMenu(body: any) {
    return await this.post(`weekly/duplicate`, body);
  }
}

export default new MenuService();
