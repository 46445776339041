import React from 'react'
import { Tag as AntTag, TagProps as AntTagProps } from 'antd'

export interface TagProps extends AntTagProps {
  type: 'primary' | 'info' | 'danger' | 'brown' | 'yellow' | 'black'
}

const Tag: React.FC<TagProps> = (props) => {
  return (
    <AntTag { ...props } />
  )
}

export default Tag