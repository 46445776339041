import React, { useState, useEffect } from 'react';
import WrapOverview from './styled';
import {Tabs, TabPane} from 'components/shared/Tabs';
import PaymentSetup from './PaymentSetup';
import ManageOverview from './ManageOverview';
import userService from "services/user.service";
import Flex, { FlexItem } from "components/shared/Flex";
import Button from 'components/shared/Button';
import Visa from 'assets/images/visa.png';

const OverViewUI: React.FC = (props) => {
  const TabsList: Array<string> = ['Overview', 'Payment Setup'];
  const [user, setUser] = useState<any>([]);

  const getRoles = () => {
    userService.getMe().then((res) => {
      setUser(res);
    });
  }

  useEffect(() => {
    getRoles();
  }, []);


  const _handleChangeTabs = () => {}

  const _handleConnectNewAccount = () => {}

  const _handleSwitchTabs: any = (e: any) => {
    if (e === 0) {
      return <ManageOverview />
    } else {
      return <PaymentSetup />
    }
  }

  return (
    <WrapOverview>
      {user?.roles?.[0].id === "admin"
        ? (
            <Tabs defaultActiveKey="0" onChange={_handleChangeTabs}>
              {
                TabsList.map((item, index) => {
                  return (
                    <TabPane tab={item} key={index}>
                      {_handleSwitchTabs(index)}
                    </TabPane>
                  )
                })
              }
            </Tabs>
        )
      : (
        <WrapOverview.ManageOverview>
          <Flex>
              <FlexItem basis='33.3%'>
                <div className="wrap-card">
                  <h3 className="title-bank">BANK ACCOUNT</h3>
                  <p className="sub-title-bank">Bank Account</p>
                  <Flex alignCenter style={{padding: '10px 0' }}>
                    <FlexItem>
                      <img src={Visa} alt="card-bank" />
                    </FlexItem>
                    <FlexItem>**** **** **** 2187</FlexItem>
                  </Flex>
                  <Button type='link' className='custome-btn'
                  onClick={_handleConnectNewAccount}
                  >Connect New Account</Button>
                </div>
              </FlexItem>
          </Flex>
        </WrapOverview.ManageOverview>
        )
      }
    </WrapOverview>
  );
}

export default OverViewUI;
