import React, {useState, useEffect} from 'react';
import {useHistory} from "react-router-dom";
import {AllCustomersWrapper} from './styled';
import Button from 'components/shared/Button';
import {Table2} from 'components/shared/Table';
import {ArrowForwardIcon, FunnelSharpIcon} from 'components/shared/Icons';
import InputSearch from 'components/shared/InputSearch';
import Flex from 'components/shared/Flex';
import customerService from 'services/customers.service';

type ICustomerProps = {
  id: number,
  name: string,
  registeredAddress: string,
  phoneNumber: string,
  email: string,
  user: any,
}

const columns = [
  {
    title: 'No.',
    dataIndex: 'MenuNo',
    key: 'MenuNo',
  },
  {
    title: 'Company Name',
    dataIndex: 'CompanyName',
    key: 'CompanyName',
  },
  {
    title: 'Address',
    dataIndex: 'Address',
    key: 'Address',
  },
  {
    title: 'Contact Number',
    dataIndex: 'ContactNumber',
    key: 'ContactNumber',
  },
  {
    title: 'Email Address',
    dataIndex: 'EmailAddress',
    key: 'EmailAddress',
  },
  {
    title: <FunnelSharpIcon height="14px"/>,
    dataIndex: 'DetailsCourier',
    key: 'DetailsCourier',
  },
];

const AllCustomersUI: React.FC = (props) => {
  const history = useHistory();
  const [listAllUsers, setListAllUsers] = useState<any>([]);
  const [allUsers, setAllUser] = useState<any>([]);
  const [isActive, setActive] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const getAllUsers = async () => {
    customerService.getAllCorporates().then(
      (allUsers) => {
        setLoading(false);
        const listUsers = allUsers.map((row: ICustomerProps, index: number) => ({
          key: index,
          MenuNo: index + 1,
          CompanyName: row.name,
          Address: row.registeredAddress,
          ContactNumber: row.phoneNumber,
          EmailAddress: row.user.email,
          DetailsCourier: <ArrowForwardIcon type="success"/>,
          user: row.user
        }));
        setAllUser(listUsers);
        setListAllUsers(listUsers);
      }
    );
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  const handleClickCustomer = (record: any) => {
    history.push({
      pathname: `/manage-customers/customer-details/${record?.user?.id}`,
      state: { user: record?.user }
    });
  };

  const _handleClickAddNew = () => {
    setActive(true);
    history.push(`/manage-customers/new`);
  };

  const _handleClickAllCustomers = () => {
  }

  const handleSearchCustomer = (e: any) => {
    const value = e.target.value.toLowerCase();
    var dataSourceFilter = listAllUsers;
    if (value !== '') {
      const arrKeysSearch = ['CompanyName', 'ContactNumber', 'Address', 'EmailAddress'];
      const checkItem = (itemData: any) => {
        if (arrKeysSearch.some(key => itemData[key].toLowerCase().indexOf(value) !== -1)) {
          return itemData;
        } else {
          return null;
        }
      }
      dataSourceFilter = listAllUsers.map((item: any) => checkItem(item)).filter((item: any) => item !== null);
    }
    setAllUser(dataSourceFilter);
  };

  return (
    <AllCustomersWrapper>
      <Flex justifyBetween>
        <Flex>
          <Button
            type="primary"
            className={`btn-control btn-add-new-customer ${!isActive ? 'active-btn' : ''}`}
            onClick={_handleClickAddNew}
          >
            Add New
          </Button>
          <Button
            type="primary"
            className={`btn-control btn-all-customer`}
            onClick={_handleClickAllCustomers}
          >
            All Customer
          </Button>
        </Flex>
        <InputSearch
          placeholder="Search Vendors"
          className="input-search-customer"
          onInput={e => handleSearchCustomer(e)}
        />
      </Flex>
      {
        loading
          ? ('Loading...')
          : (
          <Table2
            dataSource={allUsers}
            columns={columns}
            pagination={false}
            className="table-all-customer"
            onRow={(record, rowIndex) => {
              return {
                onClick: () => handleClickCustomer(record),
              };
            }}
          />
        )
      }
    </AllCustomersWrapper>
  )
}

export default AllCustomersUI;
