import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import StyledTabsLink from './styled'

export interface TabLinkProps {
  text: string;
  link: string;
  roles?: string[]
}

export interface TabsLinkProps {
  tabs: Array<TabLinkProps>;
  className?: string;
}

const TabsLink: React.FC<TabsLinkProps> = ({ tabs, className }) => {
  const { pathname }  = useLocation();

  return (
    <StyledTabsLink className={className}>
      {tabs.map((item, index) =>
        <Link
          to={item.link}
          key={index}
          className={item.link === pathname ? 'active' : ''}
        >
          {item.text}
        </Link>
      )}
    </StyledTabsLink>
  )
}

export default TabsLink