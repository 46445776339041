import React from "react";
import { FlexItem } from "components/shared/Flex";
import WrapOverview from "./styled";

interface IpropsInvoices {
  label?: any,
  title?: string,
  content?: string,
  position?: boolean
}

const InvoicesItems: React.FC<IpropsInvoices> = ({
  label,
  title,
  content,
  position
}) => {
  return (
    <WrapOverview.WrapInvoices>
      <div 
        className="label-invoices"
        style={
          position
          ? {textAlign: "start", fontWeight: "bold"}
          : {textAlign: "end", fontWeight: "normal"}
        }
      >
        {label}
      </div>
      <FlexItem basis="50%">
        <div className="wrap-content">
          <h3 className="title">
            {title}
          </h3>
          <p className="content">
            {content}
          </p>
        </div>
      </FlexItem>
    </WrapOverview.WrapInvoices>
  )
}

export default InvoicesItems;
