import React from 'react'
import { Col as AntCol, ColProps as AntColProps } from 'antd'

export interface ColProps extends AntColProps {}

const Col: React.FC<ColProps> = (props) => {
  return (
    <AntCol className='ddl-col' { ...props } />
  )
}

export default Col