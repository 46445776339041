import React from 'react';
import Flex, { FlexItem } from 'components/shared/Flex';
import GroupTitle from 'components/shared/GroupTitle';
import StyledAllAddOns from './styled';
import { ArrowBackOutlineIcon } from 'components/shared/Icons';
import { useHistory } from "react-router-dom";
import menuService from 'services/menu.service';
import { Form } from 'antd';
import { Toastify } from 'components/shared/Toast/Toast';
import FormAddOns from './FormAddOns';
import Theme from "theme";

const AllAddOns: React.FC = (props) => {
  const history = useHistory();
  const [form] = Form.useForm();

  const addOns = (body: any) => {
    menuService.addFoods(body)
    .then(() => {
      Toastify.showSuccessToast('Create new add ons success !');
      history.goBack();
    })
    .catch((error) => {
      Toastify.showDangerToast(error?.response?.data?.messages?.[0]);
    })
  }

  const _handleClickAllAddOns = () => {
    history.goBack();
  }

  return (
    <StyledAllAddOns.AddOns>
      <Flex nowrap>
          <FlexItem>
            <GroupTitle
              fontSize={16}
              icon={<ArrowBackOutlineIcon color={Theme.global.whiteColor} onClick={_handleClickAllAddOns} />} 
              padding={5}
              className='title-new-add-ons'
            >
                Create New Add Ons
            </GroupTitle>
          </FlexItem>
      </Flex>
      <Form
        name="basic"
        form={form}
      >
        <Flex nowrap>
          <FlexItem css={`flex: 1`}>
            <FormAddOns
              isCheck={true}
              submitForm={addOns}
              form={form}
            />
          </FlexItem>
        </Flex>
      </Form>
    </StyledAllAddOns.AddOns>
  );
} 

export default AllAddOns;
