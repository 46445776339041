import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Button from "components/shared/Button";
import { Table3 } from "components/shared/Table";
import Tag from "components/shared/Tag";
import InputSearch from "components/shared/InputSearch";
import { WeeklyMenuEdit } from "./styled";
import menuService from "services/menu.service";
import { Toastify } from "components/shared/Toast/Toast";
import _ from "lodash";
import { filterAndSearchFood, renderTags } from "./weekly-menu-edit.util";
import { getFruitIdState } from "store/modules/manage-menu";

export interface IPropFruitPlatter {
  selected: number[];
  onChange: (selecteds: number[]) => void;
  dateFood: string;
  idWeekly: number;
  getRole: string;
  columns: any;
  renderImageFood: (url: any) => any;
  getUrl: (url: any) => any;
  resultFoods: [];
  loading: boolean;
}

type IPropsFoodsType = {
  id: number;
  photo: string;
  name: string;
  menu: any;
  types: any;
  calories: string;
  price: string;
};

const FruitPlatterTable: React.FC<IPropFruitPlatter> = ({
  selected,
  onChange,
  dateFood,
  idWeekly,
  getRole,
  columns,
  renderImageFood,
  getUrl,
  resultFoods,
  loading,
}) => {
  const dispatch = useDispatch();
  const [fruitPlatter, setFruitPlatter] = useState<any>([]);
  const [allFruitPlatter, setAllFruitPlatter] = useState<any>([]);
  const [valueSearch, setValueSearch] = useState<string>("");
  const [filterTags, setFilterTags] = useState<Array<any>>([]);

  const getFruitFoods = () => {
    const fruitPlatter = resultFoods.map(
      (row: IPropsFoodsType, index: number) => ({
        key: row.id,
        MenuNumber: <span>{row.id}</span>,
        ImageFood: renderImageFood(`${getUrl(row.photo)}`),
        FoodID: row.id,
        Name: row.name,
        MenuCategory: row?.menu?.name,
        FoodType: row?.types?.map((data: any, i: number) => {
          return (
            <Tag
              type="primary"
              style={{ backgroundColor: `${data.color}` }}
              key={i}
            >
              {data.name}
            </Tag>
          );
        }),
        FoodTypeSearch: row.types[0].name,
        Calories: row.calories,
        Price: `$${row.price}`,
      })
    );

    setAllFruitPlatter(fruitPlatter);
    setFruitPlatter(fruitPlatter);
  };

  useEffect(() => {
    getFruitFoods();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultFoods]);

  const searchFruitPlatter = (e: any) => {
    const inputSearch = e?.target?.value?.toLowerCase();

    setValueSearch(inputSearch);

    const filterFood = filterAndSearchFood(
      fruitPlatter,
      filterTags,
      inputSearch
    );

    setAllFruitPlatter(filterFood);
  };

  const searchEnter = (e: any) => {
    if (e.key === "Enter") {
      setFilterTags([...filterTags, e?.target?.value]);
      setValueSearch("");
    }
  };

  // Search when Tags are changed
  useEffect(() => {
    // Note: keep valueSearch when close a tag
    searchFruitPlatter({ target: { value: valueSearch } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterTags]);

  const submitFruitPlatter = () => {
    const body = {
      logicType: "main-foods",
      foodIds: selected,
      forFruit: true,
    };

    if (selected.length === 0) {
      Toastify.showDangerToast("Checkbox field are required !");
    } else {
      menuService
        .editWeeklyMenu(idWeekly, getRole, dateFood, body)
        .then(() => {
          Toastify.showSuccessToast(`Edit ${dateFood} ${getRole} success !`);
        })
        .catch((error) => {
          Toastify.showDangerToast(error?.response?.data?.messages?.[0]);
        });
    }
  };

  const removeTag = (tag: String) => {
    const newTags = _.filter(filterTags, (item) => item !== tag);
    setFilterTags(newTags);
  };

  const onSelectFood = (_selectedFoods: any) => {
    dispatch(getFruitIdState([..._selectedFoods]));
    onChange(_selectedFoods);
  };

  return (
    <WeeklyMenuEdit>
      <WeeklyMenuEdit.TitleWrap>Fruit Platter</WeeklyMenuEdit.TitleWrap>
      <WeeklyMenuEdit.Actions>
        <InputSearch
          className="search-menu"
          placeholder="Search Fruits"
          onInput={(e) => searchFruitPlatter(e)}
          onKeyDown={(e) => searchEnter(e)}
          value={valueSearch}
        />
        <WeeklyMenuEdit.RemoveCategory>
          {renderTags(filterTags, removeTag)}
        </WeeklyMenuEdit.RemoveCategory>
      </WeeklyMenuEdit.Actions>
      {loading ? (
        "Loading..."
      ) : (
        <Table3
          dataSource={allFruitPlatter}
          rowSelection={{
            type: "radio",
            preserveSelectedRowKeys: true,
            selectedRowKeys: selected,
            onChange: onSelectFood,
          }}
          columns={columns}
          pagination={false}
          className="table-all-couriers"
        />
      )}
      <div className="wrap-submit">
        <p className="note">Select your choice of food menu for the day.</p>
        <Button
          type="primary"
          onClick={submitFruitPlatter}
          className="customer-button"
        >
          Save
        </Button>
      </div>
    </WeeklyMenuEdit>
  );
};

export default FruitPlatterTable;
