import styled from 'styled-components';
interface IpropsForm {
    fontSize?: number;
    icon?: any;
    padding?: number;
}


const StyledGroup: any = styled.div``;

StyledGroup.WrapperIcon = styled.div `
    background-color: ${props => props.theme.global.primaryColor};
    border-radius: 50%;
    cursor: pointer;
    line-height: 1.3px;
    padding: ${(props: IpropsForm) => props.padding + 'px' || 0};
    border-radius: 50%;
`;

StyledGroup.WrapperTitle = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
`;

StyledGroup.GroupLabel = styled.label`
    color: ${props => props.theme.menu.textColor};
    font-size: ${(props: IpropsForm) => props.fontSize + 'px' || 16} !important;
    margin: 0;
    font-weight: 500;
    letter-spacing: normal;
    line-height: 1.3;
    margin-left: 13px;
`;


export default StyledGroup;
