import React from "react";
import StyledVendorForm from "../styled";
import Input from "components/shared/Input";
import GroupTitle from "components/shared/GroupTitle/GroupTitle";
import Flex, { FlexItem } from "components/shared/Flex";
import Form from "components/shared/Form";
import generate from "generate-password";
interface AccountInfoProps {
  isNewVendor: boolean;
  form: any;
}

const AccountInfo: React.FC<AccountInfoProps> = ({ isNewVendor, form }) => {

  const _handleGeneratePassword = () => {

    const password = generate.generate({
      length: 10,
      numbers: true,
    });

    form.setFields([
      {
        name: ["password"],
        value: password,
      },
      {
        name: ["reEnterPassword"],
        value: password,
      },
    ]);
  };

  return (
    <StyledVendorForm>
      <GroupTitle
        fontSize={18}
        style={{ paddingTop: "23px", paddingBottom: "21px", marginLeft: "0" }}
      >
        Account Information
      </GroupTitle>
      <Flex>
        <FlexItem
          basis="33.3%"
          style={{ paddingTop: "10px", paddingBottom: "34px" }}
        >
          <Form.Item
            name="vendorId"
            rules={[
              { required: true, message: "Please input your VENDOR ID!" },
            ]}
          >
            <Input
              placeholder="VENDOR ID"
              notes="*This ID is for admin log in and for employees in conjunction"
            />
          </Form.Item>
        </FlexItem>
      </Flex>
      <Flex>
        <FlexItem basis="33.3%">
          <Form.Item
            name="password"
            rules={[
              {
                required: isNewVendor,
                message: "Please input your Enter Password!",
              },
            ]}
          >
            <Input
              placeholder="Enter Password"
              isVisible={true}
            />
          </Form.Item>
        </FlexItem>
        <FlexItem style={{ padding: "0 10px" }} basis="33.3%">
          <Form.Item
            name="reEnterPassword"
            dependencies={["password"]}
            rules={[
              {
                required: isNewVendor,
                message: "Please input your Re Enter Password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  const passwordField = getFieldValue("password") || '';
                  value = value || ''
                  
                  if (passwordField === value) {
                    return Promise.resolve();
                  }

                  return Promise.reject(
                    new Error(
                      "The two passwords that you entered do not match!"
                    )
                  );
                },
              }),
            ]}
          >
            <Input
              placeholder="Re Enter Password"
              isVisible={true}
            />
          </Form.Item>
        </FlexItem>
      </Flex>
      <StyledVendorForm.Generate onClick={_handleGeneratePassword}>
        Generate Password
      </StyledVendorForm.Generate>
    </StyledVendorForm>
  );
};

export default AccountInfo;
