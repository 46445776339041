import React from "react";
import { NewOrdersWrapper } from "./styled";
import RealTimeLiveOrders from "./RealTimeLiveOrders";
import AllPreOrders from "./AllPreOrders";

const NewOrdersUI: React.FC = () => {
  return (
    <NewOrdersWrapper>
      <RealTimeLiveOrders />
      <AllPreOrders />
    </NewOrdersWrapper>
  );
};

export default NewOrdersUI;
