import React, { useState } from "react";
import Card from "components/shared/Card";
import Flex, { FlexItem } from "components/shared/Flex";
import { SettingIcon } from "components/shared/Icons";
import ReportDetail from "./ReportDetail";
import { OverviewReportUIProps } from "./interfaces";
import { durationKeys } from "utils/common.util";
import OverviewReportStyled from "./styled"
import Duration from "components/shared/Duration/Duration";

interface ICardTitleRight {
  onDurationChange: (val: string) => void
  duration: string
}

const CardTitleRight: React.FC<ICardTitleRight> = ({
  duration,
  onDurationChange,
}) => {
  const handleMenuClick = (durationKey: string) => {
    onDurationChange(durationKey);
  };

  return (
    <Flex center>
      <Duration suffixIcon={<SettingIcon />} duration={duration} onChangeDuration={handleMenuClick}/>
    </Flex>
  );
};

const OverviewReportUI: React.FC<OverviewReportUIProps> = ({
  dashboard = {},
  getDashboard
}) => {
  const [duration, setDuration] = useState<string>(durationKeys["30days"]);

  const onDurationChange = (_duration: string) => {
    setDuration(_duration);
    getDashboard(_duration);
  }

  const fixedNumber = (number: number = 0) => {
    return Math.abs(+number.toFixed(2));
  };

  const getUpDownType = (percent: number = 0) => {
    if (percent >= 0) {
      return "up";
    }

    return "down";
  };

  const kFormatter = (num: number = 0) => {
    return Math.abs(num) > 999
      ? Math.sign(num) * +(Math.abs(num) / 1000).toFixed(2) + "k"
      : Math.sign(num) * Math.abs(num);
  };

  return (
    <OverviewReportStyled> 
    <Card
      title="Overview Report"
      extra={
        <CardTitleRight duration={duration} onDurationChange={onDurationChange} />
      }
      style={{ marginRight: "10px" }}
    >
      <Flex style={{ flexWrap: "wrap" }}>
        <FlexItem style={{ width: "50%" }}>
          <ReportDetail
            duration={duration}
            percent={fixedNumber(dashboard.totalOrdersChangePercent)}
            type={getUpDownType(dashboard.totalOrdersChangePercent)}
            title={<span style={{ fontWeight: "bold" }}>Total Orders</span>}
            value={kFormatter(dashboard.totalOrders)}
          />
        </FlexItem>
        <FlexItem style={{ width: "50%" }}>
          <ReportDetail
            duration={duration}
            percent={fixedNumber(dashboard.newVendorSignupChangePercent)}
            type={getUpDownType(dashboard.newVendorSignupChangePercent)}
            hideBorderLeft
            title={
              <span style={{ fontWeight: "bold" }}>New Vendor Sign Ups</span>
            }
            value={kFormatter(dashboard.newVendorSignup)}
          />
        </FlexItem>
        <FlexItem style={{ width: "50%" }}>
          <ReportDetail
            duration={duration}
            percent={fixedNumber(dashboard.totalSaleChangePercent)}
            type={getUpDownType(dashboard.totalSaleChangePercent)}
            hideBorderTop
            title={<span style={{ fontWeight: "bold" }}>Total Sales</span>}
            value={kFormatter(dashboard.totalSale)}
            valueRender={(value) => `$${value}`}
          />
        </FlexItem>
        <FlexItem style={{ width: "50%" }}>
          <ReportDetail
            duration={duration}
            percent={fixedNumber(dashboard.consumerSalesChangePercent)}
            type={getUpDownType(dashboard.consumerSalesChangePercent)}
            hideBorderLeft
            hideBorderTop
            title={
              <div>
                <span style={{ fontWeight: "bold" }}>Consumer Sales</span>
                <div style={{ position: "absolute" }}>
                  From Daily Lunch Menu
                </div>
              </div>
            }
            value={kFormatter(dashboard.consumerSales)}
            valueRender={(value) => `$${value}`}
          />
        </FlexItem>
      </Flex>
    </Card></OverviewReportStyled>
  );
};

export default OverviewReportUI;
