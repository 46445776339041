import React, { useEffect, useState } from "react";
import { Form } from "antd";
import MarketingForm from "../marketing-form";
import { useHistory } from "react-router-dom";
import marketingService from "services/marketing.service";
import { Toastify } from "components/shared/Toast/Toast";
import moment from "moment";

const CouponsUI: React.FC = () => {
  const history: any = useHistory();
  const [form] = Form.useForm();
  const id = history?.location?.state?.id;
  const [formTitle, setFormTitle] = useState<string>();

  const getCoupons = (id: number) => {
    marketingService.detailNews(id).then((result = {}) => {
      setFormTitle(result.title);
      form.setFields([
        {
          name: ["title"],
          value: result.title,
        },
        {
          name: ["description"],
          value: result.description,
        },
        {
          name: ["banner"],
          value: result.banner,
        },
        {
          name: ["expiry"],
          value: moment(result.expiry),
        },
      ]);
    });
  };

  useEffect(() => {
    getCoupons(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const editCoupons = (body: any) => {
    marketingService
      .editCoupons(id, body)
      .then(() => {
        Toastify.showSuccessToast("Edit success!");
        history.push("/marketing/promotions");
      })
      .catch((error) =>
        Toastify.showDangerToast(error?.response?.data?.messages?.[0])
      );
  };

  return (
    <Form name="basic" form={form}>
      <Form.Item>
        <MarketingForm
          formTitle={formTitle}
          form={form}
          isCheck={false}
          submitPromotions={editCoupons}
          id={id}
        />
      </Form.Item>
    </Form>
  );
};

export default CouponsUI;
