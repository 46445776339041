import React from "react";
import Flex, { FlexItem } from "components/shared/Flex";
import AllVendorsWrapper from "../styled";
import GroupTitle from "components/shared/GroupTitle";
import Table from "components/shared/Table";

const renderContent: any = (value: string, row: object, index: number) => {
  const obj: any = {
    children: value,
    props: {},
  };
  // if (index < 2) {

  //   obj.props.rowSpan = 1;
  // }
  return obj;
};

const columns = (maxRow:any) => [
  {
    title: "",
    dataIndex: "columnHeader",
    className: 'column-header',
    render: (value: string, row: object, index: number) => {
      const obj: any = {
        children: '',
        props: {},
      };
      if (index + 1 === maxRow.maxRowMainMenu) {
        obj.children = <div >Main Menu</div>;
      }
      if (index + 1 === maxRow.maxRowFruitPlatter + maxRow.maxRowMainMenu) {
        obj.children = <div >Fruit Platter</div>;
      }
      if (index +1 === maxRow.maxRowAddOn + maxRow.maxRowFruitPlatter + maxRow.maxRowMainMenu) {
        obj.children = <div >Add On</div>;
      }
      return obj;
    },
  },
  {
    title: "Monday",
    dataIndex: "monday",
    render: renderContent,
  },
  {
    title: "Tuesday",
    dataIndex: "tuesday",
    render: renderContent,
  },
  {
    title: "Wednesday",
    dataIndex: "wednesday",
    render: renderContent,
  },
  {
    title: "Thursday",
    dataIndex: "thursday",
    render: renderContent,
  },
  {
    title: "Friday",
    dataIndex: "friday",
    render: renderContent,
  },
  {
    title: "Saturday",
    dataIndex: "saturday",
    render: renderContent,
  },
  {
    title: "Sunday",
    dataIndex: "sunday",
    render: renderContent,
  },
];

interface IWeeklyMenu {
  weeklyMenu: any;
  maxRow: any;
}

const WeeklyMenu: React.FC<IWeeklyMenu> = ({ weeklyMenu, maxRow }) => {
  return (
    <AllVendorsWrapper.WrapperWeeklyMenu>
      <GroupTitle
        fontSize={18}
        className="weekly-menu-title"
        style={{ margin: "0" }}
      >
        Weekly Menu
      </GroupTitle>
      <div className="bg-table-weekly-menu">
        <Flex alignEnd nowrap>
          {/* <FlexItem>
            <div className="bar-menu-item">Main Menu</div>
            <div className="bar-menu-item">Fruit Platter</div>
            <div className="bar-menu-item">Add On</div>
          </FlexItem> */}
          <FlexItem basis="100%">
            <Table
              columns={columns(maxRow)}
              dataSource={weeklyMenu}
              pagination={false}
            />
          </FlexItem>
        </Flex>
      </div>
    </AllVendorsWrapper.WrapperWeeklyMenu>
  );
};

export default WeeklyMenu;
