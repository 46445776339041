import React from 'react';
import { DatePicker as AntDatePicker, DatePickerProps as AntDatePickerProps} from 'antd';
import StyledInputDatePicker from "./styled";

type DatePickerProps = {} & AntDatePickerProps

const InputDatePicker: React.FC<DatePickerProps> = (props: any) => {
  return (
    <StyledInputDatePicker>
      <AntDatePicker className='ddl-input-date-picker' {...props} />
    </StyledInputDatePicker>
  )
}

export default InputDatePicker;

