import { FunnelSharpIcon, ImageSharpIcon } from 'components/shared/Icons';

const columns = [
  {
    title: 'Menu Number',
    dataIndex: 'MenuNumber',
    key: 'MenuNumber',
  },
  {
    title: <ImageSharpIcon />,
    dataIndex: 'ImageFood',
    key: 'ImageFood',
  },
  {
    title: <>Food ID <FunnelSharpIcon height="14px"/></>,
    dataIndex: 'FoodID',
    key: 'FoodID',
  },
  {
    title: <>Name <FunnelSharpIcon height="14px"/></>,
    dataIndex: 'Name',
    key: 'Name',
  },
  {
    title: <>Menu Category <FunnelSharpIcon height="14px"/></>,
    dataIndex: 'MenuCategory',
    key: 'MenuCategory',
  },
  {
    title: <>Food Type <FunnelSharpIcon height="14px"/></>,
    dataIndex: 'FoodType',
    key: 'FoodType',
  },
  {
    title: <>Calories <FunnelSharpIcon height="14px"/></>,
    dataIndex: 'Calories',
    key: 'Calories',
  },
  {
    title: <>Price <FunnelSharpIcon height="14px"/></>,
    dataIndex: 'Price',
    key: 'Price',
  },
];

export { columns };
