import React from 'react';
import { Switch as AntSwitch, SwitchProps as AntSwitchProps } from 'antd';

export interface SwitchProps extends AntSwitchProps {
  type: 'primary' | 'info'
}

const Switch: React.FC<SwitchProps> = (props) => {
  return <AntSwitch className='ddl-switch' {...props} />
}

export default Switch;
