import React from 'react'
import styled from 'styled-components'

const MarketingContainer = styled.div`
  width: 100%;
`

const Marketing: React.FC = ({ children }) => {
  return <MarketingContainer>{ children }</MarketingContainer>
}

export default Marketing
