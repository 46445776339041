import styled from "styled-components";
import { getCss } from "utils/cssHelpers";

const WrapPaymentTerms = styled.div`
  width: 30%;
  margin-left: auto;
  text-align: left;
  .terms {
    padding: 10px 0 20px 0;
    margin: 0;
    ${getCss("color")("theme.global.color444444")};
    font-size: 14px;
    font-weight: 600;
  }
  .content {
    margin: 0;
    ${getCss("color")("theme.global.color444444")};
    font-size: 14px;
  }
  .dummy-img {
    padding: 18px 0 30px 0;
  }
  .customer-btn {
    padding: 10px 40px;
    line-height: 0;
  }
`;

export default WrapPaymentTerms;
