import React from 'react'
import { Tabs, TabPaneProps as AntTabPanelProps } from 'antd'

const { TabPane: AntTabPane } = Tabs

export interface TabPaneProps extends AntTabPanelProps {}

const TabPane: React.FC<TabPaneProps> = (props) => {
  return (
    <AntTabPane className='ddl-tabs-pane' { ...props } />
  )
}

export default TabPane




