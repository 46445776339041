import React from 'react'
import styled from 'styled-components'

const ManageCouriersContainer = styled.div`
  width: 100%;
`

const ManageCouriers: React.FC = ({ children }) => {
  return <ManageCouriersContainer>{ children }</ManageCouriersContainer>
}

export default ManageCouriers
