import { BaseAPI } from 'core/services/BaseAPI';

export interface IMarketingService {}

class MarketingService extends BaseAPI implements IMarketingService {
  async getAllNews() {
    return await this.get("marketing/news");
  }

  async getActiveNews() {
    return await this.get("marketing/news/active");
  }

  async getExpiredNews() {
    return await this.get("marketing/news/expired");
  }

  async postNews(body: any) {
    return await this.post("marketing/news", body);
  }

  async deleteCoupons(id: any) {
    return await this.delete(`marketing/news/${id}`);
  }

  async editCoupons(id: number, body: any) {
    return await this.patch(`marketing/news/${id}`, body);
  }

  async detailNews(id: number) {
    return await this.get(`marketing/news/${id}/details`);
  }
}

export default new MarketingService();