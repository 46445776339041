import styled from "styled-components";
import { getCss } from "utils/cssHelpers";

export const ProcessingOrdersWrapper: any = styled.div`
  position: relative;

  .ant-tabs {
    .ant-tabs-nav {
      margin-bottom: 13px;
    }
    .ant-tabs-tab {
      width: 140px;
      padding: 2px;
      margin: 0 10px 0 0 !important;
      ${getCss("background")("theme.global.primaryColor")};
      justify-content: center;
      &.ant-tabs-tab-active {
        ${getCss("background")("theme.global.orangeColor")};
        .ant-tabs-tab-btn {
          font-weight: normal;
          ${getCss("color")("theme.global.whiteColor")};
        }
      }
      .ant-tabs-tab-btn {
        font-weight: normal;
        ${getCss("color")("theme.global.whiteColor")};
      }
    }
    .ant-tabs-ink-bar {
      display: none;
    }
  }
  .group-title-orders {
    padding: 7px 14px;
    ${getCss("background")("theme.global.tertiaryColor")};
    margin-bottom: 10px;
    label {
      ${getCss("color")("theme.global.color444444")};
    }
  }
  .type-company {
    font-size: 14px;
    font-weight: 600;
    ${getCss("color")("theme.global.primaryColor")};
    margin-left: 10px;
  }
  .title-details-receipt {
    font-size: 18px;
  }
  .ant-btn[disabled] {
    background-color: #cfcfcf;
    color: white;
  }
`;

ProcessingOrdersWrapper.Packing = styled.div`
  .ant-table {
    margin-bottom: 10px;
    margin-top: 20px;
    table {
      .ant-table-tbody tr {
        ${getCss("background")("theme.table2.bodyBgColor")};
      }
      .ant-table-placeholder {
        display: none;
      }
    }
  }
  .btn-view-processing-orders {
    width: 220px;
    height: 50px;
    display: block;
    margin-left: auto;
  }
  .title-processing {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.2;
    margin-top: 13px;
  }
  .btn-relate {
    width: 140px;
    padding: 2px;
    margin-right: 10px;
  }
  .courier-assign {
    position: absolute;
    right: 10px;
    top: 30px;
    z-index: 1;
    font-size: 12px;
    color: white;
    cursor: pointer;
    text-decoration: underline;
  }
  .region-table {
    position: relative;
  }
`;

ProcessingOrdersWrapper.CloudKitchen = styled.div`
  .table-complete-cloud-kitchen {
    margin-bottom: 20px;
    table tr th:first-child,
    table tr td:first-child {
      text-align: center;
    }
  }
  .btn-complete-cloud-kitchen {
    width: 220px;
    height: 50px;
    font-size: 20px;
    font-weight: 500;
    display: block;
    margin-left: auto;
  }
`;

ProcessingOrdersWrapper.PurchaseOrder = styled.div`
  .ant-table {
    table {
      .ant-table-tbody tr {
        ${getCss("background")("theme.table2.bodyBgColor")};
        cursor: pointer;
      }
    }
  }
  .general-info {
    ${getCss("background")("theme.global.tertiaryColor")};
    width: 50%;
    .title-general-info {
      background: #f0f2f5;
      font-size: 14px;
      font-weight: 500;
      padding-bottom: 6px;
    }
  }
  .text-underline {
    text-decoration: underline;
  }
  .label-complete-order {
    width: 180px;
    height: 39px;
    display: block;
    margin-left: auto;
    font-size: 17px;
    color: white;
    text-align: center;
    line-height: 39px;
  }
`;

ProcessingOrdersWrapper.DetailsRegion = styled.div`
  .header-details-receipt {
    ${getCss("background")("theme.global.tertiaryColor")};
    padding: 16px 14px;
  }
  .ant-tag {
    font-size: 6px;
    width: 95px;
    height: 20px;
    padding: 6px 16px;
    min-height: auto;
    margin-left: 34px;
  }
`;

ProcessingOrdersWrapper.CourierAssign = styled.div`
  .title-courier-assign {
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    color: #767676;
    padding: 30px 0 30px 0;
  }
  .find-couriers {
    margin-left: 20px;
    .title-find-couriers {
      font-size: 16px;
      font-weight: 600;
      color: #767676;
    }
    .search-couriers {
      margin: 15px 0 15px 0;
      width: 300px;
    }
    .ant-table-tbody > tr > td {
      background: #f8ffa0 !important;
    }
  }
  .footer-courier-assign {
    text-align: center;
    margin-top: 32px;
  }

  .ant-table {
    table {
      .ant-table-thead {
        font-size: 14px;
      }
      .ant-table-tbody > tr {
        td {
          font-size: 14px;
          font-weight: 500;
        }
      }
      .row-active {
        background-color: rgba(252, 96, 17, 0.5);
      }

      .row-not-active {
        ${getCss("background")("theme.table2.bodyBgColor")};
      }
    }
  }
  .order-deselect {
    font-size: 10px;
    color: #fc6016;
    text-decoration: underline;
    cursor: pointer;
    margin-left: 5px;
  }
`;

ProcessingOrdersWrapper.ExportButton = styled.p`
  color: #1cad48;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;

  display: flex;
  align-items: flex-end;

  position: absolute;
  z-index: 2;

  top: 5px;
  right: 24px;
  cursor: pointer;

  img {
    margin-left: 5px;
  }
`;
