import React from 'react'
import styled from 'styled-components'

const FinanceAndPaymentContainer = styled.div`
  width: 100%;
`

const FinanceAndPayment: React.FC = ({ children }) => {
  return <FinanceAndPaymentContainer>{children}</FinanceAndPaymentContainer>
}

export default FinanceAndPayment
