import React from "react";
import { Form } from "antd";
import { Toastify } from "components/shared/Toast/Toast";
import FormFoodType from "./FormFoodType";
import menuService from 'services/menu.service';

export interface IPropsAddFoodType {
  listFoodType: any
}

const AddFoodType: React.FC<IPropsAddFoodType> = ({
  listFoodType
}) => {
  const [form] = Form.useForm();

  const addFoodType = (body: any) => {
    menuService
    .postNewFoods(body)
    .then(() => {
      Toastify.showSuccessToast("Create success!");
      form.resetFields();
      listFoodType();
    })
    .catch((err) =>
      Toastify.showDangerToast(err?.response?.data?.messages?.[0])
    );
  }

  return (
  <Form
    name="basic"
    form={form}
  >
    <FormFoodType
      isCheck={true}
      submitForm={addFoodType}
      form={form}
    />
  </Form>
  )
}

export default AddFoodType;
