import React from "react";
import Input from "components/shared/Input";
import Select from "components/shared/Select";
import Flex, { FlexItem } from "components/shared/Flex";
import {Form} from "antd";
import WrapCouriersForm from "./styled";
import { AddOutlineIcon, CloseSharpIcon } from 'components/shared/Icons';
import generate from 'generate-password';
import Theme from "theme";

interface IPopsAddCouriers {
  dropRegion: any[]
  dataForm: any
  onChangeRegion(e: any, index: number): any
  addMoreRegion() : any,
  removeRegion(index: number): any
  form: any;
}

const AddCouriersAccount: React.FC<IPopsAddCouriers> = ({
  dropRegion,
  dataForm,
  onChangeRegion,
  addMoreRegion,
  removeRegion,
  form
}) => {
  const _handleGeneratePassword = () => {
    const password = generate.generate({
      length: 10,
      numbers: true
    });

    form.setFields([
      {
        name: ["password"],
        value: password,
      },
      {
        name: ["rePassword"],
        value: password,
      },
    ]);
  };

  return (
    <>
      <div className="wrapper-title-add">Courier Details</div>
      <Flex row>
        <FlexItem basis='33.3%'>
          <Form.Item
            css={`margin-bottom: 0`}
            name="name"
            rules={[{required: true, message: 'Please fill your Courier Name'}]}>
            <Input placeholder="Courier Name"/>
          </Form.Item>
        </FlexItem>
        <FlexItem css={`width: calc(33.333333% - 8px); margin: 0 10px;`}>
          <Form.Item
            css={`margin-bottom: 0`}
            name="phoneNumber"
            rules={[{
              required: true,
              message: 'Please fill your Phone Number'
            }]}>
            <Input placeholder="Phone Number"/>
          </Form.Item>
        </FlexItem>
        <FlexItem basis='33.3%'>
          <Form.Item
            css={`margin-bottom: 0`}
            name="email"
            rules={[{required: true, message: 'Please fill your Email Address'}]}>
            <Input placeholder="Email Address"/>
          </Form.Item>
        </FlexItem>
      </Flex>
      <Flex>
        <FlexItem className="wrapper-account-infomation" css={`width: calc(33.333333% - 8px)`}>
          <div className="wrapper-title-add">Account Information</div>
          <Form.Item
            css={`margin-bottom: 0`}
            name="courierId"
            rules={[{required: true, message: 'Please fill your Courier ID'}]}>
            <Input
              placeholder="Courier ID"
              notes="*This ID is used for courier to log in"
            />
          </Form.Item>
        </FlexItem>
        <FlexItem basis='66.6%' css={`margin: 0 10px;`}>
        <div className="wrapper-title-add">Assign Courier To Region</div>
        {dataForm?.regionIds?.map((item: any, index: number) => (
          <Flex alignCenter css={`margin-bottom: 20px`} key={index}>
            <FlexItem basis='50%'>
              <Select
                placeholder="Region"
                value={item.name || null}
                css={`margin-top: 0`}
                options={dropRegion}
                onChange={(e) => onChangeRegion(e, index)}
              />
            </FlexItem>
            {dataForm.regionIds.length - 1 === index
              ?  <FlexItem
                    className="wrapper-add-region"
                    onClick={addMoreRegion}
                  >
                    <Flex>
                      {dropRegion?.length === dataForm?.regionIds?.length
                        ? ""
                        : <><AddOutlineIcon width="19px" height="19px"/>Add more assigned region</>
                      }
                    </Flex>
                  </FlexItem>
                :  <FlexItem onClick={() => removeRegion(index)}>
                    {index >= 0
                      ? <Flex
                          style={{ color: `${Theme.global.dangerColor}`, cursor: "pointer", marginLeft: "20px" }}
                          alignCenter
                        >
                          <CloseSharpIcon /> <div>Delete</div>
                        </Flex>
                      : ""
                      }
                </FlexItem>
              }
            </Flex>
          ))}
      </FlexItem>
      </Flex>
      <Flex className="wrapper-password">
        <FlexItem basis='33.3%'>
          <Form.Item
            name="password"
            css={`margin-bottom: 0; margin-right: 10px`}
            rules={[{required: true, message: 'Please fill Your Password'}]}
          >
            <Input
              placeholder="Enter Password"
              isVisible={true}
            />
          </Form.Item>
        </FlexItem>
        <FlexItem basis='33.3%'>
          <Form.Item
            name="rePassword"
            css={`margin-bottom: 0`}
            rules={[
              {
                required: true,
                message: "Please input your Re Enter Password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      "The two passwords that you entered do not match!"
                    )
                  );
                },
              }),
            ]}
            >
            <Input
              placeholder="Re Enter Password"
              isVisible={true}
            />
          </Form.Item>
        </FlexItem>
      </Flex>
      <WrapCouriersForm.Generate className="btn-generate-password" onClick={_handleGeneratePassword}>
        Generate Password
      </WrapCouriersForm.Generate>
    </>
  );
}

export default AddCouriersAccount;
