import React, { useEffect, useState } from 'react';
import WrapCouriersForm from './styled';
import Button from 'components/shared/Button';
import AddCouriersAccount from './AddCouriersAccount';
import EditCouriersAccount from './EditCourierAccount';
import commonServices from 'services/common.service';
import GroupTitle from "components/shared/GroupTitle";
import { ArrowBackOutlineIcon } from 'components/shared/Icons';
import { useHistory } from "react-router";
export interface CouriersFormProps {
  isCheck: boolean;
  submitCouriers(body: any): any;
  form: any;
  filedRegion?: any;
  setFiledRegion?: any;
}

type IPropRegion = {
  id: number,
  name: string
}

const CouriersForm: React.FC<CouriersFormProps> = ({
  isCheck,
  submitCouriers,
  form,
  filedRegion,
  setFiledRegion
}) => {
  const history: any = useHistory();
  const [region, setRegion] = useState<Array<any>>([]);
  const [dataForm, setDataForm] = useState<any>({regionIds: [{}]});

  useEffect(() => {
    commonServices.getRegions().then((region) => {
      const resultRegion = region?.map((item: IPropRegion) => {
        return {
          id: item.id,
          label: item.name,
          value: item.name,
        };
      })
      setRegion(resultRegion);
    });
  }, []);

  const _submitCouriers = async () => {
    try {
      const values = await form.validateFields();
      delete values.rePassword;
      const regionIdsAdd = dataForm?.regionIds?.map((item: any) => {return item.id});
      const regionIdsEdit = filedRegion?.map((item: any) => {return item.id});
      const regionIds = isCheck ? regionIdsAdd : regionIdsEdit;
      const body = {
        name: values.name,
        email: values.email,
        phoneNumber: values.phoneNumber,
        courierId: values.courierId,
        regionIds: regionIds,
        password: values.password,
      }
      submitCouriers(body);
    } catch (error) {
      console.log("Failed:", error);
    }
  }

  const onChangeRegion = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    let fieldTypeIds: any = isCheck ? dataForm.regionIds : filedRegion;
    const get = region.find((item: any) => item.value === e);
    if (get) {
      if (isCheck) {
        fieldTypeIds[index] = {
          id: get.id,
          name: get.value
        };
        setDataForm({
          ...dataForm,
          ...{regionIds: fieldTypeIds}
        });
      } else {
        fieldTypeIds[index] = {
          id: get.id,
          name: get.value,
        };
        setFiledRegion(
          [...filedRegion],
        )
      }
    }
  }

  const addMoreRegion = () => {
    if (isCheck) {
      setDataForm({
        regionIds: [...dataForm.regionIds, {}],
      });
    } else {
      setFiledRegion(
        [...filedRegion, {}],
      );
    }
  }

  const removeRegion = (index: number) => {
    if (isCheck) {
      dataForm.regionIds.splice(index, 1);
      setDataForm({
        regionIds: [...dataForm.regionIds],
      });
    } else {
      filedRegion.splice(index, 1);
      setFiledRegion([...filedRegion]);
    }
  }

  function onKeypress(e: any) {
    if (e.key === "Enter") {
      _submitCouriers();
    }
  }

  useEffect(() => {
    window.addEventListener("keypress", onKeypress);
    return () => {
      window.removeEventListener("keypress", onKeypress);
    };
  });

  return (
    <WrapCouriersForm>
      {isCheck
        ? (
            <GroupTitle
              fontSize={18}
              icon={<ArrowBackOutlineIcon color="#fff"
              onClick={history.goBack}/>}
              padding={10}
              className='title-create-account'
            >
              Create A Courier Account
            </GroupTitle>
          )
        : (
            <GroupTitle
              fontSize={16}
              icon={<ArrowBackOutlineIcon color="#fff"
              onClick={history.goBack}/>}
              padding={10}
              className='title-create-account'
            >
              <Button
                type="primary"
                htmlType="submit"
                onClick={_submitCouriers}
                className="customer-btn-save"
              >
                Save
              </Button>
            </GroupTitle>
          )
      }
      {isCheck
        ? <AddCouriersAccount
            dropRegion={region}
            dataForm={dataForm}
            onChangeRegion={onChangeRegion}
            addMoreRegion={addMoreRegion}
            removeRegion={removeRegion}
            form={form}
          />
        : <EditCouriersAccount
            isCheck={isCheck}
            dropRegion={region}
            filedRegion={filedRegion}
            addMoreRegion={addMoreRegion}
            removeRegion={removeRegion}
            onChangeRegion={onChangeRegion}
          />
      }
      {isCheck
        ? <Button
            type="info"
            htmlType="submit"
            onClick={_submitCouriers}
          >
            Create Account
          </Button>
        : ""
      }
    </WrapCouriersForm>
  )
}

export default CouriersForm;
