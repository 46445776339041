import { AdminLayout } from "layouts";
import { IRoute } from "./renderRoutes";
import AuthWrapper from "wrappers/AuthWrapper";
import { SettingIcon } from "components/shared/Icons";

/** =========== Login ========== */
import Login from "pages/login";
import ForgetPassword from "pages/forget-password";
import ResetPasswordSuccess from "pages/reset-password-success";

/** =========== Dashboard ========== */
import Dashboard from "pages/dashboard";

/** =========== Reports ========== */
import Reports from "pages/reports";
import Customers from "pages/reports/customers";

/** =========== Settings ========== */
import Settings from "pages/settings";

/** =========== Manage Orders ========== */
import ManageOrders from "pages/manage-orders";
import NewOrders from "pages/manage-orders/new-orders";
import ProcessingOrders from "pages/manage-orders/processing-orders";
import CompletedOrders from "pages/manage-orders/completed-orders";

/** =========== Manage Cloud Kitchen ========== */
import ManageCloudKitchen from "pages/manage-cloud-kitchen";
import AddNewCloudKitchen from "pages/manage-cloud-kitchen/add-new";
import AllVendors from "pages/manage-cloud-kitchen/all-vendors";
import DetailsKitchen from "pages/manage-cloud-kitchen/details-kitchen";
import EditVendor from "pages/manage-cloud-kitchen/edit-vendor";

/** =========== Manage Couriers ========== */
import ManageCouriers from "pages/manage-couriers";
import AddNewCourier from "pages/manage-couriers/add-new";
import EditCouriers from "pages/manage-couriers/edit-couriers";
import AllCouriers from "pages/manage-couriers/all-couriers";
import DetailsCourier from "pages/manage-couriers/details-couriers";

/** =========== Manage Customers ========== */
import ManageCustomers from "pages/manage-customers";
import AddNewCustomer from "pages/manage-customers/add-new";
import EditCustomers from "pages/manage-customers/edit-customers";
import AllCustomers from "pages/manage-customers/all-customers";
import DetailsCustomer from "pages/manage-customers/details-customer";
import DetailsEmployees from "pages/manage-customers/details-employees";
import DetailCustomerUnpaidInvoices from "pages/manage-customers/details-customer/DetailCustomerUnpaidInvoices";

/** =========== Manage Menu ========== */
import ManageMenu from "pages/manage-menu";
import MyMenu from "pages/manage-menu/my-menu";
import WeeklyMenu from "pages/manage-menu/weekly-menu";
import WeeklyMenuDetails from "pages/manage-menu/weekly-menu-details";
import WeeklyMenuEdit from "pages/manage-menu/weekly-menu-edit";
import CountryMenu from "pages/manage-menu/my-menu/my-menu-details";
import AddFood from "pages/manage-menu/my-menu/all-foods/AddFoods";
import EditFood from "pages/manage-menu/my-menu/all-foods/EditFoods";
import ManageTypeFood from "pages/manage-menu/my-menu/manage-food-type";
import NewsAddOns from "pages/manage-menu/my-menu/all-add-ons/AddOns";
import EditAddOns from "pages/manage-menu/my-menu/all-add-ons/EditOns";

/** =========== Marketing ========== */
import Marketing from "pages/marketing";
import Coupons from "pages/marketing/coupons";
import Overview from "pages/marketing/overview";
import Promotions from "pages/marketing/promotions";

/** =========== Finance & Payment ========== */
import FinanceAndPayment from "pages/finance-and-payment";
import OverviewFinanceAndPayment from "pages/finance-and-payment/overview";
import CustomersFinanceAndPayment from "pages/finance-and-payment/customers";

import PaymentSetupFrom from "pages/finance-and-payment/overview/PaymentSetupFrom";
import PaymentsByCustomerDetails from "pages/finance-and-payment/customers/PaymentsByCustomeDetails";
import PaymentsByCustomerDetailsINV from "pages/finance-and-payment/customers/PaymentsByCustomeDetailsINV";
import PaymentsByCustomerDetailsReceipt from "pages/finance-and-payment/customers/PaymentsByCustomeDetailsReceipt";
import PaymentsByCustomeDetailsINVSubmit from "pages/finance-and-payment/customers/PaymentsByCustomeDetailsINVSubmit";

import CloudKitchenVendors from "pages/finance-and-payment/cloud-kitchen-vendors";
import CloudKitchenVendorDetails from "pages/finance-and-payment/cloud-kitchen-vendors/CloudKitchenVendorsDetails";
import CloudKitchenVendorDetailsINV from "pages/finance-and-payment/cloud-kitchen-vendors/CloudKitchenVendorsDetailsINV";

/** =========== Information Centre ========== */
import InformationCentre from "pages/information-centre";
import MyFeedback from "pages/information-centre/my-feedback";
// import SendFeedback from "pages/information-centre/send-feedback";
import MyFeedbackParticulars from "pages/information-centre/my-feedback-particulars";

const routes: IRoute[] = [
  /** =========== Login ========== */
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/forget-password",
    component: ForgetPassword,
  },
  {
    path: "/reset-password-success",
    component: ResetPasswordSuccess,
  },
  /** =========== Home ========== */
  {
    path: "/",
    component: AdminLayout,
    wrappers: [AuthWrapper],
    routes: [
      {
        path: "/",
        exact: true,
        meta: { hidden: true },
        redirect: "/dashboard",
        roles: ["admin"],
      },
      /** =========== Dashboard ========== */
      {
        key: "dashboard",
        path: "/dashboard",
        component: Dashboard,
        meta: { title: "Dashboard" },
        roles: ["admin"],
        // roles: ["admin", "corporate"],
      },
      /** =========== Manage Orders ========== */
      {
        key: "manage-orders",
        path: "/manage-orders",
        meta: { title: "Manage Orders" },
        component: ManageOrders,
        routes: [
          {
            path: "/manage-orders",
            exact: true,
            meta: { hidden: true },
            redirect: "/manage-orders/new",
            roles: ["admin", "cloud-kitchen"],
          },
          {
            key: "manage-orders/new",
            path: "/manage-orders/new",
            meta: { title: "New" },
            component: NewOrders,
            roles: ["admin", "cloud-kitchen"],
          },
          {
            key: "manage-orders/processing",
            path: "/manage-orders/processing",
            meta: { title: "Processing" },
            component: ProcessingOrders,
            roles: ["admin", "cloud-kitchen"],
          },
          {
            key: "manage-orders/completed",
            path: "/manage-orders/completed",
            meta: { title: "Completed" },
            component: CompletedOrders,
            roles: ["admin"],
          },
        ],
        roles: ["admin", "cloud-kitchen"],
      },
      /** =========== Manage Cloud Kitchen ========== */
      {
        key: "manage-cloud-kitchen",
        path: "/manage-cloud-kitchen",
        component: ManageCloudKitchen,
        meta: { title: "Manage Cloud Kitchen" },
        routes: [
          {
            path: "/manage-cloud-kitchen",
            exact: true,
            meta: { hidden: true },
            redirect: "/manage-cloud-kitchen/all-cloud-kitchens",
            roles: ["admin"],
          },
          {
            key: "manage-cloud-kitchen/all-cloud-kitchens",
            path: "/manage-cloud-kitchen/all-cloud-kitchens",
            meta: { title: "All Cloud Kitchen" },
            component: AllVendors,
            roles: ["admin"],
          },
          {
            key: "manage-cloud-kitchen/details-kitchen",
            path: "/manage-cloud-kitchen/details-kitchen/:id",
            meta: { hidden: true, title: "All Kitchen" },
            component: DetailsKitchen,
            roles: ["admin"],
          },
          {
            key: "manage-cloud-kitchen/new",
            path: "/manage-cloud-kitchen/new",
            meta: { title: "Add New" },
            component: AddNewCloudKitchen,
            roles: ["admin"],
          },
          {
            key: "manage-cloud-kitchen/edit",
            path: "/manage-cloud-kitchen/edit/:id",
            meta: { hidden: true, title: "Edit Vendor" },
            component: EditVendor,
            roles: ["admin"],
          },
        ],
        roles: ["admin"],
      },
      /** =========== Manage Couriers ========== */
      {
        key: "manage-couriers",
        path: "/manage-couriers",
        component: ManageCouriers,
        meta: { title: "Manage Couriers" },
        routes: [
          {
            path: "/manage-couriers",
            exact: true,
            meta: { hidden: true },
            redirect: "/manage-couriers/all-couriers",
            roles: ["admin"],
          },
          {
            key: "manage-couriers/all-couriers",
            path: "/manage-couriers/all-couriers",
            meta: { title: "All Couriers" },
            component: AllCouriers,
            roles: ["admin"],
          },
          {
            key: "manage-couriers/courier-details",
            path: "/manage-couriers/courier-details/:id",
            meta: { title: "Couriers Details", hidden: true },
            component: DetailsCourier,
            roles: ["admin"],
          },
          {
            key: "manage-couriers/new",
            path: "/manage-couriers/new",
            meta: { title: "Add New" },
            component: AddNewCourier,
            roles: ["admin"],
          },
          {
            key: "manage-couriers/edit",
            path: "/manage-couriers/edit/:id",
            meta: { title: "Edit Courier", hidden: true },
            component: EditCouriers,
            roles: ["admin"],
          },
        ],
        roles: ["admin"],
      },
      /** =========== Manage Customers ========== */
      {
        key: "manage-customers",
        path: "/manage-customers",
        component: ManageCustomers,
        meta: { title: "Manage Customers" },
        routes: [
          {
            path: "/manage-customers",
            exact: true,
            meta: { hidden: true },
            redirect: "/manage-customers/all-customers",
            roles: ["admin"],
          },
          {
            key: "manage-customers/all-customers",
            path: "/manage-customers/all-customers",
            meta: { title: "All Customers" },
            component: AllCustomers,
            roles: ["admin"],
          },
          {
            key: "manage-customers/customer-details",
            path: "/manage-customers/customer-details/:id",
            meta: { title: "Customer Details" },
            component: DetailsCustomer,
            roles: ["admin", "corporate"],
          },
          {
            key: "manage-customers/employees-details",
            path: "/manage-customers/employees-details/:id",
            meta: { title: "Employees Details", hidden: true },
            component: DetailsEmployees,
            roles: ["admin"],
          },
          {
            key: "manage-customers/customer-details-unpaid-invoices",
            path: "/manage-customers/customer-details-unpaid-invoices/:id",
            meta: { title: "Customer Details Unpaid Invoices", hidden: true },
            component: DetailCustomerUnpaidInvoices,
            roles: ["admin"],
          },
          {
            key: "manage-customers/new",
            path: "/manage-customers/new",
            meta: { title: "Add New" },
            component: AddNewCustomer,
            roles: ["admin"],
          },
          {
            key: "manage-customers/edit",
            path: "/manage-customers/edit/:id",
            meta: { title: "Edit Customers", hidden: true },
            component: EditCustomers,
            roles: ["admin", "corporate"],
          },
        ],
        roles: ["admin", "corporate"],
      },
      /** =========== Manage Menu ========== */
      {
        key: "manage-menu",
        path: "/manage-menu",
        component: ManageMenu,
        meta: { title: "Manage Menu" },
        routes: [
          {
            path: "/manage-menu",
            exact: true,
            meta: { hidden: true },
            redirect: "/manage-menu/my-menu",
            roles: ["admin"],
          },
          {
            key: "manage-menu/my-menu",
            path: "/manage-menu/my-menu",
            meta: { title: "My Menu" },
            component: MyMenu,
            roles: ["admin"],
          },
          {
            key: "manage-menu/menu-details",
            path: "/manage-menu/menu-details/:id",
            meta: { title: "My Menu Details", hidden: true },
            component: CountryMenu,
            roles: ["admin"],
          },
          {
            key: "manage-menu/all-foods/add-food",
            path: "/manage-menu/all-foods/add-food",
            meta: { title: "Add Food", hidden: true },
            component: AddFood,
            roles: ["admin"],
          },
          {
            key: "manage-menu/all-foods/edit-food",
            path: "/manage-menu/all-foods/edit-food/:id",
            meta: { title: "Edit Food", hidden: true },
            component: EditFood,
            roles: ["admin"],
          },
          {
            key: "manage-menu/manage-food-type",
            path: "/manage-menu/manage-food-type",
            meta: { title: "Manage Type Food", hidden: true },
            component: ManageTypeFood,
            roles: ["admin"],
          },
          {
            key: "manage-menu/all-add-ons/add-ons",
            path: "/manage-menu/all-add-ons/add-ons",
            meta: { title: "Create News Add Food", hidden: true },
            component: NewsAddOns,
            roles: ["admin"],
          },
          {
            key: "manage-menu/all-add-ons/edit-ons",
            path: "/manage-menu/all-add-ons/edit-ons/:id",
            meta: { title: "Edit Add Food", hidden: true },
            component: EditAddOns,
            roles: ["admin"],
          },
          {
            key: "manage-menu/weekly-menu",
            path: "/manage-menu/weekly-menu",
            meta: { title: "Weekly Menu" },
            component: WeeklyMenu,
            roles: ["admin"],
          },
          {
            key: "manage-menu/weekly-details",
            path: "/manage-menu/weekly-details/:id",
            meta: { title: "Weekly Menu Details", hidden: true },
            component: WeeklyMenuDetails,
            roles: ["admin"],
          },
          {
            key: "manage-menu/weekly-edit",
            path: "/manage-menu/weekly-edit/:id",
            meta: { title: "Weekly Menu Edit", hidden: true },
            component: WeeklyMenuEdit,
            roles: ["admin"],
          },
        ],
        roles: ["admin"],
      },
      /** =========== Marketing ========== */
      {
        key: "marketing",
        path: "/marketing",
        component: Marketing,
        meta: { title: "Marketing" },
        routes: [
          {
            path: "/marketing",
            exact: true,
            redirect: "/marketing/overview",
            meta: { hidden: true },
            roles: ["admin"],
          },
          {
            key: "marketing/overview",
            path: "/marketing/overview",
            meta: { title: "Overview" },
            component: Overview,
            roles: ["admin"],
          },
          {
            key: "marketing/coupons",
            path: "/marketing/coupons/:id",
            meta: { title: "Coupons", hidden: true },
            component: Coupons,
            roles: ["admin"],
          },
          {
            key: "marketing/promotions",
            path: "/marketing/promotions",
            meta: { title: "Promotions" },
            component: Promotions,
            roles: ["admin"],
          },
        ],
        roles: ["admin"],
      },
      /** =========== Finance & Payment ========== */
      {
        key: "finance-payment",
        path: "/finance-payment",
        component: FinanceAndPayment,
        meta: { title: "Finance & Payment" },
        routes: [
          {
            path: "/finance-payment",
            exact: true,
            meta: { hidden: true },
            redirect: "/finance-payment/overview",
            roles: ["admin"],
          },
          {
            key: "finance-payment/overview",
            path: "/finance-payment/overview",
            meta: { title: "Overview" },
            component: OverviewFinanceAndPayment,
            roles: ["admin"],
          },
          {
            key: "finance-payment/payment-setup-from",
            path: "/finance-payment/payment-setup-from/:id",
            meta: { title: "Payment Setup From", hidden: true },
            component: PaymentSetupFrom,
            roles: ["admin"],
          },
          {
            key: "finance-payment/customers",
            path: "/finance-payment/customers",
            meta: { title: "Customers" },
            component: CustomersFinanceAndPayment,
            roles: ["admin"],
          },
          {
            key: "finance-payment/payments-by-customers-details/customer",
            path: "/finance-payment/payments-by-customers-details/:customerId",
            meta: { title: "Customers Details", hidden: true },
            component: PaymentsByCustomerDetails,
            roles: ["admin"],
          },
          {
            key: "finance-payment/payments-by-customers-details-receipt",
            path: "/finance-payment/payments-by-customers-details-inv/:customerId/:invId/receipt/:receiptId",
            meta: { title: "Customers Details Receipt", hidden: true },
            component: PaymentsByCustomerDetailsReceipt,
            roles: ["admin", "corporate"],
          },
          {
            key: "finance-payment/payments-by-customers-details-inv/subtmit",
            path: "/finance-payment/payments-by-customers-details-inv/:customerId/:invId/submit",
            meta: { title: "Customers Details INV No", hidden: true },
            component: PaymentsByCustomeDetailsINVSubmit,
            roles: ["admin", "corporate"],
          },
          {
            key: "finance-payment/payments-by-customers-details-inv",
            path: "/finance-payment/payments-by-customers-details-inv/:customerId/:invId",
            meta: { title: "Customers Details INV", hidden: true },
            component: PaymentsByCustomerDetailsINV,
            roles: ["admin", "corporate"],
          },
          {
            key: "finance-payment/cloud-kitchen-vendors",
            path: "/finance-payment/cloud-kitchen-vendors",
            meta: { title: "Cloud Kitchen Vendors" },
            component: CloudKitchenVendors,
            roles: ["admin"],
          },
          {
            key: "finance-payment/cloud-kitchen-vendors-details",
            path: "/finance-payment/cloud-kitchen-vendors-details/:idKitchen",
            meta: { title: "Cloud Kitchen Vendors Details", hidden: true },
            component: CloudKitchenVendorDetails,
            roles: ["admin"],
          },
          {
            key: "finance-payment/cloud-kitchen-vendors-details-inv",
            path: "/finance-payment/cloud-kitchen-vendors-details-inv/:idKitchen/:invId",
            meta: { title: "Cloud Kitchen Vendors Details INV", hidden: true },
            component: CloudKitchenVendorDetailsINV,
            roles: ["admin"],
          },
        ],
        roles: ["admin", "corporate"],
      },
      /** =========== Information Centre ========== */
      {
        key: "information-centre",
        path: "/information-centre",
        component: InformationCentre,
        meta: { title: "Information Centre" },
        routes: [
          {
            path: "/information-centre",
            exact: true,
            meta: { hidden: true },
            redirect: "/information-centre/my-feedback",
            roles: ["admin"],
          },
          {
            key: "information-centre/my-feedback",
            path: "/information-centre/my-feedback",
            meta: { title: "My Feedback" },
            component: MyFeedback,
            roles: ["admin"],
          },
          // {
          //   key: "information-centre/send-feedback",
          //   path: "/information-centre/send-feedback",
          //   meta: { title: "Send Feedback" },
          //   component: SendFeedback,
          //   roles: ["admin"],
          // },
          {
            key: "information-centre/my-feedback-particulars",
            path: "/information-centre/my-feedback-particulars/:id",
            meta: { title: "My Feedback Particulars", hidden: true },
            component: MyFeedbackParticulars,
            roles: ["admin"],
          },
        ],
        roles: ["admin"],
      },
      /** =========== Reports ========== */
      {
        key: "reports/orders",
        path: "/reports/orders",
        component: Reports,
        meta: { title: "Reports" },
        roles: ["admin"],
      },
      {
        key: "reports/customers",
        path: "/reports/customers",
        component: Customers,
        meta: { title: "Customers", hidden: true },
        roles: ["admin"],
      },
      /** =========== Settings ========== */
      {
        key: "settings",
        path: "/settings",
        meta: { title: "Settings", icon: <SettingIcon /> },
        component: Settings,
        roles: ["admin"],
        // roles: ["admin", "corporate"],
      },
    ],
  },
];

export default routes;
