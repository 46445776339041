import styled from 'styled-components'
import { getCss } from 'utils/cssHelpers'

export const WeeklyMenuEdit: any = styled.div`
    .customer-title {
        padding-bottom: 39px;
    }
    .ant-table {
        margin-bottom: 20px;
            .ant-table-thead {
                .ant-checkbox-wrapper {
                    display: none;
                }
            }
            .ant-table-tbody {
                .ant-table-cell:first-child {
                   background: unset !important;
                }
                tr td.ant-table-cell:nth-child(2) {
                    padding-right: 20px;
                    span {
                        font-size: 20px;
                        font-weight: 500;
                        ${getCss('color')('theme.global.primaryColor')};
                    }
                }
                .ant-table-cell:nth-child(7) {
                    span {
                      display: block;
                      width: 0;
                      margin-bottom: 3px;
                    }
                }
            }
    
            .ant-radio-inner {
                ${getCss('border-color')('theme.global.primaryColor')};
                ${getCss('background')('theme.global.whiteColor')};
                width: 35px;
                height: 35px;
                border-radius: 3px;
                box-shadow: 0 3px 6px 0 rgb(0 0 0 / 16%);
                border-style: solid;
                border-width: 3px;
                border-radius: 50%;
            }
            .ant-radio-checked {
                .ant-radio-inner {
                    &:after {
                        width: 20px;
                        height: 20px;
                        border-radius: 50%;
                        position: absolute;
                        top: 5px;
                        left: 5px;
                        ${getCss('background')('theme.global.color4f4f4f')};
                    }
                }
            }
            .ant-checkbox-wrapper {
                .ant-checkbox-checked {
                    .ant-checkbox-inner:after {
                        border: 6px solid ${props => props.theme.global.color4f4f4f};
                        border-top: 0;
                        border-left: 0;
                    }
                }
                .ant-checkbox-inner:after {
                    width: 17px;
                    height: 25px;
                    top: 55%;
                    left: 15%;
                }
            }
            tr {
                td:first-child {
                    width: 62px;
                }
                td.ant-table-cell:first-child {
                    text-align: center;
                }
                td:nth-child(4) {
                    width: 20%;
                }
            }
        }
        .ant-tag {
            width: 100px;
        }
    }
    .btn-edit-weekly-menu {
        font-size: 16px;
        font-weight: 500;
        border-radius: 20px;
        margin-bottom: 23px;
        position: absolute;
        top: 0;
        right: 0;
    }
    .wrap-submit {
        text-align: end;
        padding-bottom: 20px;
        .note {
            margin-bottom: 5px;
            ${getCss('color')('theme.global.blackColor')};
            font-size: 14px;
        }
        .customer-button {
            padding: 20px 80px;
            line-height: 0;
            border-radius: 50px;
            ${getCss('color')('theme.global.whiteColor')};
            font-size: 16px;
        }
    }
`

WeeklyMenuEdit.ImageFood = styled.div`
    width: 69px;
    height: 69px;
    border: solid 1px ${props => props.theme.global.color707070};
    .ant-image-img {
        width: 69px;
        height: 69px;
    }
`;

WeeklyMenuEdit.TitleWrap = styled.div`
    height: 60px;
    ${getCss('background')('theme.global.color4f4f4f')};
    ${getCss('color')('theme.global.whiteColor')};
    padding: 20px 0 21px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
`;

WeeklyMenuEdit.Actions = styled.div`
    padding: 15px;
    ${getCss('background')('theme.global.tertiaryColor')};
    .search-menu {
        margin: 0 auto 19px;
        display: flex;
        align-items: center;
        width: 363px;
        height: 36px;
        input {
            &::placeholder {
                font-size: 17px;
                line-height: 1.18;
            }
        }
        .ant-input-prefix {
            margin-top: 6px;
        }
    }
`;

WeeklyMenuEdit.RemoveCategory = styled.div`
    display: flex;
    justify-content: center;
    .btn-category {
        width: 160px;
        height: 36px;
        border-radius: 18px;
        span {
            font-size: 17px;
            font-weight: normal;
        }
    }
    .icon-remove-category, .icon-remove-type {
        margin-right: 17px;
        line-height: 0;
        height: max-content;
        vertical-align: bottom;
        svg {
            height: 37px;
            width: auto;
        }
    }
`;

WeeklyMenuEdit.ResetMenu = styled.div`
    font-size: 16px;
    font-weight: 600;
    text-decoration: underline;
    ${getCss('color')('theme.global.grayColor')};
    flex-shrink: 0;
    cursor: pointer;
`;