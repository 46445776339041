import React from "react";
import StyledVendorForm from "../styled";
import Input from "components/shared/Input";
import Select from "components/shared/Select";
import Textarea from "components/shared/Textarea";
import Flex, { FlexItem } from "components/shared/Flex";
import GroupTitle from "components/shared/GroupTitle";
import Form from "components/shared/Form";

interface AccountDetailsProps {
  title: string;
  region: any[];
}

const AccountDetails: React.FC<AccountDetailsProps> = ({ title, region }) => {
  return (
    <StyledVendorForm>
      <GroupTitle
        fontSize={18}
        style={{ paddingBottom: "12px", marginLeft: "0" }}
      >
        {title}
      </GroupTitle>

      <Flex>
        <FlexItem basis="33.3%">
          <Form.Item
            name="name"
            rules={[
              { required: true, message: "Please input your Company Name!" },
            ]}
          >
            <Input placeholder="Company Name" />
          </Form.Item>
        </FlexItem>
        <FlexItem style={{ padding: "0 10px" }} basis="25%">
          <Form.Item
            name="uen"
            rules={[
              { required: true, message: "Please input your UEN Number!" },
            ]}
          >
            <Input placeholder="UEN Number" />
          </Form.Item>
        </FlexItem>
        <FlexItem basis="33.3%">
          <Form.Item
            name="email"
            rules={[
              {
                type: "email",
                required: true,
                message: "Please input your Company Email Address!",
              },
            ]}
          >
            <Input placeholder="Company Email Address" />
          </Form.Item>
        </FlexItem>
      </Flex>
      <Flex
        css={`
          padding: 10px 0;
          width: 100%;
        `}
      >
        <FlexItem basis="33.3%">
          <Form.Item
            name="phone"
            rules={[
              {
                required: true,
                message: "Please input your Company Direct Hotline!",
              },
            ]}
          >
            <Input placeholder="Direct Hotline" />
          </Form.Item>

          <Form.Item
            name="regionId"
            rules={[
              {
                required: true,
                message: "Please input your Region Selection!",
              },
            ]}
          >
            <Select placeholder="Region Selection" options={region} />
          </Form.Item>
        </FlexItem>

        <FlexItem style={{ padding: "0 10px" }} basis="33.3%">
          <Form.Item
            name="address"
            rules={[{ required: true, message: "Please input your Address!" }]}
          >
            <Textarea placeholder="Address" style={{ minHeight: "114px" }} />
          </Form.Item>
        </FlexItem>
        <FlexItem basis="25%">
          <Form.Item
            name="postalCode"
            rules={[
              {
                required: true,
                message: "Please input your Postal Code!",
              },
            ]}
          >
            <Input placeholder="Postal Code" />
          </Form.Item>
        </FlexItem>
      </Flex>
    </StyledVendorForm>
  );
};

export default AccountDetails;
