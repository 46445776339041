import React, { useEffect, useState } from "react";
import SettingsWrapper from "./styled";
import Card from "components/shared/Card";
import Input from "components/shared/Input";
import Button from "components/shared/Button";
import { CloudUploadSharpIcon } from "components/shared/Icons";
import Upload from "components/shared/Upload";
import Flex, { FlexItem } from "components/shared/Flex";
import { CloseOutlined } from "@ant-design/icons";
import { Form } from "antd";
import userService from "services/user.service";
import commonService from "services/common.service";
import { Toastify } from "components/shared/Toast/Toast";
import config from "config";

const SettingsUI: React.FC = (props) => {
  const [form] = Form.useForm();
  const [image, setImage] = useState<any>([]);
  const [defaultImage, setDefaultImage] = useState<any>([]);
  const [currentAdmin, setAdmin] = useState<any>({});

  const _handleClickButton = async () => {
    try {
      const values = await form.validateFields();
      const imageData = new FormData();
      imageData.append("files", image[0]);

      // Handle Password field
      if (!values.password) {
        delete values.password;
      }
      delete values.rePassword;

      // Update admin
      if (!image.length) {
        // Remove Profile Picture
        if (!defaultImage.length) {
          values.avatar = "";
        }

        updateAdmin(values);
      } else {
        // Add Profile Picture and update admin
        commonService
          .uploadFile(imageData)
          .then((res) => {
            values.avatar = res[0];
            updateAdmin(values);
          })
          .catch((err) => {
            Toastify.showDangerToast("Upload Profile Picture fail!");
          });
      }
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };

  const updateAdmin = (body: any) => {
    userService
      .updateAdmin("" + currentAdmin.user?.id, body)
      .then(() => {
        Toastify.showSuccessToast("Update Success!");
        setImage([]);
        setDefaultImage([]);

        getAdmin();

        form.setFields([
          {
            name: ["password"],
            value: "",
          },
          {
            name: ["rePassword"],
            value: "",
          },
        ]);
      })
      .catch((err) => {
        Toastify.showDangerToast("Update Setting fail!");
      });
  };

  function onKeypress(e: any) {
    if (e.key === "Enter") {
      _handleClickButton();
    }
  }

  const getAdmin = () => {
    userService.getMe().then((res) => {
      if (res.userId) {
        userService.getAdmin("" + res.userId).then((admin) => {
          setAdmin(admin);

          if (admin.user?.avatar) {
            const defaultAvatar = [
              {
                uid: "-1",
                name: "xxx.png",
                status: "done",
                url: `${config.hostName}${admin.user?.avatar}`,
                thumbUrl: `${config.hostName}${admin.user?.avatar}`,
              },
            ];
            setDefaultImage(defaultAvatar);
          }

          form.setFields([
            {
              name: ["name"],
              value: admin.name,
            },
            {
              name: ["contact"],
              value: admin.contact,
            },
            {
              name: ["email"],
              value: admin.user?.email,
            },
            {
              name: ["contact"],
              value: admin.contact,
            },
          ]);
        });
      }
    });
  };

  useEffect(() => {
    getAdmin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.addEventListener("keypress", onKeypress);
    return () => {
      window.removeEventListener("keypress", onKeypress);
    };
  });

  const propsUpload = {
    showUploadList: {
      showRemoveIcon: true,
      removeIcon: (
        <CloseOutlined
          style={{ color: "#ed3833", fontSize: "20px", top: 0, right: 0 }}
          onClick={(e) => {
            setImage([]);
            setDefaultImage([]);
          }}
        />
      ),
    },
    accept: "image/*",

    beforeUpload: (file: any) => {
      setImage([file]);

      return false;
    },
    fileList: defaultImage,
  };

  // Remove old picture
  if (!!image.length) {
    delete propsUpload.fileList;
  }

  return (
    <SettingsWrapper>
      <Form name="basic" form={form}>
        <Card title="User Information">
          <Flex alignCenter style={{ paddingBottom: "24px" }}>
            <FlexItem basis="15%">
              <div className="sub-text-input">Name</div>
            </FlexItem>
            <FlexItem basis="85%">
              <Form.Item
                name="name"
                rules={[
                  { required: true, message: "Please input your Sharon!" },
                ]}
              >
                <Input
                  className="customer-input"
                  placeholder="Sharon"
                  type="text"
                />
              </Form.Item>
            </FlexItem>
          </Flex>
          <Flex alignCenter style={{ paddingBottom: "24px" }}>
            <FlexItem basis="15%">
              <div className="sub-text-input">User ID</div>
            </FlexItem>
            <FlexItem basis="85%">
              <div className="wrap-user-id">{currentAdmin.user?.id}</div>
            </FlexItem>
          </Flex>
          <Flex alignCenter style={{ paddingBottom: "24px" }}>
            <FlexItem basis="15%">
              <div className="sub-text-input">Email</div>
            </FlexItem>
            <FlexItem basis="85%">
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    type: "email",
                    message: "Please input your Email!",
                  },
                ]}
              >
                <Input
                  className="customer-input"
                  placeholder="sharon@jpknights.com"
                  type="text"
                />
              </Form.Item>
            </FlexItem>
          </Flex>
          <Flex alignCenter style={{ paddingBottom: "24px" }}>
            <FlexItem basis="15%">
              <div className="sub-text-input">Contact</div>
            </FlexItem>
            <FlexItem basis="85%">
              <Form.Item
                name="contact"
                rules={[
                  { required: true, message: "Please input your Contact!" },
                ]}
              >
                <Input
                  className="customer-input"
                  placeholder="Contact"
                  type="text"
                />
              </Form.Item>
            </FlexItem>
          </Flex>
          <Flex alignCenter style={{ paddingBottom: "24px" }}>
            <FlexItem basis="15%">
              <div className="sub-text-input">Profile Picture</div>
            </FlexItem>
            <FlexItem basis="85%">
              <div
                className="wrap-btn-upload"
                style={
                  !!image.length || !!defaultImage.length
                    ? { marginLeft: "75px" }
                    : {}
                }
              >
                <Upload
                  listType="picture"
                  maxCount={1}
                  name="avatar"
                  {...propsUpload}
                >
                  <Button icon={<CloudUploadSharpIcon />}>Upload</Button>
                </Upload>
              </div>
            </FlexItem>
          </Flex>
          <Flex alignCenter style={{ paddingBottom: "24px" }}>
            <FlexItem basis="15%">
              <div className="sub-text-input">Role</div>
            </FlexItem>
            <FlexItem basis="85%">
              <Form.Item>
                <Input
                  className="customer-input"
                  placeholder="Administrator"
                  type="text"
                  disabled
                />
              </Form.Item>
            </FlexItem>
          </Flex>
          <Flex alignCenter style={{ paddingBottom: "24px" }}>
            <FlexItem basis="15%">
              <div className="sub-text-input">Change Password</div>
            </FlexItem>
            <FlexItem basis="85%">
              <Form.Item name="password">
                <Input
                  className="customer-input"
                  placeholder="************"
                  isVisible={true}
                />
              </Form.Item>
            </FlexItem>
          </Flex>
          <Flex alignCenter style={{ paddingBottom: "24px" }}>
            <FlexItem basis="15%">
              <div className="sub-text-input">Re-enter password</div>
            </FlexItem>
            <FlexItem basis="85%">
              <Form.Item
                name="rePassword"
                dependencies={["password"]}
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (
                        getFieldValue("password") &&
                        getFieldValue("password") !== value
                      ) {
                        return Promise.reject(
                          new Error(
                            "The two passwords that you entered do not match!"
                          )
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input
                  className="customer-input"
                  placeholder="************"
                  isVisible={true}
                />
              </Form.Item>
            </FlexItem>
          </Flex>
          <Flex>
            <FlexItem basis="15%"></FlexItem>
            <FlexItem basis="85%">
              <Form.Item>
                <Button
                  className="customer-btn"
                  htmlType="submit"
                  onClick={_handleClickButton}
                >
                  Save
                </Button>
              </Form.Item>
            </FlexItem>
          </Flex>
        </Card>
      </Form>
    </SettingsWrapper>
  );
};

export default SettingsUI;
