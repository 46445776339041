import React from 'react'
import styled from 'styled-components'

const InformationCentreContainer = styled.div`
  width: 100%;
`

const InformationCentre: React.FC = ({ children }) => {
  return <InformationCentreContainer>{ children }</InformationCentreContainer>
}

export default InformationCentre
