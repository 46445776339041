
type Object = {
  [key: string]: any
}

export function omit(obj: Object, fields: string[]) {
  const shallowCopy = Object.assign({}, obj);
  for (let i = 0; i < fields.length; i += 1) {
    const key = fields[i];
    delete shallowCopy[key];
  }
  return shallowCopy;
}


