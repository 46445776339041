import styled from 'styled-components';
import { getCss } from 'utils/cssHelpers';

export const NewOrdersWrapper = styled.div`
    &.all-pre-orders {
        margin-top: 20px;
        table tr:nth-child(2n) td {
            ${getCss('background')('theme.table.trHovered')};
        }
        .wrapper-live-orders {
            ${getCss('background')('theme.mngOrders.wrapperLiveBgOrdersInAll')};
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 70px;
            .box-shadow-live-orders {
                width: 400px;
                height: 200px;
                padding: 0.5px;
                border-radius: 10px;
                box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
                background-color: #ffffff;
                font-size: 20px;
                font-weight: bold;
                display: flex;
                justify-content: center;
                align-items: center;
                ${getCss('color')('theme.global.color444444')};
            }
        }
    }
    &.manage-orders {
        table {
            tr td:first-child,
            tr th:first-child {
                text-align: center;
            }
        }
        .ant-card-head {
            text-align: center;
            .ant-card-head-title {
                font-size: 30px;
                font-weight: 600;
            }
        }
    }
    .btn-process-all-order {
        width: 220px;
        height: 50px;
        margin-left: auto;
        padding: 13px 23px;
        border-radius: 5px;
        font-size: 20px;
        font-weight: 500;
        border: 0;
        display: flex;
        align-items: center;
        margin-top: 100px;
        justify-content: center;
    }
    .ant-btn[disabled] {
        background-color: #cfcfcf;
        color: white;
    }
    .btn-ctrl-modal-set-live-order {
        background: transparent;
        border: 0;
        font-size: 12px;
        ${getCss('color')('theme.global.color707070')};
        padding: 0;
        display: block;
        margin-bottom: 10px;
        margin-left: auto;
        span {
            text-decoration: underline;
        }
    }
`

const ModalSetLiveOrderStyled = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .input-set-time-live-order {
        width: 120px;
        margin-top: 22px;
        margin-bottom: 10px;
    }
    .input-set-date-live-order {
        margin-top: 11px;
        margin-bottom: 36px;
        width: 180px;
    }
    .btn-save-set-live-order {
        width: 140px;
    }
`;

export { ModalSetLiveOrderStyled }