import React from 'react'
import Content from 'components/shared/Layout/Content'
export interface LayoutContentProps {
  loading?: boolean
}

const LayoutContent: React.FC<LayoutContentProps> = ({ children }) => {
  return (
    <Content
      css={`
        display: flex;
        padding: 24px;
        min-height: calc(100vh - 64px);
      `}
      className="admin-layout__content"
    >
      {children}
    </Content>
  )
}

export default LayoutContent
