import React, { useEffect, useState } from "react";
import WrapOverview from "./styled";
import DetailsChart from "./DetailsChart";
import Chart from "react-apexcharts";
import financePaymentService from "services/finance-payment.service";
import { getDurationTimeParam, durationKeys } from "utils/common.util";
import { map } from "lodash";
import { optionsDonut } from "./finance-overview.util";

const MainMenuSales: React.FC = (props) => {
  const [mainMenuSales, setMainMenuSales] = useState<{
    series: number[];
    options: any;
  }>({
    series: [],
    options: optionsDonut,
  });

  const getMainMenuSales = (duration: string) => {
    const params = getDurationTimeParam(+duration);
    financePaymentService.getMainMenuSales(params).then((res) => {
      const _mainMenuSales = { ...mainMenuSales };
      _mainMenuSales.series = map(res, (item) => item.value);
      _mainMenuSales.options.labels = map(res, (item) => item.name);
      setMainMenuSales(_mainMenuSales);
    });
  };

  useEffect(() => {
    getMainMenuSales(durationKeys["30days"]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeDuration = (durationKey: string) => {
    getMainMenuSales(durationKey);
  };

  return (
    <WrapOverview.ManageOverview>
      <DetailsChart
        title="Main Menu Sales"
        duration={durationKeys["30days"]}
        onChangeDuration={onChangeDuration}
      >
        <Chart
          options={{ ...mainMenuSales.options }}
          series={[...mainMenuSales.series]}
          type="donut"
          width={450}
          style={{ paddingTop: "30px" }}
        />
      </DetailsChart>
    </WrapOverview.ManageOverview>
  );
};

export default MainMenuSales;
