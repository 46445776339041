import React from 'react';
import { Select as AntSelect, SelectProps as AntSelectProps } from 'antd';
import StyledSelect from './styled';

export type SelectProps = {
  notesTop?: string;
  notesBottom?: string;
} & AntSelectProps<any>

const Select: React.FC<SelectProps> = ({ notesTop, notesBottom, ...props}) => {
  return (
    <StyledSelect>
      {notesTop &&
        <div style={{ paddingTop: '6px' }}>
          {notesTop}
        </div>}
      <AntSelect className="wrapper-select" { ...props } />
      {notesBottom && 
        <div style={{ paddingBottom: '10px', fontSize: '13px', fontStyle: 'italic' }}>
          {notesBottom}
        </div>
      }
    </StyledSelect>
  )
}

export default Select;
