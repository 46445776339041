import React from "react";
import GroupTitle from "components/shared/GroupTitle";
import Flex, { FlexItem } from "components/shared/Flex";
import AllVendorsWrapper from "../styled";
import DetailsInfo from "components/shared/DetailsInfo";

interface IVendorCompanyDetails {
  vendorCompany: any;
}

const VendorCompanyDetails: React.FC<IVendorCompanyDetails> = ({
  vendorCompany = {},
}) => {
  const dataSource = [
    {
      name: {
        label: "Vendor Name",
        value: vendorCompany.name,
      },
      contactNumber: {
        label: "Main Contact Number",
        value: vendorCompany.phone,
      },
      registeredAddress: {
        label: "Registered Address",
        value: vendorCompany.address,
      },
      emailAddress: {
        label: "Email Address",
        value: vendorCompany.user?.email,
      },
      vendorID: {
        label: "Vendor ID",
        value: vendorCompany.vendorId,
      },
      uenNumber: {
        label: "UEN Number",
        value: vendorCompany.uen,
      },
      password: {
        label: "Password",
        value: "************",
      },
    },
  ];
  return (
    <AllVendorsWrapper.WrapperVendorCompanyDetails>
      <Flex>
        <GroupTitle
          fontSize={18}
          className="vendor-company-details-title"
          style={{ margin: "0" }}
        >
          Vendor Company Details
        </GroupTitle>
      </Flex>
      <Flex>
        <FlexItem basis="100%">
          <DetailsInfo dataInfo={dataSource} className="vendor-details" />
        </FlexItem>
      </Flex>
    </AllVendorsWrapper.WrapperVendorCompanyDetails>
  );
};

export default VendorCompanyDetails;
