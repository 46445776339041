import React, { useState, useEffect } from "react";
import DetailsCustomerWrapper from "./styled";
import Flex, { FlexItem } from "components/shared/Flex";
import { Table2 } from "components/shared/Table";
import Button from "components/shared/Button";
import Upload from "components/shared/Upload";
import { CloseOutlined } from "@ant-design/icons";
import { Form } from "antd";
import { Toastify } from "components/shared/Toast/Toast";
import commonService from "services/common.service";
import customersService from "services/customers.service";
import { convertToJson } from "utils/common.util";
export interface IPropsModal {
  userId: number;
  onImportSuccess?: () => void;
}

type IPropsAdjustment = {
  name: string;
  number: string;
  email: string;
};

const colmnsAdjustment = [
  {
    title: "ID",
    dataIndex: "key",
    key: "key",
  },
  {
    title: "Employee Name",
    dataIndex: "employeeName",
    key: "employeeName",
  },
  {
    title: "Contact Number",
    dataIndex: "contactNumber",
    key: "contactNumber",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
];

const ModalAdjustment: React.FC<IPropsModal> = ({
  userId,
  onImportSuccess,
}) => {
  const [form] = Form.useForm();
  const [file, setFile] = useState<any>([]);
  const [defaultFile, setDefaultFile] = useState<any>([]);
  const [dataFile, setDataFile] = useState<any>([]);
  const [dataTable, setDataTable] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  const renderDataTable = () => {
    const result = dataFile.map((row: IPropsAdjustment, index: number) => ({
      key: index + 1,
      employeeName: row.name,
      contactNumber: row.number,
      email: row.email,
    }));
    setDataTable(result);
  };

  useEffect(() => {
    renderDataTable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFile]);

  const submitAdjustment = async () => {
    setLoading(true);
    try {
      const fileData = new FormData();
      fileData.append("files", file[0]);
      const res = await commonService.uploadCSV(fileData);
      await createAdjustment(res[0]);
      onImportSuccess?.();
    } catch (error: any) {
      Toastify.showDangerToast(error?.response?.data?.messages[0]);
    }
    setLoading(false);
  };

  const createAdjustment = async (data: string) => {
    const body = { csvPath: data };
    if (body.csvPath === undefined) {
      Toastify.showDangerToast("Create Fail!");
    } else {
      await customersService.postCSV(userId, body);
    }
  };

  const processCSV = async (str: any) => {
    const newArray = await convertToJson(str);
    setDataFile(newArray);
  };

  const propsUpload = {
    showUploadList: {
      showRemoveIcon: true,
      removeIcon: (
        <CloseOutlined
          style={{ color: "#ed3833", fontSize: "20px", top: 0, right: 0 }}
          onClick={(e) => {
            setFile([]);
            setDefaultFile([]);
          }}
        />
      ),
    },
    accept: ".csv",

    beforeUpload: (file: any) => {
      const reader = new FileReader();
      reader.onload = (data: any) => {
        processCSV(data.target.result);
      };
      reader.readAsText(file);
      setFile([file]);

      // Prevent upload
      return false;
    },

    fileList: defaultFile,
  };

  // Remove old picture
  if (!!file.length) {
    delete propsUpload.fileList;
  }

  return (
    <DetailsCustomerWrapper.Modal>
      <Form name="basic" form={form}>
        <div className="wrap-heading">
          <div className="title-adjustment">
            Customer Employee Adjustment Upload
          </div>
          <div className="wrap-type">
            <Form.Item
              name="file"
              rules={[
                { required: true, message: "Please upload your file csv!" },
              ]}
            >
              <Upload listType="text" maxCount={1} name="file" {...propsUpload}>
                Upload CSV
              </Upload>
            </Form.Item>
          </div>
        </div>
        <Flex>
          <FlexItem
            css={`
              flex: 1;
            `}
          >
            <Table2
              className="table-modal-adjustment"
              dataSource={dataTable}
              columns={colmnsAdjustment}
              pagination={false}
            />
          </FlexItem>
        </Flex>
        <div className="generated-password-account">
          Generated password will be sent to these accounts
        </div>
        <Button
          loading={loading}
          htmlType="submit"
          type="info"
          className="btn-modal-adjustment"
          onClick={submitAdjustment}
        >
          Create
        </Button>
      </Form>
    </DetailsCustomerWrapper.Modal>
  );
};

export default ModalAdjustment;
