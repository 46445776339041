import React, { useState, useEffect } from "react";
import WrapCustomers from "./styled";
import Flex, { FlexItem } from "components/shared/Flex";
import InputSearch from "components/shared/InputSearch";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import Theme from "theme";
import { renderTagStatus } from "./finance-customer.util";
import financePaymentService from "services/finance-payment.service";
import GroupTitle from "components/shared/GroupTitle";
import { ArrowBackOutlineIcon } from "components/shared/Icons";
import CompanyCustomers from "./table-detail-customers/CompanyCustomers";
import UnpaidInvoices from "./table-detail-customers/UnpaidInvoices";
import PastInvoices from "./table-detail-customers/PastInvoices";

const PaymentsByCustomerDetails: React.FC = () => {
  const history = useHistory();
  const { customerId }: any = useParams<Record<string, string | undefined>>();
  const [customerInfo, setCustomerInfo] = useState<any>([]);
  const [pastInvoices, setPastInvoices] = useState<Array<any>>([]);
  const [unpaidInvoices, setUnpaidInvoices] = useState<Array<any>>([]);
  const [search, setSearch] = useState<Array<any>>([]);

  const listDetailCustomer = () => {
    financePaymentService.getDetailCustomer(customerId).then((data) => {
      const dataCustomerInfo = () => [
        {
          key: data?.customerInfo?.id,
          CustomerName: data?.customerInfo?.name,
          POC: data?.customerInfo?.poc,
          Contact: data?.customerInfo?.contact,
          Email: data?.customerInfo?.email,
          BillingAddress: data?.customerInfo?.billingAddress,
        },
      ];

      const dataunpaidInvoices = data?.unpaidInvoices?.map(
        (item: any, index: number) => ({
          key: item.no,
          Date: item.date,
          NoNumberINV: `INV${item.no}`,
          ForTheMonth: item.forMonth,
          Total: `$${item.total}`,
          Status: renderTagStatus(`${item.status}`),
        })
      );

      const dataPastInvoices = data?.pastInvoices?.map(
        (item: any, index: number) => ({
          key: item.no,
          Date: item.date,
          NoNumberINV: `INV${item.no}`,
          ForTheMonth: item.forMonth,
          Total: `$${item.total}`,
          Status: renderTagStatus(`${item.status}`),
        })
      );

      setCustomerInfo(dataCustomerInfo);
      setUnpaidInvoices(dataunpaidInvoices);
      setPastInvoices(dataPastInvoices);
      setSearch(dataunpaidInvoices && dataPastInvoices);
    });
  };

  useEffect(() => {
    listDetailCustomer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _handleSearch = (e: any) => {
    const value = e.target.value.toLowerCase();
    var dataSourceFilter = search;
    if (value !== "") {
      const listKeysSearch: Array<string> = [
        "Date",
        "NoNumberINV",
        "ForTheMonth",
        "Total",
      ];
      const checkItem = (itemData: any) => {
        if (
          listKeysSearch.some(
            (key) => itemData[key].toLowerCase().indexOf(value) !== -1
          )
        ) {
          return itemData;
        } else {
          return null;
        }
      };
      dataSourceFilter = search
        .map((item: any) => checkItem(item))
        .filter((item: any) => item !== null);
    }

    setUnpaidInvoices(dataSourceFilter);
    setPastInvoices(dataSourceFilter);
  };

  return (
    <WrapCustomers>
      <WrapCustomers.Details>
        <Flex justifyBetween alignCenter className="header-settlement">
          <GroupTitle
            fontSize={16}
            icon={
              <ArrowBackOutlineIcon
                color={Theme.global.whiteColor}
                onClick={() => history.goBack()}
              />
            }
            padding={5}
            className="group-title"
          >
            {customerInfo[0] ? customerInfo[0].CustomerName || "N/A" : "..."}
          </GroupTitle>
          <FlexItem>
            <InputSearch
              placeholder="Search Settlement"
              onInput={(e) => _handleSearch(e)}
              className="input-search-settlement"
            />
          </FlexItem>
        </Flex>
        <CompanyCustomers listCompany={customerInfo} />
        <UnpaidInvoices listUnpaidInvoices={unpaidInvoices} />
        <PastInvoices listPastInvoices={pastInvoices} />
      </WrapCustomers.Details>
    </WrapCustomers>
  );
};

export default PaymentsByCustomerDetails;
