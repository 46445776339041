import styled from 'styled-components'
import { getCss } from 'utils/cssHelpers'

export const AllCouriersWrapper = styled.div`
    .table-all-couriers table {
        margin-top: 10px;
        .ant-table-thead .ant-table-cell,
        .ant-table-tbody > tr > td {
            font-size: 14px;
            font-weight: 500;
        }
        .ant-table-tbody > tr > td {
            cursor: pointer;
            ${getCss('color')('theme.global.grayColor')};
        }
    }
    .btn-control {
        width: 140px;
        &.btn-add-new-account {
            margin-right: 5px;
        }
    }
    .input-search-couriers {
        width: 300px;
    }
`