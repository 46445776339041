import styled from 'styled-components';
import { getCss } from 'utils/cssHelpers';

const StyledInput = styled.div`
  box-shadow: ${props => props.theme.global.blackColor};
  input {
    padding: 8px 0 8px 20px;
    box-shadow: unset;
    width: 100%;
    &:hover, &:focus {
      ${getCss('border-color')('theme.global.colord9d9d9')};
      box-shadow: ${props => props.theme.global.blackColor};
    }
    &:hover {
      box-shadow: unset;
    }
    ::placeholder {
      font-size: 14px;
      font-weight: normal;
      font-style: italic;
      ${getCss('color')('theme.global.grayColor')};
    }
  }
  .ant-input-affix-wrapper {
    padding: 8px 0 8px 20px;
    box-shadow: ${props => props.theme.global.blackColor};
    &:not(.ant-input-affix-wrapper-disabled):hover {
      border-color: ${props => props.theme.global.colord9d9d9} !important;
    }
  }
  .ddl-input-visible {
    position: absolute;
    right: 10px;
    z-index: 9;
  }
`

export const StyleNotes = styled.div`
  .dd-input-notes, .dd-input-label {
    font-size: 14px;
    font-style: italic;
    ${getCss('color')('theme.global.grayColor')};
    margin-top: 12px;
  }
`;

export default StyledInput;