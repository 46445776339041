import React from 'react'
import AppRoutesContext from './AppRoutesContext'
import { IRoute } from './renderRoutes'

const AppRoutesProvider: React.FC<{ routes: IRoute[] }> = ({ routes, children }) => {
  return (
    <AppRoutesContext.Provider value={{ routes }}>
      {children}
    </AppRoutesContext.Provider>
  )
}

export default AppRoutesProvider