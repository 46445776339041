import React from "react";
import WrapOverview from "./styled";
import Flex, { FlexItem } from "components/shared/Flex";
import { ChevronDownOutlineIcon } from "components/shared/Icons";
import Theme from "theme";
import Card from "components/shared/Card";
import InvoicesItems from "./InvoicesItems";
import MainMenuSales from "./MainMenuSales";
import AddonMainPax from "./AddonMainPax";
import Top10Addons from "./Top10Addons";

const ManageOverview: React.FC = (props) => {
  const lastDay = () => {
    return (
      <>
        Last 30 days{" "}
        <ChevronDownOutlineIcon color={Theme.global.primaryColor} />
      </>
    );
  };

  return (
    <WrapOverview.ManageOverview>
      <Card title="Invoices">
        <Flex
          justifyAround
          css={`
            flex: 1;
          `}
        >
          <InvoicesItems
            label="Customers (Corporate)"
            title="Invoices Paid"
            content="NONE"
            position={true}
          />
          <InvoicesItems
            label={lastDay()}
            title="Pending Payment"
            content="NONE"
            position={false}
          />
        </Flex>
      </Card>

      <Flex
        style={{ paddingTop: "20px" }}
        css={`
          flex: 1;
        `}
      >
        {/* MainMenuSales */}
        <FlexItem basis="50%" style={{ paddingRight: "5px" }}>
          <MainMenuSales></MainMenuSales>
        </FlexItem>
        {/* End MainMenuSales */}

        {/* AddonMainPax */}
        <FlexItem basis="50%" style={{ paddingLeft: "5px" }}>
          <AddonMainPax />
        </FlexItem>
        {/* End AddonMainPax */}
      </Flex>

      {/* Top10Addons */}
      <Flex
        css={`
          flex: 1;
        `}
        style={{ marginTop: "25px", backgroundColor: Theme.global.whiteColor }}
      >
        <FlexItem basis="100%">
          <Top10Addons />
        </FlexItem>
      </Flex>
      {/* End Top10Addons */}
    </WrapOverview.ManageOverview>
  );
};

export default ManageOverview;
