import styled from 'styled-components'
import { getCss } from 'utils/cssHelpers'

export const Text = styled.div`
  color: #707070;
`

export const QtyCardExtraTitle = styled.div`
  ${getCss('color')('theme.global.primaryColor')};
  font-weight: 500;
`