import { FunnelSharpIcon } from "components/shared/Icons";

const orderColumns = [
  {
    title: "Menu No.",
    dataIndex: "menuNo",
    key: "menuNo",
  },
  {
    title: "Dish Name",
    dataIndex: ["food", "name"],
    key: "name",
  },
  {
    title: "Pax",
    dataIndex: "quantity",
    key: "quantity",
  },
];

const orderStatus = {
  CREATED: "created",
  PAID: "paid",
  PREPARING: "preparing",
  DELIVERING: "delivering",
  COMPLETED: "completed",
  CANCELLED: "cancelled",
};

const orgType :{ [key: string]: any }= {
  individual: 'IND',
  corporate: 'CORP'
}

const purchaseColumns = [
  {
    title: (
      <>
        Organisation Name <FunnelSharpIcon height="14px" />
      </>
    ),
    dataIndex: "corpName",
    key: "corpName",
  },
  {
    title: "Adds(Main)",
    dataIndex: "addMain",
    key: "addMain",
  },
  {
    title: "Adds(Sides)",
    dataIndex: "addSide",
    key: "addSide",
  },
  {
    title: (
      <>
        Mode <FunnelSharpIcon height="14px" />
      </>
    ),
    dataIndex: "mode",
    key: "mode",
  },
  {
    title: (
      <>
        Kitchen <FunnelSharpIcon height="14px" />
      </>
    ),
    dataIndex: "kitchenName",
    key: "kitchenName",
  },
  {
    title: (
      <>
        Add On Cost <FunnelSharpIcon height="14px" />
      </>
    ),
    dataIndex: "addOnCost",
    key: "addOnCost",
  },
  {
    title: (
      <>
        Order No <FunnelSharpIcon height="14px" />
      </>
    ),
    dataIndex: "orderId",
    key: "orderId",
  },
  {
    title: "",
    dataIndex: "detail",
    key: "detail",
  },
];

export { orderColumns, orderStatus, orgType, purchaseColumns };
