import React from 'react';
import Table from "components/shared/Table";
import { columnsCompany } from "../finance-customer.util";

export interface IPropsCompany {
  listCompany?: []
}

const CompanyCustomers: React.FC<IPropsCompany> = ({
  listCompany
}) => {
  return (
    <Table
      dataSource={listCompany}
      columns={columnsCompany}
      pagination={false}
      className="table-settlement"
    />
  )
}

export default CompanyCustomers;
