import React from "react";
import Table from "components/shared/Table";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { columnsInvoices } from "../finance-customer.util";

export interface IPropsUnpaidInvoices {
  listUnpaidInvoices?: any[];
}

const UnpaidInvoices: React.FC<IPropsUnpaidInvoices> = ({
  listUnpaidInvoices,
}) => {
  const { customerId }: any = useParams<Record<string, string | undefined>>();
  const history = useHistory();

  const handleClickInvoice = (record: any) => {
    history.push(
      `/finance-payment/payments-by-customers-details-inv/${customerId}/${record.key}`
    );
  };

  return (
    <Table
      title={() => "Unpaid Invoices (Auto Generated)"}
      dataSource={listUnpaidInvoices}
      columns={columnsInvoices}
      pagination={false}
      className="table-invoices"
      onRow={(record, rowIndex) => {
        return {
          onClick: () => handleClickInvoice(record),
        };
      }}
    />
  );
};

export default UnpaidInvoices;
