import React from 'react';
import { Form as AntForm, FormItemProps as AntFormItemProps} from 'antd';

const { Item: AntFormItem } = AntForm

export interface FormItemProps extends AntFormItemProps {}

const FormItem: React.FC<FormItemProps> = (props) => {
    return <AntFormItem className='ddl-form-item' {...props} />
}

export default FormItem;
