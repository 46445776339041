import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { ArrowBackOutlineIcon } from 'components/shared/Icons';
import Flex, { FlexItem } from 'components/shared/Flex';
import GroupTitle from 'components/shared/GroupTitle';
import InputSearch from 'components/shared/InputSearch';
import WrapCloudKitChenVendors from './styled';
import { useParams } from 'react-router-dom';
import Theme from "theme";
import {
  IPropsCloudKitchenDetail,
  renderTagStatus,
} from "./finance-CloudKitchenVendors.util";
import financePaymentService from "services/finance-payment.service";
import moment from 'moment';
import CloudKitchen from "./table-detail-cloud-kitchen-vendors/CloudKitchen";
import SendPayment from "./table-detail-cloud-kitchen-vendors/SendPayment";
import PastInvoices from "./table-detail-cloud-kitchen-vendors/PastInvoices";

const CloudKitchenVendorsDetails: React.FC = (props) => {
  const history = useHistory();
  const { idKitchen }: any = useParams<Record<string, string | undefined>>();
  const [customerInfo, setCustomerInfo] = useState<any>([]);
  const [pastInvoices, setPastInvoices] = useState<Array<any>>([]);
  const [unpaidInvoices, setUnpaidInvoices] = useState<Array<any>>([]);
  const [search, setSearch] = useState<Array<any>>([]);

  const listDetailCloudKitchen = () => {
    financePaymentService.getDetailCloudkitchens(idKitchen).then((data) => {
      const dataCustomerInfo = () => (
        [{
          key: data?.customerInfo?.id,
          CustomerName: data?.customerInfo?.name,
          POC: data?.customerInfo?.poc,
          Contact: data?.customerInfo?.contact,
          Email: data?.customerInfo?.email,
          BillingAddress: data?.customerInfo?.billingAddress,
        }]);

      const dataunpaidInvoices = data?.unpaidInvoices?.map((item: IPropsCloudKitchenDetail, index: number) => ({
        key: index,
        Date: moment(item.date).format("DD-MM-YYYY"),
        NoNumberINV: item.invoiceNo,
        ForTheMonth: (function(){
          return moment(item.date).format('MMM D');
        }()),
        Total: `$${item.total}`,
        Status: renderTagStatus(`${item.status}`),
      }));

      const dataPastInvoices = data?.pastInvoices?.map((item: IPropsCloudKitchenDetail, index: number) => ({
        key: index,
        Date: moment(item.date).format("DD-MM-YYYY"),
        NoNumberINV: item.invoiceNo,
        ForTheMonth: (function(){
          return moment(item.date).format('MMM D');
        }()),
        Total: `$${item.total}`,
        Status: renderTagStatus(`${item.status}`),
      }));

      setCustomerInfo(dataCustomerInfo);
      setUnpaidInvoices(dataunpaidInvoices);
      setPastInvoices(dataPastInvoices);
      setSearch(dataunpaidInvoices && dataPastInvoices);
    })
  }

  useEffect(() => {
    listDetailCloudKitchen();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _handleSearch = (e: any) => {
    const value = e.target.value.toLowerCase();
    var dataSourceFilter = search;
    if (value !== '') {
      const listKeysSearch: Array<string> = ['Date', 'NoNumberINV', 'ForTheMonth', 'Total'];
      const checkItem = (itemData: any) => {
        if (listKeysSearch.some(key => itemData[key].toLowerCase().indexOf(value) !== -1)) {
          return itemData;
        } else {
          return null;
        }
      }
      dataSourceFilter = search.map((item: any) => checkItem(item)).filter((item: any) => item !== null);
    }

    setUnpaidInvoices(dataSourceFilter);
    setPastInvoices(dataSourceFilter);
  }

  return (
    <WrapCloudKitChenVendors>
      <WrapCloudKitChenVendors.Details>
        <Flex justifyBetween alignCenter className="header-settlement">
          <GroupTitle
            fontSize={16}
            icon={<ArrowBackOutlineIcon color={Theme.global.whiteColor} onClick={() => history.goBack()} />} 
            padding={5}
            className="group-title"
          >
            Cloud Kitchen A
          </GroupTitle>
          <FlexItem>
            <InputSearch
              placeholder="Search Settlement"
              onInput={e => _handleSearch(e)}
              className="input-search-settlement"
            />
          </FlexItem>
          </Flex>
          <CloudKitchen listCloudKitchen={customerInfo} />
          <SendPayment listSendPayment={unpaidInvoices} />
          <PastInvoices  listPastInvoices={pastInvoices} />
      </WrapCloudKitChenVendors.Details>
    </WrapCloudKitChenVendors>
  );
}

export default CloudKitchenVendorsDetails;
