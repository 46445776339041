import React from 'react';
import { Upload as AntUpload, UploadProps as AntUploadProps } from 'antd';
import StyledUpload from './styled';

export type UploadProps = {
  children?: any;
} & AntUploadProps<any>

const UploadComponent: React.FC<UploadProps> = ({children, ...props}) => {
  return (
    <StyledUpload>
      <AntUpload className="ddl-upload" {...props}>
        {children}
      </AntUpload>
    </StyledUpload>
  );
}

export default UploadComponent;
