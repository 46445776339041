import React, { useEffect, useState } from "react";
import WrapOverview from "./styled";
import Chart from "react-apexcharts";
import financePaymentService from "services/finance-payment.service";
import { getDurationTimeParam, durationKeys } from "utils/common.util";
import { map } from "lodash";
import Flex, { FlexItem } from "components/shared/Flex";
import Duration from "components/shared/Duration/Duration";
import { optionsBarColumn } from "./finance-overview.util";


const Top10Addons: React.FC = (props) => {
  const [top10Addons, setTop10Addons] = useState<{
    series: any[];
    options: any;
  }>({
    series: [],
    options: optionsBarColumn,
  });

  const getAddonMainPax = (duration: string) => {
    const params = getDurationTimeParam(+duration);
    financePaymentService.getTop10Addons(params).then((res) => {
      const _top10Addons = { ...top10Addons };
      _top10Addons.series = [{ data: map(res, (item) => item.value) }];
      _top10Addons.options.xaxis.categories = map(res, (item) => item.name);
      setTop10Addons(_top10Addons);
    });
  };

  useEffect(() => {
    getAddonMainPax(durationKeys["30days"]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeDuration = (durationKey: string) => {
    getAddonMainPax(durationKey);
  };

  return (
    <WrapOverview.ManageOverview>
      <Flex justifyBetween style={{ padding: "20px" }}>
        <FlexItem basis="50%">
          <div className="report">
            Add Ons (Extras) <br />
            Extract From: Add Ons <br />
            <span style={{ fontWeight: "normal" }}>
              Top 10 Add Ons During This Period
            </span>
          </div>
        </FlexItem>
        <FlexItem basis="50%" style={{ textAlign: "end" }}>
          <Duration
            duration={durationKeys["30days"]}
            onChangeDuration={onChangeDuration}
          />
        </FlexItem>
      </Flex>
      <Chart
        options={JSON.parse(JSON.stringify(top10Addons.options))}
        series={JSON.parse(JSON.stringify(top10Addons.series))}
        type="bar"
      />
    </WrapOverview.ManageOverview>
  );
};

export default Top10Addons;
