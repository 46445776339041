import styled from 'styled-components'

const MyFeedbackWrapper = styled.div`
  & {
    .custome-btn {
      padding: 6px 35px;
      line-height: 0;
    }
    .ml-10 {
      margin-left: 10px;
    }
    .ant-table-tbody > tr {
      cursor: pointer;
    }
  }
`;

export default MyFeedbackWrapper;
