import React, { useState, useEffect } from 'react';
import DDLLogo from 'assets/images/ddl-logo.png'
import Flex, { FlexItem } from 'components/shared/Flex';
import {
  NotificationsSharpIcon,
  ChevronDownOutlineIcon,
  CloseSharpIcon } from 'components/shared/Icons';
import Theme from 'theme';
import StyledActionsUserHeader from './styled';
import DropdownComponent from 'components/shared/Dropdown';
import Menu from 'components/shared/Menu'
import { Link } from "react-router-dom";
import { useHistory } from 'react-router';
import userService from 'services/user.service';

const ActionsUserHeader: React.FC = (props) => {
  const history = useHistory();
  const [active, isActive] = useState<boolean>(false);
  const [userInfo, setUserInfo] = useState<any>({});

  const _handleViewNotifications = () => {
    isActive(true);
  }

  const _handleLogout = () => {
    userService.logout();
    history.push('/login');
  }

  useEffect(()=> {
    userService.getCurrentUser().then((userInfo: any) => {
      setUserInfo(userInfo);
   });
  }, []);

  const menu = (
    <StyledActionsUserHeader.StyledMenuItem>
      <Menu>
        <Menu.Item key="0">
          <Flex justifyBetween alignCenter>
            <FlexItem>
              <Link to="#">My Profile</Link>
            </FlexItem>
            <FlexItem>
              <CloseSharpIcon className='close-notification-popup' />
            </FlexItem>
          </Flex>
        </Menu.Item>
        <Menu.Item key="1">
          <Link to="/settings">Settings</Link>
        </Menu.Item>
        <Menu.Item key="2">
          <Link to="#" onClick={_handleLogout}>Log out</Link>
        </Menu.Item>
      </Menu>
    </StyledActionsUserHeader.StyledMenuItem>
  );

  return (
    <StyledActionsUserHeader>
    <Flex alignCenter>
      <FlexItem style={{ borderRight: `1px solid ${Theme.global.colorEbebf2}`, paddingRight: '10px' }}>
        <NotificationsSharpIcon style={{ display: 'flex' }} onClick={_handleViewNotifications} />
        {active &&
          <StyledActionsUserHeader.StyledNotifications>
            <Flex alignCenter justifyBetween className='styled-border'>
              <FlexItem basis='50%' style={{ fontSize: '16px' }}>
                Notification
              </FlexItem>
              <FlexItem basis='50%' style={{ textAlign: 'end' }}>
                <CloseSharpIcon
                  className='close-notification-popup'
                  onClick={() => isActive(false)}
                />
              </FlexItem>
            </Flex>
            <div className="content-notification">
              <p className="text-notification">
                Real Time Live Order Stopped @10:00AM
              </p>
              <span className="sub-text-notification">
                2 minutes ago
              </span>
            </div>
            <div className="content-notification">
              <p className="text-notification">
                Real Time Live Order Opened @07:00AM
              </p>
              <span className="sub-text-notification">
                3 hours ago
              </span>
            </div>
          </StyledActionsUserHeader.StyledNotifications>
        }
      </FlexItem>
      <FlexItem style={{ padding: '0 10px' }}>
        <DropdownComponent
          content={userInfo.email}
          icon={<ChevronDownOutlineIcon />}
          trigger='click'
          onClick={(e) => e.preventDefault()}
          menu={menu}
          href='#'
        >
        </DropdownComponent>
      </FlexItem>
      <FlexItem>
        <img src={DDLLogo} alt="ddlunch" className='style-avatar' />
      </FlexItem>
    </Flex>
    </StyledActionsUserHeader>
  );
}

export default ActionsUserHeader;
