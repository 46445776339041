import React, {useState, useEffect} from 'react';
import Table3 from 'components/shared/Table';
import Flex, { FlexItem } from 'components/shared/Flex';
import Button from 'components/shared/Button';
import InputSearch from 'components/shared/InputSearch';
import StyledAllAddOns from './styled';
import Tag from 'components/shared/Tag';
import { useHistory } from 'react-router-dom';
import RenderImgFood from 'components/shared/RenderImgFood';
import menuService from 'services/menu.service';
import config from "config";
import { collumsAddOns } from "./AddOns.config";
import Pagination from "components/shared/Pagination";

type IPropsFoodType = {
  id: number,
  photo: string,
  name: string,
  calories: number,
  price: number,
  menu: any,
  types: any,
}

type IPagination = {
  total: number,
  onChange: any,
  current: number
}

const _renderImg = (url: string) => {
  return <RenderImgFood src={url} alt={url} />
}

const AllAddOns: React.FC = (props) => {
  const history = useHistory();
  const [addOns, setAddOns] = useState([]);
  const [itemAddOns, setItemAddOns] = useState<any>([]);
  const [current, setCurrent] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(true);

  const getUrl = (url: any) => {
    if (url.startsWith("http")) {
      return url;
    }
    return config.hostName + url
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const renderTableFoodType = () => {
    const nameFoodType = "addons";
    menuService.getFoodByType(nameFoodType).then((addOns) => {
      setLoading(false);
      const resultFoodType = addOns?.map((row: IPropsFoodType, index: number) => ({
        key: index,
        image: _renderImg(`${getUrl(row.photo)}`),
        food: row.id,
        name: row.name,
        category: row?.menu?.name,
        type: <Tag type='black'>{row?.types?.map((item: any) => {return item.name})}</Tag>,
        calories: row.calories,
        price: `$${ row.price}`,
      }));
      setItemAddOns(resultFoodType);
      setAddOns(resultFoodType);
    });
  }

  useEffect(() => {
    renderTableFoodType();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _handleSearch = (e: any) => {
    const value = e.target.value.toLowerCase();
    var dataSourceFilter: Array<string> = addOns;
    if (value !== '') {
      const listKeysSearch: Array<string> = ["name", "category"];
      const checkItem = (itemData: any) => {
        if (listKeysSearch.some(key => itemData[key].toLowerCase().indexOf(value) !== -1)) {
          return itemData;
        } else {
          return null;
        }
      }
      dataSourceFilter = addOns.map((item: any) => checkItem(item)).filter((item: any) => item !== null);
    }
    setItemAddOns(dataSourceFilter);
  }

  const _handleClickCreateNewAddOns = () => {
    history.push('/manage-menu/all-add-ons/add-ons');
  }

  const editAddOns = (record: any) => {
    history.push({
      pathname: `/manage-menu/all-add-ons/edit-ons/${record.food}`,
      state: { food: record?.food }
    });
  }

  const _renderTabsTable = () => {
    return (
      <Flex alignCenter>
          <FlexItem className='active-tabs'>All</FlexItem>
          <FlexItem style={{ padding: '0 28px' }}>Published</FlexItem>
          <FlexItem>Draft</FlexItem>
      </Flex>
    )
  }

  const pageSize: number = 5;

  const getData = (current: number, pageSize: number) => {
    return itemAddOns.slice((current - 1) * pageSize, current * pageSize);
  }

  const MyPagination = ({ total, onChange, current }: IPagination) => {
    return (
      <Pagination
        onChange={onChange}
        total={total}
        current={current}
        pageSize={pageSize}
      />
    )
  }

  return (
    <StyledAllAddOns.TableAddOns>
        <Flex justifyBetween alignCenter style={{ paddingBottom: '22px' }}>
          <FlexItem>
              <Button type='primary' className='custome-btn' onClick={_handleClickCreateNewAddOns}>
                Create New Add Ons
              </Button>
          </FlexItem>
          <FlexItem>
              <InputSearch
                placeholder='Search Food In This Menu'
                style={{ minWidth: '300px'}}
                onInput={e => _handleSearch(e)}
              />
          </FlexItem>
      </Flex>
      <Flex>
        <FlexItem css={`flex: 1`}>
          {
            loading
            ? "Loading..."
            : (
              <>
                 <Table3
                  title={() => _renderTabsTable()}
                  columns={collumsAddOns}
                  dataSource={getData(current, pageSize)}
                  pagination={false}
                  onRow={(record) => {
                    return {
                      onClick: () => editAddOns(record),
                    };
                  }}
                />
                <MyPagination
                  total={itemAddOns.length}
                  current={current}
                  onChange={setCurrent}
                />
              </>
            )
          }
        </FlexItem>
      </Flex>
    </StyledAllAddOns.TableAddOns>
  );
} 

export default AllAddOns;
