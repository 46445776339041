import React, { useState, useEffect } from "react";
import { CompletedOrdersWrapper } from "./styled";
import GroupTitle from "components/shared/GroupTitle";
import { ArrowBackOutlineIcon } from "components/shared/Icons";
import Flex, { FlexItem } from "components/shared/Flex";
import { PersonCircleOutlineIcon } from "components/shared/Icons";
import DetailsReceipt from "components/shared/DetailsReceipt";
import OrdersForLocation from "components/shared/OrdersForLocation";
import orderService from "services/order.service";
import groupBy from "lodash/groupBy";
import {
  totalPrice,
  buildFoodLocation,
  totalQuantity,
} from "../manage-order.util";
import {
  orgType as ORG_TYPE,
  orderStatus as STATUS,
} from "../manage-orders.config";
import corpLogo from "assets/icons/corp.svg";
import Theme from "theme";

interface IProps {
  clickBack: (event?: Event) => void;
  order: any;
}

const CompletedOrdersDetailsUI: React.FC<IProps> = ({ order, clickBack }) => {
  const [orderDetail, setOrderDetail] = useState<any>({});

  useEffect(() => {
    orderService.getOrder(order.orderId).then((order) => {
      const locationGroup = groupBy(order.items, (item) => item.location?.id);
      const customer = [
        {
          "Organisation Name": order.corporate?.name,
          "Registered Address": order.corporate?.registeredAddress,
        },
        {
          "Main Contact Number": order.corporate?.phoneNumber,
          "Email Address": order.corporate?.user?.email,
        },
        {
          "No. Of Order Location": Object.keys(locationGroup).length,
          "Total QTY + Adds": totalQuantity(order.items),
        },
      ];

      const vendor = [
        {
          "Vendor Name": order.vendor?.name,
          "Registered Address": order.vendor?.address,
        },
        {
          "Main Contact Number": order.vendor?.phone,
          "Email Address": order.vendor?.user?.email,
        },
      ];

      const foodLocation = {
        totalQTY: totalQuantity(order.items),
        totalCost: totalPrice(order.items),
        noOfLocation: Object.keys(locationGroup).length,
        data: buildFoodLocation(locationGroup),
      };

      setOrderDetail({
        vendor,
        customer,
        orders: foodLocation,
        status: order.status,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <CompletedOrdersWrapper.CompletedOrders>
        <Flex justifyEnd>
          <Flex
            className="header-export-pdf"
            alignCenter
            justifyCenter
            css={"margin-bottom: 13px"}
          >
            {/* <div className="export-pdf">Export PDF</div>
            <CloudDownloadSharpIcon
              className="icon-download-pdf"
              width="22px"
              height="22px"
              css={"margin-left: 14px"}
            /> */}
          </Flex>
        </Flex>

        <GroupTitle
          fontSize={16}
          icon={
            <ArrowBackOutlineIcon
              color={Theme.global.whiteColor}
              onClick={clickBack}
            />
          }
          padding={10}
          className="group-title-orders"
        >
          {order.corpName}
          <span style={{ position: "absolute", right: "40px" }}>
            Order No: {order.orderId}
          </span>
        </GroupTitle>

        <Flex
          justifyBetween
          className="header-details-receipt"
          css={"margin-top: 18px"}
        >
          <div className="title-details-receipt">
            Order Details ... Via{" "}
            <span className="text-underline">{order.mode}</span>
          </div>
          <Flex alignCenter>
            {order.mode === ORG_TYPE.individual ? (
              <PersonCircleOutlineIcon
                className="icon-person"
                width="50px"
                height="50px"
              />
            ) : (
              <img src={corpLogo} alt="corpLogo" />
            )}
            <div className="type-company">{order.mode}</div>
          </Flex>
        </Flex>

        <Flex>
          <FlexItem className="general-info" css={"margin-right: 7px"}>
            <div className="title-general-info">Customer</div>
            <DetailsReceipt dataInfo={orderDetail.customer} />
          </FlexItem>
          <FlexItem className="general-info" css={"margin-left: 7px"}>
            <div className="title-general-info">Vendor On This Day</div>
            <DetailsReceipt dataInfo={orderDetail.vendor} />
          </FlexItem>
        </Flex>

        <OrdersForLocation dataInfo={orderDetail.orders} />

        <div
          className="label-complete-order"
          style={{
            backgroundColor:
              orderDetail.status === STATUS.COMPLETED
                ? Theme.global.redColor
                : Theme.global.colorCfcfcf,
          }}
        >
          Order Complete
        </div>
      </CompletedOrdersWrapper.CompletedOrders>
    </>
  );
};
export default CompletedOrdersDetailsUI;
