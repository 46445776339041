const PRIMARY_COLOR = '#1aae48';
const SECONDARY_COLOR = '#f9fff5';
const TERTIARY_COLOR = '#e0ecde';
const WHITE_COLOR = '#FFF';
const RED_COLOR = '#ff3939';
const ORANGE_COLOR = '#fc6011';
const YELLOW_COLOR = '#efff34';
const YELLOW2_COLOR = '#f8ffa0';
const GRAY_COLOR = '#767676';
const DANGER_COLOR = '#de1a1a';
const COLOR_BLACK = '#000';
const COLOR_444444 = '#444444';
const COLOR_CFCFCF = '#cfcfcf';
const COLOR_CBCBCB = '#cbcbcb';
const COLOR_707070 = '#707070';
const COLOR_AFAFAF = '#afafaf';
const COLOR_d9d9d9 = '#d9d9d9';
const COLOR_4F4F4F = '#4f4f4f';
const COLOR_B9B9B9 = "#b9b9b9";
const COLOR_DEDEDE = '#dedede';
const COLOR_A55A25 = '#a55a28';
const COLOR_ECB942 = '#ecb942';
const COLOR_3E4656 = '#3e4656';
const COLOR_BCBCCB = '#bcbccb';
const COLOR_EBEBF2 = '#ebebf2';
const COLOR_43425D = '#43425d';
const COLOR_F6FCB5 = '#f6fcb5';
const COLOR_ED3833 = "#ed3833";
const COLOR_878787 = "#878787";
const COLOR_5D5D5D = "#5d5d5d";
const COLOR_TRANSPARENT = 'transparent';

/** ====== Button ======== */
const BUTTON_PRIMARY_COLOR = PRIMARY_COLOR
const BUTTON_INFO_COLOR = ORANGE_COLOR
const BUTTON_DANGER_COLOR = RED_COLOR
const BUTTON_DELETE_COLOR = COLOR_AFAFAF
const BUTTON_YELLOW_COLOR = YELLOW_COLOR
const BUTTON_DISABLED_COLOR = COLOR_B9B9B9

/** ====== Menu ======== */
const MENU_ACTIVE_BG_COLOR = YELLOW_COLOR
const MENU_ITEM_ACTIVE_BG_COLOR = YELLOW2_COLOR
const MENU_TEXT_COLOR = GRAY_COLOR
const MENU_SUBITEM_BG_COLOR = TERTIARY_COLOR

/** ====== Modal ======== */
const MODAL_LABLE_ITEM_COLOR = GRAY_COLOR

/** ====== TAG ======== */
const TAG_PRIMARY_COLOR = PRIMARY_COLOR
const TAG_INFO_COLOR = ORANGE_COLOR
const TAG_DANGER_COLOR = RED_COLOR
const TAG_GROW_COLOR = COLOR_A55A25
const TAG_YELLOW_COLOR = COLOR_ECB942

/** ====== TABS ======== */
const TABS_BAR_COLOR = COLOR_444444

/** ====== Table ======== */
const TABLE_HEAD_BG_COLOR = TERTIARY_COLOR
const TABLE_BORDER_COLOR = TERTIARY_COLOR
const TABLE_TR_HOVERED_COLOR = TERTIARY_COLOR
const TABLE_SUB_BG_COLOR = ORANGE_COLOR

/** ====== Table2 ======== */
const TABLE2_HEAD_BG_COLOR = PRIMARY_COLOR
const TABLE2_BODY_BG_COLOR = YELLOW2_COLOR

/** ====== Manage Orders ======== */
const MNG_BG_ORDERS_REAL_TIME = RED_COLOR
const MNG_BG_ALL_PRE_ORDERS = PRIMARY_COLOR
const MNG_BG_BTN_PROCESS_ALL_ORDER = COLOR_CFCFCF

/** ====== Manage Menu ======== */
const TEXT_TABS_MENU = COLOR_BLACK
const BG_ALL_TITLE_TABLE = COLOR_4F4F4F

const theme = {
  global: {
    primaryColor: PRIMARY_COLOR,
    secondaryColor: SECONDARY_COLOR,
    tertiaryColor: TERTIARY_COLOR,
    whiteColor: WHITE_COLOR,
    redColor: RED_COLOR,
    grayColor: GRAY_COLOR,
    orangeColor: ORANGE_COLOR,
    dangerColor: DANGER_COLOR,
    blackColor: COLOR_BLACK,
    color444444: COLOR_444444,
    color4f4f4f: COLOR_4F4F4F,
    colorCbcbcb: COLOR_CBCBCB,
    color707070: COLOR_707070,
    colord9d9d9: COLOR_d9d9d9,
    colorDedede: COLOR_DEDEDE,
    colorTransparent: COLOR_TRANSPARENT,
    backColor: COLOR_BLACK,
    colorBcbccb: COLOR_BCBCCB,
    colorEbebf2: COLOR_EBEBF2,
    color43425d: COLOR_43425D,
    colorF6fcb5: COLOR_F6FCB5,
    colorCfcfcf: COLOR_CFCFCF,
    coloreD3833: COLOR_ED3833,
    color878787: COLOR_878787,
    color5d5d5d: COLOR_5D5D5D,
  },
  switch: {
    primary: {
      bgColor: BUTTON_PRIMARY_COLOR,
      borderColor: BUTTON_PRIMARY_COLOR
    },
    info: {
      bgColor: RED_COLOR,
      borderColor: RED_COLOR
    },
  },
  button: {
    primary: {
      bgColor: BUTTON_PRIMARY_COLOR,
      borderColor: BUTTON_PRIMARY_COLOR
    },
    info: {
      bgColor: BUTTON_INFO_COLOR,
      borderColor: BUTTON_INFO_COLOR
    },
    danger: {
      bgColor: BUTTON_DANGER_COLOR,
      borderColor: BUTTON_DANGER_COLOR
    },
    link: {
      bgColor: 'none',
      borderColor: 'none'
    },
    delete: {
      bgColor: BUTTON_DELETE_COLOR,
      borderColor: BUTTON_DELETE_COLOR
    },
    yellow: {
      bgColor: BUTTON_YELLOW_COLOR,
      borderColor: BUTTON_YELLOW_COLOR
    },
    disabled: {
      bgColor: BUTTON_DISABLED_COLOR,
      borderColor: BUTTON_DISABLED_COLOR
    }
  },
  menu: {
    activeBgColor: MENU_ACTIVE_BG_COLOR,
    itemActiveBgColor: MENU_ITEM_ACTIVE_BG_COLOR,
    textColor: MENU_TEXT_COLOR,
    subItemBgColor: MENU_SUBITEM_BG_COLOR
  },
  modal: {
    lableItemColor: MODAL_LABLE_ITEM_COLOR,
  },
  tag: {
    primary: {
      bgColor: TAG_PRIMARY_COLOR,
      borderColor: TAG_PRIMARY_COLOR
    },
    info: {
      bgColor: TAG_INFO_COLOR,
      borderColor: TAG_INFO_COLOR
    },
    danger: {
      bgColor: TAG_DANGER_COLOR,
      borderColor: TAG_DANGER_COLOR
    },
    brown: {
      bgColor: TAG_GROW_COLOR,
      borderColor: TAG_GROW_COLOR
    },
    yellow: {
      bgColor: TAG_YELLOW_COLOR,
      borderColor: TAG_YELLOW_COLOR
    },
    black: {
      bgColor: COLOR_3E4656,
      borderColor: COLOR_3E4656
    },
  },
  tabs : {
    barColor: TABS_BAR_COLOR,
    tabColor: COLOR_444444,
    textColor: TEXT_TABS_MENU,
  },
  table: {
    theadBgColor: TABLE_HEAD_BG_COLOR,
    borderColor: TABLE_BORDER_COLOR,
    trHovered: TABLE_TR_HOVERED_COLOR,
    headeBgColor: BG_ALL_TITLE_TABLE,
    subBodyBgColor: TABLE_SUB_BG_COLOR,
  },
  table2: {
    theadBgColor: TABLE2_HEAD_BG_COLOR,
    bodyBgColor: TABLE2_BODY_BG_COLOR,
  },
  mngOrders: {
    titleRealTimeLiveOrders: MNG_BG_ORDERS_REAL_TIME,
    titleAllPreOrders: MNG_BG_ALL_PRE_ORDERS,
    wrapperLiveBgOrdersInAll: TERTIARY_COLOR,
    btnProcessAllOrders: MNG_BG_BTN_PROCESS_ALL_ORDER
  },
}

type ThemeType = typeof theme

export type { ThemeType }
export default theme