import React from "react";
import Input from "components/shared/Input";
import Flex, { FlexItem } from "components/shared/Flex";
import GroupTitle from "components/shared/GroupTitle";
import Form from "components/shared/Form";
import WrapCustomersForm from "../styled";
import generate from "generate-password";

interface IPropsAccountInformation {
  isNewCustomer: boolean,
  title: string,
  isCheck: boolean,
  form: any
}

const AccountInformation: React.FC<IPropsAccountInformation> = ({
  isNewCustomer,
  title,
  isCheck,
  form
}) => {

  const generatePassword = () => {
    const password = generate.generate({
      length: 10,
      numbers: true,
    });
    form.setFields([
      {
        name: ["password"],
        value: password,
      },
      {
        name: ["reEnterPassword"],
        value: password,
      },
    ]);
  };

  return (
    <>
      <GroupTitle fontSize={18} style={{ paddingTop: '23px', paddingBottom: '12px', marginLeft: '0'}}>
        {title}
      </GroupTitle>
      <Flex>
        <FlexItem basis='33.3%' style={{ paddingBottom: '34px'}}>
          {isNewCustomer
            ?
            <Form.Item
              name="corporateId"
              rules={[{ required: true, message: 'Please input your CORPORATE ID!' }]}
            >
              <Input
                placeholder="CORPORATE ID"
                notes="*This ID is for admin log in and for employees in conjunction"
              />
            </Form.Item>
            :
            <Form.Item
              name="vendorId"
              rules={[{ required: true, message: 'Please input your CORPORATE ID!' }]}
            >
              <Input
                placeholder="VENDOR ID"
                notes="*This ID is for admin log in and for employees in conjunction"
              />
            </Form.Item>
          }
        </FlexItem>
      </Flex>
      <Flex>
        <FlexItem basis='33.3%'>
          <Form.Item
            name="password"
            rules={[{ required: isCheck ? true : false, message: 'Please input your Enter Password!' }]}
          >
            <Input placeholder="Enter Password" isVisible={true} />
          </Form.Item>
        </FlexItem>
        <FlexItem style={{ padding: '0 10px' }} basis='33.3%'>
          <Form.Item
            name="reEnterPassword"
            dependencies={["password"]}
            rules={[
              {
                required: isCheck ? true : false,
                message: "Please input your Re Enter Password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      "The two passwords that you entered do not match!"
                    )
                  );
                },
              }),
            ]}
          >
            <Input placeholder="Re Enter Password" isVisible={true} />
          </Form.Item>
        </FlexItem>
      </Flex>
      <WrapCustomersForm.Generate onClick={generatePassword}>
        Generate Password
      </WrapCustomersForm.Generate>
    </>
  )
}

export default AccountInformation;