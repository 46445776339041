import React, { useState } from "react";
import GroupTitle from "components/shared/GroupTitle";
import Flex, { FlexItem } from "components/shared/Flex";
import { ArrowBackOutlineIcon } from "components/shared/Icons";
import StyledFood from "./styled";
import { useHistory } from "react-router-dom";
import { Form } from "antd";
import menuService from "services/menu.service";
import { Toastify } from "components/shared/Toast/Toast";
import FormFoods from "./FormFoods";
import Theme from "theme";

const AddFoods: React.FC = (props) => {
  const history: any = useHistory();
  const [form] = Form.useForm();
  const [resultTypeIds, setResultTypeIds] = useState<any>([
    {
      id: undefined,
    },
  ]);

  const addFoods = (body: any) => {
    menuService
      .addFoods(body)
      .then(() => {
        history.goBack();
        Toastify.showSuccessToast("Add foods success !");
        form.resetFields();
      })
      .catch((error) => {
        Toastify.showDangerToast(error?.response?.data?.messages?.[0]);
      });
  };

  const _handleBackAllFood = () => {
    history.goBack();
  };

  return (
    <StyledFood.AddFood>
      <Flex full>
        <FlexItem>
          <GroupTitle
            fontSize={16}
            icon={
              <ArrowBackOutlineIcon
                color={Theme.global.whiteColor}
                onClick={_handleBackAllFood}
              />
            }
            padding={5}
          >
            Add Food
          </GroupTitle>
        </FlexItem>
      </Flex>
      <Flex>
        <Form name="basic" form={form}>
          <Form.Item>
            <FormFoods
              isCheck={true}
              submitForm={addFoods}
              form={form}
              resultTypeIds={resultTypeIds}
              setResultTypeIds={setResultTypeIds}
            />
          </Form.Item>
        </Form>
      </Flex>
    </StyledFood.AddFood>
  );
};

export default AddFoods;
