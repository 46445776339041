import React from "react";
import StyledVendorForm from "../styled";
import Input from "components/shared/Input";
import GroupTitle from "components/shared/GroupTitle";
import Flex, { FlexItem } from "components/shared/Flex";
import Form from "components/shared/Form";

interface IPointOfContact {}

const PointOfContact: React.FC<IPointOfContact> = () => {
  return (
    <StyledVendorForm>
      <GroupTitle
        fontSize={18}
        style={{ paddingBottom: "12px", paddingTop: "23px", marginLeft: "0" }}
      >
        Point Of Contact
      </GroupTitle>
      <Flex>
        <FlexItem basis="33.3%">
          <Form.Item
            name="pocName"
            rules={[{ required: true, message: "Please input your Name!" }]}
          >
            <Input placeholder="Name"  />
          </Form.Item>
        </FlexItem>
        <FlexItem style={{ padding: "0 10px" }} basis="25%">
          <Form.Item
            name="pocPhone"
            rules={[
              { required: true, message: "Please input your Direct Number!" },
            ]}
          >
            <Input placeholder="Direct Number"  />
          </Form.Item>
        </FlexItem>
        <FlexItem basis="33.3%">
          <Form.Item
            name="pocEmail"
            rules={[
              {
                type: "email",
                required: true,
                message: "Please input your Direct Email Address!",
              },
            ]}
          >
            <Input placeholder="Direct Email Address" />
          </Form.Item>
        </FlexItem>
      </Flex>
    </StyledVendorForm>
  );
};

export default PointOfContact;
