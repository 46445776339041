import styled from "styled-components";
import { getCss } from 'utils/cssHelpers';

const StyledFood: any = styled.div``;

StyledFood.AllFood = styled.div`
  & {
    .active-tabs {
      ${getCss('color')('theme.global.primaryColor')};
    }
    .btn-custome {
      border-radius: 50px;
      text-align: center;
      line-height: 0;
      width: 200px;
      height: 40px;
    }
    .ant-table-title {
      background-color: ${props => props.theme.table.headeBgColor} !important;
    }
    .ant-table-thead > tr > .ant-table-cell {
      ${getCss('color')('theme.global.whiteColor')};
      background-color: ${props => props.theme.global.primaryColor} !important;
    }
    .ant-table-tbody {
      tr.ant-table-row:hover > td {
        ${getCss('background-color')('theme.global.colorTransparent')} !important;
      }
      tr[data-row-key="3"],
      tr[data-row-key="4"] {
        .ant-table-cell > span {
          display: table-caption;
          margin-bottom: 2px;
        }
      }
      tr:nth-child(1),
      tr:nth-child(3),
      tr:nth-child(5),
      tr:nth-child(7),
      tr:nth-child(9) {
        background-color: ${props => props.theme.table.theadBgColor} !important;
      }
      .ant-table-cell {
        padding: 50px 17px !important;
        font-size: 14px;
        font-weight: 500;
      }
      .ant-table-cell:nth-child(5) {
        span {
          display: block;
          width: 0;
          margin-bottom: 3px;
        }
      }
    }
    .box-tags-status {
      display: table-caption;
      line-height: 2;
    }
  }
`;

StyledFood.AddFood = styled.div`
  & {
    padding-top: 38px;
    .styled-title-add-food {
      padding-bottom: 27px;
    }
    .notes-top {
      font-size: 13px;
      font-weight: normal;
      line-height: 1.3;
      margin: 0;
      padding: 17px 0 5px 0;
      ${getCss('color')('theme.global.grayColor')};
    }
    .add-food-image {
      width: 350px;
      padding: 90px 50px;
      ${getCss('background')('theme.global.whiteColor')};
      text-align: center;
      .note-add-food-image {
        margin: 0;
        white-space: nowrap;
        font-size: 14px;
        font-weight: normal;
        line-height: 1.3;
        ${getCss('color')('theme.global.grayColor')};
        padding-top: 10px;
      }
    }
    .custom-btn-add-food {
      border-radius: 50px;
      line-height: 0;
      width: 200px;
      height: 40px;
    }
    .notes-bottom {
      ${getCss('color')('theme.global.grayColor')};
    }
    .ant-form-item {
      margin-bottom: 0px !important;
    }
  }
`;

export default StyledFood;
