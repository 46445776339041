import styled from "styled-components";
import { getCss } from "utils/cssHelpers";

const WrapCouriersForm: any = styled.div`
    .title-create-account {
        padding-bottom: 39px;
        font-size: 16px !important;
    }
    svg {
        vertical-align: middle;
    }
    .customer-btn-save {
        padding: 6px 54px;
        line-height: 0;
    }
    .bg-color {
        ${getCss('background-color')('theme.global.primaryColor')};
        padding: 20px;
    }
    .wrapper-title-add {
        font-size: 18px;
        font-weight: 500;
        ${getCss('color')('theme.global.grayColor')};
        margin-bottom: 12px;
    }
    .wrapper-title-edit {
        font-size: 18px;
        font-weight: 500;
        ${getCss('color')('theme.global.whiteColor')};
        margin-bottom: 12px;
    }
    .wrapper-courier-details {
        .wrap-ddl-input {
            width: 33.3333%;
            &:nth-child(2) {
                margin-left: 10px;
                margin-right: 10px;
            }
        }
    }
    .wrapper-add-region {
        font-size: 14px;
        font-style: italic;
        ${getCss('color')('theme.global.grayColor')};
        cursor: pointer;
        margin-left: 20px;
    }
    .wrapper-region {
        .ant-select {
            &:first-child {
                margin-top: 0;
            }
        }
        .ant-select-selector {
            box-shadow: rgb(0 0 0 / 15%) 1.95px 1.95px 2.6px;
            min-height: 40px;
        }
    }
    .ant-form-item-control {
        margin-bottom: 28px;
    }
    .wrapper-password {
        .ant-form-item-control {
            margin-bottom: 0;
        }
        .wrap-ddl-input {
            width: 100%;
            margin-bottom: 12px;
            &:first-child {
                margin-right: 20px;
            }
        }
    }
    .btn-generate-password {
        font-size: 12px;
        font-style: italic;
        background-color: transparent;
        border: 0;
        box-shadow: none;
        padding: 0;
        display: block;
        margin-bottom: 35px;
        ${getCss('color')('theme.global.grayColor')};
        height: auto;
        span {
            text-decoration: underline;
        }
    }
    .ant-select-selector {
        min-height: 40px;
        box-shadow: rgb(0 0 0 / 15%) 1.95px 1.95px 2.6px;
    }
`;

WrapCouriersForm.Generate = styled.div`
    padding: 12px 0 29px 0;
    font-size: 12px;
    cursor: pointer;
    text-decoration: underline;
    font-family: Montserrat;
    color: ${props => props.theme.menu.textColor};
`;

export default WrapCouriersForm;
