// @ts-nocheck
import React from 'react';
import { Form as AntForm, FormProps as AntFormProps} from 'antd';
import { FormItemProps } from './FormItem';

interface FormProps extends AntFormProps {}

type FormExt = { Item: React.FC<FormItemProps> }

const Form: React.FC<FormProps> & FormExt = ({
    children,
    props
}) => {
    return (
    <AntForm className='ddl-form' {...props}>
        {children}
    </AntForm>
    )
}

export default Form;
