import React from 'react';
import WrapOverview from './styled';
import GroupTitle from 'components/shared/GroupTitle';
import Table from 'components/shared/Table';
import Flex from 'components/shared/Flex';
import Switch from 'components/shared/Switch';
import Button from 'components/shared/Button';
import { useHistory } from 'react-router-dom';

const _handleChangeSwitch = (e: boolean) => {}

const _handleActions = (e: any) => {
  e.stopPropagation();
}

const renderSwitchStatus = (value: any) => {
  return <Switch type={value} onChange={(e) => _handleChangeSwitch(e)}></Switch>
};

const dataSource = [
  {
    key: '1',
    method: 'Paypal',
    enabled: (
      <span onClick={e => { e.preventDefault(); e.stopPropagation(); }}>
        {renderSwitchStatus('info')}
      </span>
    ),
    description: 'PayPal Standard redirects customers to PayPal to enter their payment information.	',
    action: <Button type='delete' onClick={_handleActions}>Set Up</Button>,
  },
  {
    key: '2',
    method: 'Braintree - Credit Card',
    enabled: (
      <span onClick={e => { e.preventDefault(); e.stopPropagation(); }}>
        {renderSwitchStatus('primary')}
      </span>
    ),
    description: 'Allow customers to securely pay using their credit card via Braintree.',
    action: <Button type='primary' onClick={_handleActions}>Manage</Button>,
  },
  {
    key: '3',
    method: 'Braintree - Paypal',
    enabled: (
      <span onClick={e => { e.preventDefault(); e.stopPropagation(); }}>
        {renderSwitchStatus('primary')}
      </span>
    ),
    description: 'Allow customers to securely pay using their PayPal account via Braintree.',
    action: <Button type='primary' onClick={_handleActions}>Manage</Button>,
  },
  {
    key: '4',
    method: 'Add Payment  1',
    enabled: (
      <span onClick={e => { e.preventDefault(); e.stopPropagation(); }}>
        {renderSwitchStatus('info')}
      </span>
    ),
    description: 'Add any payment gateway as your preferred payment method.',
    action: <Button type='delete' onClick={_handleActions}>Set Up</Button>,
  },
  {
    key: '5',
    method: 'Add Payment 2',
    enabled: (
      <span onClick={e => { e.preventDefault(); e.stopPropagation(); }}>
        {renderSwitchStatus('info')}
      </span>
    ),
    description: 'Add any payment gateway as your preferred payment method.',
    action: <Button type='delete' onClick={_handleActions}>Set Up</Button>,
  },
];

const columns = [
  {
    title: 'Method',
    dataIndex: 'method',
    key: 'method',
    action: '10 Downing Street',
  },
  {
    title: 'Enabled',
    dataIndex: 'enabled',
    key: 'enabled',
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: null,
    dataIndex: 'action',
    key: 'action',
  },
];

const PaymentSetup: React.FC = () => {
  const history = useHistory();
  const _handleClickDetails = (record: any) => {
    history.push(`/finance-payment/payment-setup-from/${record.key}`);
  }
  
  return (
    <WrapOverview.PaymentSetup>
      <GroupTitle fontSize={14} style={{ paddingBottom: '15px', marginLeft: '0', fontStyle: 'italic' }}>
        Installed payment methods are listed below and can be sorted to control their display order on the frontend.
      </GroupTitle>
      <Flex>
        <Table
          css={`flex: 1`}
          columns={columns}
          dataSource={dataSource}
          className='custome-table'
          onRow={(record) => {
            return {
              onClick: () => _handleClickDetails(record),
            };
          }}
        />
      </Flex>
    </WrapOverview.PaymentSetup>
  );
}

export default PaymentSetup;
