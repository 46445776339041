import React, { useState, useEffect } from 'react'
import WrapCustomers from './styled'
import InputSearch from 'components/shared/InputSearch';
import Flex from 'components/shared/Flex';
import Table from 'components/shared/Table';
import { useHistory } from 'react-router';
import { columns, IPropsCustomers } from "./finance-customer.util";
import { ArrowForwardIcon } from 'components/shared/Icons';
import financePaymentService from "services/finance-payment.service";

const CustomersUI: React.FC = () => {
  const history = useHistory();
  const [customers, setCustomers] = useState<Array<any>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [searchCustomers, setSearchCustomers] = useState<Array<any>>([]);

  const listCustomers = () => {
    financePaymentService.getListCustomer().then((data) => {
      setIsLoading(false);
      const result = data?.map((item: IPropsCustomers, index: number) => ({
          key: item.id,
          CustomerName: item.name,
          POC: item.poc,
          Contact: item.contact,
          Email: item.email,
          BillingAddress: item.billingAddress,
          Details: <ArrowForwardIcon />,
      }));
      setCustomers(result);
      setSearchCustomers(result);
    })
  }

  useEffect(() => {
    listCustomers();
  }, []);

  const _handleSearch = (e: any) => {
    const value = e.target.value.toLowerCase();
    var dataSourceFilter = searchCustomers;
    if (value !== '') {
      const listKeysSearch: Array<string> = ['CustomerName', 'POC', 'Contact', 'Email', 'BillingAddress'];
      const checkItem = (itemData: any) => {
        if (listKeysSearch.some(key => itemData[key].toLowerCase().indexOf(value) !== -1)) {
          return itemData;
        } else {
          return null;
        }
      }
      dataSourceFilter = searchCustomers.map((item: any) => checkItem(item)).filter((item: any) => item !== null);
    }
    setCustomers(dataSourceFilter);
  };

  const handleClickCustomer = (record: any) => {
    history.push({
      pathname: `/finance-payment/payments-by-customers-details/${record.key}`,
      state: { key: record?.key }
    });
  };

  return (
    <WrapCustomers>
      <Flex justifyBetween alignCenter className="header-settlement">
        <div>Accounts Receivable By Customer</div>
        <InputSearch
          placeholder="Search Settlement"
          onInput={e => _handleSearch(e)}
          className="input-search-settlement"
        />
      </Flex>
      {
        isLoading
          ? "Loading..."
          : (
            <Table
              dataSource={customers}
              columns={columns}
              pagination={false}
              className="table-settlement"
              onRow={(record) => {
                return {
                  onClick: () => handleClickCustomer(record),
                };
              }}
            />
          )
      }
    </WrapCustomers>
  );
}

export default CustomersUI;
