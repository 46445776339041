import React from "react";
import WrapAddRessGird from "./styled";
import Flex, { FlexItem } from "components/shared/Flex";
import { Row, Col } from 'antd';
import Theme from "theme";

export interface IPropsAddRess {
  dataInfo: any
  span: number
}

const AddRessGird: React.FC<IPropsAddRess> = ({
  dataInfo,
  span
}) => {
  return (
    <WrapAddRessGird>
      <Flex style={{ flexWrap: "wrap", backgroundColor: `${Theme.global.tertiaryColor}`, padding: "10px" }}>
        <FlexItem basis="100%">
          <Row>
            {dataInfo.map((item: any, index: number) => (
              <Col span={span} key={index}>
                <h3 className="title-address">
                  {item.title}
                </h3>
                <p className="content-address">
                  {item.content}
                </p>
              </Col>
            ))}
          </Row>
        </FlexItem>
      </Flex>
    </WrapAddRessGird>
  )
}

export default AddRessGird;
