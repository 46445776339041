import React, { useEffect } from "react";
import { Form } from "antd";
import CustomersForm from "../customers-form";
import customersService from "services/customers.service";
import { useHistory, useParams } from "react-router";
import { Toastify } from "components/shared/Toast/Toast";

const EditCustomers: React.FC = () => {
  const [form] = Form.useForm();
  const history: any = useHistory();
  const { id } = useParams<{ id: string }>();
  const userId = Number(id);

  const getcustomers = (id: number) => {
    customersService.getDetailsCorporates(id).then((customers = {}) => {
      form.setFields([
        {
          name: ["name"],
          value: customers.name
        },
        {
          name: ["uenNumber"],
          value: customers.uenNumber
        },
        {
          name: ["email"],
          value: customers.user.email
        },
        {
          name: ["phoneNumber"],
          value: customers.phoneNumber
        },
        {
          name: ["registeredAddress"],
          value: customers.registeredAddress
        },
        {
          name: ["postalCode"],
          value: customers.postalCode
        },
        {
          name: ["industryId"],
          value: customers.industry.name
        },
        {
          name: ["pocName"],
          value: customers.user.pointOfContacts[0]?.name
        },
        {
          name: ["pocNumber"],
          value: customers.user.pointOfContacts[0]?.phoneNumber
        },
        {
          name: ["pocEmail"],
          value: customers.user.pointOfContacts[0]?.email
        },
        {
          name: ["corporateId"],
          value: customers.corporateId
        }
      ]);
    });
  }

  useEffect(() => {
    getcustomers(userId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const editCustomers = (body: any) => {
    customersService.editCustomers(body, userId)
    .then(() => {
      Toastify.showSuccessToast("Edit success!");
      history.push('/manage-customers/new');
    })
    .catch((err) =>
      Toastify.showDangerToast(err?.response?.data?.messages?.[0] || "Can't update Corporate")
    );
  }

  return (
    <>
      <Form name="basic" form={form}>
        <Form.Item>
          <CustomersForm
            isCheck={false}
            submitForm={editCustomers}
            form={form}
          />
        </Form.Item>
      </Form>
    </>
  );
}

export default EditCustomers;
