import React from 'react';
import { Input as AntInput, InputProps as AntInputProps } from 'antd';
import { SearchOutlineIcon } from '../Icons';

export interface InputProps extends AntInputProps {}

const InputSearch: React.FC<InputProps> = (props) => {
  return (
    <AntInput className='ddl-input-search' prefix={<SearchOutlineIcon />} { ...props } />
  )
}

export default InputSearch