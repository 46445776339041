import React from "react";
import Input from "components/shared/Input";
import Flex, { FlexItem } from "components/shared/Flex";
import GroupTitle from "components/shared/GroupTitle";
import Form from "components/shared/Form";

interface IPropsPointOfContact {
  title: string
}

const PointOfContact: React.FC<IPropsPointOfContact> = ({
  title
}) => {
  return (
    <>
      <GroupTitle fontSize={18} style={{ paddingBottom: '12px', paddingTop: '23px', marginLeft: '0' }}>
        {title}
      </GroupTitle>
      <Flex>
        <FlexItem basis='33.3%'>
          <Form.Item
            name="pocName"
            rules={[{ required: true, message: 'Please input your pocName!' }]}
          >
            <Input placeholder="pocName" />
          </Form.Item>
        </FlexItem>
        <FlexItem style={{ padding: '0 10px' }} basis='25%'>
          <Form.Item
            name="pocNumber"
            rules={[{ required: true, message: 'Please input your Direct Number!' }]}
          >
            <Input placeholder="Direct Number" />
          </Form.Item>
        </FlexItem>
        <FlexItem basis='33.3%'>
          <Form.Item
            name="pocEmail"
            rules={[{ required: true, type: "regexp",
            pattern: new RegExp("^[0-9]*$"), message: 'Please input your Direct Email Address!' }]}
          >
            <Input placeholder="Direct Email Address" />
          </Form.Item>
        </FlexItem>
      </Flex>
    </>
  );
}

export default PointOfContact;
