import styled from 'styled-components';
import InputTimePicker from './InputTimePicker';
import { getCss } from 'utils/cssHelpers';

const StyledInputTimePicker= styled(InputTimePicker)`
    &.ant-picker {
        border: 0;
        padding: 0;
        border-radius: 0;
    }
    .ant-picker-input {
        input {
            height: 40px;
            padding: 10px;
            ${getCss('background-color')('theme.global.colorCbcbcb')};
            border-radius: 0;
            font-size: 16px;
            font-weight: 500;
            color: #ffffff;
            text-align: center;
            &::placeholder {
                color: #ffffff;
                opacity: 1;
            }
        }
        .ant-picker-suffix,
        .ant-picker-clear {
            display: none;
        }
    }
`

export default StyledInputTimePicker