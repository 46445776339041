import styled from 'styled-components'
import { getCss } from 'utils/cssHelpers'

const StyledTabsLink = styled.div`
    a {
        font-size: 14px;
        line-height: 1.2;
        font-weight: normal;
        display: inline-block;
        ${getCss('color')('theme.global.blackColor')};
        padding: 18px 18px;
        &:not(:first-child) {
            margin-left: 30px;
        }
        &.active {
            border-bottom: 3px solid;
            ${getCss('border-color')('theme.global.primaryColor')};
        }
    }
`

export default StyledTabsLink