import { Toastify } from "components/shared/Toast/Toast";
import { BaseAPI } from "core/services/BaseAPI";
export interface ICustomersServices {
  getAllCorporates(): void;

  postCustomers(data: Object): void;
  editCustomers: (data: Object, userId: number) => {};

  getDetailsCorporates(userId: number): any;
  deleteDetailsCorporates(userId: number): any;

  getAllLocations: (userId: number) => {};
  getAllEmployeesOfCorporate(userId: number): void;
}

type TypeAddLocation = {
  name: string;
  address: string;
  capacity: number;
  dateFrom: string;
  dateTo: string;
  isMainLocation: boolean;
  regionId: number;
};
class CustomersService extends BaseAPI implements ICustomersServices {
  async getAllCorporates() {
    try {
      const getCorporatesCustomers = await this.get(
        "manage-customers/corporates"
      );
      return getCorporatesCustomers;
    } catch (error) {
      console.log("error :", error);
    }
  }

  async getDetailsCorporates(userId: number) {
    try {
      const getDetailsCorporatesCustomers = await this.get(
        `manage-customers/corporates/${userId}/detail`
      );
      return getDetailsCorporatesCustomers;
    } catch (error) {
      console.log("error :", error);
    }
  }

  async editCustomers(data: Object, userId: number) {
    return await this.patch(`manage-customers/corporates/${userId}`, data);
  }

  async postCustomers(data: Object) {
    return await this.post("manage-customers/corporates", data);
  }

  async deleteDetailsCorporates(userId: number) {
    try {
      const deleteUser = await this.delete(
        `manage-customers/corporates/${userId}`
      );
      return deleteUser;
    } catch (error) {
      console.log("error :", error);
    }
  }

  async getAllLocations(userId: number) {
    try {
      const resultAllLocation = await this.get(
        `manage-customers/corporates/${userId}/locations`
      );
      return resultAllLocation;
    } catch (error) {
      console.log("error :", error);
    }
  }

  async getAllEmployeesOfCorporate(userId: number) {
    try {
      const resultAllEmployees = await this.get(
        `manage-customers/corporates/${userId}/individuals`
      );
      return resultAllEmployees;
    } catch (error) {
      console.log("error :", error);
    }
  }

  deleteLocationOfUser(userId: number, locationId: number) {
    return this.delete(
      `manage-customers/corporates/${userId}/locations/${locationId}`
    );
  }

  async addLocationOfUser(userId: number, params: TypeAddLocation) {
    try {
      const addLocation = await this.post(
        `manage-customers/corporates/${userId}/locations`,
        params
      );
      return addLocation;
    } catch (error) {
      console.log("error :", error);
    }
  }

  async editLocationOfUser(
    userId: number,
    locationID: number,
    params: TypeAddLocation
  ) {
    try {
      const location = await this.patch(
        `manage-customers/corporates/${userId}/locations/${locationID}`,
        params
      );
      return location;
    } catch (error) {
      console.log("error :", error);
    }
  }

  async getEmployeeDetails(userId: number) {
    try {
      return await this.get(`manage-customers/individuals/${userId}/detail`);
    } catch (error) {
      console.log("error :", error);
    }
  }

  async editEmployeeDetails(userId: number, params: Object) {
    try {
      return await this.patch(`manage-customers/individuals/${userId}`, params);
    } catch (error) {
      console.log("error :", error);
    }
  }

  async deleteEmployee(userId: number) {
    try {
      return await this.delete(`manage-customers/individuals/${userId}`);
    } catch (error) {
      console.log("error :", error);
    }
  }

  async getAllEmployees() {
    try {
      const resultAllEmployees = await this.get(`manage-customers/individuals`);
      return resultAllEmployees;
    } catch (error) {
      console.log("error :", error);
    }
  }

  async postCSV(userId: number, body: any) {
    try {
      const {
        data: { duplicateEmails, duplicateEmpIDs },
      } = await this.post(`manage-customers/corporates/${userId}/import`, body);
      const messages = [];
      if (duplicateEmails) {
        for (const email of duplicateEmails) {
          messages.push(`Email ${email} already exist`);
        }
      }
      if (duplicateEmpIDs) {
        for (const empID of duplicateEmpIDs) {
          messages.push(`ID ${empID} already exist, new ID has been generated`);
        }
      }
      if (messages.length) {
        Toastify.showWarningToast(messages.join("\n"));
      } else {
        Toastify.showSuccessToast("Create success!");
      }
    } catch (error) {
      Toastify.showDangerToast("Something not correct in csv file!");
    }
  }
}

export default new CustomersService();
