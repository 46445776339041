import styled from 'styled-components'
import { getCss } from 'utils/cssHelpers'
import Tag from './Tag'

const StyledTag = styled(Tag)`
  ${getCss('color')('theme.global.whiteColor')};
  font-weight: 500;
  background-color: ${props => props.theme.tag[props.type].bgColor};
  border-radius: 50px;
  padding: 4px 11px 4px 13px;
  min-width: 92px;
  min-height: 23px;
  line-height: 1.2;
  border: none;
  text-align: center;
`

StyledTag.defaultProps = {
  type: 'primary'
}

export default StyledTag