import React, { useState, useEffect } from "react";
import { ProcessingOrdersWrapper } from "./styled";
import DetailsReceipt from "components/shared/DetailsReceipt";
import OrdersForLocation from "components/shared/OrdersForLocation";
import GroupTitle from "components/shared/GroupTitle";
import { ArrowBackOutlineIcon } from "components/shared/Icons";
import Flex, { FlexItem } from "components/shared/Flex";
import { PersonCircleOutlineIcon } from "components/shared/Icons";
import orderService from "services/order.service";
import groupBy from "lodash/groupBy";
import {
  totalPrice,
  buildFoodLocation,
  totalQuantity,
} from "../manage-order.util";
import {
  orgType as ORG_TYPE,
  orderStatus as STATUS,
} from "../manage-orders.config";
import corpLogo from "assets/icons/corp.svg";
import Theme from "theme";

interface IDetailsPurchaseOrder {
  clickBack: (event?: Event) => void;
  order: any;
}

const DetailsPurchaseOrders: React.FC<IDetailsPurchaseOrder> = ({
  order,
  clickBack,
}) => {
  const [orderDetail, setOrderDetail] = useState<any>({});

  useEffect(() => {
    orderService.getOrder(order.orderId).then((order) => {
      const locationGroup = groupBy(order.items, (item) => item.location?.id);
      const customer = [
        {
          "Organisation Name": order.corporate?.name,
          "Registered Address": order.corporate?.registeredAddress,
        },
        {
          "Main Contact Number": order.corporate?.phoneNumber,
          "Email Address": order.corporate?.user?.email,
        },
        {
          "No. Of Order Location": Object.keys(locationGroup).length,
          "Total QTY + Adds": totalQuantity(order.items),
        },
      ];

      const vendor = [
        {
          "Vendor Name": order.vendor?.name,
          "Registered Address": order.vendor?.address,
        },
        {
          "Main Contact Number": order.vendor?.phone,
          "Email Address": order.vendor?.user?.email,
        },
      ];

      const foodLocation = {
        totalQTY: totalQuantity(order.items),
        totalCost: totalPrice(order.items),
        noOfLocation: Object.keys(locationGroup).length,
        data: buildFoodLocation(locationGroup),
      };

      setOrderDetail({
        vendor,
        customer,
        orders: foodLocation,
        status: order.status,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ProcessingOrdersWrapper.PurchaseOrder>
      <GroupTitle
        fontSize={16}
        icon={<ArrowBackOutlineIcon color="#fff" onClick={clickBack} />}
        padding={10}
        className="group-title-orders"
      >
        {order.corpName}
        <span style={{ position: "absolute", right: "40px" }}>
          Order No: {order.orderId}
        </span>
      </GroupTitle>

      <Flex
        justifyBetween
        className="header-details-receipt"
        css={"margin-top: 18px"}
      >
        <div className="title-details-receipt">
          Order Details ... Via{" "}
          <span className="text-underline">{order.mode}</span>
        </div>
        <Flex alignCenter>
          {order.mode === ORG_TYPE.individual ? (
            <PersonCircleOutlineIcon
              className="icon-person"
              width="50px"
              height="50px"
            />
          ) : (
            <img src={corpLogo} alt="corpLogo" />
          )}
          <div className="type-company">{order.mode}</div>
        </Flex>
      </Flex>

      <Flex>
        <FlexItem className="general-info" css={"margin-right: 7px"}>
          <div className="title-general-info">Customer</div>
          <DetailsReceipt dataInfo={orderDetail.customer} />
        </FlexItem>
        <FlexItem className="general-info" css={"margin-left: 7px"}>
          <div className="title-general-info">Vendor On This Day</div>
          <DetailsReceipt dataInfo={orderDetail.vendor} />
        </FlexItem>
      </Flex>

      <OrdersForLocation dataInfo={orderDetail.orders} />

      <div
        className="label-complete-order"
        style={{
          backgroundColor:
            orderDetail.status === STATUS.COMPLETED
              ? Theme.global.redColor
              : Theme.global.colorCfcfcf,
        }}
      >
        Order Complete
      </div>
    </ProcessingOrdersWrapper.PurchaseOrder>
  );
};

export default DetailsPurchaseOrders;

// const generalInfoCustomer = [
//   {
//     'Organisation Name': 'Company A',
//     'Registered Address': '456 Company B, #01-52, Pavillion Block, Singapore 123422',
//   },
//   {
//     'Main Contact Number': '+65 664839384',
//     'Email Address': 'admin@companyb.com',
//   },
//   {
//     'No. Of Order Location': '5',
//     'Total QTY + Adds': '640'
//   },
// ];

// const generalInfoVendor = [
//   {
//     'Vendor Name': 'Company A',
//     'Registered Address': '1 Buona Vista Road, Hawker Valley, #01-22, Singapore 243148',
//   },
//   {
//     'Main Contact Number': '+65 665739734',
//     'Email Address': 'sales@hawkervendora.com',
//   },
// ];

// const dataOrders = {
//   data: [
//     {
//       key: 1,
//       location: 'Boon Lay Construction Site  ',
//       deliveryLocation: 'Boon Lay Way East Ave 3, S 5837292',
//       totalOrders: 250,
//       billQTY: [
//         {
//           name: 'Chicken Hor Fun',
//           QTY: 200,
//           cost: 'Billed',
//         },
//         {
//           name: 'Chicken Soup',
//           QTY: 50,
//           cost: 'Billed',
//         },
//       ],
//       addOns: {
//       },
//     },
//     {
//       key: 2,
//       location: 'Bukit Batok Construction Site',
//       deliveryLocation: 'Bukit Batok East Ave 1, S947923',
//       totalOrders: 60,
//       billQTY: [
//         {
//           name: 'Steamed Chicken Rice',
//           QTY: 60,
//           cost: 'Billed',
//         },
//       ],
//       addOns: {}
//     },
//     {
//       key: 3,
//       location: 'Clementi Construction Site',
//       deliveryLocation: 'Clementi Drive 5, S394793',
//       totalOrders: 200,
//       billQTY: [
//         {
//           name: 'Roasted Chicken Rice',
//           QTY: 25,
//           cost: 'Billed',
//         },
//         {
//           name: 'Chicken Hor Fun',
//           QTY: 185,
//           cost: 'Billed',
//         },
//       ],
//       addOns: {}
//     },
//     {
//       key: 4,
//       location: 'Sengkang Construction Site',
//       deliveryLocation: 'Fernvale Drive, S382347',
//       totalOrders: 60,
//       billQTY: [
//         {
//           name: 'Steamed Chicken Rice',
//           QTY: 60,
//           cost: 'Billed',
//         },
//       ],
//       addOns: {}
//     },
//   ],
//   totalQTY: 640,
//   totalCost: '$100',
//   noOfLocation: 5,
// };
