import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {AllCouriersWrapper} from './styled';
import Button from 'components/shared/Button';
import {Table2} from 'components/shared/Table';
import {ArrowForwardIcon, FunnelSharpIcon} from 'components/shared/Icons';
import InputSearch from 'components/shared/InputSearch';
import Flex from 'components/shared/Flex';
import couriersService from 'services/couriers.service';

type ICourierProps = {
  id?: number;
  courierId?: string;
  name?: string;
  phoneNumber?: string;
  email?: string;
  user?: any;
}

const columns = [
  {
    title: 'No.',
    dataIndex: 'MenuNo',
    key: 'MenuNo',
  },
  {
    title: 'Courier ID',
    dataIndex: 'CourierID',
    key: 'CourierID',
  },
  {
    title: 'Courier Name',
    dataIndex: 'CourierName',
    key: 'CourierName',
  },
  {
    title: 'Contact Number',
    dataIndex: 'ContactNumber',
    key: 'ContactNumber',
  },
  {
    title: 'Email Address',
    dataIndex: 'EmailAddress',
    key: 'EmailAddress',
  },
  {
    title: <FunnelSharpIcon height="14px"/>,
    dataIndex: 'DetailsCourier',
    key: 'DetailsCourier',
  },
];

const AllCouriersUI: React.FC = () => {
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(true);
  const [listAllCouriers, setListAllCouriers] = useState<any>([]);
  const [allCouriers, setAllCouriers] = useState<any>([]);

  const getAllCouriers = async () => {
    couriersService.getAllCouriers().then((couriers) => {
      setLoading(false);
      if (couriers) {
        const listCouriers: any[] = couriers.map((row: ICourierProps, i: number) => ({
          key: row.user.id,
          MenuNo: row.id || i + 1,
          CourierID: row.courierId,
          CourierName: row.name,
          ContactNumber: row.phoneNumber || '',
          EmailAddress: row.user.email || '',
          DetailsCourier: <ArrowForwardIcon type="success"/>,
          user: row.user
        }))
        setAllCouriers(listCouriers);
        setListAllCouriers(listCouriers);
      }
    });
  };

  useEffect(() => {
    getAllCouriers();
  }, []);

  const handleClickCourier = (record: any) => {
    history.push({
      pathname: `/manage-couriers/courier-details/${record.key}`,
      state: {user: record.user}
    });
  };

  const handleSearchCouriers = (e: any) => {
    const value = e.target.value.toLowerCase();
    var dataSourceFilter = listAllCouriers;
    if (value !== '') {
      const arrKeysSearch = ['CompanyName', 'ContactNumber', 'Address', 'EmailAddress'];
      const checkItem = (itemData: any) => {
        if (arrKeysSearch.some(key => (itemData[key] || '').toLowerCase().indexOf(value) !== -1)) {
          return itemData;
        } else {
          return null;
        }
      }
      dataSourceFilter = listAllCouriers.map((item: any) => checkItem(item)).filter((item: any) => item !== null);
    }
    setAllCouriers(dataSourceFilter);
  };


  const _handleClickAddCourier = () => {
    history.push(`/manage-couriers/new`);
  };

  return (
    <AllCouriersWrapper>
      <Flex justifyBetween>
        <Flex>
          <Button type="info" className="btn-add-new-account btn-control" onClick={_handleClickAddCourier}>Add
            New</Button>
          <Button type="primary" className="btn-control">All Couriers</Button>
        </Flex>
        <InputSearch
          placeholder="Search Couriers"
          className="input-search-couriers"
          onInput={e => handleSearchCouriers(e)}
        />
      </Flex>
      {
        loading
          ? 'Loading...'
          : (
              <Table2
                dataSource={allCouriers}
                columns={columns}
                pagination={false}
                className="table-all-couriers"
                onRow={(record, rowIndex) => {
                  return {
                    onClick: () => handleClickCourier(record),
                  };
                }}
              />
            )
      }
    </AllCouriersWrapper>
  )
}

export default AllCouriersUI

