import React, { useState, useEffect } from "react";
import WrapDetailsEmployees from "./styled";
import Flex, { FlexItem } from "components/shared/Flex";
import Button from "components/shared/Button";
import { ArrowBackIcon } from "components/shared/Icons";
import DetailsInfo from "./components/DetailsInfo";
import { useHistory, useParams } from "react-router-dom";
import { Form } from "antd";
import customersService from "services/customers.service";

export interface FieldData {
  title?: string;
  name: string | number | (string | number)[];
  value?: any;
  touched?: boolean;
  validating?: boolean;
  errors?: string[];
}

const DetailsEmployeesUI: React.FC = (props) => {
  const history: any = useHistory();
  const [form] = Form.useForm();
  const { id }: any = useParams();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [employeeDetail, setEmployeeDetail] = useState<FieldData[]>([
    {
      title: "Employee Name",
      name: ["name"],
      value: "",
    },
    {
      title: "Main Contact Number",
      name: ["contactNumber"],
      value: "",
    },
    {
      title: "Email Address",
      name: ["email"],
      value: "",
    },
    {
      title: "Employee ID",
      name: ["employeeId"],
      value: "",
    },
    {
      title: "Password",
      name: ["password"],
      value: "************",
    },
  ]);

  const mapDataToState = async () => {
    customersService.getEmployeeDetails(id).then((res) => {
      const override: FieldData[] = employeeDetail.map((employee: any) => {
        if (employee.name[0] === "email") {
          employee.value = res.user.email;
        } else {
          employee.value = res[employee.name[0]];
        }
        return employee;
      });
      form.setFields(override);
      setEmployeeDetail(override);
    });
  };

  useEffect(() => {
    mapDataToState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBackAllCustomers = () => {
    history.goBack();
  };

  const _handleEditInformation = () => {
    setIsEdit(true);
  };

  const _handleClickSave = () => {
    setIsEdit(false);
    const params = form.getFieldsValue();
    customersService.editEmployeeDetails(id, params);
  };

  const _handleDeleteAccount = () => {
    customersService.deleteEmployee(id).then((res) => {
      if (res) {
        history.goBack();
      }
    });
  };

  return (
    <WrapDetailsEmployees>
      <Flex justifyBetween className="group-action">
        <Flex alignStretch>
          <ArrowBackIcon
            height="34px"
            width="34px"
            onClick={handleBackAllCustomers}
          />
          {isEdit ? (
            <Button
              type="primary"
              className={`btn-control btn-edit-account`}
              onClick={_handleClickSave}
            >
              Save
            </Button>
          ) : (
            <Button
              type="info"
              className="btn-edit-account"
              onClick={_handleEditInformation}
            >
              Edit Information
            </Button>
          )}
        </Flex>
        <Button
          type="delete"
          className="btn-delete-account"
          onClick={_handleDeleteAccount}
        >
          Delete Account
        </Button>
      </Flex>
      <Flex>
        <FlexItem
          css={`
            flex: 1;
          `}
        >
          <DetailsInfo
            form={form}
            dataInfo={employeeDetail}
            className="courier-details"
            title={isEdit ? "Edit Employee Details" : "Employee Details"}
            edit={isEdit}
            span={7}
            onChange={(newFields) => {
              setEmployeeDetail(newFields);
            }}
          />
        </FlexItem>
      </Flex>
    </WrapDetailsEmployees>
  );
};

export default DetailsEmployeesUI;
