import React from 'react'
import WrapOverview from './styled';
import CheckBox from 'components/shared/CheckBox';
import GroupTitle from 'components/shared/GroupTitle';
import { ArrowBackOutlineIcon } from 'components/shared/Icons';
import { useHistory } from 'react-router-dom';
import Flex, { FlexItem } from 'components/shared/Flex';
import Input from 'components/shared/Input';
import Textarea from 'components/shared/Textarea';
import Button from 'components/shared/Button';
import Theme from 'theme';
import { Form, Switch } from "antd";

const PaymentFrom: React.FC = () => {
  const history = useHistory();
  const [form] = Form.useForm();

  const _handleBackPaymentSetup = () => {
    history.push('/finance-payment/overview');
  }

  const onFinish = (values: any) => {
    const body = {
      disable: values.disable,
      title: values.title,
      description: values.description,
      cardVerification: values.cardVerification,
      tokenization: values.tokenization,
      cardLogo: values.cardLogo,
      declineMessage: values.declineMessage,
      merchantId: values.merchantId,
      publicKey: values.publicKey,
      privateKey: values.privateKey,
    }
    console.log("body :", body);
  }

  return (
    <WrapOverview.PaymentSetupFrom >
      <GroupTitle
        style={{ fontWeight: 'bold', color: `${Theme.global.color444444}` }}
        padding={5}
        fontSize={16}
        icon={<ArrowBackOutlineIcon onClick={_handleBackPaymentSetup} />}
      >
        Payment Method / Braintree (Credit Card)
      </GroupTitle>
      <Form
        form={form}
        onFinish={onFinish}
      >
        <Flex style={{ paddingTop: "34px", flexWrap: "wrap" }} alignCenter>
          <FlexItem basis='45%'>
            <Flex style={{ flexWrap: "wrap" }} css={`flex: 1`}>
              <FlexItem basis='30%'>Enable/Disable</FlexItem>
              <FlexItem basis='70%'>
                <Form.Item
                  name="disable"
                  valuePropName="checked"
                  rules={[{ required: false, message: "do you want Disable?" }]}
                >
                  <Switch className="style-switch-btn" />
                </Form.Item>
              </FlexItem>
            </Flex>
            <Flex style={{ paddingTop: "16px", flexWrap: "wrap" }} alignCenter>
              <FlexItem basis='30%'>Title</FlexItem>
              <FlexItem basis='70%'>
                <Form.Item
                  name="title"
                  rules={[{ required: true, message: "Please input Title!" }]}
                >
                  <Input className='customer-input' placeholder='Credit Card' />
                </Form.Item>
              </FlexItem>
            </Flex>
            <Flex style={{ paddingTop: '14px' }}>
              <FlexItem basis='30%'>Description</FlexItem>
              <FlexItem basis='70%'>
                <Form.Item
                  name="description"
                  rules={[{ required: true, message: "Please input Description!" }]}
                >
                  <Textarea className='customer-textarea' placeholder='Pay securely with your card' />
                </Form.Item>
              </FlexItem>
            </Flex>
            <Flex style={{ paddingTop: '14px' }} alignCenter>
              <FlexItem basis='30%'>Card Verification (CSC)</FlexItem>
              <FlexItem basis='70%'>
                <Form.Item
                  name="cardVerification"
                  valuePropName="checked"
                  rules={[{ required: false, message: "do you want Card Verification?" }]}
                >
                  <CheckBox>
                    Display and Require the Card Security <br />
                    Code (CVV/CID) field on checkout
                  </CheckBox>
                </Form.Item>
              </FlexItem>
            </Flex>
            <Flex style={{ paddingTop: '22px' }} alignCenter>
              <FlexItem basis='30%'>Tokenization</FlexItem>
              <FlexItem basis='70%'>
                <Form.Item
                  name="tokenization"
                  valuePropName="checked"
                  rules={[{ required: false, message: "do you want Tokenization?" }]}
                >
                  <CheckBox>
                    Allow customers to securely save their <br />
                    payment details for future checkout.
                  </CheckBox>
                </Form.Item>
              </FlexItem>
            </Flex>
          </FlexItem>
          <FlexItem basis='50%'>
            <Flex style={{ flexWrap: "wrap" }} alignCenter>
              <FlexItem basis='30%'>Accepted Card Logo</FlexItem>
              <FlexItem basis='70%'>
                <Form.Item
                  name="cardLogo"
                  rules={[{ required: true, message: "Please input Card Logo!" }]}
                >
                  <Input className='customer-input' placeholder='Visa ; MasterCard ; AMEX ; Maestro' />
                </Form.Item>
              </FlexItem>
            </Flex>
            <Flex alignCenter style={{ paddingTop: "14px", flexWrap: "wrap" }}>
              <FlexItem basis='30%'>Detailed Decline Message</FlexItem>
              <FlexItem basis='70%'>
                <Form.Item
                  name="declineMessage"
                  valuePropName="checked"
                  rules={[{ required: false, message: "do you want Decline Message?" }]}
                >
                  <CheckBox>
                    Check to enable detailed decline messages <br />
                    to the customer during checkout when <br />
                    possible, rather than a generic <br />
                    decline message.
                  </CheckBox>
                </Form.Item>
              </FlexItem>
            </Flex>
            <Flex alignCenter style={{ paddingTop: '14px', flexWrap: "wrap" }}>
              <FlexItem basis='30%'>Merchant ID</FlexItem>
              <FlexItem basis='70%'>
                <Form.Item
                  name="merchantId"
                  rules={[{ required: true, message: "Please input Merchant ID!" }]}
                >
                  <Input className='customer-input' placeholder='Dfvknssaklsk44v' />
                </Form.Item>
              </FlexItem>
            </Flex>
            <Flex alignCenter style={{ paddingTop: '14px', flexWrap: "wrap" }}>
              <FlexItem basis='30%'>Public Key</FlexItem>
              <FlexItem basis='70%'>
                <Form.Item
                  name="publicKey"
                  rules={[{ required: true, message: "Please input Public Key!" }]}
                >
                  <Input className='customer-input' placeholder='Vjkdnscuis84jkfs9' />
                </Form.Item>
              </FlexItem>
            </Flex>
            <Flex alignCenter style={{ paddingTop: '14px', flexWrap: "wrap" }}>
              <FlexItem basis='30%'>Private Key</FlexItem>
              <FlexItem basis='70%'>
                <Form.Item
                  name="privateKey"
                  rules={[{ required: true, message: "Please input Private Key!" }]}
                >
                  <Input className='customer-input' placeholder='*****************************' isVisible={true} />
                </Form.Item>
              </FlexItem>
            </Flex>
          </FlexItem>
        </Flex>
      <Button type='info' htmlType="submit" style={{ marginTop: '35px' }}>Add Payment Gateway</Button>
      </Form>
    </WrapOverview.PaymentSetupFrom>
  );
}

export default PaymentFrom;
