import React from "react";
import ForgotPasswordWrapper from "./styled";
import Flex, { FlexItem } from "components/shared/Flex";
import Button from "components/shared/Button";
import Logo from "assets/images/ddl-logo.png";
import { useHistory } from "react-router";
import { Form } from "antd";

const ResetPasswordSuccess: React.FC = (props) => {
  const history = useHistory();

  const _handleSubmitFrom = async ({ email }: any) => {
    history.push("/login");
  };

  return (
    <ForgotPasswordWrapper>
      <Flex
        css={`
          flex: 1;
        `}
        justifyCenter
        nowrap
      >
        <ForgotPasswordWrapper.Heading>
          <ForgotPasswordWrapper.Image src={Logo} alt="logo-ddlunch" />
          <ForgotPasswordWrapper.Description>
            Password Reset. Please check your email
          </ForgotPasswordWrapper.Description>
        </ForgotPasswordWrapper.Heading>
      </Flex>
      <Flex
        css={`
          flex: 1;
        `}
        justifyCenter
        nowrap
      >
        <FlexItem>
          <Form.Item>
            <Button
              onClick={_handleSubmitFrom}
              type="info"
              htmlType="submit"
              className="customer-btn mb"
            >
              Back To Login
            </Button>
          </Form.Item>
        </FlexItem>
      </Flex>
    </ForgotPasswordWrapper>
  );
};

export default ResetPasswordSuccess;
