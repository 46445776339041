import React from 'react';
import { Checkbox as AntCheckbox, CheckboxProps as AntCheckboxProps } from 'antd';
import StyleCheckBox from "./styled";

export interface CheckboxProps extends AntCheckboxProps {}

const CheckBox: React.FC<CheckboxProps> = (props) => {
  return (
    <StyleCheckBox>
      <AntCheckbox className='ddl-checkbox' {...props} />
    </StyleCheckBox>
    )
}

export default CheckBox;
