type IFoodsIdProps = {
  mainFoodsFId: [],
  fruitFoodsId: [],
}

const getFoodsById: IFoodsIdProps = {
  mainFoodsFId: [],
  fruitFoodsId: [],
}

const initState = {
  mainIdState: [getFoodsById.mainFoodsFId],
  fruitIdState: [getFoodsById.fruitFoodsId],
}

export default initState;
