import styled from 'styled-components';
import { getCss } from 'utils/cssHelpers';

const StyledActionsUserHeader: any = styled.div`
  & {
    .style-avatar {
      width: 38px;
      height: 38px;
      border-radius: 50px;
    }
    .ant-dropdown-trigger span svg {
      vertical-align: middle;
      height: 18px;
      width: 22px;
    }
    .name-user {
      font-size: 15px;
      font-weight: normal;
      margin: 0;
      ${getCss('color')('theme.global.color43425d')};
    }
  }
`;

StyledActionsUserHeader.StyledMenuItem = styled.div`
  & {
    .ant-menu {
      padding-top: 20px !important;
      position: absolute;
      right: -70px;
      width: 350px !important;
      top: 20px;
    }
    .ant-menu-item-only-child {
      box-shadow: 0 4px 6px -6px #222 !important;
    }
    .close-notification-popup {
      color: ${props => props.theme.global.color43425d} !important;
      svg {
        color: ${props => props.theme.global.color43425d} !important;
        fill: ${props => props.theme.global.color43425d} !important;
      }
    }
    .ant-menu-title-content a {
      font-size: 16px;
      font-weight: normal;
      color: ${props => props.theme.global.color43425d} !important;
    }
    .ant-menu:not(.ant-menu-horizontal) {
      .ant-menu-item-selected {
        background-color: ${props => props.theme.global.colorTransparent} !important;
      }
    }
  }
`;

StyledActionsUserHeader.StyledNotifications = styled.div`
  position: absolute;
  right: 0;
  width: 350px !important;
  top: 65px;
  z-index: 99;
  padding: 0 10px 10px 10px;
  ${getCss('background-color')('theme.global.whiteColor')};
  & {
    .close-notification-popup {
      color: ${props => props.theme.global.color43425d} !important;
      svg {
        color: ${props => props.theme.global.color43425d} !important;
        fill: ${props => props.theme.global.color43425d} !important;
      }
    }
    span svg {
      vertical-align: middle;
    }
    .content-notification {
      line-height: 2.3;
      margin-bottom: 10px;
      border-bottom: 1px solid ${props => props.theme.global.color707070};
      .text-notification {
        font-size: 14px;
        font-weight: normal;
        color: ${props => props.theme.global.color43425d};
        margin: 0;
        line-height: 2.3;
      }
      .sub-text-notification {
        font-size: 12px;
        font-weight: normal;
        margin-bottom: 10px;
        color: ${props => props.theme.global.color43425d};
      }
    }
    .styled-border {
      box-shadow: 0 4px 6px -6px #222 !important;
      margin-bottom: 10px;
    }
  }
`;

export default StyledActionsUserHeader;
