import styled from 'styled-components'
import { getCss } from 'utils/cssHelpers'

export const DetailsCourierWrapper = styled.div`
    svg {
        vertical-align: bottom;
    }
    .group-action {
        margin-bottom: 17px;
    }
    .courier-details {
        margin-bottom: 16px;
        .ant-row:last-child {
            .value-info {
                margin-bottom: 22px;
            }
        }
    }
    .table-all-orders-courier table {
        .ant-table-thead > tr > th:not(:first-child),
        .ant-table-tbody > tr > td:not(:first-child)
         {
            text-align: center;
        }
        .ant-table-tbody > tr > td {
            ${getCss('background-color')('theme.table2.bodyBgColor')};
            font-weight: 500;
        }
    }
    .btn-edit-account {
        margin-left: 20px;
    }
`