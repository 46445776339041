import development from './development'
import staging from './staging'
import production from './production'

import { EnvName } from './types'

const env = process.env.REACT_APP_NODE_ENV as EnvName;

const configs = {
  development,
  staging,
  production
}

export default configs[env]