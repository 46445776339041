import React from 'react';
import StyledGroup from './styled';
interface IGroupTitle {
    fontSize?: number;
    children?: any;
    icon?: any;
    padding?: number;
    style?: any;
    className?: string;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
}

const GroupTitle: React.FC<IGroupTitle> = ({
  fontSize,
  children,
  icon,
  padding,
  style,
  className,
  ...rest
}) => {
  return (
    <StyledGroup.WrapperTitle {...rest} className={className}>
      <StyledGroup.WrapperIcon padding={padding}>{icon}</StyledGroup.WrapperIcon>
      <StyledGroup.GroupLabel
        fontSize={fontSize}
        children={children}
        style={style}
      />
    </StyledGroup.WrapperTitle>
  );
}

export default GroupTitle;
