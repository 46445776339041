import React from "react";
import Table from "components/shared/Table";
import Button from "components/shared/Button";
import Flex, { FlexItem } from "components/shared/Flex";
import { columns } from "../dashboard.config";
import map from "lodash/map";
import { useHistory } from 'react-router';

const PayInSettlementReceive: React.FC<{ dashboard: any }> = ({
  dashboard,
}) => {
  const history = useHistory();

  const payInSettlement = map(
    dashboard.receivePayInSettlement,
    (item, index) => ({ ...item, key: index })
  );

  const manageCustomerPayments = () => {
    history.push("/finance-payment/customers");
  };

  return (
    <FlexItem
      css={`
        flex: 1;
      `}
    >
      <Table
        title={() => "Pay In Settlement (Receive)"}
        dataSource={payInSettlement}
        columns={columns}
        pagination={false}
        footer={() => (
          <Flex justifyEnd>
            <Button type="link" onClick={manageCustomerPayments}>Manage Customer Payments</Button>
          </Flex>
        )}
      />
    </FlexItem>
  );
};

export default PayInSettlementReceive;
