import React, { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";

import { debounce } from "lodash";

import config from "config";

import StyledFood from "./styled";
import { collumsFoods } from "./AllFood.config";
import menuService from "services/menu.service";

import Table3 from "components/shared/Table";
import Flex, { FlexItem } from "components/shared/Flex";
import InputSearch from "components/shared/InputSearch";
import Tag from "components/shared/Tag";
import Button from "components/shared/Button";
import Pagination from "components/shared/Pagination";

type IAllMenuProps = {
  id: number;
  photo: string;
  name: string;
  menu: any;
  types: any;
  calories: string;
  price: string;
};

const FOODS_PER_PAGE = 5;

const AllFoods: React.FC = (props) => {
  const history = useHistory();

  const [keyword, setKeyword] = useState("");
  const [current, setCurrent] = useState(1);
  const [data, setData] = useState<{
    items: any[];
    total: number;
  }>({
    items: [],
    total: 0,
  });

  const [loading, setLoading] = useState<boolean>(true);

  const getUrl = (url: any) => {
    if (url.startsWith("http")) {
      return url;
    }
    return config.hostName + url;
  };

  const changeKeyword = useMemo(() => {
    return debounce(setKeyword, 200);
  }, []);

  useEffect(() => {
    setLoading(true);
    menuService
      .getAllFoods(current - 1, FOODS_PER_PAGE, keyword)
      .then((result) => {
        if (result) {
          const resultAllFoods = result.items.map((row: IAllMenuProps) => ({
            key: row.id,
            image: getUrl(row.photo),
            food: row.id,
            name: row.name,
            category: row.menu.name,
            type: row?.types?.map((item: any, index: number) => {
              return (
                <Tag
                  type="primary"
                  style={{ backgroundColor: `${item.color}` }}
                  key={index}
                >
                  {item.name}
                </Tag>
              );
            }),
            calories: row.calories,
            price: `$${row.price}`,
          }));
          setData({
            items: resultAllFoods,
            total: result.total,
          });
        }
        setLoading(false);
      });
  }, [current, keyword]);

  const _handleSearch = (e: any) => {
    const value = e.target.value.toLowerCase();
    setCurrent(1);
    changeKeyword(value);
  };

  const _renderTabsTable = () => {
    return (
      <Flex alignCenter>
        <FlexItem className="active-tabs">All</FlexItem>
        <FlexItem style={{ padding: "0 28px" }}>Published</FlexItem>
        <FlexItem>Draft</FlexItem>
      </Flex>
    );
  };

  const _handleAddFood = () => {
    history.push("/manage-menu/all-foods/add-food");
  };

  const _manageFoodType = () => {
    history.push("/manage-menu/manage-food-type");
  };

  const editFoods = (record: any) => {
    history.push({
      pathname: `/manage-menu/all-foods/edit-food/${record.key}`,
      state: { key: record?.key },
    });
  };

  return (
    <StyledFood.AllFood>
      <Flex justifyBetween alignCenter style={{ padding: "38px 0 22px 0" }}>
        <FlexItem>
          <Button
            type="primary"
            className="btn-custome"
            onClick={_handleAddFood}
          >
            Add Food
          </Button>
          <Button
            type="primary"
            className="btn-custome"
            style={{ marginLeft: "10px" }}
            onClick={_manageFoodType}
          >
            Manage Food Type
          </Button>
        </FlexItem>
        <FlexItem>
          <InputSearch
            placeholder="Search Food In This Menu"
            onInput={(e) => _handleSearch(e)}
            style={{ minWidth: "300px" }}
          />
        </FlexItem>
      </Flex>
      <Flex>
        <FlexItem
          css={`
            flex: 1;
          `}
        >
          {loading ? (
            "Loading..."
          ) : (
            <>
              <Table3
                title={() => _renderTabsTable()}
                columns={collumsFoods}
                pagination={false}
                dataSource={data.items}
                onRow={(record) => {
                  return {
                    onClick: () => editFoods(record),
                  };
                }}
              />
              <Pagination
                onChange={setCurrent}
                total={data.total}
                current={current}
                pageSize={FOODS_PER_PAGE}
              />
            </>
          )}
        </FlexItem>
      </Flex>
    </StyledFood.AllFood>
  );
};

export default AllFoods;
