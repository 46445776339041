import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import DetailsCustomerWrapper from "./styled";
import Button from "components/shared/Button";
import {
  FunnelSharpIcon,
  ArrowForwardCircleIcon,
} from "components/shared/Icons";
import Tag, { TagProps } from "components/shared/Tag";
import Table, { Table2 } from "components/shared/Table";
import DetailsInfo from "components/shared/DetailsInfo";
import { ArrowBackIcon } from "components/shared/Icons";
import Flex, { FlexItem } from "components/shared/Flex";
import InputSearch from "components/shared/InputSearch";
import { Toastify } from "components/shared/Toast/Toast";
import Modal from "components/shared/Modal";
import ModalAdjustment from "./ModalAdjustment";
import ModalAddLocation from "./ModalAddLocation";
import customersService from "services/customers.service";
import orderService from "services/order.service";
import moment from "moment";
import userService from "services/user.service";
import theme from "theme";
import config from "config";

type IDetailCustomerProps = {
  id: number;
  name: string;
  email: string;
  user: any;
  phoneNumber: string;
  employeeId?: string;
  contactNumber?: string;
  address: string;
  capacity: string;
  dateFrom: any;
  dateTo: any;
  isMainLocation: boolean;
};

const renderTagStatus = (status: string) => {
  var type: TagProps["type"] = "primary";
  switch (status) {
    case "Delete":
    case "Unpaid":
      type = "danger";
      break;
    case "Completed":
      type = "primary";
      break;
    default:
      break;
  }

  return <Tag type={type}>{status}</Tag>;
};

const columnsPayInSettlement = [
  {
    title: "Customer Name",
    dataIndex: "customerName",
    key: "customerName",
  },
  {
    title: "For The Month",
    dataIndex: "month",
    key: "month",
  },
  {
    title: "INV",
    dataIndex: "invoice",
    key: "invoice",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (status: string) => renderTagStatus(status),
  },
];

const columnsUnpaidInvoices = [
  {
    title: "Customer Name",
    dataIndex: "customerName",
    key: "customerName",
  },
  {
    title: "For The Month",
    dataIndex: "month",
    key: "month",
  },
  {
    title: "INV",
    dataIndex: "invoice",
    key: "invoice",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (status: string) => renderTagStatus(status),
  },
];

// data for table: Unpaid Invoices (Payable)

const columnsDeliveryLocation = [
  {
    title: "Address",
    dataIndex: "Address",
    key: "Address",
  },
  {
    title: "Pax",
    dataIndex: "Pax",
    key: "Pax",
  },
  {
    title: "Any Period",
    dataIndex: "AnyPeriod",
    key: "AnyPeriod",
    render: (value: any) => {
      const arrDate: Array<any> = value.split(" - ");
      const dateFrom = moment(arrDate[0]).format("DD-MM-YYYY");
      const dateTo = moment(arrDate[1]).format("DD-MM-YYYY");
      return `${dateFrom} - ${dateTo}`;
    },
  },
  {
    title: "",
    dataIndex: "MainLocation",
    key: "MainLocation",
  },
  {
    title: "",
    dataIndex: "Edit",
    key: "Edit",
  },
];

// data for table: Unpaid Invoices (Payable)

const columnsPointOfContact = [
  {
    title: "Name",
    dataIndex: "Name",
    key: "Name",
  },
  {
    title: "Contact Number",
    dataIndex: "ContactNumber",
    key: "ContactNumber",
  },
  {
    title: "Email Address",
    dataIndex: "EmailAddress",
    key: "EmailAddress",
  },
];

// data employees details

const columnsEmployeesDetails = [
  {
    title: "No",
    dataIndex: "No",
    key: "No",
  },
  {
    title: "Employee ID",
    dataIndex: "EmployeeID",
    key: "EmployeeID",
  },
  {
    title: "Employee Name",
    dataIndex: "EmployeeName",
    key: "EmployeeName",
  },
  {
    title: "Contact Number",
    dataIndex: "ContactNumber",
    key: "ContactNumber",
  },
  {
    title: "Email Address",
    dataIndex: "EmailAddress",
    key: "EmailAddress",
  },
  {
    title: <FunnelSharpIcon />,
    dataIndex: "Next",
    key: "Next",
  },
];

const DetailsCustomerUI: React.FC = (props) => {
  const history: any = useHistory();
  const [loading, setLoading] = useState<boolean>(true);
  const [poinContact, setPoinContact] = useState([]);
  const [employees, setEmployees] = useState<Array<any>>([]);
  const [allEmployees, setAllEmployees] = useState<Array<any>>([]);
  const [detailsCorporates, setDetailsCorporates] = useState({});
  const [adjustment, setAdjustment] = useState<boolean>(false);
  const [isAddLocation, setIsAddLocation] = useState<boolean>(false);
  const [locationId, setLocationId] = useState<number>(0);
  const [unpaidInvoices, setUnpaidInvoices] = useState<Array<any>>([]);
  const [payInSettlement, setPayInSettlement] = useState<Array<any>>([]);
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [user, setUser] = useState<any>(null);
  const [isModal, setIsModal] = useState<boolean>(false);
  const [locationSelected, setLocationSelected] = useState<object>({});

  const [locationCorp, setLocationCorp] = useState([]);
  const { id } = useParams<{ id: string }>();
  const userId = Number(id);

  const deleteLocation = () => {
    customersService
      .deleteLocationOfUser(userId, locationId)
      .then(() => {
        Toastify.showSuccessToast("Delete success !");
        setIsModalVisible(false);
        getAllLocations();
      })
      .catch((error) => {
        Toastify.showDangerToast(error?.response?.data?.messages?.[0]);
      });
  };

  const openModalLocation = (locationId: number) => {
    setLocationId(locationId);
    setIsModal(false);
    setIsModalVisible(true);
  };

  const renderDeleteColOnLocationTable = (row: any) => {
    if (row.isMainLocation) {
      return "Main Location";
    }

    return (
      <Button
        size="small"
        type="danger"
        shape="round"
        onClick={() => openModalLocation(row.id)}
      >
        Delete
      </Button>
    );
  };

  const renderEditColOnLocationTable = (row: any) => {
    return (
      <span
        style={{ cursor: "pointer", textDecoration: "underline" }}
        onClick={() => {
          setLocationSelected(row);
          setIsAddLocation(true);
        }}
      >
        Edit
      </span>
    );
  };

  const getAllLocations = useCallback(() => {
    customersService.getAllLocations(userId).then((locationCorp) => {
      setLoading(false);
      setLocationCorp(
        locationCorp.map((row: IDetailCustomerProps) => ({
          key: row.id,
          Address: row.address,
          Pax: row.capacity,
          AnyPeriod: (function () {
            if (row?.dateFrom === null && row?.dateTo === null) {
              return "";
            } else {
              return `${row?.dateFrom + " - " + row?.dateTo}`;
            }
          })(),
          MainLocation: renderDeleteColOnLocationTable(row),
          Edit: renderEditColOnLocationTable(row),
        }))
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const _handleRenderDetailsCorporates = () => {
    userService.getMe().then((res) => {
      setUser(res);
    });
    customersService.getDetailsCorporates(userId).then((detailsCorporates) => {
      setLoading(false);
      setDetailsCorporates({
        name: {
          label: "Customer Details",
          value: detailsCorporates.name,
        },
        phoneNumber: {
          label: "Main Contact Number",
          value: detailsCorporates.phoneNumber,
        },
        registeredAddress: {
          label: "Registered Address",
          value: detailsCorporates.registeredAddress,
        },
        email: {
          label: "Email Address",
          value: detailsCorporates?.user?.email,
        },
        corporateId: {
          label: "Vendor ID",
          value: detailsCorporates.corporateId,
        },
        uenNumber: {
          label: "UEN Number",
          value: detailsCorporates.uenNumber,
        },
        industry: {
          label: "Industry",
          value: detailsCorporates?.industry?.name,
        },
        password: {
          label: "Password",
          value: "************",
        },
      });

      setPoinContact(
        detailsCorporates.user.pointOfContacts.map(
          (row: IDetailCustomerProps, index: number) => ({
            key: index,
            Name: row.name,
            ContactNumber: row.phoneNumber,
            EmailAddress: row.email,
          })
        )
      );
    });

    customersService.getAllEmployeesOfCorporate(userId).then((employees) => {
      setLoading(false);
      const result = employees?.map(
        (row: IDetailCustomerProps, index: number) => ({
          key: index,
          No: index + 1,
          EmployeeID: row.employeeId,
          EmployeeName: row.name,
          ContactNumber: row.contactNumber,
          EmailAddress: row?.user?.email,
          Next: (
            <ArrowForwardCircleIcon
              onClick={() => _handleEmployeeDetails(row)}
            />
          ),
        })
      );
      setEmployees(result);
      setAllEmployees(result);
    });

    getAllLocations();

    orderService.getUnpaidInvoices(userId).then((res) => {
      setLoading(false);
      const resultUnpaidInvoices = res.map((row: any, index: number) => ({
        key: row.no,
        customerName: row.customerName,
        month: row.forMonth,
        invoice: `INV${row.no}`,
        status: renderTagStatus(row.status),
      }));
      setUnpaidInvoices(resultUnpaidInvoices);
    });

    orderService.getPayInSettlement(userId).then((res) => {
      setLoading(false);
      const resultPayInSettlement = res.map((row: any, index: number) => ({
        key: index + 1,
        customerName: row.customerName,
        month: row.forMonth,
        invoice: `INV${row.no}`,
        status: renderTagStatus(row.status),
      }));
      setPayInSettlement(resultPayInSettlement);
    });
  };

  const searchEmployees = (e: any) => {
    const value = e.target.value.toLowerCase();
    var dataSourceFilter = allEmployees;
    if (value !== "") {
      const listKeysSearch: Array<string> = [
        "EmployeeID",
        "EmployeeName",
        "ContactNumber",
        "EmailAddress",
      ];
      const checkItem = (itemData: any) => {
        if (
          listKeysSearch.some(
            (key) => itemData[key].toLowerCase().indexOf(value) !== -1
          )
        ) {
          return itemData;
        } else {
          return null;
        }
      };
      dataSourceFilter = allEmployees
        .map((item: any) => checkItem(item))
        .filter((item: any) => item !== null);
    }
    setEmployees(dataSourceFilter);
  };

  useEffect(() => {
    _handleRenderDetailsCorporates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isSubmit) {
      getAllLocations();
      setIsSubmit(false);
    }
  }, [isSubmit, getAllLocations]);

  const handleBackAllCourier = () => {
    history.push("/manage-customers/all-customers");
  };

  const _handleEditInformation = () => {
    history.push({
      pathname: `/manage-customers/edit/${userId}`,
      state: { user: history?.location?.state?.user },
    });
  };

  const handleDeleteAccount = async () => {
    const deleteUser = await customersService.deleteDetailsCorporates(userId);
    if (deleteUser) {
      history.push("/manage-customers/all-customers");
      Toastify.showSuccessToast("Delete Success !");
    } else {
      Toastify.showDangerToast("Can't delete corporate");
    }
  };

  const openModalAccount = () => {
    setIsModal(true);
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  const _handleEmployeeDetails = (employee: any) => {
    history.push({
      pathname: `/manage-customers/employees-details/${employee.user.id}`,
    });
  };

  const detailInvoices = (record: any) => {
    history.push({
      pathname: `/finance-payment/payments-by-customers-details-inv/${id}/${record.key}`,
      state: { id: record.key },
    });
  };

  return (
    <DetailsCustomerWrapper>
      <Flex justifyBetween className="group-action">
        <Flex alignStretch>
          {user?.roles[0].id === "admin" && (
            <ArrowBackIcon
              height="34px"
              width="34px"
              onClick={handleBackAllCourier}
            />
          )}

          <Button
            type="info"
            className={user?.roles[0].id === "admin" ? "btn-edit-account" : ""}
            onClick={_handleEditInformation}
          >
            Edit Information
          </Button>
        </Flex>
        <Button
          type="delete"
          className="btn-delete-account"
          onClick={openModalAccount}
        >
          Delete Account
        </Button>
      </Flex>
      {loading ? (
        "Loading..."
      ) : (
        <DetailsInfo
          dataInfo={[detailsCorporates]}
          className="courier-details"
          title="Customer Details"
        />
      )}
      <div className="wrapp-point-of-contact">
        <div className="title-wrap">Point Of Contact</div>
        {loading ? (
          "Loading..."
        ) : (
          <Table2
            className="table-point-of-contact"
            dataSource={poinContact}
            columns={columnsPointOfContact}
            pagination={false}
          />
        )}
      </div>

      <div className="wrapp-employee-details">
        <div className="title-wrap">Employee Details</div>
        <Flex justifyBetween className="group-total-employee">
          <span>Total Number Of Employees</span>
          <span className="import-csv">
            <a
              href={`${
                config.baseApiURL + "manage-customers/corporates/template"
              }`}
              style={{
                textDecoration: "none",
                color: `${theme.global.whiteColor}`,
              }}
            >
              Download CSV template to add employee accounts
            </a>
          </span>
        </Flex>
        <Flex justifyBetween className="group-action-employee">
          <FlexItem>
            <span className="total-employees">{employees?.length || 0}</span>
            <Button type="primary" onClick={() => setAdjustment(true)}>
              Request For Adjustment
            </Button>
            <Modal
              visible={adjustment}
              onCancel={() => setAdjustment(false)}
              width="900px"
              style={{ padding: "20px 0" }}
              destroyOnClose
            >
              <ModalAdjustment
                userId={userId}
                onImportSuccess={() => {
                  _handleRenderDetailsCorporates();
                }}
              />
            </Modal>
          </FlexItem>
          <InputSearch
            placeholder="Search Employees"
            className="input-search-employee"
            onInput={(e) => searchEmployees(e)}
          />
        </Flex>
        {loading ? (
          "Loading..."
        ) : (
          <Table2
            className="table-employee-details"
            dataSource={employees}
            columns={columnsEmployeesDetails}
            pagination={false}
          />
        )}
      </div>
      {loading ? (
        "Loading..."
      ) : (
        <Table
          className="table-delivery-location"
          title={() => (
            <Flex justifyBetween>
              <span>Delivery Multiple Location Settings</span>
              <span
                onClick={() => {
                  setLocationSelected({});
                  setIsAddLocation(true);
                }}
                className="add-location"
              >
                + Add Location
              </span>

              <ModalAddLocation
                userId={userId}
                isAddLocation={isAddLocation}
                setIsAddLocation={(status: boolean) => setIsAddLocation(status)}
                isSubmit={isSubmit}
                setIsSubmit={(submit: boolean) => setIsSubmit(submit)}
                locationSelected={locationSelected}
              />
            </Flex>
          )}
          dataSource={locationCorp}
          columns={columnsDeliveryLocation}
          pagination={false}
        />
      )}
      <Flex>
        <FlexItem style={{ width: "100%" }}>
          {loading ? (
            "Loading..."
          ) : (
            <Table
              className="table-unpaid-invoices"
              title={() => "Unpaid Invoices (Payable)"}
              dataSource={unpaidInvoices}
              columns={columnsUnpaidInvoices}
              pagination={false}
              rowKey="customerName"
              onRow={(record) => {
                return {
                  onClick: () => detailInvoices(record),
                };
              }}
            />
          )}
        </FlexItem>
      </Flex>
      {user?.roles[0].id === "corporate" ? null : (
        <Flex>
          <FlexItem style={{ width: "100%" }}>
            {loading ? (
              "Loading..."
            ) : (
              <Table
                className="table-pay-in-settlement"
                title={() => "Pay In Settlement (Payable)"}
                dataSource={payInSettlement}
                columns={columnsPayInSettlement}
                pagination={false}
                rowKey="customerName"
                onRow={(record) => {
                  return {
                    onClick: () => detailInvoices(record),
                  };
                }}
              />
            )}
          </FlexItem>
        </Flex>
      )}
      <Modal visible={isModalVisible}>
        <Flex justifyCenter>
          <p className="content-request-for-payment">
            Do you want to delete {isModal ? "this customers?" : "location?"}.
          </p>
        </Flex>
        <Flex justifyEnd>
          <Button type="info" onClick={closeModal}>
            Cancel
          </Button>
          {isModal ? (
            <Button
              onClick={handleDeleteAccount}
              style={{ marginLeft: "15px" }}
            >
              OK
            </Button>
          ) : (
            <Button onClick={deleteLocation} style={{ marginLeft: "15px" }}>
              OK
            </Button>
          )}
        </Flex>
      </Modal>
    </DetailsCustomerWrapper>
  );
};

export default DetailsCustomerUI;
