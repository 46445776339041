import styled from "styled-components";
import { getCss } from 'utils/cssHelpers';

const StyledOrder: any = styled.div`
& {
}
`;

StyledOrder.OrderWrap = styled.div`
  .apexcharts-xaxis-title {
    text {
      ${getCss('fill')('theme.global.primaryColor')};
    }
  }
  & {
    .custome-btn {
      width: 150px;
      height: 30px;
      line-height: 0;
    }
    .active-btn {
      ${getCss('background')('theme.button.yellow.bgColor')};
      ${getCss('border-color')('theme.button.yellow.bgColor')};
      ${getCss('color')('theme.global.grayColor')};
    }
    .custome-btn-go {
      border-radius: 50px;
      margin-left: 20px;
    }
    .ml-20 {
      margin-left: 20px;
    }
    .ant-tabs-tab-btn, .ant-tabs-tab-active {
      font-weight: 600 !important;
      font-size: 16px !important;
      color: ${props => props.theme.global.grayColor} !important;
    }
  }
`;

StyledOrder.SalesOrderWrap = styled.div`
  padding-top: 24px;
  & {
    .ant-card-body {
      ${getCss('background')('theme.global.tertiaryColor')};
    }
  }
`;

StyledOrder.TotalOrderWrap = styled.div`
  padding-top: 24px;
  & {
    .ant-card-body {
      ${getCss('background')('theme.global.tertiaryColor')};
    }
  }
`;

export default StyledOrder;
