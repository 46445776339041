import styled from 'styled-components'

// interface IpropsKitchen {}

const AddNewCloudKitchenWrapper: any = styled.div`
  max-width: 100%;
  .styled-create-account {
    padding-bottom: 39px;
  }
`;

AddNewCloudKitchenWrapper.Block = styled.div`
  padding: 10px 0;
  width: 100%;
`;

AddNewCloudKitchenWrapper.Generate = styled.h3`
  padding: 12px 0 29px 0;
  font-size: 12px;
  cursor: pointer;
  text-decoration: underline;
  font-family: Montserrat;
  color: ${props => props.theme.menu.textColor};
`;

export default AddNewCloudKitchenWrapper;