import React from 'react';
import WrapCouriersForm from '../couriers-form/styled';
import {useHistory} from "react-router";
import CouriersForm from "../couriers-form";
import { Form } from "antd";
import couriersService from 'services/couriers.service';
import { Toastify } from "components/shared/Toast/Toast";

const AddNewCourierUI: React.FC = (props) => {
  const history: any = useHistory();
  const [form] = Form.useForm();

  const addCouriers = (body: any) => {
    couriersService.postCourier(body).then(() => {
      Toastify.showSuccessToast("Create courier account success!");
      form.resetFields();
      history.push('/manage-couriers/all-couriers');
    })
    .catch((error) => {
      Toastify.showDangerToast(error?.response?.data?.messages?.[0])
    })
  }

  return (
    <WrapCouriersForm>
      <Form
        name="basic"
        form={form}
      >
        <Form.Item>
          <CouriersForm
            isCheck={true}
            submitCouriers={addCouriers}
            form={form}
          />
        </Form.Item>
      </Form>
    </WrapCouriersForm>
  )
}

export default AddNewCourierUI;
