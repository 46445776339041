import React from 'react'
import ReportsWrapper from './styled'
import Order from './order';

const ReportsUI: React.FC = (props) => {
  return (
    <ReportsWrapper>
      <Order />
    </ReportsWrapper>
  )
}

export default ReportsUI
