import styled from 'styled-components';
import { getCss } from 'utils/cssHelpers';

const StyledInputDatePicker = styled.div`
    text-align: center;
    &.ant-picker {
        border: 0;
        padding: 0;
        border-radius: 0;
    }
    .ant-picker-focused {
        box-shadow: none;
    }
    .customer-input-date {
            background: unset;
            border: none;
        .ant-picker-input {
            input {
                height: 40px;
                padding: 10px;
                ${getCss('background-color')('theme.global.colorCbcbcb')};
                border-radius: 0;
                font-size: 16px;
                font-weight: 500;
                ${getCss('color')('theme.global.whiteColor')};
                text-align: center;
                &::placeholder {
                    ${getCss('color')('theme.global.whiteColor')};
                    opacity: 1;
                }
            }
            .ant-picker-suffix,
            .ant-picker-clear {
                display: none;
            }
        }
    }
`

export default StyledInputDatePicker;
