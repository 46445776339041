import styled from 'styled-components'
import { getCss } from 'utils/cssHelpers'

export const AllCustomersWrapper = styled.div`
    .active-btn {
        ${getCss('background-color')('theme.global.orangeColor')};
        ${getCss('border-color')('theme.global.orangeColor')};
    }
    .table-all-customer table {
        margin-top: 10px;
        .ant-table-thead .ant-table-cell,
        .ant-table-tbody > tr > td {
            font-size: 14px;
            font-weight: 500;
        }
        .ant-table-tbody > tr > td {
            cursor: pointer;
            ${getCss('color')('theme.global.grayColor')};
        }
    }
    .btn-control {
        width: 140px;
        &.btn-all-customer {
            margin-right: 5px;
            margin-left: 5px;
        }
    }
    .input-search-customer {
        width: 300px;
    }
`;