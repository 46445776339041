import React from 'react';
import { Form } from "antd";
import CustomersForm from "../customers-form";
import customersService from 'services/customers.service';
import { Toastify } from "components/shared/Toast/Toast";
import { useHistory } from "react-router";

const AddNewCustomerUI: React.FC = () => {
  const history = useHistory();
  const [form] = Form.useForm();

  const addCustomers = (body: any) => {
    customersService.postCustomers(body)
    .then(() => {
      Toastify.showSuccessToast("Create success!");
      form.resetFields();
      history.push('/manage-customers/all-customers');
    })
    .catch((error) => 
      Toastify.showDangerToast(error?.response?.data?.messages[0])
    );
  }

  return (
    <>
      <Form name="basic" form={form}>
        <Form.Item>
          <CustomersForm
            isCheck={true}
            submitForm={addCustomers}
            form={form}
          />
        </Form.Item>
      </Form>
    </>
  )
}

export default AddNewCustomerUI;
