import React from 'react'
import { Row as AntRow, RowProps as AntRowProps } from 'antd'

export interface RowProps extends AntRowProps {}

const Row: React.FC<RowProps> = (props) => {
  return (
    <AntRow className='ddl-row' { ...props } />
  )
}

export default Row