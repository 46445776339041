import React from 'react';
import { TimePicker as AntTimePicker, TimePickerProps as AntTimePickerProps } from 'antd';

interface TimePickerProps extends AntTimePickerProps {}

const InputTimePicker: React.FC<TimePickerProps> = (props: any) => {
  return <AntTimePicker className='ddl-input-time-picker' {...props} />
}

export default InputTimePicker
