// Table Foods Type
const collumsFoodsType = [
  {
    title: null,
    dataIndex: 'foodName',
    key: 'foodName',
    render(record: any) {
      return {
        props: {
          style: { background: record.color },
        },
      };
    },
  },
  {
    title: null,
    dataIndex: 'action',
    key: 'action',
  }
];
// Table Foods Type

const colors = [
  "#afb7cc",
  "#ee5b4d",
  "#ecb942",
  "#51b47a",
  "#5ed7c6",
  "#4db3a4",
  "#2549ac",
  "#584a8e",
  "#953a63",
  "#614238",
  "#3e4656",
  "#c9352a",
  "#e97e38",
  "#255a38",
  "#2b6a5f",
  "#2678bc",
  "#04237f",
  "#39265e",
  "#4b253f",
  "#3d2622",
  "#272a2f",
  "#95281f",
  "#a55a28",
  "#1d3c28",
  "#183e38",
  "#22659d",
  "#11245c",
  "#1f172f",
  "#271622",
  "#2c1410",
];

export { collumsFoodsType, colors };
