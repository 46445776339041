import styled from 'styled-components';
import { getCss } from 'utils/cssHelpers';

const StyledAllMenu: any = styled.div`
  padding-top: 38px;
  & {}
`;


StyledAllMenu.NewMenu = styled.div`
  & {
    .all-new-menu-title {
      font-size: 16px;
      font-weight: 700;
      ${getCss('color')('theme.tabs.textColor')};
      padding: 0 0 10px 0;
    }
    .wrapper-input {
      padding: 8px 0;
    }
    .custome-btn {
      border-radius: 50px;
      width: 200px;
      height: 40px;
    }
    .label-item {
      font-size: 14px;
      ${getCss('color')('theme.tabs.textColor')};
      font-weight: 400;
      line-height: 1.3;
      font-style: italic;
    }
  }
`;

StyledAllMenu.MenuTable = styled.div`
  & {
    .custome-table {
      padding-top: 30px;
      .ant-table-title {
        background: ${props => props.theme.table.headeBgColor} !important;
      }
      .ant-table-tbody, tr {
        cursor: pointer;
      }
    }
    .all-title-table {
      margin-top: 30px;
      padding: 6px 0 6px 22px !important;
      background: ${props => props.theme.table.headeBgColor} !important;
      color: ${props => props.theme.global.primaryColor} !important;
      font-size: 16px;
      font-weight: 500;
    }
    .custom-search {
      max-width: 300px;
      margin-left: auto;
    }
    .all-menu-name {
      margin: 0;
    }
    .all-menu-action { 
      line-height: 1.3;
      span {
        font-size: 10px;
        font-weight: normal;
        letter-spacing: normal;
        cursor: pointer;
        ${getCss('color')('theme.tabs.textColor')}
      }
    }
    .ant-table-thead {
      .ant-table-cell {
        background-color: ${props => props.theme.table2.theadBgColor} !important;
        padding: 17px !important;
        ${getCss('color')('theme.global.whiteColor')};
      }
    }
    .ant-table-tbody {
      tr.ant-table-row:hover > td {
        ${getCss('background-color')('theme.global.colorTransparent')} !important;
      }
      tr:nth-child(1),
      tr:nth-child(3),
      tr:nth-child(5),
      tr:nth-child(7),
      tr:nth-child(9) {
        background-color: ${props => props.theme.table.theadBgColor} !important;
      }
      .ant-table-cell {
        padding: 50px 17px !important;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
`;

export default StyledAllMenu;
