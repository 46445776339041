import styled from 'styled-components';

const AllVendors: any = styled.div`
  & {
   .btn-add-new {
     margin-right: 5px;
     padding: 6px 32px;
     line-height: 1.3;
     text-align: center;
   }
   .btn-all-vendors {
      padding: 6px 32px;
      line-height: 1.3;
      text-align: center;
   }
   .styled-search {
     min-width: 300px;
   }
  }
`;

export default AllVendors;