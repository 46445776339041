// @ts-nocheck
import {
    StyledTable as Table,
    Styled2Table as Table2,
    StyledTableColumn as TableColumn,
    Styled3Table as Table3
} from './styled'

Table.Column = TableColumn

export default Table

export { Table2, Table3 }