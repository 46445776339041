import React from 'react';
import { Row, Col } from '../Grid';
export interface DetailsInfoProps {
  dataInfo: Array<Record<string, { label: string; value: string }>>;
  className?: string;
  title?: string;
}

const DetailsInfo: React.FC<DetailsInfoProps> = ({
  dataInfo,
  className,
  title,
}) => {
  return (
    <div className={className}>
      {title ? <div className="title-info-details">{title}</div> : null}
      <div className="main-info-details">
        {dataInfo.map((item, index) => (
          <Row key={index}>
            {Object.keys(item).map((data) => (
              <Col span={6} key={data}>
                <div className="title-info">{item[data].label}</div>
                <div className="value-info">{item[data].value}</div>
              </Col>
            ))}
          </Row>
        ))}
      </div>
    </div>
  );
};
export default DetailsInfo;
