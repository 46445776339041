import { Toastify } from "components/shared/Toast/Toast";
import { BaseAPI } from "core/services/BaseAPI";

export interface IReportService {}

class FinancePaymentService extends BaseAPI implements IReportService {
  getMainMenuSales(param: string) {
    return this.get(`finance-and-payment/main-menu-sales?${param}`);
  }

  getAddonMainPax(param: string) {
    return this.get(`finance-and-payment/addon-sales?${param}`);
  }

  getTop10Addons(param: string) {
    return this.get(`finance-and-payment/top-10-addon-sales?${param}`);
  }

  getListCustomer() {
    return this.get("finance-and-payment/customers");
  }

  getDetailCustomer(id: number) {
    return this.get(`finance-and-payment/customers/${id}`);
  }

  getListCloudkitchens() {
    return this.get("finance-and-payment/cloudkitchens");
  }

  getDetailCloudkitchens(id: number) {
    return this.get(`finance-and-payment/cloudkitchens/${id}`);
  }

  async updateInvoice(no: number, info: Record<string, any>) {
    try {
      await this.patch(`finance-and-payment/invoices/${no}`, info);
      Toastify.showSuccessToast("Update success");
      return true;
    } catch (error: any) {
      const err = error.response?.data.messages || error.message;
      Toastify.showDangerToast(err[0]);
    }
  }

  async getDetailInvoice(no: number) {
    try {
      const res = await this.get(`finance-and-payment/invoices/${no}`);
      return res;
    } catch (error: any) {
      const err = error.response?.data.messages || error.message;
      Toastify.showDangerToast(err[0]);
      return null;
    }
  }

  async requestPayment(no: number, body: any) {
    try {
      await this.post(
        `finance-and-payment/invoices/${no}/request-payment`,
        body
      );
      return true;
    } catch (error: any) {
      const err = error.response?.data.messages || error.message;
      Toastify.showDangerToast(err[0]);
      return;
    }
  }
}

export default new FinancePaymentService();
