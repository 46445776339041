import React from 'react'
import { User } from 'shared/user'
import Layout from 'components/shared/Layout/LayoutContainer'
import LayoutHeader, { LayoutHeaderProps } from './components/LayoutHeader'
import LayoutSider, { LayoutSiderProps } from './components/LayoutSider'
import LayoutContent, { LayoutContentProps } from './components/LayoutContent'

export type BaseLayoutProps = LayoutHeaderProps &
  LayoutSiderProps &
  LayoutContentProps & {
    loading?: boolean
    className?: string
    user: User | null
  }

const BaseLayout: React.FC<BaseLayoutProps> = ({
  user,
  logo,
  title,
  loading,
  menuHeaderRender,
  headerRightContentRender,
  className,
  menuData,
  children,
}) => {
  return (
    <Layout className={className}>
      <LayoutSider
        user={user}
        menuHeaderRender={menuHeaderRender}
        loading={loading}
        logo={logo}
        title={title}
        menuData={menuData}
      />
      <Layout>
        <LayoutHeader 
          loading={loading} 
          user={user}
          headerRightContentRender={headerRightContentRender} 
        />
        <LayoutContent 
          loading={loading}
        >
          { children }
        </LayoutContent>
      </Layout>
    </Layout>
  )
}

export default BaseLayout
