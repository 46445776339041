import styled from 'styled-components'
import Modal from './Modal'
import { getCss } from 'utils/cssHelpers';

const StyledModal: any = styled(Modal)`
    .ant-modal-content {
        padding: 33px;
        border-radius: 10px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        background-color: #ffffff;
    }
    .lable-item {
        ${getCss('color')('theme.modal.lableItemColor')};
        font-size: 16px;
        font-weight: 600;
        text-align: center;
    }
`
export default StyledModal