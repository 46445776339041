import React from 'react';
import DetailsEmployeesUI from './DetailsEmployees';

const DetailsEmployees: React.FC = () => {
  return (
    <DetailsEmployeesUI />
  )
}

export default DetailsEmployees;
