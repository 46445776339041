import styled from "styled-components";
// import { getCss } from 'utils/cssHelpers';

const WrapDetailsEmployees: any = styled.div`
  .group-action {
    margin-bottom: 17px;
  }
  .btn-edit-account {
    margin-left: 20px;
  }
`;

export default WrapDetailsEmployees;
