import React, { useState, useEffect } from "react";
import { ProcessingOrdersWrapper } from "./styled";
import DetailsReceipt from "components/shared/DetailsReceipt";
import OrdersForLocation from "components/shared/OrdersForLocation";
import GroupTitle from "components/shared/GroupTitle";
import { ArrowBackOutlineIcon } from "components/shared/Icons";
import Flex from "components/shared/Flex";
import Tag from "components/shared/Tag";
import orderService from "services/order.service";
import { PersonCircleOutlineIcon } from "components/shared/Icons";
import map from "lodash/map";
import groupBy from "lodash/groupBy";
import filter from "lodash/filter";
import { orgType as ORG_TYPE } from "../manage-orders.config";
import corpLogo from "assets/icons/corp.svg";
import { buildFoodLocation, totalQuantity } from '../manage-order.util';

interface IProps {
  region: any;
  clickBack: (event?: Event) => void;
}

const DetailsRegionOrders: React.FC<IProps> = ({ region, clickBack }) => {
  const [orders, setOrders] = useState<any[]>([]);

  useEffect(() => {
    orderService.getTodayOrdersByRegion(region.regionId).then((res) => {
      const newOrders = map(res, (order: any) => {
        const locationGroup = groupBy(order.items, (item) => item.location?.id);

        const orderDetail: any = {
          type: ORG_TYPE[order.user?.roles[0]?.id],
        };

        const orgDetail = [
          {
            "Organisation Name": order.corporate?.name,
            "Registered Address": order.corporate?.registeredAddress,
          },
          {
            "Main Contact Number": order.corporate?.phoneNumber,
            "Email Address": order.corporate?.user?.email,
          },
          {
            "No. Of Order Location For This Region": Object.keys(locationGroup)
              .length,
            "Total QTY + Adds": totalQuantity(order.items),
          },
        ];
        orderDetail.orgDetail = orgDetail;

        //.order
        const foodDetail = {
          totalQTY: totalQuantity(
            filter(order.items, (item) => item.food?.logicType === "main-foods")
          ),
          totalCost: null,
          noOfLocation: Object.keys(locationGroup).length,
          data: buildFoodLocation(locationGroup),
        };
        orderDetail.orders = foodDetail;
        return orderDetail;
      });

      setOrders(newOrders);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ProcessingOrdersWrapper.DetailsRegion>
      <GroupTitle
        fontSize={16}
        icon={<ArrowBackOutlineIcon color="#fff" onClick={clickBack} />}
        padding={10}
        className="group-title-orders"
      >
        {`${region.regionName} Region Orders`}
      </GroupTitle>

      {orders.map((item, index) => (
        <div key={index}>
          <Flex justifyBetween className="header-details-receipt">
            <Flex alignCenter className="title-details-receipt">
              <div>Order Details Receipt ... Via {item.type}</div>
              <Tag type="primary">{`${region.regionName} Region Orders`}</Tag>
            </Flex>
            <Flex alignCenter>
              {item.type === ORG_TYPE.individual ? (
                <PersonCircleOutlineIcon
                  className="icon-person"
                  width="50px"
                  height="50px"
                />
              ) : (
                <img src={corpLogo} alt="corpLogo" />
              )}

              <div className="type-company">{item.type}</div>
            </Flex>
          </Flex>
          <DetailsReceipt dataInfo={item.orgDetail} />

          <OrdersForLocation dataInfo={item.orders} />
        </div>
      ))}
    </ProcessingOrdersWrapper.DetailsRegion>
  );
};

export default DetailsRegionOrders;
