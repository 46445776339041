import styled from 'styled-components'
import DetailsReceipt from './DetailsReceipt'
import { getCss } from 'utils/cssHelpers'

const StyledDetailsReceipt = styled(DetailsReceipt)`
    .title-receipt {
        ${getCss('color')('theme.global.color444444')};
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 6px;
    }
    .main-details-receipt {
        ${getCss('background')('theme.global.tertiaryColor')};
        padding: 16px 10px;
    }
    .title-info {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 6px;
    }
    .value-info {
        font-size: 12px;
        font-weight: 500;
        margin-bottom: 22px;
    }
    .ant-row:last-child {
        .value-info {
            margin-bottom: 0
        }
    }
`

export default StyledDetailsReceipt