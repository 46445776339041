import React, { useState, useEffect } from "react";
import { ProcessingOrdersWrapper } from "./styled";
import { Table2 } from "components/shared/Table";
import Button from "components/shared/Button";
import { orderColumns, orderStatus as STATUS } from "../manage-orders.config";
import orderService from "services/order.service";
import map from "lodash/map";
import { Toastify } from "components/shared/Toast/Toast";

interface ICloudKitchen {
  navigateTab(tab: string): void;
  user: any;
}

const CloudKitchen: React.FC<ICloudKitchen> = ({ navigateTab, user }) => {
  const [orders, setOrders] = useState<any[]>([]);
  const [orderStatus, setOrderStatus] = useState("");

  const getLiveOrders = () => {
    orderService
      .getLiveOrders()
      .then((res) => {
        const newOrders: any[] = map(res, (item: any, index: number) => ({
          ...item,
          menuNo: index + 1,
          key: item.food.id,
        }));
        setOrders(newOrders);
      })
      .catch((err) => {});
  };

  const getTodayOrderStatus = () => {
    orderService
      .getTodayOrderStatus()
      .then((res) => {
        setOrderStatus(res.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getTodayOrderStatus();
    getLiveOrders();
  }, []);

  const finishPreparingOrder = () => {
    if (orderStatus === STATUS.PREPARING && user?.roles?.[0].id === "cloud-kitchen") {
      orderService
        .finishPreparingOrder()
        .then(() => {
          Toastify.showSuccessToast("Success!");
          getTodayOrderStatus();
          // navigateTab('2');
        })
        .catch((err) => {
          Toastify.showDangerToast("Failure!");
        });
    }
  };

  return (
    <ProcessingOrdersWrapper.CloudKitchen>
      <Table2
        dataSource={orders}
        columns={orderColumns}
        pagination={false}
        className="table-complete-cloud-kitchen"
      />
      <Button
        type="primary"
        className="btn-complete-cloud-kitchen"
        disabled={orderStatus !== STATUS.PREPARING || user?.roles?.[0].id !== "cloud-kitchen"}
        onClick={finishPreparingOrder}
      >
        Complete
      </Button>
    </ProcessingOrdersWrapper.CloudKitchen>
  );
};

export default CloudKitchen;
