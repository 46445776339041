import styled from 'styled-components'
import Sider from 'components/shared/Layout/Sider'

const SiderWrapper = styled(Sider)`
  background: ${props => props.theme.global.secondaryColor};
  box-shadow: 6px 3px 6px 0 rgba(0, 0, 0, 0.16);
  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    .ddl-logo {
      padding: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      img {
        width: 120px;
      }
    }
    .ant-menu {
      color: ${props => props.theme.global.whiteColor};
      .ant-menu-item {
        width: 100%;
        padding-left: 16px !important;
        margin-bottom: 0;
        margin-top: 0;
        background: ${props => props.theme.global.primaryColor};
        span {
          svg {
            vertical-align: middle;
          }
        }
        a {
          color: ${props => props.theme.global.whiteColor};
          &:hover {
            color: ${props => props.theme.global.whiteColor};
          }
        }
      }
      .ant-menu-item-selected {
        background: ${props => props.theme.menu.activeBgColor};
        a {
          color: ${props => props.theme.menu.textColor};
          &:hover {
            color: ${props => props.theme.menu.textColor};
          }
        }
        &:after {
          display: none;
        }
      }
    }
    .ant-menu-submenu {
      background: ${props => props.theme.global.primaryColor};
      .ant-menu-item {
        background: ${props => props.theme.menu.subItemBgColor};
        a {
          color: ${props => props.theme.menu.textColor};
          &:hover {
            color: ${props => props.theme.menu.textColor};
          }
        }
      }
      .ant-menu-sub {
        .ant-menu-item-selected {
          background: ${props => props.theme.menu.itemActiveBgColor};
        }
      }
    }
    .ant-menu-submenu-title {
      padding-left: 16px !important;
      margin-top: 0;
      margin-bottom: 0;
      &:hover {
        color: ${props => props.theme.global.whiteColor};
      }
    }
    .ant-menu-submenu-arrow {
      color: ${props => props.theme.global.whiteColor} !important;
    }
    .ant-menu-submenu-active {
      color: ${props => props.theme.global.whiteColor};
    }
  }
`
export default SiderWrapper