import axios, {
  AxiosInstance,
  AxiosError,
  AxiosResponse,
  AxiosRequestConfig,
} from "axios";
import { get } from "lodash";
import authService from "services/auth.service";
import config from "config";
// import { getHistory } from 'utils/hooks'
import { Toastify } from "components/shared/Toast/Toast";
import history from "../../utils/history";

// const history = getHistory()
export abstract class BaseAPI {
  protected baseUrl: string;
  private axiosInstance: AxiosInstance;
  private authService = authService;

  constructor() {
    this.baseUrl = config.baseApiURL;
    this.axiosInstance = axios.create({});
    this.enableInterceptors();
  }

  private enableInterceptors(): void {
    this.axiosInstance.interceptors.response.use(
      this.getSuccessResponseHandler(),
      this.getErrorResponseHandler()
    );

    this.axiosInstance.interceptors.request.use(
      this.getSuccessRequestHandler(),
      this.getErrorRequestHandler()
    );
  }

  private getErrorRequestHandler() {
    return (error: any) => {
      console.log("error", error);
      return Promise.reject(error);
    };
  }

  private getSuccessRequestHandler() {
    return (config: AxiosRequestConfig) => {
      if (this.authService.isAuthenticated()) {
        this.axiosInstance.defaults.headers.common["Authorization"] =
          this.authService.getToken("access-token");
      }
      return config;
    };
  }

  private getSuccessResponseHandler() {
    return (response: AxiosResponse) => {
      const res = response.data;
      if (res.code === 401 || this.isAccessTokenExpired(res)) {
        history && history.push("/login");
      } else {
        return res;
      }
    };
  }

  private getErrorResponseHandler() {
    return async (error: AxiosError) => {
      if (error?.response?.status === 401 || this.isAccessTokenExpired(error)) {
        localStorage.clear();

        history && history.push("/login");

        Toastify.showDangerToast("Session expired!");
      }

      return Promise.reject(error);
    };
  }

  private isAccessTokenExpired(response: any) {
    return get(response, "message") === "Token Expired";
  }

  protected get(url: string, params?: any): Promise<any> {
    return this.axiosInstance({
      method: "GET",
      url: `${this.baseUrl}${url}`,
      params: params ? params : null,
      headers: {
        Authorization: `Bearer ${authService.getToken("access-token")}`,
      },
    });
  }

  protected download(url: string, params?: any): Promise<any> {
    return this.axiosInstance({
      method: "GET",
      url: `${this.baseUrl}${url}`,
      responseType: "blob",
      params: params ? params : null,
      headers: {
        Authorization: `Bearer ${authService.getToken("access-token")}`,
      },
    });
  }

  protected post(url: string, data?: any, params?: any): Promise<any> {
    return this.axiosInstance({
      method: "POST",
      url: `${this.baseUrl}${url}`,
      data: data ? data : null,
      params: params ? params : null,
      headers: {
        Authorization: `Bearer ${authService.getToken("access-token")}`,
      },
    });
  }

  protected delete(url: string, params?: any): Promise<any> {
    return this.axiosInstance({
      method: "DELETE",
      url: `${this.baseUrl}${url}`,
      params: params ? params : null,
      headers: {
        Authorization: `Bearer ${authService.getToken("access-token")}`,
      },
    });
  }

  protected patch(url: string, data?: any, params?: any): Promise<any> {
    return this.axiosInstance({
      method: "PATCH",
      url: `${this.baseUrl}${url}`,
      data: data ? data : null,
      params: params ? params : null,
      headers: {
        Authorization: `Bearer ${authService.getToken("access-token")}`,
      },
    });
  }

  protected postFormData(url: string, data?: any, params?: any): Promise<any> {
    return this.axiosInstance({
      method: "POST",
      url: `${this.baseUrl}${url}`,
      data: data ? data : null,
      params: params ? params : null,
      headers: {
        Authorization: `Bearer ${authService.getToken("access-token")}`,
        "content-type": "multipart/form-data",
      },
    });
  }

  protected put(url: string, data?: any, params?: any): Promise<any> {
    return this.axiosInstance({
      method: "PUT",
      url: `${this.baseUrl}${url}`,
      data: data ? data : null,
      params: params ? params : null,
      headers: {
        Authorization: `Bearer ${authService.getToken("access-token")}`,
      },
    });
  }
}
