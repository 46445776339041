import { getCss } from 'utils/cssHelpers';
import styled from 'styled-components'

const OverviewWrapper: any = styled.div`
  & {
    .customer-btn {
      margin-left: 10px;
      ${getCss('background')('theme.global.orangeColor')};
      ${getCss('border-color')('theme.global.orangeColor')};
    }
    .size-btn {
      width: 140px;
      height: 30px;
      line-height: 0;
    }
    .wrap-total-ongoing-news {
      border: 1px solid ${props => props.theme.global.primaryColor};
      width: 100%;
      padding: 10px;
      line-height: 1.3;
      span {
        ${getCss('color')('theme.global.primaryColor')};
        font-size: 14px;
        font-weight: 500;
      }
      h1 {
        margin: 0;
        font-size: 50px;
        font-weight: normal;
      }
    }
    .ant-tabs-tab-btn {
      font-weight: 600;
      font-size: 16px;
    }
  }
`;

export const Img = styled.img`
  & {
    max-width: 236px;
    height: 163px;
    object-fit: cover;
  }
`;

OverviewWrapper.WrapAllCard = styled.div`
  & {
    cursor: pointer;
    .title-card {
      font-size: 18px;
      font-weight: 500;
      ${getCss('color')('theme.global.primaryColor')};
      margin: 0;
    }
    .date-card {
      font-size: 14px;
      font-weight: 500;
      ${getCss('color')('theme.global.grayColor')};
      margin: 0;
  }
`;

export default OverviewWrapper;
