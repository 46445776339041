import styled from 'styled-components'
import Menu from './Menu'
import MenuItem from './MenuItem'
import SubMenu from './SubMenu'

const StyledMenu = styled(Menu)``

export const StyledMenuItem = styled(MenuItem)``

export const StyledSubMenu = styled(SubMenu)``

export default StyledMenu