import React, { useEffect, useState } from "react";
import Flex, { FlexItem } from "components/shared/Flex";
import Table from "components/shared/Table";
import Button from "components/shared/Button";
import GroupTitle from "components/shared/GroupTitle";
import DetailsCustomerWrapper from "./styled";
import { useHistory } from "react-router-dom";
import {
  ArrowBackOutlineIcon,
  ArrowForwardIcon,
} from "components/shared/Icons";
import userService from "services/user.service";
import { useCallback } from "react";
import financePaymentService from "services/finance-payment.service";
import {
  columnsInvoicesINV,
  columnsOrderINV,
} from "pages/finance-and-payment/customers/finance-customer.util";
import { formatCurrency } from "utils/common.util";
import Tag from "components/shared/Tag";

const DetailCustomerUnpaidInvoices: React.FC = (props) => {
  const history: any = useHistory();
  const { id: invNo } = history.location.state || {};
  const [user, setUser] = useState<any>([]);
  const [invoice, setInvoice] =
    useState<{
      id: number;
      date: string;
      no: number;
      month: string;
      total: number;
      status: string;
      receips: {
        id: number;
        date: string;
        receipNo: number;
        totalQty: number;
        totalPrice: number;
      }[];
    }>();

  const fetchInvoice = useCallback(async () => {
    const data = invNo
      ? await financePaymentService.getDetailInvoice(+invNo)
      : null;
    setInvoice(data);
  }, [invNo]);

  useEffect(() => {
    fetchInvoice();
  }, [fetchInvoice]);

  useEffect(() => {
    userService.getMe().then((res) => {
      setUser(res);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _handlePayment = () => {
    history.push(
      "/finance-payment/payments-by-customers-details-inv/1/INV46/submit"
    );
  };

  return (
    <DetailsCustomerWrapper.DetailCustomerINV>
      <Flex>
        <FlexItem
          css={`
            flex: 1;
          `}
        >
          <GroupTitle
            fontSize={16}
            icon={
              <ArrowBackOutlineIcon
                color="#fff"
                onClick={() => history.goBack()}
              />
            }
            padding={5}
          >
            INV {invNo}
          </GroupTitle>
        </FlexItem>
      </Flex>
      <Flex>
        <FlexItem
          css={`
            flex: 1;
          `}
        >
          <Table
            dataSource={
              invoice
                ? [
                    {
                      key: invoice.id,
                      Date: invoice.date,
                      INVNo: `INV${invoice.no}`,
                      ForTheMonth: invoice.month,
                      Total: `$${formatCurrency(invoice.total)}`,
                      Status: (
                        <Tag type="yellow">{invoice.status.toUpperCase()}</Tag>
                      ),
                    },
                  ]
                : []
            }
            columns={columnsOrderINV}
            pagination={false}
            className="customer-table-inv"
          />
        </FlexItem>
      </Flex>
      {user?.roles?.[0].id === "admin" ? (
        ""
      ) : (
        <Button
          type="info"
          onClick={_handlePayment}
          className="btn-customer-payment"
        >
          Make Payment
        </Button>
      )}
      <Flex>
        <FlexItem
          css={`
            flex: 1;
          `}
        >
          <div className="title-invoice-table">
            Invoice Breakdown For Main Menu{" "}
          </div>
          <Table
            className="customer-table-invoice"
            dataSource={
              invoice
                ? invoice.receips.map((rec) => ({
                    key: rec.id.toString(),
                    Date: rec.date,
                    ReceiptNo: rec.receipNo,
                    TotalQTY: rec.totalQty,
                    Total: `$${formatCurrency(rec.totalPrice)}`,
                    Details: <ArrowForwardIcon />,
                  }))
                : []
            }
            columns={columnsInvoicesINV}
            pagination={false}
          />
        </FlexItem>
      </Flex>
    </DetailsCustomerWrapper.DetailCustomerINV>
  );
};

export default DetailCustomerUnpaidInvoices;
