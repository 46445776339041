import React, { useEffect, useState } from "react";
import WrapMarketingForm from "./styled";
import GroupTitle from "components/shared/GroupTitle";
import Input from "components/shared/Input";
import Textarea from "components/shared/Textarea";
import Button from "components/shared/Button";
import InputDatePicker from "components/shared/InputDatePicker";
import Flex, { FlexItem } from "components/shared/Flex";
import { ArrowBackOutlineIcon } from "components/shared/Icons";
import Upload from "components/shared/Upload";
import Form from "components/shared/Form";
import { CloseSharpIcon } from "components/shared/Icons";
import commonService from "services/common.service";
import marketingService from "services/marketing.service";
import { Toastify } from "components/shared/Toast/Toast";
import { useHistory } from "react-router";
import Modal from "components/shared/Modal";
import Theme from "theme";
import { FormInstance } from "antd";

export interface IPropsMarketingForm {
  formTitle?: string;
  isCheck: boolean;
  submitPromotions(body: any): any;
  form: FormInstance<any>;
  id?: number;
}

const MarketingForm: React.FC<IPropsMarketingForm> = ({
  formTitle = "Add A Promotion/News",
  isCheck,
  submitPromotions,
  form,
  id,
}) => {
  const history: any = useHistory();
  const [image, setImage] = useState<any>([]);
  const [defaultImage, setDefaultImage] = useState<any>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const _handleSubmitForm = async () => {
    try {
      const values = await form.validateFields();
      const expiry = values.expiry.toISOString();
      const imageData = new FormData();
      imageData.append("files", image[0]);
      const body = {
        title: values.title,
        description: values.description,
        banner: "",
        expiry: expiry,
      };
      commonService
        .uploadFile(imageData)
        .then((res) => {
          body.banner = res[0];
          submitForm(body);
        })
        .catch((err) => {
          Toastify.showDangerToast(`Upload Profile ${err} Picture fail!`);
        });
    } catch (error) {
      console.log("Failed:", error);
    }
  };

  const submitForm = (body: any) => {
    submitPromotions(body);
  };

  const _handleDeleteCoupons = () => {
    const result: any = marketingService.deleteCoupons(id);
    if (result) {
      Toastify.showSuccessToast("Delete success!");
      setIsModalVisible(false);
      history.push("/marketing/overview");
    } else {
      Toastify.showDangerToast("Delete Fail!");
    }
  };

  const propsUpload = {
    showUploadList: {
      showRemoveIcon: true,
      removeIcon: (
        <CloseSharpIcon
          style={{
            color: Theme.global.coloreD3833,
            fontSize: "20px",
            top: 0,
            right: 0,
          }}
          onClick={(e: any) => {
            setImage([]);
            setDefaultImage([]);
          }}
        />
      ),
    },
    accept: "image/*",

    beforeUpload: (file: any) => {
      setImage([file]);

      return false;
    },
    fileList: defaultImage,
  };

  // Remove old picture
  if (!!image.length) {
    delete propsUpload.fileList;
  }

  const handleBack = () => {
    if (isCheck) {
      history.push("/marketing/overview");
    } else {
      history.push("/marketing/promotions");
    }
  };
  const openModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  function onKeypress(e: any) {
    if (e.key === "Enter") {
      _handleSubmitForm();
    }
  }

  useEffect(() => {
    window.addEventListener("keypress", onKeypress);
    return () => {
      window.removeEventListener("keypress", onKeypress);
    };
  });

  return (
    <WrapMarketingForm>
      <Flex alignCenter justifyBetween>
        <GroupTitle
          padding={5}
          icon={
            <ArrowBackOutlineIcon
              color={Theme.global.whiteColor}
              onClick={handleBack}
            />
          }
          fontSize={18}
        >
          {formTitle}
        </GroupTitle>
        {!isCheck && (
          <Button type="danger" onClick={openModal} className="customer-btn">
            Delete
          </Button>
        )}
      </Flex>
      <Flex
        css={`
          flex: 1;
        `}
        style={{ paddingTop: "25px" }}
      >
        <FlexItem basis="50%">
          <Form.Item
            name="title"
            rules={[{ required: true, message: "Please input your title!" }]}
          >
            <Input placeholder="Title" />
          </Form.Item>
          <Form.Item
            name="description"
            rules={[
              { required: true, message: "Please input your description!" },
            ]}
          >
            <Textarea placeholder="Description" className="customer-textarea" />
          </Form.Item>
        </FlexItem>
        <FlexItem basis="50%" style={{ paddingLeft: "20px" }}>
          <GroupTitle
            fontSize={18}
            style={{ marginLeft: "0", paddingTop: "10px" }}
          >
            Upload Banner
          </GroupTitle>
          <div className="wrap-action">
            <Form.Item
              name="banner"
              rules={[
                {
                  required: isCheck ? true : false,
                  message: "Please input your banner!",
                },
              ]}
            >
              <Upload
                listType="picture"
                maxCount={1}
                name="photo"
                {...propsUpload}
              >
                {isCheck ? "Upload" : "Re-Upload"}
              </Upload>
            </Form.Item>
            <p>
              Your banner size has to be
              <br /> 450px by 450px
            </p>
            <h4>News Expiry (If Applicable)</h4>
            <Form.Item
              name="expiry"
              rules={[
                {
                  required: isCheck ? true : false,
                  message: "Please input your expiry!",
                },
              ]}
            >
              <InputDatePicker
                format="DD-MM-YYYY"
                className="customer-input-date"
              />
            </Form.Item>
          </div>
        </FlexItem>
      </Flex>
      <Button
        type="danger"
        htmlType="submit"
        className="customer-btn"
        onClick={_handleSubmitForm}
      >
        {isCheck ? "Publish" : "Update"}
      </Button>
      <Modal visible={isModalVisible}>
        <Flex justifyCenter>
          <p className="content-request-for-payment">
            Do you want to delete this coupons?.
          </p>
        </Flex>
        <Flex justifyEnd>
          <Button type="info" onClick={closeModal}>
            Cancel
          </Button>
          <Button onClick={_handleDeleteCoupons} style={{ marginLeft: "15px" }}>
            OK
          </Button>
        </Flex>
      </Modal>
    </WrapMarketingForm>
  );
};

export default MarketingForm;
