import styled from 'styled-components'
import { getCss } from 'utils/cssHelpers'
import Button, { ButtonProps } from './Button'

const getBtnColorByType = (type: ButtonProps['type'], theme: any): string => {
  if (type === 'link') {
    return theme.global.primaryColor
  } else if (type === 'yellow' || type === 'disabled') {
    return theme.global.grayColor
  } else {
    return theme.global.whiteColor
  }
}

const DDLButton = styled(Button)`
  & {
    font-weight: 500;
    ${getCss('color')(props => getBtnColorByType(props.type, props.theme))};
    ${getCss('background')(props => props.theme.button[props.type].bgColor)};
    ${getCss('border-color')(props => props.theme.button[props.type].borderColor)};
    &:hover, &:focus, &:active {
      ${getCss('color')(props => getBtnColorByType(props.type, props.theme))};
      ${getCss('background')(props => props.theme.button[props.type].bgColor)};
      ${getCss('border-color')(props => props.theme.button[props.type].borderColor)};
    }
    span {
      ${getCss('text-decoration')(props => props.type === 'link' ? 'underline' : 'none')};
    }
  }
`

DDLButton.defaultProps = {
  type: 'primary'
} as ButtonProps

export default DDLButton