import styled from 'styled-components'
import { getCss } from 'utils/cssHelpers'

const DetailsCustomerWrapper: any = styled.div`
    .title-wrap {
        font-size: 18px;
        font-weight: 500;
        ${getCss('color')('theme.global.grayColor')};
        margin-bottom: 7px;
    }
    .group-action {
        margin-bottom: 17px;
    }
    .courier-details {
        margin-bottom: 16px;
    }
    .table-pay-in-settlement,
    .table-delivery-location,
    .table-unpaid-invoices {
        margin-top: 15px;
        table {
            .ant-table-thead > tr > th:not(:first-child),
            .ant-table-tbody > tr > td:not(:first-child) {
                text-align: center;
            }
            .ant-table-tbody > tr > td {
                ${getCss('background-color')('theme.table2.bodyBgColor')};
                ${getCss('color')('theme.global.grayColor')};
                font-weight: 500;
            }
            .ant-table-tbody {
                .ant-table-cell:nth-child(4) {
                    span:nth-child(1) {
                        display: initial;
                        margin: 0;
                    }
                }
            }
        }
    .add-location {
        cursor: pointer;
    }
    }
    .table-delivery-location {
        table {
            .ant-table-thead > tr > th:nth-child(3),
            .ant-table-tbody > tr > td:nth-child(3) {
                text-align: left!important;
            }
        }
    }
    .table-unpaid-invoices {
        table {
            .ant-table-tbody > tr > td {
                ${getCss('background-color')('theme.global.whiteColor')};
            }
        }
    }
    .wrapp-point-of-contact {
        margin-bottom: 27px;
        .table-point-of-contact {
            table {
                .ant-table-thead > tr > .ant-table-cell {
                    font-size: 18px;
                    text-align: left!important;
                }
                .ant-table-tbody > tr > .ant-table-cell {
                    font-size: 16px;
                    text-align: left!important;
                    ${getCss('background-color')('theme.global.tertiaryColor')};
                    ${getCss('color')('theme.global.grayColor')};
                }
            }
        }
    }
    .table-employee-details {
        table {
            .ant-table-thead > tr > .ant-table-cell {
                font-size: 14px;
            }
            .ant-table-tbody > tr > .ant-table-cell {
                font-size: 14px;
                ${getCss('color')('theme.global.grayColor')};
            }
        }
    }
    .btn-edit-account {
        margin-left: 20px;
    }
    .input-search-employee {
        width: 300px;
    }
    .group-total-employee {
        ${getCss('color')('theme.global.whiteColor')};
        font-size: 18px;
        font-weight: 500;
        ${getCss('background-color')('theme.global.primaryColor')};
        padding: 12px 10px;
        .import-csv {
            text-decoration: underline;
            cursor: pointer;
        }
    }
    .group-action-employee {
        ${getCss('background-color')('theme.global.tertiaryColor')};
        padding: 10px;
        span.total-employees {
            margin-right: 30px;
        }
        .ant-modal {
            .ant-modal-content {
                width: 100% !important;
            }
        }
    }
`;

DetailsCustomerWrapper.Modal = styled.div`
    text-align: center;
    .title-adjustment {
        text-align: center;
        font-size: 16px;
        ${getCss('color')('theme.global.grayColor')};
        font-weight: 600;
    }
    .table-modal-adjustment {
        table {
            .ant-table-thead > tr > .ant-table-cell {
                font-size: 18px;
                text-align: left!important;
            }
            .ant-table-tbody > tr > .ant-table-cell {
                font-size: 16px;
                text-align: left!important;
                ${getCss('background-color')('theme.global.tertiaryColor')};
                ${getCss('color')('theme.global.grayColor')};
            }
        }
    }
    .generated-password-account {
        padding: 15px 0 33px 0;
        font-size: 12px;
        ${getCss('color')('theme.global.grayColor')};
    }
    .btn-modal-adjustment {
        padding: 10px 50px;
        line-height: 0;
    }
    .wrap-type {
        font-size: 12px;
        ${getCss('color')('theme.global.primaryColor')};
        padding: 30px 0 48px 0;
        .ant-upload {
            padding: 8px 20px !important;
        }
    }
    .ant-upload-list-item {
        width: 20% !important;
        margin: 0 auto !important;
    }
    .csv-reader-input {
        input[type=file] {
            cursor: pointer;
            overflow: hidden;
            margin: 0 auto;
            display: flex;
            width: 35%;
        }
        input[type=file]:before {
            font-size: 14px;
            border-radius: 50px;
            line-height: 32px;
            content: 'Upload CSV';
            display: inline-block;
            ${getCss('background-color')('theme.global.primaryColor')};
            ${getCss('color')('theme.global.whiteColor')};
            text-align: center;
            width: 50%;
            margin: 0 auto;
        }
        
        input[type=file]::-webkit-file-upload-button {
        visibility: hidden;
        }
    }
`;

DetailsCustomerWrapper.DetailCustomerINV = styled.div`
    .customer-table-inv {
        padding-top: 14px;
    }
    .ant-table table .ant-table-thead .ant-table-cell {
        ${getCss('background-color')('theme.global.primaryColor')};
        ${getCss('color')('theme.global.whiteColor')};
        font-weight: 500;
    }
    .title-invoice-table {
        font-size: 16px;
        ${getCss('color')('theme.global.color4f4f4f')};
        font-weight: bold;
        padding: 35px 0 10px 0;
    }
    .btn-customer-payment {
        padding: 20px 75px;
        line-height: 0;
        margin-top: 15px;
    }
    .customer-table-invoice {
        .ant-table-tbody {
            ${getCss('background-color')('theme.table2.bodyBgColor')};
        }
    }
    .view-next-7-days {
        font-size: 16px;
        font-weight: 600;
        ${getCss('color')('theme.global.grayColor')};
        margin: 44px 0 29px 0;
    }
`;

export default DetailsCustomerWrapper;
