import styled from "styled-components";
import { getCss } from 'utils/cssHelpers';

const StyledCustomers = styled.div`
  width: 100%;
  .apexcharts-xaxis-title {
    text {
      ${getCss('fill')('theme.global.primaryColor')};
    }
  }
  & {
    .customer-btn {
      width: 150px;
      height: 30px;
      line-height: 0;
    }
    .active-btn {
      ${getCss('background')('theme.button.yellow.bgColor')};
      ${getCss('border-color')('theme.button.yellow.bgColor')};
      ${getCss('color')('theme.global.grayColor')};
    }
    .ant-card {
      ${getCss('background')('theme.global.tertiaryColor')};
    }
    .ml-20 {
      margin-left: 20px;
    }
    
    .customer-btn-go {
      border-radius: 50px;
      margin-left: 20px;
    }
  }
`;
export default StyledCustomers;
