import React from 'react'
import { Row, Col } from '../Grid';
import map from "lodash/map";

export interface DetailsReceiptProps {
  dataInfo: Array<any>;
  className?: string; 
  title?: string;
}

const DetailsReceipt: React.FC<DetailsReceiptProps> = ({ dataInfo, className, title }) => {
  return (
    <div className={className}>
      {title ? <div className="title-receipt">{title}</div> : null}
      <div className="main-details-receipt">
        {map(dataInfo, (item, index) =>
          <Row key={index}>
            {Object.keys(item).map(data =>
              <Col span={12} key={data}>
                <div className="title-info">{data}</div>
                <div className="value-info">{item[data]}</div>
              </Col>
            )}
          </Row>
        )}
      </div>
    </div>
  )
}

export default DetailsReceipt