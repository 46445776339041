import { toast } from "react-toastify";

export const Toastify = {

    showDefaultToast: (message: any) => {
        toast(message, {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
            closeButton: true,
            className: "toast-body",
        });
    },

    showCustomToast: (message: any, options = {}) => {
        toast(message, Object.assign({}, {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
            closeButton: true,
            className: "toast-body",
        }, options));
    },

    showPrimaryToast: (message: any, options = {}) => {
        toast(message, Object.assign({}, {
            type: toast.TYPE.SUCCESS,
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
            closeButton: true,
            className: "toast-body",
        }, options));
    },

    showSecondaryToast: (message: any, options = {}) => {
        toast(message, Object.assign({}, {
            type: toast.TYPE.DEFAULT,
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
            closeButton: true,
            className: "toast-body",
        }, options));
    },

    showSuccessToast: (message: any, options = {}) => {
        toast(message, Object.assign({}, {
            type: toast.TYPE.SUCCESS,
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
            closeButton: true,
            className: "toast-body",
        }, options));
    },

    showInfoToast: (message: any, options = {}) => {
        toast(message, Object.assign({}, {
            type: toast.TYPE.INFO,
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
            closeButton: true,
            className: "toast-body",
        }, options));
    },

    showWarningToast: (message: any, options = {}) => {
        toast(message, Object.assign({}, {
            type: toast.TYPE.WARNING,
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
            closeButton: true,
            className: "toast-body",
        }, options));
    },

    showDangerToast: (message: any, options = {}) => {
        toast(message, Object.assign({}, {
            type: toast.TYPE.ERROR,
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
            closeButton: true,
            className: "toast-body",
        }, options));
    },
};
