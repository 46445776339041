import styled from 'styled-components'
import DetailsInfo from './DetailsInfo'
import { getCss } from 'utils/cssHelpers'

const StyledDetailsInfo = styled(DetailsInfo)`
    .title-info-details {
        font-size: 18px;
        font-weight: 500;
        ${getCss('color')('theme.global.grayColor')};
        margin-bottom: 7px;
    }
    .main-info-details {
        ${getCss('background-color')('theme.global.primaryColor')};
        ${getCss('color')('theme.global.whiteColor')};
        padding: 12px 10px;
    }
    .title-info {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 26px;
        ${getCss('color')('theme.global.whiteColor')};
    }
    .value-info {
        font-size: 16px;
        margin-bottom: 52px;
        ${getCss('color')('theme.global.whiteColor')};
    }
`

export default StyledDetailsInfo