import React, { useState, useEffect } from "react";
import { ProcessingOrdersWrapper } from "./styled";
import Table, { Table3 } from "components/shared/Table";
import map from "lodash/map";
import filter from "lodash/filter";
import find from "lodash/find";
import some from "lodash/some";
import debounce from "lodash/debounce";
import { Row, Col } from "antd";
import InputSearch from "components/shared/InputSearch";
import Button from "components/shared/Button";
import couriersService from "services/couriers.service";
import { Toastify } from "components/shared/Toast/Toast";

const orderColumns = [
  {
    title: "Organsiation Name",
    dataIndex: "corpName",
  },
  {
    title: "No. Of Locations",
    dataIndex: "numberOfLocations",
  },
  {
    title: "Assignee",
    dataIndex: "assignee",
  },
];

const courierColumns = [
  {
    title: "Courier ID",
    dataIndex: "courierId",
  },
  {
    title: "Courier Name",
    dataIndex: "name",
  },
  {
    title: "Status",
    dataIndex: "status",
    render: (text:string,record:any) => record.isLive ? 'Live' : 'Not Live'
  },
  {
    title: "",
    dataIndex: "selected",
  },
];

interface ICourierAssign {
  regionInfo: any;
  updateAssignedCouriers: any;
}

const CourierAssign: React.FC<ICourierAssign> = ({ regionInfo, updateAssignedCouriers }) => {
  const [selectedOrders, setSelectedOrders] = useState<any[]>([]);
  const [listCouriers, setListCouriers] = useState<any>([]);
  const [couriers, setCouriers] = useState<any>([]);
  const [courierSelected, setCourierSelected] = useState<any>({});
  const [courierSelectedDefault, setCourierSelectedDefault] = useState<any>({});
  const [orders, setOrders] = useState<any>(regionInfo.companies || []);

  const selectCourier = (courier: any) => {
    const isDeselect = courierSelected.courierId === courier.courierId;
    isDeselect ? setCourierSelected({}) : setCourierSelected(courier);
  };

  const renderSelectedCell = (courier: any) => {
    const isSelected = courier.courierId === courierSelected.courierId;
    
    return (
      <Button
        type={isSelected ? "info" : "primary" }
        disabled={!courier.isLive}
        onClick={() => selectCourier(courier)}
      >
        {/* Selected {isSelected ? selectedRowKeys.length : ""} */}
        Selected
      </Button>
    );
  };

  const getCouriers = () => {
    couriersService.getAllCouriers().then((couriers) => {
      const listCouriers: any[] = map(couriers, (courier) => ({
        ...courier,
        key: courier.courierId,
        selected: renderSelectedCell(courier),
      }));

      setCouriers(listCouriers);

      setListCouriers(listCouriers);

      const courierAssigned = find(couriers, (courier) => {
        return some(
          courier.regions,
          (item) => item.name === regionInfo.regionName
        );
      });

      if (courierAssigned) {
        setCourierSelected(courierAssigned);
        setCourierSelectedDefault(courierAssigned);
      }
    });
  };

  const renderAssigneeCell = (orderData: any) => {
    const isSelectedOrder = find(selectedOrders, { key: orderData.key });
    if (!isSelectedOrder) {
      return "";
    }
    return (
      <span>
        {courierSelected.name}
        <span
          className="order-deselect"
          onClick={() => deselectOrder(orderData)}
        >
          Deselect
        </span>
      </span>
    );
  };

  const deselectOrder = (order: any) => {
    const newSelectedOrders = filter(
      selectedOrders,
      (item: any) => item.key !== order.key
    );

    setSelectedOrders(newSelectedOrders);
  };

  useEffect(() => {
    getCouriers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Re update courier table
    const listCouriers: any[] = map(couriers, (courier) => ({
      ...courier,
      selected: renderSelectedCell(courier),
    }));
    setCouriers(listCouriers);

    // Re update order table
    const listOrders: any[] = map(orders, (order) => ({
      ...order,
      assignee: renderAssigneeCell(order),
    }));
    setOrders(listOrders);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courierSelected, selectedOrders]);

  const checkItemSearch = (itemData: any, value: string) => {
    const arrKeysSearch = ["courierId", "name", "status"];
    const hasItem = arrKeysSearch.some(
      (key) => (itemData[key] + "")?.toLowerCase().indexOf(value) !== -1
    );
    if (hasItem) {
      return itemData;
    } else {
      return null;
    }
  };

  const _handleSearch = debounce((e: any) => {
    const value = e.target.value.toLowerCase();
    let dataSourceFilter = listCouriers;
    if (value !== "") {
      dataSourceFilter = map(dataSourceFilter, (item: any) =>
        checkItemSearch(item, value)
      ).filter((item: any) => item !== null);
    }

    // Re update courier table
    const newCouriers: any[] = map(dataSourceFilter, (courier) => ({
      ...courier,
      selected: renderSelectedCell(courier),
    }));
    setCouriers(newCouriers);
  }, 1000);

  const selectRow = (record: any) => {
    const isExist = find(selectedOrders, { key: record.key });

    if (!isExist) {
      setSelectedOrders([...selectedOrders, record]);
    }
  };

  const handelAssignedCouriers = () => {
    if(courierSelected.courierId) {
      couriersService.assignRegionToCourier(courierSelected.user.id, regionInfo.regionId).then(() => {
        Toastify.showSuccessToast("Success!");
        updateAssignedCouriers();
      }).catch((err) => {
        Toastify.showDangerToast("Failure!");
      });
    } else {
      couriersService.removeRegionToCourier(courierSelectedDefault.user.id, regionInfo.regionId).then(() => {
        Toastify.showSuccessToast("Success!");
        updateAssignedCouriers();
      }).catch((err) => {
        Toastify.showDangerToast("Failure!");
      });
    }
  }

  const checkOrderRowActive = (record:any) => {
    const isSelectedOrder = find(selectedOrders, { key: record.key });

    return isSelectedOrder ? 'row-active' : 'row-not-active'
  }

  return (
    <ProcessingOrdersWrapper.CourierAssign>
      <div className="title-courier-assign">Assign Region Based Order</div>
      <Row>
        <Col span={14}>
          <Table
            title={() => `${regionInfo.regionName} Region Orders`}
            dataSource={orders}
            columns={orderColumns}
            pagination={false}
            onRow={(record, rowIndex) => {
              return {
                onClick: () => selectRow(record),
              };
            }}
            rowClassName={checkOrderRowActive}
          />
        </Col>
        <Col span={10}>
          <div className="find-couriers">
            <div className="title-find-couriers">
              Find Couriers In This Region To Assign
            </div>
            <InputSearch
              placeholder="Search Couriers"
              onChange={(e) => _handleSearch(e)}
              className="search-couriers"
            />
            <Table3
              dataSource={couriers}
              columns={courierColumns}
              pagination={false}
            />
          </div>
        </Col>
      </Row>
      <div className="footer-courier-assign">
        <Button
          type="info"
          onClick={handelAssignedCouriers}
          disabled={courierSelectedDefault.courierId === courierSelected.courierId}
        >
          Update Assigned Couriers
        </Button>
      </div>
    </ProcessingOrdersWrapper.CourierAssign>
  );
};

export default CourierAssign;
