import { configureStore } from '@reduxjs/toolkit'
import currentUser from './modules/current-user'
import manageMenu from './modules/manage-menu';

export const store = configureStore({
  reducer: {
    currentUser: currentUser,
    manageMenu: manageMenu,
  },
})

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch

export * from './hooks'