import React, { useState, useEffect } from "react";
import Flex, { FlexItem } from "components/shared/Flex";
import Button from "components/shared/Button";
import Input from "components/shared/Input";
import Select from "components/shared/Select";
import { AddOutlineIcon, CloseSharpIcon } from "components/shared/Icons";
import StyledFood from "./styled";
import Upload from "components/shared/Upload";
import { Form } from "antd";
import menuService from "services/menu.service";
import commonService from "services/common.service";
import { Toastify } from "components/shared/Toast/Toast";
import Theme from "theme";
import NumberFormat from "react-number-format";

export interface IPropsFoods {
  isCheck: boolean;
  submitForm(body: any): any;
  form: any;
  resultTypeIds?: any;
  setResultTypeIds?: any;
}

type IPropField = {
  id: number;
  name: string;
};

const FormFoods: React.FC<IPropsFoods> = ({
  isCheck,
  submitForm,
  form,
  resultTypeIds,
  setResultTypeIds,
}) => {
  const [image, setImage] = useState<any>([]);
  const [defaultImage, setDefaultImage] = useState<any>([]);
  const [menuId, setMenuId] = useState<any>();
  const [foodType, setFoodType] = useState([]);
  const [menuCategory, setMenuCategory] = useState([]);

  useEffect(() => {
    menuService.getMenuNoPagination().then((resultMenu) => {
      const menuCategory = resultMenu.map((item: IPropField) => {
        return {
          id: item.id,
          name: item.name,
        };
      });
      setMenuCategory(menuCategory);
    });

    menuService.getAllFoodsType().then((resultFoodType) => {
      const allFoodType = resultFoodType.map((item: IPropField) => {
        return {
          id: item.id,
          name: item.name,
        };
      });
      setFoodType(allFoodType);
    });
  }, []);

  const dropMenuCategory = menuCategory.map((item: IPropField) => {
    return {
      id: item.id,
      value: item.id,
      label: item.name,
    };
  });

  const dropFoodType = foodType.map((item: IPropField) => {
    return {
      id: item.id,
      value: item.id,
      label: item.name,
    };
  });

  const _handleChangeMenuCategory = (e: any) => {
    setMenuId(e);
  };

  const _handleClickAddFoodType = () => {
    setResultTypeIds((prev: any[]) => [...prev, { id: undefined }]);
  };

  const removeFoodType = (index: number) => {
    setResultTypeIds((prev: any[]) => {
      prev.splice(index, 1);
      return [...prev];
    });
  };

  const _handlePublished = async (isPush: boolean) => {
    try {
      const values = await form.validateFields();
      const imageData = new FormData();
      imageData.append("files", image[0]);
      const body: any = {
        name: values.name,
        photo: "",
        typeIds: resultTypeIds.map((type: any) => type.id),
        menuId,
        calories: parseInt(values.calories),
        price: +values.price,
        isPublished: isPush,
      };

      commonService
        .uploadFile(imageData)
        .then((res) => {
          body.photo = res[0];
          addFoods(body);
        })
        .catch((err) => {
          Toastify.showDangerToast(`Upload Profile ${err} Picture fail!`);
        });
    } catch (error) {
      console.log("Failed:", error);
    }
  };

  const addFoods = async (body: any) => {
    submitForm(body);
  };

  const _handleChangeFoodType = (i: number, value: number) => {
    setResultTypeIds((prev: any[]) => {
      prev[i].id = value;
      return [...prev];
    });
  };

  const propsUpload = {
    showUploadList: {
      showRemoveIcon: true,
      removeIcon: (
        <CloseSharpIcon
          style={{
            color: Theme.global.coloreD3833,
            fontSize: "20px",
            top: 0,
            right: 0,
          }}
          onClick={(e: any) => {
            setImage([]);
            setDefaultImage([]);
          }}
        />
      ),
    },
    accept: "image/*",

    beforeUpload: (file: any) => {
      setImage([file]);

      return false;
    },
    fileList: defaultImage,
  };

  // Remove old picture
  if (!!image.length) {
    delete propsUpload.fileList;
  }

  function onKeypress(e: any) {
    if (e.key === "Enter") {
      addFoods(true || false);
    }
  }

  useEffect(() => {
    window.addEventListener("keypress", onKeypress);
    return () => {
      window.removeEventListener("keypress", onKeypress);
    };
  });

  return (
    <StyledFood.AddFood>
      <Flex>
        <FlexItem>
          <Form.Item
            name="name"
            rules={[{ required: true, message: "Please input your name!" }]}
          >
            <Input
              placeholder=""
              style={{ maxWidth: "350px" }}
              label="Food Name"
            />
          </Form.Item>
          <FlexItem>
            <p className="notes-top">Add Food Image</p>
            <div className="add-food-image">
              <Form.Item
                name="photo"
                rules={[
                  { required: true, message: "Please input your photo!" },
                ]}
              >
                <Upload
                  listType="picture"
                  maxCount={1}
                  name="photo"
                  {...propsUpload}
                >
                  Upload Image
                </Upload>
              </Form.Item>
              <p className="note-add-food-image">
                Image size at least 500px by 500px in
                <br />
                Jpeg or PNG format.
              </p>
            </div>
          </FlexItem>
          <Form.Item
            name="menuId"
            rules={[
              { required: true, message: "Please input your menu category!" },
            ]}
          >
            <Select
              style={{ maxWidth: "350px" }}
              onChange={(e) => _handleChangeMenuCategory(e)}
              options={dropMenuCategory}
              notesTop="Add Menu Category"
              notesBottom="Choose between the different Cuisine"
            ></Select>
          </Form.Item>
          {resultTypeIds.map((type: any, index: number) => (
            <div key={index}>
              <Flex nowrap alignEnd>
                <Select
                  style={{ minWidth: "350px" }}
                  notesTop="Add Food Type"
                  value={type.id}
                  options={dropFoodType}
                  onChange={(value) => _handleChangeFoodType(index, value)}
                />

                {index === resultTypeIds.length - 1 && (
                  <div
                    onClick={_handleClickAddFoodType}
                    style={{
                      display: "flex",
                      paddingLeft: "23px",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <AddOutlineIcon
                      style={{
                        marginBottom: "-5px",
                      }}
                    />{" "}
                    <span>Add More Food Type</span>
                  </div>
                )}

                {resultTypeIds.length > 1 && (
                  <div
                    onClick={() => removeFoodType(index)}
                    style={{
                      color: `${Theme.global.dangerColor}`,
                      display: "flex",
                      paddingLeft: "23px",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <CloseSharpIcon
                      style={{
                        marginBottom: "-5px",
                      }}
                    />{" "}
                    <span>Delete</span>
                  </div>
                )}
              </Flex>
              {type.id == null && (
                <span style={{ color: "#ff4040", fontSize: 12 }}>
                  Plase select food type
                </span>
              )}
            </div>
          ))}
          <Form.Item
            style={{ marginTop: 10 }}
            name="calories"
            rules={[{ required: true, message: "Please input your calories!" }]}
          >
            <Input
              placeholder=""
              style={{ maxWidth: "350px" }}
              label="Add Calories"
              notes="Choose amount of Calories"
            />
          </Form.Item>
          <Form.Item
            name="price"
            rules={[{ required: true, message: "Please input your price!" }]}
          >
            <NumberFormat
              label="Add Price"
              placeholder=""
              notes="Select amount"
              customInput={Input}
              decimalSeparator="."
              decimalScale={2}
              style={{ maxWidth: "350px" }}
            />
          </Form.Item>
          <FlexItem style={{ paddingTop: "38px" }}>
            <Button
              type="delete"
              htmlType="submit"
              className="custom-btn-add-food"
              onClick={() => _handlePublished(false)}
            >
              Save As Draft
            </Button>
            <Button
              disabled={
                !resultTypeIds.length ||
                resultTypeIds.some((type: any) => type.id == null)
              }
              type="primary"
              htmlType="submit"
              style={{ marginLeft: "20px" }}
              className="custom-btn-add-food"
              onClick={() => _handlePublished(true)}
            >
              {isCheck ? "Publish" : "Edit"}
            </Button>
          </FlexItem>
        </FlexItem>
      </Flex>
    </StyledFood.AddFood>
  );
};

export default FormFoods;
