import React from 'react';
import WeeklyMenuEditUI from './WeeklyMenuEdit';

const WeeklyMenuDetails: React.FC = () => {
  return (
    <WeeklyMenuEditUI />
  )
}

export default WeeklyMenuDetails;
