import { createContext, useContext } from 'react'
import { IRoute } from './renderRoutes'

const AppRoutesContext = createContext<{ routes: IRoute[]} | undefined>(undefined)

export const useAppRoutesContext = () => {
  const context = useContext(AppRoutesContext)
  if(!context) throw new Error('useAppRoutesContext must be used within AppRoutesContext.Provider')
  return context
}

export default AppRoutesContext

