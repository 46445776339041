import React from 'react'
import { Modal as AntModal, ModalProps as AntModalProps } from 'antd'

interface ModalProps extends AntModalProps {}

const Modal: React.FC<ModalProps> = (props) => {
  return <AntModal
    maskStyle={{background: '#cbcbcb', opacity: '0.8'}}
    footer={null}
    centered
    className='ddl-modal'
    {...props}
  />
}

export default Modal
