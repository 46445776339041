import React from 'react';
import Wrapper from './styled';
import Card, { BorderHidden } from 'components/shared/Card'

type ReportDetailsProps = {
  value?: string | number
  subtitle?: string
  description?: string
  width?: number
} & BorderHidden

const ReportDetails: React.FC<ReportDetailsProps> = ({
  value,
  subtitle,
  description,
  width,
  ...rest
}) => {
  return (
  <Card
    bordered={false}
    withGreenBorder
    {...rest}
  >
    <Wrapper.DetailsReports width={width}>
      <h3 className="number">
        {value}
      </h3>
      <div className="text">
        {subtitle}
        <p className="description">
          {description}
        </p>
      </div>
    </Wrapper.DetailsReports>
  </Card>
  )
}

export default ReportDetails;
