import React from "react";
import OverviewReportUI from "./OverviewReport.UI";

const OverviewReport: React.FC<{ dashboard: any; getDashboard: any }> = ({
  dashboard,
  getDashboard,
}) => {
  return (
    <OverviewReportUI
      dashboard={dashboard}
      getDashboard={getDashboard}
    />
  );
};

export default OverviewReport;
