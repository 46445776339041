import React, { useEffect } from "react";
import Input from 'components/shared/Input';
import Button from 'components/shared/Button';
import Form from 'components/shared/Form';
import StyledFoodType from './styled';
import GroupTitle from 'components/shared/GroupTitle';
import { ArrowBackOutlineIcon } from 'components/shared/Icons';
import { GithubPicker } from 'react-color';
import { useHistory } from "react-router-dom";
import { colors } from "./FoodType.config";
import Theme from "theme";
export interface FoodTypeProps {
  isCheck: boolean;
  submitForm(body: any): any;
  form: any;
}

const FormFoodType: React.FC<FoodTypeProps> = ({
  isCheck,
  submitForm,
  form,
}) => {
  const history = useHistory();

  const _handleClickAddFoodType = () => {
    history.push('/manage-menu/my-menu');
  }

  const _handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      const body = {
        name: values.name,
        color: values.color.hex,
      }
      submitForm(body);
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  }

  function onKeypress(e: any) {
    if (e.key === "Enter") {
      _handleSubmit();
    }
  }

  useEffect(() => {
    window.addEventListener("keypress", onKeypress);
    return () => {
      window.removeEventListener("keypress", onKeypress);
    };
  });

  return (
    <StyledFoodType.Form>
      <GroupTitle
        fontSize={16}
        icon={
          <ArrowBackOutlineIcon
            color={Theme.global.whiteColor}
            onClick={_handleClickAddFoodType}
          />
        }
        padding={5}
        className="styled-title-type-food"
      >
        {isCheck ? "Add Foods Type" : "Edit Foods Type"}
      </GroupTitle>
      <Form.Item
        name="name"
        rules={[
          { required: true, message: "Please input your new food type!" },
        ]}
      >
        <Input placeholder="" label="Add New Food Type" />
      </Form.Item>
      <div className="label-top">Food Type Legend</div>
      <Form.Item
        name="color"
        rules={[{ required: true, message: "Please input your color!" }]}
      >
        <GithubPicker
          colors={colors}
        />
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          className="custome-btn"
          htmlType="submit"
          onClick={_handleSubmit}
        >
          {isCheck ? "Save" : "Edit"}
        </Button>
      </Form.Item>
    </StyledFoodType.Form>
  );
}

export default FormFoodType;
