import React, { useState, useEffect } from "react";
import Flex, { FlexItem } from "components/shared/Flex";
import StyledOrder from "./styled";
import { Tabs2, TabPane } from "components/shared/Tabs";
import ReportDetails from "../reports-shared/ReportDetails";
import Chart from "react-apexcharts";
import downloadIcon from "assets/images/download.svg";
import reportService from "services/report.service";
import map from "lodash/map";
import { getPeriodTimeParam } from "../reports.util";
import Theme from "theme";
import CustomDate from "../reports-shared/CustomDate";

const dataDetailsReport = [
  {
    id: 1,
    value: "",
    subtitle: "Total Orders in this period",
    description: "*Not inclusive of “Add Ons”",
    borderTop: "hideBorderTop",
    width: "25%",
  },
  {
    id: 2,
    value: "",
    subtitle: "Average orders per week",
    description: "*Not inclusive of “Add Ons”",
    borderLeft: "hideBorderLeft",
    borderRight: "hideBorderRight",
    borderTop: "hideBorderTop",
    width: "25%",
  },
  {
    id: 3,
    value: "",
    subtitle: (
      <>
        Total “Add On” orders <br /> in this period
      </>
    ),
    description: null,
    borderRight: "hideBorderRight",
    borderTop: "hideBorderTop",
    width: "25%",
  },
  {
    id: 4,
    value: "",
    subtitle: (
      <>
        Total Average “Add On” <br /> orders per week
      </>
    ),
    description: null,
    borderTop: "hideBorderTop",
    width: "25%",
  },
  {
    id: 5,
    value: "",
    subtitle: (
      <>
        IND - CORP orders in this <br />
        Period
      </>
    ),
    description: null,
    borderTop: "hideBorderTop",
    width: "25%",
  },
  {
    id: 6,
    value: "",
    subtitle: (
      <>
        Average time consumed per <br /> order
      </>
    ),
    description: null,
    borderLeft: "hideBorderLeft",
    borderRight: "hideBorderRight",
    borderTop: "hideBorderTop",
    width: "25%",
  },
  {
    id: 7,
    value: null,
    subtitle: null,
    description: null,
    borderTop: "hideBorderTop",
    width: "50%",
  },
];

const TotalOrder: React.FC = (props) => {
  const [totalOrders, setTotalOrders] = useState<any>(dataDetailsReport);
  const [series, setSeries] = useState<any>([]);

  const options: any = {
    chart: {
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: { show: false },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    title: {
      text: "Orders",
      align: "right",
      style: {
        fontSize: "10px",
        fontWeight: "500",
        color: `${Theme.global.primaryColor}`,
      },
    },
    grid: {
      row: {
        colors: [`${Theme.global.whiteColor}`, `${Theme.global.whiteColor}`],
      },
      column: {
        colors: [`${Theme.global.whiteColor}`, `${Theme.global.whiteColor}`],
      },
    },
    colors: [`${Theme.global.orangeColor}`],
    xaxis: {
      title: {
        text: "Date",
      },
      // min: 1,
      // max: 31,
      labels: {
        style: {
          color: `${Theme.global.primaryColor}`,
          fontSize: "9px",
          fontWeight: 500,
          cssClass: "apexcharts-xaxis-label",
        },
      },
    },
    yaxis: {
      show: true,
      opposite: true,
      // title: {
      //   text: "Orders",
      // },
      labels: {
        show: true,
        align: "right",
        style: {
          color: `${Theme.global.primaryColor}`,
          fontSize: "9px",
          fontWeight: 500,
          cssClass: "apexcharts-xaxis-label",
        },
      },
    },
  };

  const tabTimeChange = (tab: string) => {
    // Export CSV
    if (tab === "5") {
      return;
    }

    // Custom Date
    if (tab === "4") {
      return;
    }

    const param = getPeriodTimeParam(+tab);
    getTotalOrders(param);
  };

  const getTotalOrders = (param: string) => {
    reportService.getTotalOrders(param).then((res) => {
      const dataTotal = totalOrders;

      dataTotal[0].value = res.total;
      dataTotal[1].value = res.avgPerWeek;
      dataTotal[2].value = res.totalAddOn;
      dataTotal[3].value = res.avgAddonPerWeek;
      dataTotal[4].value = `${res.numIndividual} : ${res.numCorporate}`;
      dataTotal[5].value = `${res.avgTimeConsumedPerItem[0]}:${res.avgTimeConsumedPerItem[1]} minutes`;

      setTotalOrders([...dataTotal]);
      // if(param === 'from=2021-07-31T17%3A00%3A00.000Z&to=2021-08-31T16%3A59%3A59.999Z') {
      //   res.data.length = 7
      //   console.log(res.data)
      // }
      setSeries([...[{ data: res.data, name: "Orders" }]]);
    });
  };

  useEffect(() => {
    const param = getPeriodTimeParam(0);
    getTotalOrders(param);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clickGoBtn = (dateFrom: any, dateTo: any) => {
    const param = getPeriodTimeParam(4, { from: dateFrom, to: dateTo });
    getTotalOrders(param);
  };

  const titleCard: Array<any> = [
    "Last Month",
    "This Month",
    "Last 7 Days",
    "1 Year",
    <CustomDate clickGoBtn={clickGoBtn} />,
    <span>
      {" "}
      {/* Export CSV */}
      <img src={downloadIcon} alt="download" style={{ marginLeft: "10px" }} />
    </span>,
  ];

  return (
    <StyledOrder.TotalOrderWrap>
      <Tabs2 defaultActiveKey="0" onChange={tabTimeChange}>
        {titleCard.map((item, index) => {
          return <TabPane tab={item} key={index}></TabPane>;
        })}
      </Tabs2>

      {/* Info Total Orders */}
      <Flex style={{ flexWrap: "wrap" }}>
        {map(totalOrders, (item: any, index: number) => {
          return (
            <FlexItem key={index} style={{ width: item.width }}>
              <ReportDetails
                value={item.value}
                subtitle={item.subtitle}
                description={item.description}
                hideBorderLeft={item.borderLeft}
                hideBorderRight={item.borderRight}
                hideBorderTop={item.borderTop}
              />
            </FlexItem>
          );
        })}
      </Flex>

      <div
        style={{
          paddingTop: 20,
          backgroundColor: `${Theme.global.whiteColor}`,
        }}
      >
        <Chart options={options} series={series} type="line" width="99%" />
      </div>
    </StyledOrder.TotalOrderWrap>
  );
};

export default TotalOrder;
