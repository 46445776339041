import React from 'react'
import './index.css'
/* eslint @typescript-eslint/no-unused-vars: "off" */
import * as types from 'styled-components/cssprop'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import AppRoutesProvider from './AppRoutesProvider'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { store } from './store'
import routes from './routes'
import theme from 'theme'

ReactDOM.render(
  <Provider store={store}>
    <AppRoutesProvider routes={routes}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </AppRoutesProvider>
  </Provider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
