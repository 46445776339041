import styled from 'styled-components';
import { getCss } from 'utils/cssHelpers';

const WrapCloudKitChenVendors: any = styled.div`
  & {
    .ant-table-wrapper {
      margin-bottom: 20px;
      .ant-table-title {
        ${getCss('background')('theme.global.whiteColor')};
        ${getCss('color')('theme.global.color4f4f4f')};
        font-size: 16px;
        font-weight: bold;
        padding-left: 0;
      }
      table {
        .ant-table-thead {
          .ant-table-cell {
            ${getCss('background')('theme.global.primaryColor')};
            ${getCss('color')('theme.global.whiteColor')};
          }
        }
        .ant-table-tbody {
          svg {
            height: 41px;
            width: 41px;
          }
          tr {
            cursor: pointer;
            height: 88px;
            font-weight: 500;
          }
          tr:nth-child(2n) .ant-table-cell {
            ${getCss('background')('theme.global.tertiaryColor')};
        }
      }
    }
    }
    .input-search-settlement {
      width: 300px;
    }
    .header-settlement {
      margin-bottom: 20px;
      font-size: 16px;
      font-weight: 600;
      ${getCss('color')('theme.global.grayColor')};
    }
  }
`;

WrapCloudKitChenVendors.Details = styled.div`
  & {
  .table-invoices {
    .ant-table-tbody {
      .ant-table-cell {
        padding-top: 32px;
        padding-bottom: 32px;
      }
    }
  }
  .group-title {
    label {
      font-weight: bold;
      ${getCss('color')('theme.global.color4f4f4f')};
    }
  }
  .table-invoices-breakdown {
    table {
      .ant-table-tbody {
        svg {
          height: 29px;
          width: 29px;
        }
        tr {
          height: unset;
        }
      }
    }
  }
  .btn-request-for-payment {
    width: 236px;
    height: 40px;
    margin-bottom: 30px;
  }
  .general-info {
    ${getCss('background')('theme.global.tertiaryColor')};
    width: 50%;
    .title-general-info {
      ${getCss('background')('theme.global.whiteColor')};
      font-size: 14px;
      font-weight: 500;
      padding-bottom: 6px;
    }
  }
`;

WrapCloudKitChenVendors.DetailsINV = styled.div`
  & {
    .view-next-7-days {
      font-size: 16px;
      font-weight: 600;
      ${getCss('color')('theme.global.grayColor')};
      span {
        svg {
          vertical-align: bottom;
        }
      }
    }
  }
`;

WrapCloudKitChenVendors.StyledSendPayment = styled.div`
  text-align: center;
  & {
    .content-request-for-payment {
      font-size: 16px;
      font-weight: normal;
      ${getCss('color')('theme.global.grayColor')};
      white-space: nowrap;
      margin-bottom: 13px;
    }
    .date-request-for-payment {
      font-size: 16px;
      font-weight: 600;
      ${getCss('color')('theme.global.grayColor')};
      margin-bottom: 30px;
    }
    .customer-btn-close {
      padding: 10px 50px;
      line-height: 0;
    }
  }
`;

export default WrapCloudKitChenVendors;
