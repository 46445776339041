import React, { useState, useEffect } from "react";
import { ProcessingOrdersWrapper } from "./styled";
import { FunnelSharpIcon } from "components/shared/Icons";
import Table from "components/shared/Table";
import Button from "components/shared/Button";
import DetailsRegionOrders from "./DetailsRegionOrders";
import orderService from "services/order.service";
import map from "lodash/map";
import find from "lodash/find";
import some from "lodash/some";
import Tag from "components/shared/Tag";
import Modal from "components/shared/Modal";
import CourierAssign from "./CourierAssign";
import couriersService from 'services/couriers.service';

const columns = [
  {
    title: "Organisation Name",
    dataIndex: "corpName",
    key: "corpName",
  },
  {
    title: "No. Of Locations",
    dataIndex: "numberOfLocations",
    key: "numberOfLocations",
  },
  {
    title: "Adds(Main)",
    dataIndex: "addMain",
    key: "addMain",
  },
  {
    title: "Adds(Sides)",
    dataIndex: "addSide",
    key: "addSide",
  },
  {
    title: (
      <>
        Kitchen <FunnelSharpIcon height="14px" />
      </>
    ),
    dataIndex: "kitchenName",
    key: "kitchenName",
  },
  {
    title: (
      <>
        Receipt No <FunnelSharpIcon height="14px" />
      </>
    ),
    dataIndex: "receiptNo",
    key: "receiptNo",
  },
];

const PackingAndCourier: React.FC = () => {
  const [isDetails, setIsDetails] = useState<boolean>(false);
  const [regions, setRegions] = useState<any[]>([]);
  const [couriers, setCouriers] = useState<any[]>([]);
  const [region, setRegion] = useState<object>({});
  const [isOpenCourierAssign, setIsOpenCourierAssign] = useState<boolean>(
    false
  );

  const handleClickView = (regionSelected: object) => {
    setIsDetails(true);
    setRegion(regionSelected);
  };

  const getCouriers = () => {
    couriersService.getAllCouriers().then((res) => {
      setCouriers(res);
    });
  };

  const getTodayOrdersGeneral = () => {
    orderService
      .getTodayOrdersGeneral()
      .then((res) => {
        const newRegions = map(res, (item) => {
          item.companies = map(item.companies, (company, index) => ({
            ...company,
            key: index,
          }));
          return item;
        });

        setRegions(newRegions);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getCouriers();
    getTodayOrdersGeneral();
  }, []);

  const openPopupCourierAssign = (regionSelected: object) => {
    setRegion(regionSelected);
    setIsOpenCourierAssign(true);
  };

  const updateAssignedCouriers = () => {
    getTodayOrdersGeneral();
    getCouriers();
    setIsOpenCourierAssign(false);
  }

  const renderCouriersAssignedTag = (region: any) => {
    const isAssigned = !!find(couriers, courier => {
      return some(courier.regions, item => item.name === region.regionName)
    })

    if(!isAssigned) {
      return ''
    }

    return <Tag type="primary" style={{ backgroundColor: `#fc6011` }}>
      Couriers Assigned
    </Tag>
  }

  const renderTableRegions = () => {
    if(!regions.length) {
      return 'No Data';
    }

    return map(regions, (region, index) => (
      <div key={index} className="region-table">
        <span
          className="courier-assign"
          onClick={() => openPopupCourierAssign(region)}
        >
          {renderCouriersAssignedTag(region)}
          Assign Courier In This Region
        </span>
        <Table
          title={() => `${region.regionName} Region Orders`}
          dataSource={region.companies}
          columns={columns}
          pagination={false}
        />
        <Button
          type="primary"
          className="btn-view-processing-orders"
          onClick={() => handleClickView(region)}
        >
          View
        </Button>
      </div>
    ));
  };

  return (
    <ProcessingOrdersWrapper.Packing>
      {!isDetails && (
        <div className="title-processing"> Region Orders Overview </div>
      )}

      {!isDetails && renderTableRegions()}

      {isDetails && (
        <DetailsRegionOrders
          region={region}
          clickBack={() => setIsDetails(false)}
        />
      )}

      <Modal
        visible={isOpenCourierAssign}
        onCancel={() => setIsOpenCourierAssign(false)}
        width={1100}
        bodyStyle={{ padding: 0 }}
        destroyOnClose
      >
        <CourierAssign
          regionInfo={region}
          updateAssignedCouriers={updateAssignedCouriers}
        />
      </Modal>
    </ProcessingOrdersWrapper.Packing>
  );
};

export default PackingAndCourier;
