import React from "react";
import Form from "components/shared/Form";
import Input from "components/shared/Input";
import Select from "components/shared/Select";
import Textarea from "components/shared/Textarea";
import Flex, { FlexItem } from "components/shared/Flex";
import GroupTitle from "components/shared/GroupTitle";

interface IPropsAccountDetails {
  isNewCustomer: boolean
  title: string
  industries: any[];
}

const AccountDetails: React.FC<IPropsAccountDetails> = ({
  isNewCustomer,
  title,
  industries
}) => {
  return (
    <>
      <GroupTitle fontSize={18} style={{ paddingBottom: '12px', marginLeft: '0' }}>{title}</GroupTitle>
      <Flex>
        <FlexItem basis='33.3%'>
          <Form.Item
            name="name"
            rules={[{ required: true, message: 'Please input your Company Name!' }]}
          >
            <Input placeholder="Company Name" />
          </Form.Item>
        </FlexItem>
        <FlexItem style={{ padding: '0 10px' }} basis='25%'>
          <Form.Item
            name="uenNumber"
            rules={[{ required: true, message: 'Please input your UEN Number!' }]}
          >
            <Input placeholder="UEN Number" />
          </Form.Item>
        </FlexItem>
        <FlexItem basis='33.3%'>
          <Form.Item
            name="email"
            rules={[{ required: true, type: "regexp",
            pattern: new RegExp("^[0-9]*$"), message: 'Please input your Company Email Address!' }]}
          >
            <Input placeholder="Company Email Address" />
          </Form.Item>
        </FlexItem>
      </Flex>
      <Flex css={`padding: 10px 0; width: 100%;`}>
        <FlexItem basis='33.3%'>
          <Form.Item
            name="phoneNumber"
            rules={[{ required: true, message: 'Please input your Company Direct Hotline!' }]}
          >
            <Input placeholder="Direct Hotline" />
          </Form.Item>
          {isNewCustomer
            ?
            <Form.Item
              name="industryId"
              rules={[{ required: true, message: 'Please input your Industry!' }]}
            >
              <Select
                  placeholder="Industry"
                  options={industries}
                />
            </Form.Item>
            :
            <Form.Item
              name="regionSelection"
              rules={[{ required: true, message: 'Please input your Region Selection!' }]}
            >
              <Select
                  placeholder="Region Selection"
                  options={[
                    { label: 'Jack', value: 'Jack', id: '1' },
                    { label: 'Lucy', value: 'Lucy', id: '2' },
                  ]}
                />
            </Form.Item>
          }
        </FlexItem>
        <FlexItem style={{ padding: '0 10px' }} basis='33.3%'>
          <Form.Item
            name="registeredAddress"
            rules={[{ required: true, message: 'Please input your Address!' }]}
          >
            <Textarea placeholder="Address" style={{ minHeight: '114px' }} />
          </Form.Item>
        </FlexItem>
        <FlexItem basis='25%'>
          <Form.Item
            name="postalCode"
            rules={[{ required: true, message: 'Please input your Postal Code!' }]}
          >
            <Input placeholder="Postal Code" />
          </Form.Item>
        </FlexItem>
      </Flex>
    </>
  );
}

export default AccountDetails;
