import styled from 'styled-components';

const StyledSelect = styled.div`
  .wrapper-select {
    margin-top: 10px;
    min-height: 40px;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    .ant-select-selector {
      min-height: 40px;
      padding: 5px !important;
      padding-left: 20px !important;
      &:hover, &:focus {
        color: ${props => props.theme.global.colord9d9d9} !important;
        border-color: ${props => props.theme.global.colord9d9d9} !important;
      }
    }
  }
`
export default StyledSelect;
