import styled from "styled-components";
import { getCss } from 'utils/cssHelpers';

const StyledAllAddOns: any = styled.div``;

StyledAllAddOns.AddOns = styled.div`
  & {
    .title-new-add-ons {
        padding: 38px 0 24px 0;
    }
    .label {
      font-size: 14px;
      font-weight: normal;
      line-height: 1.3;
      ${getCss('color')('theme.global.grayColor')};
    }
    .add-food-image {
      text-align: center;
      padding: 90px 40px;
      ${getCss('background-color')('theme.global.whiteColor')};
      .notes {
        padding-top: 10px;
        font-size: 14px;
        font-weight: normal;
        font-style: italic;
        ${getCss('color')('theme.global.grayColor')};
      }
    }
    .custom-btn-add-food {
      border-radius: 50px;
      line-height: 0;
      width: 200px;
      height: 40px;
    }
  }
`;

StyledAllAddOns.TableAddOns = styled.div`
  padding-top: 38px;
    & {
      .active-tabs {
        ${getCss('color')('theme.global.primaryColor')};
      }
      .custome-btn {
        border-radius: 50px;
        line-height: 0;
        width: 200px;
        height: 40px;
      }
      .wrapper-img {
        width: 70px;
        height: 70px;
        .custome-img {
          border: 2px solid ${props => props.theme.global.backColor};
          width: 69px;
          height: 69px;
          object-fit: cover;
        }
      }
      .ant-table-title {
        background-color: ${props => props.theme.table.headeBgColor} !important;
      }
      .ant-table-thead > tr > .ant-table-cell {
        ${getCss('color')('theme.global.whiteColor')};
        background-color: ${props => props.theme.global.primaryColor} !important;
      }
      .ant-table-tbody {
        tr.ant-table-row:hover > td {
          ${getCss('background-color')('theme.global.colorTransparent')} !important;
        }
        tr:nth-child(1),
        tr:nth-child(3),
        tr:nth-child(5),
        tr:nth-child(7),
        tr:nth-child(9) {
          background-color: ${props => props.theme.table.theadBgColor} !important;
        }
        .ant-table-cell {
          padding: 50px 17px !important;
          font-size: 14px;
          font-weight: 500;
        }
    }
`;

export default StyledAllAddOns;
