import React from 'react'
import Card from 'components/shared/Card'
import { NewOrdersInBulkProps } from './interfaces'

const NewOrdersInBulk: React.FC<NewOrdersInBulkProps> = ({ value }) => {
  return (
    <Card withGreenBorder title={<span style={{ fontWeight: "bold" }}>New Orders In Bulk</span>}>
      <span
        css={`
          font-size: 50px;
        `}
      >
        {value}
      </span>
    </Card>
  )
}

export default NewOrdersInBulk
