import React, { useCallback, useEffect, useState } from "react";
import GroupTitle from "components/shared/GroupTitle";
import Flex, { FlexItem } from "components/shared/Flex";
import {
  ArrowBackOutlineIcon,
  ArrowForwardIcon,
} from "components/shared/Icons";
import AddRessGird from "components/shared/AddRessGird";
import WrapCustomers from "./styled";
import { useHistory, useParams } from "react-router-dom";
import Table3 from "components/shared/Table";
import PaymentTerms from "components/shared/PaymentTerms";
import Theme from "theme";
import Button from "components/shared/Button";
import Modal from "components/shared/Modal";
import Input from "components/shared/Input";
import InputDatePicker from "components/shared/InputDatePicker";
import { Form } from "antd";
import { columnsInvoicesINV } from "./finance-customer.util";
import financePaymentService from "services/finance-payment.service";
import { formatCurrency } from "utils/common.util";
import userService from "services/user.service";

const PaymentsByCustomeDetailsINVSubmit: React.FC = () => {
  const { customerId, invId: invNo } =
    useParams<Record<string, string | undefined>>();

  const history = useHistory();
  const [form] = Form.useForm();
  const [companyInfo, setCompanyInfo] = useState([
    {
      id: 1,
      title: "Organisation Name",
      content: "",
    },
    {
      id: 2,
      title: "Main Contact Number",
      content: "",
    },
    {
      id: 3,
      title: "Email Address",
      content: "",
    },
    {
      id: 4,
      title: "Registered Address",
      content: "",
    },
  ]);

  const [invoice, setInvoice] =
    useState<{
      id: number;
      date: string;
      no: number;
      month: string;
      total: number;
      status: string;
      receips: {
        id: number;
        date: string;
        receipNo: number;
        totalQty: number;
        totalPrice: number;
      }[];
    }>();

  const [modalRequestForPayment, setModalRequestForPayment] =
    useState<boolean>(false);
  const [requestLoading, setRequestLoading] = useState(false);
  const [paidLoading, setPaidLoading] = useState(false);
  const [user, setUser] = useState<any>([]);

  useEffect(() => {
    userService.getMe().then((res) => {
      setUser(res);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchCustomerInfo = useCallback(async () => {
    const { customerInfo } = await financePaymentService.getDetailCustomer(
      +customerId!
    );
    setCompanyInfo([
      {
        id: 1,
        title: "Organisation Name",
        content: customerInfo.name,
      },
      {
        id: 2,
        title: "Main Contact Number",
        content: customerInfo.phoneNumber,
      },
      {
        id: 3,
        title: "Email Address",
        content: customerInfo.email,
      },
      {
        id: 4,
        title: "Registered Address",
        content: customerInfo.billingAddress,
      },
    ]);
  }, [customerId]);

  const fetchInvoice = useCallback(async () => {
    const data = invNo
      ? await financePaymentService.getDetailInvoice(+invNo)
      : null;
    setInvoice(data);
  }, [invNo]);

  const exportPDF = () => {};

  const notyfiPaymentMade = async () => {
    setPaidLoading(true);
    const isSuccess = await financePaymentService.updateInvoice(+invNo!, {
      status: "paid",
    });
    if (isSuccess) {
      setInvoice((prev) => ({
        ...prev!,
        status: "paid",
      }));
    }
    setPaidLoading(false);
  };

  const handleClickInvoice = (record: any) => {
    history.push(
      `/finance-payment/payments-by-customers-details-inv/${customerId}/${invNo}/receipt/${record.key}`
    );
  };

  const onFinish = async (values: any) => {
    if (requestLoading) {
      return;
    }
    const body = {
      email: values.email,
      dueDate: values.dueDate?.toISOString(),
    };
    setRequestLoading(true);
    const isSuccess = await financePaymentService.requestPayment(+invNo!, body);
    if (isSuccess) {
      closePaymentRequest();
      setInvoice({
        ...invoice!,
        status: "pending",
      });
    }
    setRequestLoading(false);
  };

  const closePaymentRequest = () => {
    setModalRequestForPayment(false);
  };

  useEffect(() => {
    fetchCustomerInfo();
  }, [fetchCustomerInfo]);

  useEffect(() => {
    fetchInvoice();
  }, [fetchInvoice]);

  return (
    <WrapCustomers.StyledPaymentsByCustomeDetailsCorporateINV>
      <Flex justifyBetween alignCenter>
        <FlexItem>
          <GroupTitle
            fontSize={16}
            icon={
              <ArrowBackOutlineIcon
                color={Theme.global.whiteColor}
                onClick={() => history.goBack()}
              />
            }
            padding={5}
            className="title-header"
          >
            INV {invNo}
          </GroupTitle>
        </FlexItem>
        <FlexItem style={{ cursor: "pointer" }} onClick={exportPDF}>
          {/* Export PDF <img src={downLoadIcon} alt="download" /> */}
          {user?.roles?.[0].id === "admin" && invoice && (
            <>
              {invoice.status !== "paid" ? (
                <Button
                  type={invoice?.status === "unpaid" ? "info" : "yellow"}
                  style={{
                    marginLeft: "25px",
                    padding: "20px 40px",
                    lineHeight: 0,
                  }}
                  onClick={() => setModalRequestForPayment(true)}
                >
                  {invoice?.status === "unpaid"
                    ? "Request For Payment"
                    : "Pending"}
                </Button>
              ) : (
                <Button
                  type="primary"
                  style={{
                    marginLeft: "25px",
                    padding: "20px 40px",
                    lineHeight: 0,
                  }}
                >
                  Paid
                </Button>
              )}
            </>
          )}
        </FlexItem>
      </Flex>
      <Flex
        style={{ padding: "28px 0 37px 0" }}
        css={`
          flex: 1;
        `}
      >
        <FlexItem basis="100%">
          <AddRessGird span={6} dataInfo={companyInfo} />
        </FlexItem>
      </Flex>
      <Flex
        css={`
          flex: 1;
        `}
      >
        <FlexItem basis="100%">
          <Table3
            title={() => "Order Number Breakdown For Main Menu"}
            dataSource={
              invoice
                ? invoice.receips.map((rec) => ({
                    key: rec.id.toString(),
                    Date: rec.date,
                    ReceiptNo: rec.receipNo,
                    TotalQTY: rec.totalQty,
                    Total: `$${formatCurrency(rec.totalPrice)}`,
                    Details: <ArrowForwardIcon />,
                  }))
                : []
            }
            columns={columnsInvoicesINV}
            pagination={false}
            onRow={(record: any) => {
              return {
                onClick: () => handleClickInvoice(record),
              };
            }}
          />
          <div className="footer">
            <p className="title-totalAll">Total</p>
            <div className="bg-price">
              ${formatCurrency(invoice?.total || 0)}
            </div>
            {!!invoice && (
              <PaymentTerms
                isShowSetPaid={user?.roles?.[0].id === "admin"}
                loading={paidLoading}
                title="Payment Terms"
                code="201436627Z001"
                onClick={notyfiPaymentMade}
                isPaid={invoice.status === "paid"}
              />
            )}
          </div>
        </FlexItem>
      </Flex>
      <Modal
        visible={modalRequestForPayment}
        onCancel={() => setModalRequestForPayment(false)}
      >
        <Flex
          css={`
            flex: 1;
          `}
          justifyStart
        >
          <WrapCustomers.StyleRequestForPayment>
            {invoice?.status === "unpaid" ? (
              <Form form={form} onFinish={onFinish}>
                <p className="content-request-for-payment">
                  Payment Request To Recipient
                </p>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      type: "email",
                      message: "The input is not valid E-mail!",
                    },
                  ]}
                >
                  <Input placeholder="Recipient Email" />
                </Form.Item>
                <div className="due-date">Due Date</div>
                <Form.Item
                  name="dueDate"
                  rules={[
                    { required: true, message: "Please input Due Date!" },
                  ]}
                >
                  <InputDatePicker
                    format="DD-MM-YYYY"
                    placeholder="DD-MM-YYYY"
                    className="style-date-picker"
                  />
                </Form.Item>
                <Button loading={requestLoading} type="info" htmlType="submit">
                  Send
                </Button>
              </Form>
            ) : (
              <Flex justifyCenter>
                <FlexItem basis="100%" style={{ textAlign: "center" }}>
                  <h3 className="request-payment-success">
                    Payment Has Been Requested
                  </h3>
                  <Button
                    type="info"
                    htmlType="submit"
                    onClick={closePaymentRequest}
                    className="customer-btn-Send"
                  >
                    Close
                  </Button>
                </FlexItem>
              </Flex>
            )}
          </WrapCustomers.StyleRequestForPayment>
        </Flex>
      </Modal>
    </WrapCustomers.StyledPaymentsByCustomeDetailsCorporateINV>
  );
};

export default PaymentsByCustomeDetailsINVSubmit;
