import styled from "styled-components";
import { getCss } from "utils/cssHelpers";

const LoginWrapper: any = styled.div`
  padding-top: 50px;
  ${getCss("background-color")("theme.global.whiteColor")};
  & {
    .title-login {
      font-size: 28px;
      ${getCss("color")("theme.global.color444444")};
      padding-bottom: 20px;
      text-align: center;
    }
    .customer-input,
    .customer-btn {
      width: 480px;
      height: 50px;
    }
    .ant-input,
    .ant-select {
      box-shadow: none !important;
    }
  }
`;

LoginWrapper.Image = styled.img``;

LoginWrapper.ForgetPassword = styled.div`
  text-align: center;
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
`;

export default LoginWrapper;
