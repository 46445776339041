import React from "react";
import Table from 'components/shared/Table';
import { columnsDetailCloudKitchen } from "../finance-CloudKitchenVendors.util";

export interface IPropsCloudKitchen {
  listCloudKitchen?: any[];
}

const CloudKitchen: React.FC<IPropsCloudKitchen> = ({
  listCloudKitchen
}) => {
  return (
    <Table
      dataSource={listCloudKitchen}
      columns={columnsDetailCloudKitchen}
      pagination={false}
      className="table-settlement"
    />
  )
}

export default CloudKitchen;
