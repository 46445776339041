import React from 'react'
import { SwitchProps, Route, Redirect, Switch } from 'react-router-dom'
import includes from 'lodash/includes'
import userService from "services/user.service"

export interface IRoute {
  path?: string
  exact?: boolean
  redirect?: string
  component?: React.FC | string
  routes?: IRoute[]
  key?: any
  strict?: boolean
  wrappers?: any[]
  meta?: any
  [k: string]: any
}

interface IOpts {
  routes: IRoute[]
  extraProps?: object
  rootRoutes?: IRoute[]
}

interface IGetRouteElementOpts {
  route: IRoute
  index: number
  opts: IOpts
}

function render({ route, opts, props }: { route: IRoute; opts: IOpts; props: any }) {
  const routes = renderRoutes(
    {
      ...opts,
      routes: route.routes || [],
      rootRoutes: opts.rootRoutes,
    },
    { location: props.location }
  )
  let { component: Component, wrappers } = route
  
  // *** Private Route ***
  const user = userService.getUser();
  const userRole = user.roles?.[0].id
  const userId = user.userId

  // Check User does NOT have ACCESS
  if(route.key && !includes(route.roles, userRole)) {
    if(userRole === 'cloud-kitchen') {
      return <Redirect to={'/manage-orders/new'}  />
    }

    if(userRole === 'corporate') {
      return <Redirect to={`/manage-customers/customer-details/${userId}`}  />
    }
  }
  // *** End Private Route ***

  if (Component) {
    const newProps = {
      ...props,
      ...opts.extraProps,
      route,
      routes: opts.rootRoutes,
    }

    let ret = <Component {...newProps}>{routes}</Component>

    if (wrappers) {
      let len = wrappers.length - 1
      while (len >= 0) {
        ret = React.createElement(wrappers[len], newProps, ret)
        len -= 1
      }
    }

    return ret
  } else {
    return routes
  }
}

function getRouteElement({ route, index, opts }: IGetRouteElementOpts) {
  const routeProps = {
    key: route.key || index,
    exact: route.exact,
    strict: route.strict,
    path: route.path,
  }
  if (route.redirect) {
    return <Redirect {...routeProps} from={route.path} to={route.redirect} />
  } else {
    return (
      <Route
        {...routeProps}
        render={(props: object) => {
          return render({ route, opts, props })
        }}
      />
    )
  }
}

export default function renderRoutes(opts: IOpts, switchProps: SwitchProps = {}) {
  return opts.routes ? (
    <Switch {...switchProps}>
      {opts.routes.map((route, index) =>
        getRouteElement({
          route,
          index,
          opts: {
            ...opts,
            rootRoutes: opts.rootRoutes || opts.routes,
          },
        })
      )}
    </Switch>
  ) : null
}
