import styled from 'styled-components';
import { getCss } from 'utils/cssHelpers';

const WrapCustomers: any = styled.div`
  & {
  .ant-table-wrapper {
    margin: 20px 0;
    .ant-table-title {
      ${getCss('background')('theme.global.whiteColor')};
      ${getCss('color')('theme.global.color4f4f4f')};
      font-size: 16px;
      font-weight: bold;
      padding-left: 0;
    }
    table {
      .ant-table-thead {
        .ant-table-cell {
          ${getCss('background')('theme.global.primaryColor')};
          ${getCss('color')('theme.global.whiteColor')};
        }
      }
      .ant-table-tbody {
        svg {
          height: 41px;
          width: 41px;
        }
        tr {
          cursor: pointer;
          height: 88px;
          font-weight: 500;
        }
        tr:nth-child(2n) .ant-table-cell {
          ${getCss('background')('theme.global.tertiaryColor')};
      }
    }
  }
  }
  .input-search-settlement {
    width: 300px;
  }
  .header-settlement {
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 600;
    ${getCss('color')('theme.global.grayColor')};
  }
  }
`;

WrapCustomers.Details = styled.div`
  .table-invoices {
    .ant-table-tbody {
      .ant-table-cell {
        padding-top: 32px;
        padding-bottom: 32px;
      }
    }
  }
  .group-title {
    label {
      font-weight: bold;
      ${getCss('color')('theme.global.color4f4f4f')};
    }
  }
  .table-invoices-breakdown {
    table {
      .ant-table-tbody {
        svg {
          height: 29px;
          width: 29px;
        }
        tr {
          height: unset;
        }
      }
    }
  }
  .btn-request-for-payment {
    width: 236px;
    height: 40px;
  }
  .view-next-7-days {
    font-size: 16px;
    font-weight: 600;
    ${getCss('color')('theme.global.grayColor')};
    span {
      svg {
        vertical-align: bottom;
      }
    }
  }
  .general-info {
    ${getCss('background')('theme.global.tertiaryColor')};
    width: 50%;
    .title-general-info {
      ${getCss('background')('theme.global.whiteColor')};
      font-size: 14px;
      font-weight: 500;
      padding-bottom: 6px;
    }
  }
  .ant-dropdown-trigger {
    ${getCss('background')('theme.button.yellow.bgColor')};
    padding: 10px 50px;
    color: ${props => props.theme.global.grayColor} !important;
    font-size: 14px;
    font-weight: normal;
    span {
      vertical-align: text-top;
      color: ${props => props.theme.global.grayColor} !important;
      position: relative;
      right: -40px;
    }
  }
`;

WrapCustomers.StyleRequestForPayment = styled.div`
  width: 100%;
  & {
    .content-request-for-payment, .request-payment-success {
      font-size: 16px;
      font-weight: 600;
      ${getCss('color')('theme.global.grayColor')};
      white-space: nowrap;
      margin-bottom: 10px;
    }
    .date-request-for-payment {
      font-size: 16px;
      font-weight: 600;
      ${getCss('color')('theme.global.grayColor')};
      margin-bottom: 30px;
    }
    .customer-btn-close {
      padding: 10px 50px;
      line-height: 0;
    }
    .style-date-picker {
      display: block;
      width: 40%;
      line-height: 30px;
    }
    .due-date {
      font-size: 16px;
      font-weight: 600;
      ${getCss('color')('theme.global.grayColor')};
      white-space: nowrap;
    }
    .customer-btn-Send {
      padding: 20px 70px;
      line-height: 0;
    }
  }
`;

WrapCustomers.StyledPaymentsByCustomeDetailsCorporateINV = styled.div`
  .title-header {
    font-size: 16px;
    font-weight: bold;
  }
  .footer {
    width: 100%;
    text-align: end;
    .title-totalAll {
      max-width: 30%;
      margin-left: auto;
      text-align: center;
    }
    .bg-price {
      ${getCss('color')('theme.global.color4f4f4f')};
      ${getCss('background-color')('theme.global.tertiaryColor')};
      font-size: 14px;
      font-weight: 500;
      max-width: 30%;
      margin-left: auto;
      text-align: center;
      padding: 22px 150px;
    }
  }
`;

export default WrapCustomers;
