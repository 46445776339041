import React, { useState } from "react";
import Wrapper from "./styled";
import Flex, { FlexItem } from "components/shared/Flex";
import Button from "components/shared/Button";
import Modal from "components/shared/Modal";
import { Calendar } from 'react-multi-date-picker';
import { Toastify } from 'components/shared/Toast/Toast';
import moment from 'moment';
export interface IPropsPopup {
  clickGoBtn: (dateFrom: any, dateTo: any) => any;
}

const CustomDate: React.FC<IPropsPopup> = ({ clickGoBtn }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [toDate, setToDate] = useState<any>(null);

  const handleCustomDays = () => {
    clickGoBtn(toDate?.[0], toDate?.[1]);
  };

  const changeRangeOfDate = (date: any) => {
    const dateStart = date?.[0] ? new Date(date?.[0])?.toISOString() : "";
    const dateEnd = date?.[1] ? new Date(date?.[1])?.toISOString() : "";
    if (dateEnd === "") {
      Toastify.showDangerToast('select date end !');
    } else {
     setToDate([dateStart, dateEnd]);
    }
  }

  return (
    <Wrapper.CustomDate>
      <FlexItem>
        <span onClick={() => setIsModalVisible(true)}>
          Custom: {toDate?.[0] ? moment(toDate?.[0]).format("DD-MM-YYYY") : "dd-mm-yyyy"} to {toDate?.[1] ? moment(toDate?.[1]).format("DD-MM-YYYY") : "dd-mm-yyyy"}
        </span>
        <Button
          type="danger"
          className="custome-btn-go"
          disabled={!toDate?.[0] || !toDate?.[1]}
          onClick={handleCustomDays}
        >
          Go
        </Button>
      </FlexItem>

      <Modal visible={isModalVisible} onCancel={() => setIsModalVisible(false)}>
        <Flex justifyCenter>
          <FlexItem basis="100%">
            <Wrapper.StyledRangeOfDates>
              <h3 className="title-range-date">Select Range Of Dates </h3>
              <Calendar
                value={toDate}
                showOtherDays
                range
                onChange={(date: any) => changeRangeOfDate(date)}
              />
            </Wrapper.StyledRangeOfDates>
          </FlexItem>
        </Flex>
        <Flex justifyCenter>
          <Button type="info" onClick={() => setIsModalVisible(false)}>
            Cancel
          </Button>
          <Button onClick={() => setIsModalVisible(false)} style={{ marginLeft: "15px" }}>
            OK
          </Button>
        </Flex>
      </Modal>
    </Wrapper.CustomDate>
  );
};

export default CustomDate;
