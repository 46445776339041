import styled from 'styled-components'
import { getCss } from 'utils/cssHelpers';

export const CompletedOrdersWrapper: any = styled.div`
`
CompletedOrdersWrapper.CompletedOrders = styled.div`
  .header-export-pdf {
    color: #1aae48;
    svg {
      color: #1aae48;
      fill: #1aae48;
    }
  }
  .group-title-orders {
    padding: 7px 14px;
    ${getCss('background')('theme.global.tertiaryColor')};
    margin-bottom: 10px;
    label {
      ${getCss('color')('theme.global.color444444')};
    }
  }
  .title-details-receipt {
    font-size: 18px;
  }
  .ant-table {
    table {
      .ant-table-tbody tr {
        ${getCss('background')('theme.table2.bodyBgColor')};
        cursor: pointer;
      }
    }
  }
  .general-info {
    ${getCss('background')('theme.global.tertiaryColor')};
    width: 50%;
    .title-general-info {
      background: #f0f2f5;
      font-size: 14px;
      font-weight: 500;
      padding-bottom: 6px;
    }
  }
  .text-underline {
    text-decoration: underline;
  }
  .label-complete-order {
    width: 180px;
    height: 39px;
    display: block;
    margin-left: auto;
    font-size: 17px;
    color: white;
    text-align: center;
    line-height: 39px;
  }
`