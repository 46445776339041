import styled from 'styled-components'
import { getCss } from 'utils/cssHelpers'
import Pagination from './Pagination'

const StyledPagination = styled(Pagination)`
  text-align: center;
  padding: 10px 0;
  .ant-pagination-item {
    border-radius: 50%;
    a {
      ${getCss('color')('theme.global.whiteColor')};
    }
    ${getCss('background-color')('theme.global.primaryColor')};
    &:hover {
      ${getCss('border-color')('theme.global.primaryColor')};
    }
  }
  .ant-pagination-item-active {
    ${getCss('color')('theme.global.color878787')};
    ${getCss('background-color')('theme.table.theadBgColor')};
    ${getCss('border-color')('theme.table.borderColor !important')};
    a {
      ${getCss('color')('theme.global.color444444')};
    }
  }
  .ant-pagination-item-link {
    border-radius: 50%;
  }
`

export default StyledPagination