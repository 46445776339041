import React, { useCallback, useState } from "react";
import { useHistory } from "react-router";
import {
  ArrowBackOutlineIcon,
  ArrowForwardIcon,
} from "components/shared/Icons";
import WrapCustomers from "./styled";
import Table from "components/shared/Table";
import Button from "components/shared/Button";
import GroupTitle from "components/shared/GroupTitle";
import Tag from "components/shared/Tag";
import { useParams } from "react-router-dom";
import Theme from "theme";
import { columnsInvoicesINV, columnsOrderINV } from "./finance-customer.util";
import financePaymentService from "services/finance-payment.service";
import { useEffect } from "react";
import { formatCurrency } from "utils/common.util";

const PaymentsByCustomerDetailsINV: React.FC = () => {
  const history = useHistory();
  const { customerId, invId: invNo } =
    useParams<Record<string, string | undefined>>();
  const [invoice, setInvoice] =
    useState<{
      id: number;
      date: string;
      no: number;
      month: string;
      total: number;
      status: string;
      receips: {
        id: number;
        date: string;
        receipNo: number;
        totalQty: number;
        totalPrice: number;
      }[];
    }>();

  const handleClickToBack = () => {
    history.push(
      `/finance-payment/payments-by-customers-details/${customerId}`
    );
  };

  const requestForPayment = (record: any) => {
    history.push(
      `/finance-payment/payments-by-customers-details-inv/${customerId}/${invNo}/submit`
    );
  };

  const handleClickInvoice = (record: any) => {
    history.push(
      `/finance-payment/payments-by-customers-details-inv/${customerId}/${invNo}/receipt/${record.ReceiptNo}`
    );
  };

  const fetchInvoice = useCallback(async () => {
    const data = invNo
      ? await financePaymentService.getDetailInvoice(+invNo)
      : null;
    setInvoice(data);
  }, [invNo]);

  useEffect(() => {
    fetchInvoice();
  }, [fetchInvoice]);

  return (
    <WrapCustomers>
      <WrapCustomers.Details>
        <GroupTitle
          fontSize={16}
          icon={
            <ArrowBackOutlineIcon
              color={Theme.global.whiteColor}
              onClick={handleClickToBack}
            />
          }
          padding={5}
          className="group-title header-settlement"
        >
          INV {invNo}
        </GroupTitle>
        <Table
          dataSource={
            invoice
              ? [
                  {
                    key: invoice.id,
                    Date: invoice.date,
                    INVNo: `INV${invoice.no}`,
                    ForTheMonth: invoice.month,
                    Total: `$${formatCurrency(invoice.total)}`,
                    Status: (
                      <Tag type="yellow">{invoice.status.toUpperCase()}</Tag>
                    ),
                  },
                ]
              : []
          }
          columns={columnsOrderINV}
          pagination={false}
          className="table-settlement"
          onRow={() => {
            return {
              onClick: requestForPayment,
            };
          }}
        />
        <Button
          type="info"
          className="btn-request-for-payment"
          onClick={requestForPayment}
        >
          Make payment
        </Button>
        <Table
          title={() => "Order Number Breakdown For Main Menu"}
          dataSource={
            invoice
              ? invoice.receips.map((rec) => ({
                  key: rec.id.toString(),
                  Date: rec.date,
                  ReceiptNo: rec.receipNo,
                  TotalQTY: rec.totalQty,
                  Total: `$${formatCurrency(rec.totalPrice)}`,
                  Details: <ArrowForwardIcon />,
                }))
              : []
          }
          columns={columnsInvoicesINV}
          pagination={false}
          className="table-invoices-breakdown"
          onRow={(record, rowIndex) => {
            return {
              onClick: () => handleClickInvoice(record),
            };
          }}
        />
      </WrapCustomers.Details>
    </WrapCustomers>
  );
};

export default PaymentsByCustomerDetailsINV;
