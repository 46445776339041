import styled from "styled-components";
import { getCss } from 'utils/cssHelpers';

const WrapMarketingForm: any = styled.div`
& {
  .customer-textarea {
    margin: 20px 0 0 0;
    height: 230px !important;
  }
  .customer-btn {
    width: 140px;
    height: 30px;
    margin-top: 20px;
    ${getCss('background')('theme.global.orangeColor')};
    ${getCss('border-color')('theme.global.orangeColor')};
  }
  .wrap-action {
    padding-top: 28px;
    max-width: 200px;
    text-align: center;
    p {
      font-size: 12px;
      font-weight: normal;
      line-height: 1.3;
      padding: 10px 0 0 0;
    ${getCss('color')('theme.global.grayColor')};
    }
    h4 {
      font-size: 14px;
      font-weight: normal;
      line-height: 1.3;
      font-style: italic;
    ${getCss('color')('theme.global.grayColor')};
    }
    .ddl-upload {
      .ant-upload {
        border-radius: 0 !important;
        padding: 8px 30px;
      }
    }
    .customer-input-date {
      width: 185px;
      height: 40px;
      .ant-picker-input input {
        background: ${props => props.theme.global.whiteColor} !important;
        color: ${props => props.theme.global.grayColor} !important;
        font-style: italic;
        font-size: 14px;
        &::placeholder {
          color: ${props => props.theme.global.grayColor} !important;
          font-style: italic;
          font-size: 14px;
          font-weight: normal;
        }
      }
    }
  }
}
`;

export default WrapMarketingForm;