import { FunnelSharpIcon } from 'components/shared/Icons';
import Tag, { TagProps } from 'components/shared/Tag';
export interface IPropsCustomers {
  id: number;
  name: string;
  poc: string;
  contact: string;
  email:string;
  billingAddress: string;
  Details: any;
}
export interface IPropsCustomersDetail {
  id: number;
  date: string;
  invoiceNo: string;
  total: number;
  status: string;
}

const renderTagStatus = (status: string) => {
  var type: TagProps['type'] = 'primary';
  switch (status) {
    case 'paid':
      type = 'primary';
      break;
    case 'unpaid':
      type = 'danger';
      break;
    default:
      break;
  }

  return <Tag type={type}>{status}</Tag>
};

const columns = [
  {
    title: <>Customer Name <FunnelSharpIcon height="14px"/></>,
    dataIndex: 'CustomerName',
    key: 'CustomerName',
  },
  {
    title: <>POC <FunnelSharpIcon height="14px"/></>,
    dataIndex: 'POC',
    key: 'POC',
  },
  {
    title: <>Contact <FunnelSharpIcon height="14px"/></>,
    dataIndex: 'Contact',
    key: 'Contact',
  },
  {
    title: <>Email <FunnelSharpIcon height="14px"/></>,
    dataIndex: 'Email',
    key: 'Email',
  },
  {
    title: <>Billing Address <FunnelSharpIcon height="14px"/></>,
    dataIndex: 'BillingAddress',
    key: 'BillingAddress',
  },
  {
    title: '',
    dataIndex: 'Details',
    key: 'Details',
  },
];

const columnsCompany = [
  {
    title: <>Customer Name <FunnelSharpIcon height="14px"/></>,
    dataIndex: 'CustomerName',
    key: 'CustomerName',
  },
  {
    title: <>POC <FunnelSharpIcon height="14px"/></>,
    dataIndex: 'POC',
    key: 'POC',
  },
  {
    title: <>Contact <FunnelSharpIcon height="14px"/></>,
    dataIndex: 'Contact',
    key: 'Contact',
  },
  {
    title: <>Email <FunnelSharpIcon height="14px"/></>,
    dataIndex: 'Email',
    key: 'Email',
  },
  {
    title: <>Billing Address <FunnelSharpIcon height="14px"/></>,
    dataIndex: 'BillingAddress',
    key: 'BillingAddress',
  },
]

const columnsInvoices = [
  {
    title: 'Date',
    dataIndex: 'Date',
    key: 'Date',
  },
  {
    title: <>INV NO. <FunnelSharpIcon height="14px"/></>,
    dataIndex: 'NoNumberINV',
    key: 'NoNumberINV',
  },
  {
    title: <>For The Month <FunnelSharpIcon height="14px"/></>,
    dataIndex: 'ForTheMonth',
    key: 'ForTheMonth',
  },
  {
    title: 'Total ($)',
    dataIndex: 'Total',
    key: 'Total',
  },
  {
    title: 'Status',
    dataIndex: 'Status',
    key: 'Status',
  },
];

const collumsCorporateINV = [
  {
    key: 'date',
    title: "Date",
    dataIndex: 'date',
  },
  {
    key: 'orderNo',
    title: "Order No.",
    dataIndex: 'orderNo',
  },
  {
    key: 'totalQTY',
    title: "Total QTY",
    dataIndex: 'totalQTY',
  },
  {
    key: 'total',
    title: "Total ($)",
    dataIndex: 'total',
  }
]

const columnsInvoicesINV = [
  {
    title: 'Date',
    dataIndex: 'Date',
    key: 'Date',
  },
  {
    title: <>Receipt No. <FunnelSharpIcon height="14px"/></>,
    dataIndex: 'ReceiptNo',
    key: 'ReceiptNo',
  },
  {
    title: 'Total QTY',
    dataIndex: 'TotalQTY',
    key: 'TotalQTY',
  },
  {
    title: 'Total ($)',
    dataIndex: 'Total',
    key: 'Total',
  },
  {
    title: null,
    dataIndex: 'Details',
    key: 'Details',
  },
];

const columnsOrderINV = [
  {
    title: 'Date',
    dataIndex: 'Date',
    key: 'Date',
  },
  {
    title: <>INV No. <FunnelSharpIcon height="14px"/></>,
    dataIndex: 'INVNo',
    key: 'INVNo',
  },
  {
    title: <>For The Month <FunnelSharpIcon height="14px"/></>,
    dataIndex: 'ForTheMonth',
    key: 'ForTheMonth',
  },
  {
    title: 'Total ($)',
    dataIndex: 'Total',
    key: 'Total',
  },
  {
    title: 'Status',
    dataIndex: 'Status',
    key: 'Status',
  },
];

export {
  columns,
  columnsCompany,
  columnsInvoices,
  collumsCorporateINV,
  columnsInvoicesINV,
  columnsOrderINV,
  renderTagStatus,
};