import { BaseAPI } from "core/services/BaseAPI";

export interface IDashboardService {
  getDashboard: () => {};
  getStoreDashboard: () => {};
}

class DashboardService extends BaseAPI implements DashboardService {
  getDashboard(param: string) {
    return this.get(`dashboard?${param}`);
  }

  getStoreDashboard() {
    return this.get(`dashboard/orderCompanies`);
  }
}

export default new DashboardService();
