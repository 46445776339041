import styled from 'styled-components'
import { getCss } from 'utils/cssHelpers';

const SettingsWrapper = styled.div`
  width: 100%;
  & {
    .customer-input {
      width: 300px;
    }
    .customer-btn {
      padding: 20px 65px;
      line-height: 0;
    }
    .wrap-user-id,
    .sub-text-input {
      font-size: 16px;
      font-weight: normal;
      ${getCss("color")("theme.global.color444444")};
    }
    .wrap-btn-upload {
      width: 140px;
    }
    .ant-upload {
      padding: 5px 15px !important;
      border-radius: 5px !important;
      background-color: ${(props) => props.theme.global.orangeColor} !important;
      border-color: ${(props) => props.theme.global.orangeColor} !important;
    }
    .ant-upload-list.ant-upload-list-picture {
      position: relative;
      .ant-upload-list-picture-container {
        left: -75px;
        position: absolute;
        bottom: 0;
        .ant-upload-list-item-thumbnail {
          height: 40px;
          img {
            width: 40px;
            height: 40px;
          }
        }
      }

      .ant-upload-list-item {
        padding: 0;
        height: 40px;
        border: none;
        .ant-upload-list-item-name {display: none }
      }
    }
    .ant-btn {
      border-radius: 5px !important;
      background-color: ${(props) => props.theme.global.orangeColor} !important;
      border-color: ${(props) => props.theme.global.orangeColor} !important;
    }
    .ant-btn span svg {
      vertical-align: middle;
      margin-right: 5px;
    }
    .ant-upload-list-item-card-actions-btn {
      background-color: ${(props) =>
        props.theme.global.colorTransparent} !important;
      border-color: ${(props) =>
        props.theme.global.colorTransparent} !important;
    }
    .ant-card-head-title {
      font-size: 16px !important;
    }
    .ant-card-body {
      background-color: ${(props) =>
        props.theme.global.colorF6fcb5} !important;
    }
  }
`;

export default SettingsWrapper;
