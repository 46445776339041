import styled from "styled-components";
import DropdownComponent from "./Dropdown";

const StyleDropdown = styled(DropdownComponent)`
  color: ${props => props.theme.global.color43425d} !important;
  & {
    .anticon-down {
      padding-left: 10px;
    }
  }
`;

export default StyleDropdown;
