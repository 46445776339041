import styled from 'styled-components'
import OrdersForLocation from './OrdersForLocation'
import { getCss } from 'utils/cssHelpers'

const StyledOrderForLocation:any = styled(OrdersForLocation)`
    margin-top: 10px;
    padding-bottom: 23px;
    ${getCss('color')('theme.global.color444444')};
    font-weight: 500;
    .header-order-location {
        ${getCss('background')('theme.global.tertiaryColor')};
        font-size: 14px;
    }
    .ant-col {
        padding: 7px 15px;
    }
    .delivery-location {
        justify-content: center;
        align-items: center;
        width: 20%;
        border-bottom: 1px solid;
        ${getCss('border-color')('theme.global.tertiaryColor')};
        padding: 10px;
        text-align: center;
    }
    .exportPDF {
        ${getCss('color')('theme.global.primaryColor')};
        cursor: pointer;
        font-weight: 500;
    }
    .summary {
        font-size: 14px;
        font-weight: 500;
        ${getCss('color')('theme.global.color444444')};
        width: 544px;
        text-align: center;
        margin-top: 36px;
        margin-bottom: 49px;
        margin-left: auto;
    }
`

StyledOrderForLocation.WrapLocation = styled.div`
    ${getCss('background')('theme.global.whiteColor')};
    padding: 11px;
`;

StyledOrderForLocation.AddOns = styled.div`
    border-bottom: 1px solid;
    border-right: 1px solid;
    ${getCss('border-color')('theme.global.tertiaryColor')};
    padding: 10px 0;
`;

StyledOrderForLocation.BillQTY = styled.div`
    border-right: 1px solid;
    border-bottom:1px solid;
    ${getCss('border-color')('theme.global.tertiaryColor')};
    padding: 10px 0;
`;

StyledOrderForLocation.AddsDetails = styled.div`
    font-size: 13px;
`;

StyledOrderForLocation.TitleSummary = styled.div`
    margin-bottom: 6px;
`;

StyledOrderForLocation.ValSummary = styled.div`
    ${getCss('background')('theme.global.tertiaryColor')};
    padding: 22px;
`;

export default StyledOrderForLocation