
import Flex, { FlexItem } from "components/shared/Flex";
import { CloseSharpIcon } from "components/shared/Icons";
import Button from "components/shared/Button";
import map from 'lodash/map';

const filterAndSearchFood = (foods: any,filterTags: any,inputSearch: any) => {
    const result = foods
      .filter((item: any) => { // Filter by Category or Type
        // No tags
        if (filterTags.length === 0) {
          return true;
        }

        return filterTags
          ?.map((tag: string) => tag.toLowerCase())
          ?.some((tag: string) => {
            return (
              item?.MenuCategory?.toLowerCase()?.includes(tag) ||
              item?.FoodTypeSearch?.toLowerCase()?.includes(tag)
            );
          });
      })
      .filter((item: any) => { // Search combine with above Filter
        // InputSearch is empty
        if (!inputSearch) {
          return true;
        }

        return (
          item?.Name?.toLowerCase()?.includes(inputSearch) ||
          item?.FoodTypeSearch?.toLowerCase()?.includes(inputSearch) ||
          item?.MenuCategory?.toLowerCase()?.includes(inputSearch)
        );
      });

      return result
}

const renderTags = (filterTags: any, removeTag: any) => (
  <Flex alignCenter>
    {map(filterTags, (tag, index) => (
      <FlexItem key={index}>
        <Button className="btn-category" type="primary">
          {tag}
        </Button>
        <CloseSharpIcon
          className="icon-remove-category"
          onClick={() => removeTag(tag)}
        />
      </FlexItem>
    ))}
  </Flex>
);

export {filterAndSearchFood, renderTags}