import moment from "moment";
const csv = require("csvtojson");

const durationKeys = {
  "7days": "7",
  "14days": "14",
  "30days": "30",
};

const getDurationTimeParam = (duration: number) => {
  const start = moment().subtract(duration, "days");
  const end = moment();

  const startString = start.toISOString();
  const endString = end.toISOString();

  return `from=${encodeURIComponent(startString)}&to=${encodeURIComponent(
    endString
  )}`;
};

const formatCurrency = (number: number = 0) =>
  number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");

export { getDurationTimeParam, durationKeys, formatCurrency };

export async function convertToJson<O = Record<string, any>>(
  csvStr: string
): Promise<O[]> {
  const json: Record<string, any>[] = await csv({}).fromString(csvStr);
  let colsName: Record<string, string>;

  let rows = json.map((row: Record<string, any>) => {
    if (!colsName) {
      colsName = Object.keys(row).reduce(
        (_colsName: Record<string, any>, col: string) => {
          _colsName[col] = col.trim();
          return _colsName;
        },
        {}
      );
    }

    return Object.keys(row).reduce((obj: Record<string, any>, col) => {
      obj[colsName[col]] = row[col] ?? row[col].trim();
      return obj;
    }, {}) as O;
  });

  if (rows[0]) {
    const headings = Object.keys(rows[0]);
    rows = rows.filter((row: Record<string, any>) => {
      return headings.some((heading) => row[heading] != null);
    });
  }

  return rows;
}
