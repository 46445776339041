import {BaseAPI} from "../core/services/BaseAPI";
import {User} from "shared/user";
export interface ICouriersServices {
  getAllCouriers(): void;

  postCourier(data: Object): Promise<any>;

  editCourier(id: number, data: Object): Promise<User>;

  getDetailsCourier(id: number): any;

  deleteDetailsCourier(id: number): any;

  assignRegionToCourier(userId: number | string, regionId: number | string): any;

  removeRegionToCourier(userId: number | string, regionId: number | string): any;
}
class CouriersService extends BaseAPI implements ICouriersServices {

  getAllCouriers() {
    return this.get(`manage-couriers/couriers`, undefined);
  }

  getDetailsCourier(id: number) {
    return this.get(`manage-couriers/couriers/${id}/details`, undefined);
  }

  editCourier(id: number, data: any) {
    data = Object.keys(data).filter(key => ![null, undefined, ''].includes(data[key])).reduce(function (res: any, key) {
      res[key] = data[key];
      return res;
    }, {});
    return this.patch(`manage-couriers/couriers/${id}`, data, undefined);
  }

  postCourier(data: Object) {
    return this.post(`manage-couriers/couriers`, data, undefined);
  }

  deleteDetailsCourier(id: number) {
    return this.delete(`manage-couriers/couriers/${id}`, undefined);
  }

  getDetailOrder(id: number) {
    return this.get(`manage-couriers/couriers/${id}/orders`);
  }

  assignRegionToCourier(userId: number, regionId: number) {
    return this.post(`manage-couriers/couriers/${userId}/region/${regionId}`);
  }

  removeRegionToCourier(userId: number, regionId: number) {
    return this.delete(`manage-couriers/couriers/${userId}/region/${regionId}`);
  }
}

export default new CouriersService();