import React, { useEffect, useState } from "react";
import OverviewWrapper, { Img } from "./styled";
import Flex, { FlexItem } from "components/shared/Flex";
import Button from "components/shared/Button";
import Card from "components/shared/Card";
import { Tabs, TabPane } from "components/shared/Tabs";
import { useHistory } from "react-router-dom";
import marketingService from "services/marketing.service";
import moment from "moment";
import config from "config";

const OverviewUI: React.FC = () => {
  const history = useHistory();
  const tabsList: Array<string> = ["All", "News", "Archived"];
  const [allNews, setAllNews] = useState<Array<any>>([]);
  const [active, setActive] = useState<Array<any>>([]);
  const [expired, setExpired] = useState<Array<any>>([]);

  const overView = () => {
    marketingService.getAllNews().then((result) => {
      setAllNews(result);
    });
    marketingService.getActiveNews().then((result) => {
      setActive(result);
    });
    marketingService.getExpiredNews().then((result) => {
      setExpired(result);
    });
  };

  useEffect(() => {
    overView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active.length || allNews.length || expired.length]);

  const getUrl = (url: any) => {
    if (url.startsWith("http")) {
      return url;
    }
    return new URL(config.baseApiURL).origin + url;
  };

  const editCoupons = async (id: number) => {
    await history.push({
      pathname: `/marketing/coupons/${id}`,
      state: { id: id },
    });
  };

  const _handleAllCard = () => {
    return (
      <>
        {allNews?.map((item: any, index: number) => (
          <OverviewWrapper.WrapAllCard
            onClick={() => editCoupons(item.id)}
            key={index}
          >
            <Img
              src={getUrl(item.banner)}
              alt="card-image"
              className="styled-card-img"
            />
            <h3 className="title-card">{item.title}</h3>
            <p className="date-card">
              Until {moment(item.expiry).format("DD-MM-YYYY")}
            </p>
          </OverviewWrapper.WrapAllCard>
        ))}
      </>
    );
  };

  const _handleNewsCard = () => {
    return (
      <>
        {active?.map((item: any, index: number) => (
          <OverviewWrapper.WrapAllCard
            onClick={() => editCoupons(item.id)}
            key={index}
          >
            <Img
              src={getUrl(item.banner)}
              alt="card-image"
              className="styled-card-img"
            />
            <h3 className="title-card">{item.title}</h3>
            <p className="date-card">
              Until {moment(item.expiry).format("DD-MM-YYYY")}
            </p>
          </OverviewWrapper.WrapAllCard>
        ))}
      </>
    );
  };

  const _handleArchivedCard = () => {
    return (
      <>
        {expired?.map((item: any, index: number) => (
          <OverviewWrapper.WrapAllCard
            onClick={() => editCoupons(item.id)}
            key={index}
          >
            <Img
              src={getUrl(item.banner)}
              alt="card-image"
              className="styled-card-img"
            />
            <h3 className="title-card">{item.title}</h3>
            <p className="date-card">
              Until {moment(item.expiry).format("DD-MM-YYYY")}
            </p>
          </OverviewWrapper.WrapAllCard>
        ))}
      </>
    );
  };

  const _handleSwichtTabs: any = (e: any) => {
    if (e === 0) {
      return _handleAllCard();
    } else if (e === 1) {
      return _handleNewsCard();
    } else {
      return _handleArchivedCard();
    }
  };

  const _handleClickOverview = () => {
    history.push("/marketing/overview");
  };

  const _handleClickAddNews = () => {
    history.push("/marketing/promotions");
  };

  return (
    <OverviewWrapper>
      <Flex>
        <FlexItem>
          <Button
            type="primary"
            className="size-btn"
            onClick={_handleClickOverview}
          >
            Overview
          </Button>
          <Button
            type="danger"
            className="customer-btn size-btn"
            onClick={_handleClickAddNews}
          >
            Add News
          </Button>
        </FlexItem>
      </Flex>
      <Card title="Overview" style={{ marginTop: "30px" }}>
        <Flex
          css={`
            flex: 1;
          `}
        >
          <div className="wrap-total-ongoing-news">
            <span>Total Ongoing News</span>
            <h1>{active.length}</h1>
          </div>
        </Flex>
      </Card>
      <Flex
        css={`
          flex: 1;
        `}
        style={{ paddingTop: "15px" }}
      >
        <Tabs defaultActiveKey="0">
          {tabsList.map((item, index) => {
            return (
              <TabPane tab={item} key={index}>
                {_handleSwichtTabs(index)}
              </TabPane>
            );
          })}
        </Tabs>
      </Flex>
    </OverviewWrapper>
  );
};

export default OverviewUI;
