import styled from 'styled-components'

const ReportsWrapper = styled.div`
    width: 100%;
  & {
    .ant-card-body {
      background-color: #f0f2f5
    }
  }
`;

export default ReportsWrapper;
