import { createSlice,  PayloadAction } from '@reduxjs/toolkit';
import initialState from './state';

const { reducer, actions } = createSlice({
  name: 'manage-menu',
  initialState,
  reducers: {
    getMainIdState(state, { payload }: PayloadAction<any>) {
      state.mainIdState = [
        ...payload
      ]
    },
    getFruitIdState(state, { payload }: PayloadAction<any>) {
      state.fruitIdState = [
        ...payload
      ]
    }
  }
});

export const { getMainIdState, getFruitIdState } = actions;

export default reducer;
