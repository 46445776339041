import React from "react";
import WrapPaymentTerms from "./styled";
import bankQR from "assets/images/bank-qr.png";
import Button from "components/shared/Button";
export interface IPropsPaymentTerms {
  title: string;
  code: string;
  onClick: (e: any) => any;
  loading?: boolean;
  isPaid?: boolean;
  isShowSetPaid?: boolean;
}

const PaymentTerms: React.FC<IPropsPaymentTerms> = ({
  isShowSetPaid,
  title,
  code,
  onClick,
  loading,
  isPaid = false,
}) => {
  return (
    <WrapPaymentTerms>
      {isPaid ? (
        <>
          <h3 className="terms">{title}</h3>
          <p className="content">Invoice was paid</p>
        </>
      ) : (
        <>
          <h3 className="terms">{title}</h3>
          <p className="content">
            Please make payment to <br />
            {code} or PayNow via
          </p>
          <img
            src={bankQR}
            width={230}
            height={230}
            alt="qr"
            style={{ margin: "20px 0" }}
          />
          {isShowSetPaid && (
            <Button
              loading={loading}
              onClick={onClick}
              type="info"
              className="customer-btn"
            >
              Set Payment Received
            </Button>
          )}
        </>
      )}
    </WrapPaymentTerms>
  );
};

export default PaymentTerms;
