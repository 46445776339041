import React, { useEffect, useState } from "react";
import DetailsCustomerWrapper from "./styled";
import Flex from "components/shared/Flex";
import Button from "components/shared/Button";
import Modal from "components/shared/Modal";
import { Form, DatePicker, Input, Switch, Select } from "antd";
import commonService from "services/common.service";
import moment from "moment";
import customersService from "services/customers.service";
import { Toastify } from "components/shared/Toast/Toast";

type TypeModalAddLocationProps = {
  userId: number;
  isAddLocation: boolean;
  setIsAddLocation: (status: boolean) => void;
  isSubmit: boolean;
  setIsSubmit: (submit: boolean) => void;
  locationSelected: any
};

const ModalAddLocation: React.FC<TypeModalAddLocationProps> = ({
  userId,
  isAddLocation,
  setIsAddLocation,
  setIsSubmit,
  locationSelected,
  ...props
}) => {
  const [form] = Form.useForm();
  const [regions, setRegions] = useState<Array<any>>([]);
  const isEdit = !!locationSelected.id;

  useEffect(() => {
    commonService.getRegions().then((res) => {
      const listRegions = res.map((data: any) => {
        return {
          value: data.id,
          label: data.name,
        };
      });
      setRegions(listRegions);
    });
  }, []);

  const onFinish = (values: any) => {
    const formatData = {
      ...values,
      dateFrom: values.dateFrom.toISOString(),
      dateTo: values.dateTo.toISOString(),
      capacity: Number(values.capacity),
    };

    if (isEdit) {
      customersService
        .editLocationOfUser(userId, locationSelected.id, formatData)
        .then((res) => {
          if (res) {
            form.resetFields();
            setIsAddLocation(false);
            setIsSubmit(true);
            Toastify.showSuccessToast("Edit success!");
          }
        });
    } else {
      customersService.addLocationOfUser(userId, formatData).then((res) => {
        if (res) {
          form.resetFields();
          setIsAddLocation(false);
          setIsSubmit(true);
          Toastify.showSuccessToast("Create success!");
        }
      });
    }
  };

  useEffect(() => {
    if (isEdit && isAddLocation) {
      form.setFields([
        {
          name: ["name"],
          value: locationSelected.name,
        },
        {
          name: ["address"],
          value: locationSelected.address,
        },
        {
          name: ["capacity"],
          value: locationSelected.capacity,
        },
        {
          name: ["dateFrom"],
          value: moment(locationSelected.dateFrom),
        },
        {
          name: ["dateTo"],
          value: moment(locationSelected.dateTo),
        },
        {
          name: ["regionId"],
          value: locationSelected.region?.id,
        },
        {
          name: ["isMainLocation"],
          value: locationSelected.isMainLocation,
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAddLocation]);
  
  const handleClosePopup = () => {
    form.resetFields();
    setIsAddLocation(false);
    setIsSubmit(false);
  }

  return (
    <Modal
      visible={isAddLocation}
      onCancel={handleClosePopup}
      width="900px"
      style={{ padding: "20px 0" }}
    >
      <DetailsCustomerWrapper.Modal>
        <div className="wrap-heading" style={{ marginBottom: 16 }}>
          <div className="title-adjustment">{isEdit ? 'Edit' : 'Add New'} Location</div>
        </div>
        <Flex justifyCenter full>
          <Form
            form={form}
            onFinish={onFinish}
            size="large"
            style={{ width: "50%" }}
          >
            <Form.Item
              name="name"
              rules={[{ required: true, message: "Please input Name!" }]}
            >
              <Input bordered placeholder="Name" />
            </Form.Item>
            <Form.Item
              name="address"
              rules={[{ required: true, message: "Please input Address!" }]}
            >
              <Input bordered placeholder="Address" />
            </Form.Item>
            <Form.Item
              name="capacity"
              rules={[{ required: true, message: "Please input Capacity!" }]}
            >
              <Input type="number" bordered placeholder="Capacity" />
            </Form.Item>
            <Form.Item
              name="dateFrom"
              rules={[{ required: true, message: "Please input Date From!" }]}
            >
              <DatePicker
                bordered
                style={{ width: "100%" }}
                placeholder="Date From"
                format="DD-MM-YYYY"
              />
            </Form.Item>
            <Form.Item
              name="dateTo"
              rules={[{ required: true, message: "Please input Date To!" }]}
            >
              <DatePicker
                bordered
                style={{ width: "100%" }}
                placeholder="Date To"
                format="DD-MM-YYYY"
              />
            </Form.Item>
            <Form.Item
              name="regionId"
              rules={[{ required: true, message: "Please select Region!" }]}
            >
              <Select
                options={regions}
                placeholder="Region"
                style={{ textAlign: "left" }}
              />
            </Form.Item>
            <Form.Item
              name="isMainLocation"
              label="Main Location"
              valuePropName="checked"
              initialValue={false}
            >
              <Switch />
            </Form.Item>
            <Button
              type="info"
              className="btn-modal-adjustment"
              htmlType="submit"
            >
              {isEdit ? 'Edit' : 'Add'}
            </Button>
          </Form>
        </Flex>
      </DetailsCustomerWrapper.Modal>
    </Modal>
  );
};

export default ModalAddLocation;
