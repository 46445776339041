import React, { useState } from "react";
import { Input as AntInput, InputProps as AntInputProps } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import StyledInput from "./styled";
import { StyleNotes } from "./styled";

export type InputProps = {
  notes?: string;
  error?: string;
  label?: string;
  isVisible?: boolean
} & AntInputProps;

const Input: React.FC<InputProps> = ({ label, notes, error, isVisible = false, ...props }) => {
  const [visible, setVisible] = useState<boolean>(false);

  const showPassword = (e: boolean) => {
    if (e) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  }

  return (
    <>
      <StyledInput className="wrap-ddl-input">
        {label && (
          <div className="dd-input-label" style={{ marginBottom: "5px" }}>
            {label}
          </div>
        )}
        <AntInput
          className={"ddl-input"}
          type={`${isVisible ? visible ? "text" : "password" : null}`}
          prefix={
            isVisible
            ? visible
                ? <EyeTwoTone onClick={() => showPassword(false)} className="ddl-input-visible" />
                : <EyeInvisibleOutlined onClick={() => showPassword(true)} className="ddl-input-visible" />
            : null}
          {...props}
        />
      </StyledInput>
      <StyleNotes>
        {notes && <div className="dd-input-notes">{notes}</div>}
        {error && <div className="dd-input-error">{error}</div>}
      </StyleNotes>
    </>
  );
};

export default Input;
