import styled from 'styled-components'
import { getCss } from 'utils/cssHelpers'
import is from 'typescript-styled-is'
import Card from './Card'

const StyledCard = styled(Card)`
  .ant-card-head {
    ${getCss('background')('theme.global.primaryColor')};
    ${getCss('color')('theme.global.whiteColor')};
    .ant-card-head-wrapper {
      padding: 11px 0;
    }
    .ant-card-head-title {
      font-size: 24px;
      padding: 0;
    }
    .ant-card-extra {
      ${getCss('color')('theme.global.whiteColor')};
      padding: 0;
    }
  }
  ${is("withGreenBorder")`
    border: 1px solid ${props => props.theme.global.primaryColor};
    border-radius: unset;
    .ant-card-body {
      padding: 0 24px;
    }
    .ant-card-head {
      background: ${props => props.theme.global.whiteColor};
      color: ${props => props.theme.global.primaryColor};
      border-bottom: none;
      .ant-card-head-title {
        font-size: 14px;
      }
    }
  `};

  ${is('hideBorderLeft')`
    border-left: none;
  `};
  
  ${is('hideBorderRight')`
    border-right: none;
  `};
  
  ${is('hideBorderTop')`
    border-top: none;
  `};

  ${is('hideBorderBottom')`
    border-bottom: none;
  `};
`

export default StyledCard