import React, { useEffect, useState } from "react";
import GroupTitle from "components/shared/GroupTitle";
import Flex, { FlexItem } from "components/shared/Flex";
import { ArrowBackOutlineIcon } from "components/shared/Icons";
import StyledFood from "./styled";
import { useHistory } from "react-router-dom";
import { Form } from "antd";
import menuService from "services/menu.service";
import { Toastify } from "components/shared/Toast/Toast";
import FormFoods from "./FormFoods";
import Theme from "theme";

const AddFoods: React.FC = (props) => {
  const history: any = useHistory();
  const foodId = history?.location?.state.key;
  const [form] = Form.useForm();
  const [resultTypeIds, setResultTypeIds] = useState<any>([]);

  const getDetailFoods = (foodId: number) => {
    menuService.getDetailFoods(foodId).then((detailFoods) => {
      if (detailFoods.types.length) {
        setResultTypeIds(detailFoods.types);
      } else {
        setResultTypeIds([{ id: undefined }]);
      }
      form.setFields([
        {
          name: ["name"],
          value: detailFoods.name,
        },
        {
          name: ["photo"],
          value: detailFoods.photo,
        },
        {
          name: ["menuId"],
          value: detailFoods?.menu?.name,
        },
        {
          name: ["calories"],
          value: detailFoods.calories,
        },
        {
          name: ["price"],
          value: detailFoods.price,
        },
        {
          name: ["isPublished"],
          value: detailFoods.isPublished,
        },
      ]);
    });
  };

  useEffect(() => {
    getDetailFoods(foodId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [foodId]);

  const editFoods = (body: any) => {
    menuService
      .editFoods(foodId, body)
      .then(() => {
        Toastify.showSuccessToast("Edit foods success !");
        history.goBack();
      })
      .catch((error) => {
        Toastify.showDangerToast(error?.response?.data?.messages?.[0]);
      });
  };

  const _handleBackAllFood = () => {
    history.goBack();
  };

  return (
    <StyledFood.AddFood>
      <Flex full>
        <FlexItem>
          <GroupTitle
            fontSize={16}
            icon={
              <ArrowBackOutlineIcon
                color={Theme.global.whiteColor}
                onClick={_handleBackAllFood}
              />
            }
            padding={5}
          >
            Edit Food
          </GroupTitle>
        </FlexItem>
      </Flex>
      <Flex>
        <Form name="basic" form={form}>
          <Form.Item>
            <FormFoods
              isCheck={false}
              submitForm={editFoods}
              form={form}
              resultTypeIds={resultTypeIds}
              setResultTypeIds={setResultTypeIds}
            />
          </Form.Item>
        </Form>
      </Flex>
    </StyledFood.AddFood>
  );
};

export default AddFoods;
