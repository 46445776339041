import { FunnelSharpIcon, ImageSharpIcon } from 'components/shared/Icons';
import RenderImgFood from 'components/shared/RenderImgFood';

const renderCollums = (title: any) => {
  return <>{title} <FunnelSharpIcon  height="14px" /></>;
}

// Table Foods
const collumsFoods = [
  {
    render: (url: any) => {
      return <RenderImgFood src={url} alt={url} />
    },
    title: <ImageSharpIcon width='32px' height='24px' />,
    dataIndex: 'image',
    key: 'image',
  },
  {
    title: renderCollums('Food ID'),
    dataIndex: 'food',
    key: 'food',
  },
  {
    title: renderCollums('Name'),
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: renderCollums('Menu Category'),
    dataIndex: 'category',
    key: 'category',
  },
  {
    title: renderCollums('Food Type'),
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: renderCollums('Calories'),
    dataIndex: 'calories',
    key: 'calories',
  },
  {
    title: renderCollums('Price'),
    dataIndex: 'price',
    key: 'price',
  },
]

// Table Foods

export { collumsFoods };
