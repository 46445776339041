import styled from "styled-components";
import { getCss } from 'utils/cssHelpers';

const StyledUpload = styled.div`
  .ddl-upload {
    .ant-upload {
      ${getCss('background')('theme.global.primaryColor')};
      ${getCss('color')('theme.global.whiteColor')};
      cursor: pointer;
      font-size: 13px;
      font-weight: 700;
      border-radius: 50px;
      padding: 8px 31px;
      &:hover, &:focus {
        border: 0 !important;
      }
    }
  }
`;

export default StyledUpload;
