import styled from "styled-components";
import { getCss } from "utils/cssHelpers";

const ForgotPasswordWrapper: any = styled.div`
  padding-top: 50px;
  ${getCss("background-color")("theme.global.whiteColor")};
  & {
    .title-login {
      font-size: 28px;
      ${getCss("color")("theme.global.color444444")};
      padding-bottom: 20px;
      text-align: center;
    }
    .customer-input,
    .customer-btn {
      width: 480px;
      height: 50px;
    }
    .ant-input,
    .ant-select {
      box-shadow: none !important;
    }
  }
`;

ForgotPasswordWrapper.Image = styled.img``;

ForgotPasswordWrapper.Back = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0 10px 20px;
  cursor: pointer;

  > span:last-child {
    line-height: 34px;
    margin-top: -6px;
    margin-left: 5px;
  }
`;
ForgotPasswordWrapper.Heading = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

ForgotPasswordWrapper.Description = styled.p`
  text-align: center;
  font-size: 12px;
  margin-bottom: 20px;
  max-width: 470px;
`;

export default ForgotPasswordWrapper;
