import React from 'react'
import { Pagination as AntPagination, PaginationProps as AntPaginationProps } from 'antd'

export interface PaginationProps extends AntPaginationProps {}

const Pagination: React.FC<PaginationProps> = (props) => {
  return (
    <AntPagination className='ddl-pagination' { ...props } />
  )
}

export default Pagination