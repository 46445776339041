import React from "react";
import Table from "components/shared/Table";
import Button from "components/shared/Button";
import Flex, { FlexItem } from "components/shared/Flex";
import { columns } from "../dashboard.config";
import { useHistory } from "react-router";
import map from "lodash/map";

const PayInSettlementSend: React.FC<{ dashboard: any }> = ({ dashboard }) => {
  const history = useHistory();

  const payInSettlement = map(dashboard.sendPayInSettlement, (item, index) => ({
    ...item,
    key: index,
  }));

  const manageVendorPayments = () => {
    history.push("/finance-payment/cloud-kitchen-vendors");
  };

  return (
    <FlexItem
      css={`
        flex: 1;
        margin-right: 0.5rem;
      `}
    >
      <Table
        title={() => "Pay In Settlement (Send)"}
        dataSource={payInSettlement}
        columns={columns}
        pagination={false}
        footer={() => (
          <Flex justifyEnd>
            <Button type="link" onClick={manageVendorPayments}>
              Manage Vendor Payments
            </Button>
          </Flex>
        )}
      />
    </FlexItem>
  );
};

export default PayInSettlementSend;
