import styled from "styled-components";
import { getCss } from "utils/cssHelpers";

const WrapAddRessGird = styled.div`
  .title-address {
    font-size: 14px;
    font-weight: 500;
    ${getCss("color")("theme.global.color444444")};
  }
  .content-address {
    margin: 0;
    ${getCss("color")("theme.global.color444444")};
    font-size: 12px;
    font-weight: 500;
  }
`;

export default WrapAddRessGird;
