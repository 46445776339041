import styled from "styled-components";

const WrapCustomersForm: any = styled.div`
  max-width: 100%;
  .styled-create-account {
    padding-bottom: 19px;
  }
`;

WrapCustomersForm.Generate = styled.h3`
  padding: 12px 0 29px 0;
  font-size: 12px;
  cursor: pointer;
  text-decoration: underline;
  font-family: Montserrat;
  color: ${props => props.theme.menu.textColor};
`;

export default WrapCustomersForm;
