import React, { useState, useEffect } from "react";
import Card from "components/shared/Card";
import Flex from "components/shared/Flex";
import Button from "components/shared/Button";
import { YourStoreTodayProps } from "./interfaces";
import NewOrdersInBulk from "./NewOrdersInBulk";
import CompanyOrder from "./CompanyOrder";
import { useHistory } from "react-router";
import dashboardService from 'services/dashboard.service';

const YourStoreTodayUI: React.FC<YourStoreTodayProps<any>> = ({
  newOrders = [],
}) => {
  const history = useHistory();
  const [storeToday, setStoreToday] = useState<Array<any>>([]);

  const listStoreDashboard = () => {
    dashboardService.getStoreDashboard().then((result) => {
      setStoreToday(result);
    });
  }

  useEffect(() => {
    listStoreDashboard();
  }, []);

  const manageAllOrders = () => {
    history.push("/manage-orders/new");
  };

  return (
    <Card
      css={`
        flex: 1;
      `}
      title="Your Store Today"
    >
      <Flex column>
        <NewOrdersInBulk value={storeToday.length} />
        {storeToday.map((order, index) => (
          <CompanyOrder key={index} orderDetails={order} />
        ))}
      </Flex>
      <Flex justifyEnd>
        <Button
          css={`
            padding-right: 0;
            margin-top: 1rem;
          `}
          type="link"
          onClick={manageAllOrders}
        >
          Manage all orders
        </Button>
      </Flex>
    </Card>
  );
};

export default YourStoreTodayUI;
