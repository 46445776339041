import React from 'react'

type hook = (...args: any[]) => any

type hooksMapObject = Record<string, hook>

class ReactHookOutside {
  private _hooks: hooksMapObject = {}
  private _temp: Record<string, ReturnType<hook>> = {}

  constructor() {
    this.setHook = this.setHook.bind(this)
    this.getHook = this.getHook.bind(this)
  }

  public setHook(name: string, hook: hook): ReactHookOutside {
    if(!this._hooks[name]) {
      this._hooks[name] = hook
    }
    return this
  }

  private putHook(name: string, result: ReturnType<hook>) {
    if(!this._temp[name]) {
      this._temp[name] = result
    }
  }

  public getHook<T = any>(name: string): T | undefined {
    return this._temp[name] ? this._temp[name] : undefined
  }

  public component() {
    const EmptyComponent = () => {
      for (const [k, v] of Object.entries(this._hooks)) {
        this.putHook(k, v())
      }
      return <React.Fragment />
    }
    return EmptyComponent
  }
}

const instance = new ReactHookOutside()

const ReactHookOutsideWrapper = instance.component()

export const setHook = instance.setHook

export const getHook = instance.getHook

export default ReactHookOutsideWrapper



