import React, { useEffect } from "react";
import { Form } from "antd";
import { Toastify } from "components/shared/Toast/Toast";
import FormFoodType from "./FormFoodType";
import menuService from 'services/menu.service';

export interface IpropsEditFoodType {
  listFoodType: any;
  idFoodType: any
  checkSubmit: (isSubmit: boolean) => any
}

const EditFoodType: React.FC<IpropsEditFoodType> = ({
  listFoodType,
  idFoodType,
  checkSubmit
}) => {
  const [form] = Form.useForm();

  const detailFoodType = (idFoodType: number) => {
    menuService.getDetailFoodType(idFoodType)
    .then((res) => {
      form.setFields([
        {
          name: ["name"],
          value: res.name,
        },
        {
          name: ["color"],
          value: res.color,
        },
      ]);
    })
  }

  useEffect(() => {
    detailFoodType(idFoodType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idFoodType]);

  const editFoodType = (body: any) => {
    menuService.editFoodType(idFoodType, body).then(() => {
      Toastify.showSuccessToast("Edit success!");
      checkSubmit(true);
      listFoodType();
    })
    .catch((err) => {
      Toastify.showDangerToast(err?.response?.data?.messages?.[0])
    })
  }

  return (
    <Form
      name="basic"
      form={form}
    >
      <FormFoodType
        isCheck={false}
        submitForm={editFoodType}
        form={form}
      />
    </Form>
  )
}

export default EditFoodType;
