import { User } from 'shared/user'

export interface ICurrentUserState {
  currentUser: User | null
  loading: boolean
  error: any
}

const initialCurrentlUserState: ICurrentUserState = {
  currentUser: null,
  loading: false,
  error: null
}

export default initialCurrentlUserState