import React from 'react'
import { Layout } from 'antd'

const { Sider: AntSider } = Layout

interface SiderProps extends React.HTMLAttributes<HTMLDivElement> {}

const Sider: React.FC<SiderProps> = (props) => {
  return (
      <AntSider className='ddl-menu-sider' {...props} />
  )
}

export default Sider
