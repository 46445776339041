import React from 'react';
import { Dropdown as AntDropdown } from 'antd';
import { Link } from "react-router-dom";
interface IPropsDropdown {
  menu: any;
  content: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  trigger?: any;
  icon?: any;
  href?: string;
}

const DropdownComponent: React.FC<IPropsDropdown> = ({
  content,
  trigger,
  menu,
  icon,
  ...rest
}) => {
  return (
    <AntDropdown overlay={menu} trigger={trigger} className='ddl-dropdown' {...rest}>
      <Link to='#'>{content}{icon}</Link>
    </AntDropdown>
  );
}

export default DropdownComponent;
