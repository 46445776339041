import styled from "styled-components";
import { getCss } from 'utils/cssHelpers';

const StyledFoodType: any = styled.div`
  padding-top: 38px;
`;

StyledFoodType.Form = styled.div`
  & {
    .styled-title-type-food {
      padding-bottom: 15px;
    }
    .label-top {
      font-size: 14px;
      font-style: italic;
      ${getCss('color')('theme.global.grayColor')};
      margin-top: 12px;
    }
    .custome-btn {
      text-align: center;
      border-radius: 50px;
      margin-top: 20px;
      width: 200px;
      height: 40px;
    }
    .github-picker {
      width: 100% !important;
      background: unset !important;
      box-shadow: unset !important;
      border: 0 !important;
      padding: 5px 0 0 0 !important;
      div:not(span > div) {
        display: none !important;
      } 
      span > div {
        width: 36px !important;
        height: 36px !important;
      }
    }
  }
`;

StyledFoodType.Table = styled.div`
  & {
    .ant-table-title {
      background-color: ${props => props.theme.table.headeBgColor} !important;
      span {
        padding-left: 15px !important;
      }
    }
    .ant-table-thead {
      display: none !important;
    }
    .ant-table-row {
      .ant-table-cell {
        padding: 0 !important;
      }
      .ant-table-cell:nth-child(1) {
        display: none !important;
      }
    }
    .ant-table-tbody > tr.ant-table-row:hover > td {
      background: unset !important;
    }
    .wrap-action {
      width: 100%;
      text-align: end;
      cursor: pointer;
      padding: 10px;
      .name-foods-type {
        font-size: 16px;
        font-weight: 600;
        line-height: 1.3;
        margin: 0;
        ${getCss('color')('theme.global.whiteColor')};
      }
      .action-foods-type {
        font-size: 10px;
        font-weight: normal;
        line-height: 1.3;
        ${getCss('color')('theme.global.whiteColor')};
      }
    }
  }
`;

export default StyledFoodType;
